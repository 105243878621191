import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import ToggleModalSkeleton from '../../components/Skeletons/ToggleModalSkeleton';

const ToggleUserActive = ({ toggleUserModal, loading, deleteUserData, handleToggleModalClose, handleUserToggle }) => {
	return (
		<Modal show={toggleUserModal}>
			<ModalHeading heading={`${deleteUserData.active ? 'Deactivate' : 'Activate'} ${deleteUserData.name} Account`} />
			{loading ? (
				<ToggleModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						{deleteUserData.active ? (
							<p className='primaryText'>
								Deactivating will restrict {deleteUserData.name} to log in to account.<br /> Are you sure you want to
								proceed?
							</p>
						) : (
							<p className='primaryText'>
								Activating will allow {deleteUserData.name} to log in to account.<br /> Are you sure you want to
								proceed?
							</p>
						)}
					</InputsSection>
					<ModalButtons
						submitButtonLabel={`${deleteUserData.active ? 'Deactivate User' : 'Activate User'}`}
						onSubmit={(e) => handleUserToggle(e, deleteUserData._id, deleteUserData.active)}
						cancelButtonLabel='Cancel'
						onCancel={handleToggleModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default ToggleUserActive;
