import React, { useEffect, useState } from 'react';
import SkeletonStyles from './Skeletons.module.css';

const RecentFileUploadsSkeleton = ({ items = 9 }) => {
  const [fileUploads, setFileUploads] = useState([]);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < items; i++) {
      data.push(i);
    }
    setFileUploads(data);
  }, [items]);

  return (
    fileUploads &&
    fileUploads.length > 0 &&
    fileUploads.map((upload) => (
      <div
        key={upload}
        className={[SkeletonStyles.fileUpload, SkeletonStyles.skeleton].join(
          ' '
        )}
      />
    ))
  );
};

export default RecentFileUploadsSkeleton;
