import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import Draggable from 'react-draggable';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import classes from '../screens/SignPDF/SIgnPDF.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewerComponent = ({ document, signatureImage, pageNumber, setPageNumber, setSignaturePosition }) => {
  const [pdf, setPdf] = useState();
  const [numPages, setNumPages] = useState();
  const [currentPageDimensions, setCurrentPageDimensions] = React.useState({ height: 0, width: 0 });

  function onDocumentLoadSuccess(pdf) {
    setPdf(pdf);
    setNumPages(pdf.numPages);
  }

  const handleRenderSuccess = async pageData => {
    const height = pageData.height;
    const width = pageData.width;
    setCurrentPageDimensions({ height, width });
  };

  const handlePrevious = () => {
    setPageNumber(prevVal => (prevVal > 1 ? prevVal - 1 : prevVal));
  };
  const handleNext = () => {
    setPageNumber(prevVal => (prevVal < numPages ? prevVal + 1 : prevVal));
  };

  return (
    <div className={classes.pdfContainer}>
      {/* <div
        className={classes.signatureImage_box}
        style={{ zIndex: 1001, backgroundColor: 'red', height: currentPageDimensions.height, width: currentPageDimensions.width }}
      ></div> */}
      <div className={classes.signatureImage_box} style={{ height: currentPageDimensions.height, width: currentPageDimensions.width }}>
        {signatureImage && (
          <Draggable
            defaultPosition={{ x: 0, y: 0 }}
            onStop={(e, dragElement) => {
              const dim = e.target.style.transform.replace('translate(', '').replace(/px/g, '').replace(')', '').split(', ');
              setSignaturePosition({ x: Math.abs(dim[0]), y: Math.abs(dim[1]) });
              // console.log({ x: dim[0], y: dim[1] });
              console.log(e, dragElement);
            }}
          >
            <img src={signatureImage} alt='sign' className={classes.generated_image} />
          </Draggable>
        )}
      </div>
      <Document
        file={document}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classes.pdf}
        // style={{ height: currentPageDimensions.height + 'px', width: currentPageDimensions.width + 'px' }}
      >
        <Page onRenderSuccess={handleRenderSuccess} pageNumber={pageNumber} />
      </Document>
      <div className={classes.pdfContainer_bottom}>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <div>
          <button onClick={handlePrevious} disabled={pageNumber <= 1}>
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-chevron-left`} />
            </svg>
          </button>
          <button onClick={handleNext} disabled={pageNumber >= numPages}>
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-chevron-right`} />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PdfViewerComponent;
