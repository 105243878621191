import React, { useState } from 'react';
import Style from './GroupAddUserInput.module.css'

const GroupAddUserInput = ({inputValue, setInputValue, handleInputChange}) => {

    const inputWidth = inputValue.length * 10 + 160;

    return (
        <div style={{maxWidth: '80%'}}>
            <input
                autoFocus={true}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className={Style.input}
                style={{ width: inputWidth }}
                placeholder='Enter group name'
            />
        </div>
    )
}

export default GroupAddUserInput
