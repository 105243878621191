import React from 'react';
import './Table.css';

const Table = ({ thead, tbody, style }) => {
	return (
		<div className='tableContainer' style={style}>
			<table className='table'>
				<thead>{thead}</thead>
				<tbody>{tbody}</tbody>
			</table>
		</div>
	);
};

export default Table;
