import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteUserModal = ({ deleteUserModal, loading, handleDeleteUserModalClose, handleDeleteUser, data }) => {
  const { deleteEmail, setDeleteEmail, deleteUserData } = data;

  return (
    <Modal show={deleteUserModal}>
      <ModalHeading heading={`Delete ${deleteUserData.name}`} />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection style={{ width: '34rem' }}>
            <InputBox
              label={`Enter ${deleteUserData.email} to delete this user`}
              placeholder={deleteUserData.email}
              value={deleteEmail.value}
              onChange={event => {
                setDeleteEmail({ value: event.target.value, error: '' });
              }}
              errorMessage={deleteEmail.error}
              style={{ width: '30rem' }}
            />
            <p className='dangerText'>Are you sure? Please note that this will delete the entire data associated with this user and cannot be restored.</p>
          </InputsSection>
          <InputsSection style={{ width: '34rem' }}>
            <p className='primaryText'>If you want to restrict this user from logging in, we recommend you change their active status instead of deleting.</p>
          </InputsSection>
          <ModalButtons submitButtonLabel='Delete User' onSubmit={handleDeleteUser} cancelButtonLabel='Cancel' onCancel={handleDeleteUserModalClose} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default DeleteUserModal;
