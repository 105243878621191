import React from 'react';
import TextBox from './components/TextBox';
import SelectBox from './components/SelectBox';
import DateInput from './components/DateInput';
import classes from './Logs.module.css';
import Tags from './components/Tags';

const Filters = ({ searchKey, setSearchKey, dateRange, setDateRange, selectedRange, setSlectedRange, selectedTags, setSelectedTags, setPageNumber }) => {
  // const [selectedRange, setSlectedRange] = React.useState('all');

  React.useEffect(() => {
    const today = new Date(Date.now());
    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);

    let toDate = new Date();
    toDate.setHours(0, 0, 0, 0);

    switch (selectedRange) {
      case 'all':
        setDateRange({ startDate: '', endDate: '' });
        break;
      case '30Days':
        fromDate.setDate(today.getDate() - 29);
        toDate.setDate(today.getDate() + 1);
        setDateRange({ startDate: fromDate.toISOString().split('T')[0], endDate: toDate.toISOString().split('T')[0] });
        setPageNumber(1);
        break;
      case '7Days':
        fromDate.setDate(today.getDate() - 6);
        toDate.setDate(today.getDate() + 1);
        setDateRange({ startDate: fromDate.toISOString().split('T')[0], endDate: toDate.toISOString().split('T')[0] });
        setPageNumber(1);
        break;
      case 'custom':
        setDateRange({ startDate: '', endDate: '' });
        setPageNumber(1);
        break;
      default:
        break;
    }
  }, [selectedRange]);

  return (
    <div className={classes.filter_container}>
      <div className='flex' style={{ gap: '2rem', flexWrap: 'wrap' }}>
        <TextBox
          label='Search Logs'
          placeholder='Search here...'
          icon='search'
          value={searchKey}
          onChange={e => {
            setSearchKey(e.target.value);
            setPageNumber(1);
          }}
        />

        <SelectBox label='Date Range' value={selectedRange} onChange={e => setSlectedRange(e.target.value)}>
          <option value='all'>All Logs</option>
          <option value='30Days'>Last 30 days</option>
          <option value='7Days'>Last 7 days</option>
          <option value='custom'>Custom range</option>
        </SelectBox>

        {selectedRange === 'custom' && (
          <>
            <DateInput
              label='Start Date'
              value={dateRange.startDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={e => setDateRange(prevval => ({ ...prevval, startDate: e.target.value }))}
            />
            <DateInput
              label='End Date'
              value={dateRange.endDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={e => setDateRange(prevval => ({ ...prevval, endDate: e.target.value }))}
            />
          </>
        )}
      </div>

      <Tags selectedTags={selectedTags} setSelectedTags={setSelectedTags} setPageNumber={setPageNumber} />
    </div>
  );
};

export default Filters;
