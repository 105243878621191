import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';
import { getFormTitle } from '.';

const DeleteFormModal = ({ selectedFormDelete, loading, handleDeleteModalClose, handleDelete }) => {
  return (
    <Modal show={selectedFormDelete._id}>
      <ModalHeading heading={`Delete Website Form`} />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection style={{ width: '34rem' }}>
            <p className='dangerText'>Are you sure you want to delete this form?</p>
            <div>
              <p className='primaryText'>Form Type: {getFormTitle(selectedFormDelete.type, selectedFormDelete.agent)}</p>
              <p className='primaryText'>Sent By: {selectedFormDelete.from}</p>
            </div>
          </InputsSection>

          <ModalButtons submitButtonLabel='Delete Form' onSubmit={handleDelete} cancelButtonLabel='Cancel' onCancel={handleDeleteModalClose} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default DeleteFormModal;
