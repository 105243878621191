import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { login, multiUserLogin } from '../../redux/actions/userActions';
import { validateEmail } from '../../utils/validators';

import Button from '../../components/Modal/ButtonLight';
import TextInput from './TextInput';
import PromotionalCards from './PromotionalCards';
import LoginFormSkeleton from '../../components/Skeletons/LoginFormSkeleton';

import LoginStyles from './LoginScreen.module.css';

const LoginScreen = ({ history }) => {
  const { search } = useLocation();

  const dispatch = useDispatch();

  const [token, setToken] = React.useState('');
  const [tokenPassword, setTokenPassword] = React.useState({ value: '', error: '', validated: false });
  const [email, setEmail] = React.useState({ value: '', error: '', validated: false });
  const [password, setPassword] = React.useState({ value: '', error: '', validated: false });

  const userLogin = useSelector(state => state.userLogin);

  React.useEffect(() => {
    if (search && search.includes('?token=')) {
      setToken(search.split('?token=')[1]);
    }
  }, [search]);

  const generateLoginToken = () => {
    // Mountain West Farm Bureau
    // Frontline Cyber Solutions
    // 360 Insurance
    const token = CryptoJS.AES.encrypt(
      JSON.stringify({ clientName: 'Mountain West Farm Bureau', email: 'demoa@the-algo.com' }),
      process.env.REACT_APP_SECURE_KEY
    ).toString();

    return token;
  };

  // React.useEffect(() => {
  //   const loginWithToken = async loginToken => {
  //     const data = await dispatch(multiUserLogin(loginToken));
  //     if (data && data.status === 'success') history.push('/');
  //   };

  //   if (token) loginWithToken(token);
  // }, [token, dispatch, history]);

  React.useEffect(() => {
    // console.log(generateLoginToken());
    if (!search && userLogin && userLogin.userInfo && userLogin.userInfo.status === 'success') {
      history.push('/');
    } else if (userLogin && userLogin.error) {
      // setPassword((prevMobData) => ({ ...prevMobData, error: userLogin.error, validated: false }));
      setPassword({ value: '', error: userLogin.error, validated: false });
      setTokenPassword({ value: '', error: userLogin.error, validated: false });
    }
  }, [history, userLogin, search]);

  const onLoginHandler = async e => {
    e.preventDefault();
    if (!search) {
      if (email.value === '' || email.value.trim() === '') {
        setEmail({ ...email, error: 'Please enter your email address', validated: false });
      } else if (!validateEmail(email.value)) {
        setEmail({ ...email, error: 'Please enter valid email address', validated: false });
      } else if (password.value === '' || password.value.trim() === '') {
        setPassword({ ...password, error: 'Please enter your password', validated: false });
      } else if (password.value.length < 8) {
        setPassword({ ...password, error: 'Password must have atleast 8 characters', validated: false });
      } else {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify({ email: email.value, password: password.value }), process.env.REACT_APP_LOGIN_PASSWORD_SALT).toString();
        setPassword({ ...password, value: ciphertext });
        dispatch(login(email.value.trim(), password.value, ciphertext));
      }
    } else {
      if (tokenPassword.value === '' || tokenPassword.value.trim() === '') {
        setTokenPassword({ ...tokenPassword, error: 'Please enter your password', validated: false });
      } else if (tokenPassword.value.length < 8) {
        setTokenPassword({ ...tokenPassword, error: 'Password must have atleast 8 characters', validated: false });
      } else {
        const newTokenPassword = CryptoJS.AES.encrypt(JSON.stringify({ password: tokenPassword.value }), process.env.REACT_APP_SECURE_KEY).toString();
        const data = await dispatch(multiUserLogin(token, newTokenPassword));
        if (data && data.status === 'success') history.push('/');
      }
    }
  };

  return (
    <div
      className={LoginStyles.container}
      style={{
        // backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .95)), url(/assets/login-bg.jpg)'
        backgroundImage: 'url(/assets/login-bg-1.jpg)',
      }}
    >
      <Helmet>
        <title>Continia Dashboard</title>
      </Helmet>
      <form onSubmit={onLoginHandler} className={LoginStyles.loginContainer}>
        <h1 className={LoginStyles.loginContainer_heading}>Login Now</h1>
        {userLogin && userLogin.loading ? (
          <LoginFormSkeleton />
        ) : !search ? (
          <Fragment>
            <TextInput
              type='text'
              label='Email Address'
              placeholder='Your e-mail address'
              icon='email'
              value={email.value}
              onChange={e => {
                if (validateEmail(e.target.value)) {
                  setEmail({ value: e.target.value, validated: true });
                } else {
                  setEmail({ value: e.target.value, validated: false });
                }
              }}
              validated={email.validated}
              errorMessage={email.error}
            />
            <TextInput
              type='password'
              label='Password'
              placeholder='Your password'
              icon='key'
              value={password.value}
              onChange={e => {
                if (e.target.value.length > 7) {
                  setPassword({ value: e.target.value, validated: true });
                } else {
                  setPassword({ value: e.target.value, validated: false });
                }
              }}
              validated={password.validated}
              errorMessage={password.error}
            />

            <div className={LoginStyles.button_container}>
              <Button label='Login Now' onClick={onLoginHandler} />
              <Link to='/forgot-password' className={LoginStyles.login__forgot_btn}>
                Forgot Password?
              </Link>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <TextInput
              type='password'
              label='Password'
              placeholder='Your password'
              icon='key'
              value={tokenPassword.value}
              onChange={e => {
                if (e.target.value.length > 7) {
                  setTokenPassword({ value: e.target.value, validated: true });
                } else {
                  setTokenPassword({ value: e.target.value, validated: false });
                }
              }}
              validated={tokenPassword.validated}
              errorMessage={tokenPassword.error}
            />

            <div className={LoginStyles.button_container}>
              <Button label='Login Now' onClick={onLoginHandler} />
              {/* <Link to='/forgot-password' className={LoginStyles.login__forgot_btn}>
                Forgot Password?
              </Link> */}
            </div>
          </Fragment>
        )}

        {/* <div className={LoginStyles.companyInfo}>
          <Link to='/register' className={LoginStyles.loginCard__register_btn}>
            Don't have an account? Register now
          </Link>
          <p>Powered By: The Algorithm</p>
        </div> */}
      </form>
      <PromotionalCards />
      <div className={LoginStyles.bottom_logo}>
        <p className={LoginStyles.bottom_logo_copyright}>&copy; {new Date(Date.now()).getFullYear()}. Continia | All Rights Reserved</p>
        <img src='/assets/logo.png' alt='Continia Logo' className={LoginStyles.bottom_logo_image} />
      </div>
    </div>
  );
};

export default LoginScreen;
