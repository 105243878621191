import React from 'react';

const SearchBox = ({ placeholder, value, onChange, style }) => {
  const handleFocus = () => {
    const headerTextInput = document.querySelector('.headerTextInput');
    const headerTextInputIcon = document.querySelector('.headerTextInput_icon');
    headerTextInput.style.border = '1px solid var(--grey)';
    headerTextInputIcon.style.fill = 'var(--black)';
  };

  const handleFocusOut = () => {
    const headerTextInput = document.querySelector('.headerTextInput');
    const headerTextInputIcon = document.querySelector('.headerTextInput_icon');
    headerTextInput.style.border = '1px solid var(--light-grey)';
    headerTextInputIcon.style.fill = 'var(--light-grey)';
  };

  return (
    <div className='headerTextInput' style={style}>
      <svg className='headerTextInput_icon'>
        <use xlinkHref={`/assets/sprite.svg#icon-search`} />
      </svg>
      <input
        type='text'
        className='headerTextBox'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleFocusOut}
      />
    </div>
  );
};

export default SearchBox;
