import React, { useEffect } from "react";
import ChatStyles from "../Chat.module.css";
import { shortenWord } from "../../../utils/gernalUtils";

const GroupCard = ({ data, setGroupName, history }) => {
	const {
		user,
		checkOnline,
		newSocketUserMsg,
		setLoadMoreMsg,
		selectedUser,
		setSelectedUser,
		setShowSelectedUser,
		setSelectedNewUser,
		setDecryptedChatData,
		setEncryptedChatData,
		setShowSecurityKey,
		decryptMessageByKeyId,
	} = data;

	const [message, setMessage] = React.useState("fetching messages...");
	const [messageTime, setMessageTime] = React.useState("");
	const [senderName, setSenderName] = React.useState("");

	React.useEffect(() => {
		let isMounted = true;

		const getMsg = async () => {
			if (
				user &&
				user.lastMessage &&
				user.lastMessage.messageText !== ""
			) {
				try {
					const msg = await decryptMessageByKeyId(
						user.lastMessage.messageText,
						user.lastMessage.keyID,
						user.channelID,
						user.lastMessage.symmetricKey,
						user.lastMessage.key
					);

					if (isMounted) {
						setMessage(msg);
						if (user.lastMessage.isEncryptedMessage) {
							setSenderName(user.lastMessage.senderName + ": ");
						}

						const messageDate = new Date(
							user.lastMessage.messageTime
						);
						const today = new Date();

						if (messageDate.getDate() === today.getDate()) {
							setMessageTime(
								messageDate
									.toLocaleTimeString()
									.replace(/(.*)\D\d+/, "$1")
							);
						} else if (
							messageDate.getDate() ===
							today.getDate() - 1
						) {
							setMessageTime(
								"Yesterday " +
									messageDate
										.toLocaleTimeString()
										.replace(/(.*)\D\d+/, "$1")
							);
						} else {
							setMessageTime(
								messageDate.toLocaleDateString() +
									" " +
									messageDate
										.toLocaleTimeString()
										.replace(/(.*)\D\d+/, "$1")
							);
						}
					}
				} catch (error) {
					console.error("Error decrypting message:", error);

					if (isMounted) {
						setMessage("");
						setMessageTime("");
					}
				}
			} else {
				if (isMounted) {
					setMessage("");
					setMessageTime("");
				}
			}
		};

		if (user) {
			getMsg();
		}

		// Cleanup function to set the component mount status to false when unmounting
		return () => {
			isMounted = false;
		};
	}, [user, decryptMessageByKeyId]);

	return (
		<button
			className={ChatStyles.chatLists_user_btn}
			disabled={selectedUser._id === user._id}
			onClick={() => {
				setEncryptedChatData({});
				setDecryptedChatData([]);
				setSelectedNewUser({});
				setSelectedUser(user);
				setShowSelectedUser(true);
				setLoadMoreMsg(false);
				setShowSecurityKey(false);
			}}
		>
			<div
				className={
					selectedUser._id === user._id
						? [
								ChatStyles.chatLists_user,
								ChatStyles.chatLists_user_current,
						  ].join(" ")
						: ChatStyles.chatLists_user
				}
			>
				<div className={ChatStyles.chatLists_user_data}>
					<div className={ChatStyles.chatLists_user_imageBox}>
						<img
							src="/assets/icon-group-logo.jpg"
							alt="User"
							className={ChatStyles.chatLists_user_image}
						/>

						{/* {checkOnline(user._id) ? (
							<div className={ChatStyles.chatLists_user_active} />
						) : (
							<div className={ChatStyles.chatLists_user_deactive} />
						)} */}
					</div>
					<div>
						<h3 className={ChatStyles.chatLists_user_name}>
							{shortenWord(user.name, 25)}
						</h3>
						{/* <p className={ChatStyles.chatLists_user_msg}>
							{message && message.length > 20 ? message.slice(0, 35) + '...' : message}
						</p> */}
						{user.unReadMessageCount === 0 ? (
							<p className={ChatStyles.chatLists_user_msg}>
								{message
									? message.length > 20
										? senderName +
										  message.slice(0, 35) +
										  "..."
										: senderName + message
									: ""}
								{/* {message && message.length > 20
									? message.slice(0, 35) + "..."
									: message} */}
							</p>
						) : (
							<p className={ChatStyles.chatLists_user_msg_new}>
								{message
									? message.length > 20
										? senderName +
										  message.slice(0, 28) +
										  "..."
										: senderName + message
									: ""}
								{/* {message && message.length > 20
									? message.slice(0, 28) + "..."
									: message} */}
								<span style={{ marginLeft: "1rem" }}>
									{user.unReadMessageCount}
								</span>
							</p>
						)}
					</div>
				</div>
				{/* {(d === 2 || d === 3) && <p className={ChatStyles.chatLists_num_msg}>10</p>} */}
				<p className={ChatStyles.chatLists_user_date}>{messageTime}</p>
			</div>
		</button>
	);
};

export default GroupCard;
