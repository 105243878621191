import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  getClientLogoReducer,
  createNewUserReducer,
  deleteUserReducer,
  updateUserReducer,
  updateUserStatusReducer,
  userLoginReducer,
  usersListReducer,
  resetUserPasswordReducer,
  loggedinUserReducer,
  changeLoggedInUserPasswordReducer,
  forgotPasswordReducer,
  verifyPasswordOTPReducer,
  updateloggedinUserReducer,
  deleteMeReducer,
  onlineUsersReducer,
  allNotificationsReducer,
  callReducer,
  clinicUserReducer,
  usersLogsReducer,
} from './reducers/userReducers';
import {
  clinicsListReducer,
  addClinicReducer,
  updateClinicReducer,
  deleteClinicReducer,
} from './reducers/ClinicReducres';
import {
  createFolderReducer,
  editFolderReducer,
  deleteFolderReducer,
  folderDataReducer,
  subFolderDataReducer,
  foldersListReducer,
  getFolderReducer,
  getSubFolderReducer,
  getSecureFilesReducer,
  uploadFileReducer,
  deleteFileReducer,
  downloadFileReducer,
  shareFileReducer,
  renameFileReducer,
  copyFileReducer,
  cutFileReducer,
  subSubFolderDataReducer,
  getSubSubFolderReducer,
  getSubSubSubFolderReducer,
  subSubSubFolderDataReducer,
  subSubSubSubFolderDataReducer,
  getSubSubSubSubFolderReducer,
  foldersLevelListReducer
} from './reducers/folderReducer';

const reducer = combineReducers({
  clientLogo: getClientLogoReducer,
  onlineUsers: onlineUsersReducer,
  allNotifications: allNotificationsReducer,
  userLogin: userLoginReducer,
  usersList: usersListReducer,
  logsList: usersLogsReducer,
  createNewUser: createNewUserReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  updateUserStatus: updateUserStatusReducer,
  resetUserPassword: resetUserPasswordReducer,
  loggedinUser: loggedinUserReducer,
  updateLoggedInUser: updateloggedinUserReducer,
  deleteMe: deleteMeReducer,
  loggedInUserPasswordChange: changeLoggedInUserPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  verifyPasswordOTP: verifyPasswordOTPReducer,
  callReducer: callReducer,
  clinicDetails: clinicUserReducer,

  //Clinic Reducers
  clinicsList: clinicsListReducer,
  addClinic: addClinicReducer,
  updateClinic: updateClinicReducer,
  deleteClinic: deleteClinicReducer,

  //Folder Reducers
  getSecureFiles: getSecureFilesReducer,
  uploadFile: uploadFileReducer,
  deleteFile: deleteFileReducer,
  downloadFile: downloadFileReducer,
  shareFile: shareFileReducer,
  renameFile: renameFileReducer,
  copyFile: copyFileReducer,
  cutFile: cutFileReducer,
  foldersList: foldersListReducer,
  foldersLevelList: foldersLevelListReducer,
  getFolder: getFolderReducer,
  getSubFolder: getSubFolderReducer,
  getSubSubFolder: getSubSubFolderReducer,
  getSubSubSubFolder: getSubSubSubFolderReducer,
  getSubSubSubSubFolder: getSubSubSubSubFolderReducer,
  folderData: folderDataReducer,
  subFolderData: subFolderDataReducer,
  subSubFolderData: subSubFolderDataReducer,
  subSubSubFolderData: subSubSubFolderDataReducer,
  subSubSubSubFolderData: subSubSubSubFolderDataReducer,
  createFolder: createFolderReducer,
  editFolder: editFolderReducer,
  deleteFolder: deleteFolderReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
