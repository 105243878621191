import ChatStyles from "./Chat.module.css";

const ChatboxPlaceholder = () => {
	return (
		<div className={ChatStyles.chat_placeholder}>
			<img src="/assets/Chat-placeholder.svg" />
			<p className={ChatStyles.chat_placeholder_heading}>
				Communications within Continia are safeguarded by
				<br />
				end-to-end encryption.
			</p>
			<p className={ChatStyles.chat_placeholder_subHeading}>
				Please select a group or contact to initiate a chat.
			</p>
		</div>
	);
};

export default ChatboxPlaceholder;
