import React from 'react';
import ChatStyles from '../../screens/ChatScreenNew/Chat.module.css';
import SkeletonStyles from './Skeletons.module.css';

const WebsiteFormUserSkeleton = () => {
  const data = [1, 2, 3, 4, 5];
  return (
    data &&
    data.map(d => (
      <div className={ChatStyles.chatLists_user} key={d}>
        <div className={ChatStyles.chatLists_user_data}>
          <div className={ChatStyles.chatLists_user_imageBox}>
            <div className={[SkeletonStyles.chatLists_user_image, SkeletonStyles.skeleton].join(' ')} />
          </div>
          <div>
            <div className={[SkeletonStyles.forms_user_name, SkeletonStyles.skeleton].join(' ')} />
            <div className={[SkeletonStyles.chatLists_user_msg, SkeletonStyles.skeleton].join(' ')} />
          </div>
        </div>
        {/* {(d === 2 || d === 3) && <p className={ChatStyles.chatLists_num_msg}>10</p>} */}
        <div className={[SkeletonStyles.chatLists_user_date, SkeletonStyles.skeleton].join(' ')} />
      </div>
    ))
  );
};

export default WebsiteFormUserSkeleton;
