import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
let timeout;
const useGetAllForms = searchText => {
  const [allFormsData, setAllFormsData] = React.useState();

  const { loading: allFormsLoading, makeApiCall } = useApi({
    url: `/website-form?search=${searchText}`,
    method: 'GET',
  });

  const getAllForms = async () => {
    const { response, error } = await makeApiCall();
    if (response?.status === 'success') {
      setAllFormsData(response);
    }

    if (!response?.status === 'fail' && response?.msg) {
      toast.error(response.message);
      setAllFormsData(undefined);
    }
    if (error) {
      toast.error(error.msg);
      setAllFormsData(undefined);
    }
  };

  React.useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      getAllForms();

    }, 1000);
    // eslint-disable-new-line
  }, [searchText]);

  return {
    allFormsLoading,
    getAllForms,
    allFormsData,
  };
};

export default useGetAllForms;
