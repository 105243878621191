import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const Folder = ({ folderId, onClick, name, active = false, client, showMenu = true, menuItem }) => {
  return (
    <div className={FileExchangeStyles.folderBox}>
      <ContextMenuTrigger id={`contextmenu${folderId}`}>
        <button className={active ? FileExchangeStyles.folderSelected : FileExchangeStyles.folder} onClick={onClick}>
          <div className={FileExchangeStyles.folderLeft}>
            <svg className={FileExchangeStyles.folderIcon}>
              <use xlinkHref={`/assets/sprite.svg#icon-folder`} />
            </svg>
            <h4 className={FileExchangeStyles.folderName}>
              {/* {index + 1}.{' '} */}
              {name && name.length < 17 ? name : name.slice(0, 18) + '...'}
            </h4>
          </div>
          {client && <h6 className={FileExchangeStyles.folderfiles}>{client.length < 31 ? client : client.slice(0, 31) + '...'}</h6>}
        </button>
      </ContextMenuTrigger>
      {((name && name.length > 17) || (client && client.length > 31)) && (
        <div className={FileExchangeStyles.folderFullName}>
          <p>{name}</p>
          <p>{client}</p>
        </div>
      )}

      {showMenu && (
        <ContextMenu id={`contextmenu${folderId}`}>
          {menuItem.length > 0 &&
            menuItem.map(item => (
              <MenuItem key={item.id} onClick={item.onClick}>
                <svg className='watchlist'>
                  <use xlinkHref={`/assets/sprite.svg#icon-${item.icon}`} />
                </svg>
                <span>{item.label}</span>
              </MenuItem>
            ))}
        </ContextMenu>
      )}
    </div>
  );
};

export default Folder;
