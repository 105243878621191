import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../components/Skeletons/ShareFileSkeleton';
import { SHARE_FILE_EXCHANGE_RESET } from '../../redux/constants/folderConstants';

const ShareFileModal = ({ data }) => {
	const dispatch = useDispatch();
	const {
		shareFileModal,
		setShareFileModal,
		shareFilePasswordInput,
		setShareFilePasswordInput,
		handleShareFile
	} = data;

	const shareFile = useSelector((state) => state.shareFile);

	useEffect(
		() => {
			if (shareFile && !shareFile.loading && shareFile.error) {
				setShareFilePasswordInput((prevData) => ({ ...prevData, error: shareFile.error }));
			}
		},
		[ shareFile, setShareFilePasswordInput ]
	);

	const onModalClose = () => {
		setShareFileModal({ file: {}, status: false });
		setShareFilePasswordInput({ value: '', error: '' });

		dispatch({ type: SHARE_FILE_EXCHANGE_RESET });
	};

	return (
		<Modal show={shareFileModal.status}>
			{shareFile && shareFile.loading ? (
				<ShareFileSkeleton fileProtected={shareFileModal.file.protected} />
			) : shareFile && shareFile.file && shareFile.file.link ? (
				<Fragment>
					<div className='modal__heading--container'>
						<a
							href={`/#/share-file?file=${shareFileModal.file._id}&link='${encodeURIComponent(shareFile.file.link)}'`}
							target='_blank'
							rel='noreferrer'
							className='modal_share_heading'>
							Share File ({shareFileModal.file.name})
						</a>
					</div>
					<div className='share-link-container'>
						<p>{`${window.location.hostname}:${window.location.port}/#/share-file?file=${shareFileModal.file
							._id}&link='${encodeURIComponent(shareFile.file.link)}'`}</p>
						<CopyToClipboard
							text={`${window.location.hostname}:${window.location.port}/#/share-file?file='${shareFileModal.file
								._id}'&link='${encodeURIComponent(shareFile.file.link)}'`}
							onCopy={() => {
								toast.success('Link Copied..!');
								onModalClose();
							}}>
							<button className='share-button'>Copy</button>
						</CopyToClipboard>
					</div>
					<p className='modal__subtext'>This link is valid for 24 hours only.</p>
					<div className='modal__actions'>
						<button className='modal__button--cancel' onClick={onModalClose}>
							Cancel
						</button>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<ModalHeading heading={`Share ${shareFileModal.file.name}?`} />
					<ModalForm style={{ marginBottom: '2.5rem' }}>
						<InputsSection style={{ width: '34rem' }}>
							{shareFileModal.file.protected ? (
								<InputBox
									label={`Enter password to share file`}
									type='password'
									placeholder='••••••••••••••••'
									value={shareFilePasswordInput.value}
									onChange={(event) => {
										setShareFilePasswordInput({ value: event.target.value, error: '' });
									}}
									errorMessage={shareFilePasswordInput.error}
									style={{ width: '30rem' }}
								/>
							) : (
								<p className='modal__subtext' style={{ margin: '0', marginBottom: '0' }}>
									Please click on share button and copy the link<br /> to share this file.
								</p>
							)}
						</InputsSection>
						<ModalButtons
							submitButtonLabel='Share File'
							onSubmit={handleShareFile}
							cancelButtonLabel='Cancel'
							onCancel={onModalClose}
						/>
					</ModalForm>
				</Fragment>
			)}
		</Modal>
	);
};

export default ShareFileModal;
