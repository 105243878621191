import React, { useState } from 'react';
import TextBox from './TextBox.module.css';

const RangeInput = ({
  value,
  onChange,
  displayValue,
  errorMessage,
  label,
  style,
  disabled,
  min = '0',
  max = '100',
  step = 10,
}) => {
  return (
    <React.Fragment>
      <div className={TextBox.form_input}>
        {label && (
          <label htmlFor={label} className={TextBox.form_input__label}>
            {label}
          </label>
        )}
        <div className={TextBox.form_input__container} style={style}>
          <input
            type='range'
            id={label}
            disabled={disabled ? disabled : false}
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            className={TextBox.form_input__box}
          />
        </div>
        {errorMessage && (
          <p className={TextBox.form_input__error}>{errorMessage}</p>
        )}
      </div>
      <div className={TextBox.form_range_value}>
        <p>{displayValue}</p>
      </div>
    </React.Fragment>
  );
};

export default RangeInput;
