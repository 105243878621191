import React, { useCallback } from 'react';
import Styles from './Styles.module.css'
import TextArea from '../../../components/Modal/TextArea';
import SelectBox from '../../../components/SelectBoxButton';
import Button from '../../../components/Button';

const NotesSection = ({
  headerTitle,
  showNotes,
  setShowNotes
}) => {

  const [newVideoNote, setNewVideoNote] = React.useState({
    value: '',
    error: '',
  });
  const [videoNotesModal, setVideoNotesModal] = React.useState(false);
  const [noteType, setNoteType] = React.useState({
    value: 'internal',
    error: '',
  });

  return (
    <div className={`${Styles.notesSection} ${showNotes ? '' : Styles.hide}`}>
      <div className='video_chat_chats'>
        <div className='video_chat_chats_scroll' style={{ height: 'inherit' }}>
          <br />
          <TextArea
            placeholder='Write note here...'
            rows={5}
            value={newVideoNote.value}
            onChange={(e) =>
              setNewVideoNote({ value: e.target.value, error: '' })
            }
            errorMessage={newVideoNote.error}
          />

          <SelectBox
            buttons={[
              {
                label: 'Internal Note',
                value: 'internal',
                onClick: () => setNoteType({ value: 'internal', error: '' }),
              },
              {
                label: 'Shared with user',
                value: 'public',
                onClick: () => setNoteType({ value: 'public', error: '' }),
              },
            ]}
            value={noteType.value}
          />

          <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <Button name='Save' 
            // onClick={handleSubmitVideoNote} 
            />
            <Button
              name='Previous Notes'
              onClick={() => setVideoNotesModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesSection;
