import React from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import { useDeleteForm, useGetAllForms } from '../../hooks/WebsiteForms';

import FormData from './FormData';
import FormCard from './FormCard';
import NoData from '../../components/NoData';
import Content from '../../components/Content';
import DeleteFormModal from './DeleteFormModal';
import SearchBox from '../../components/SearchBox';
import ExportButton from '../../components/ExportButton';
import WebsiteFormUserSkeleton from '../../components/Skeletons/WebsiteFormUserSkeleton';
import WebsiteFormDataSkeleton from '../../components/Skeletons/WebsiteFormDataSkeleton';
import DownloadFormModal from './DownloadFormModal';

import classes from './WebsiteForms.module.css';

const contactFormTitle = [
  { id: 'StartAQuote', label: 'Quote Request Confirmation' },
  { id: 'ContactUs', label: 'Contact Form' },
  { id: 'ContactMe', label: 'Contact Me' },
  { id: 'ERS', label: 'ERS Incident Report' },
];

export const getFormTitle = (title, agent) => {
  let titleUpdated = title;
  contactFormTitle.forEach(data => {
    if (data.id === title) titleUpdated = data.label;
  });

  if (agent) {
    titleUpdated = titleUpdated + ' - ' + agent;
  }

  return titleUpdated;
};

const WebsiteFormsScreen = () => {
  const [searchText, setSearchText] = React.useState('');
  const [selectedForm, setSelectedForm] = React.useState();
  const [selectedFormDelete, setSelectedFormDelete] = React.useState();
  const [showAttachmentWarning, setShowAttachmentWarning] = React.useState(false);

  const { toPDF, targetRef } = usePDF({ filename: `${selectedForm?.type} (${selectedForm?.from}).pdf`, page: { margin: Margin.NONE } });
  const { allFormsLoading, getAllForms, allFormsData } = useGetAllForms(searchText);
  const { deleteFormLoading, deleteForm } = useDeleteForm({ id: selectedFormDelete?._id });

  const deleteFormData = async () => {
    if (selectedFormDelete._id) {
      const data = await deleteForm();
      if (data.status === 'success') {
        if (selectedForm && selectedForm._id === selectedFormDelete._id) setSelectedForm(undefined);
        setSelectedFormDelete(undefined);
        await getAllForms();
      }
    }
  };

  const exportAdViewCountData = () => {
    let data = [];
    if (allFormsData && allFormsData.data && allFormsData.data.data.length > 0) {
      allFormsData.data.data.forEach(item => {
        const tempData = {
          'Form Type': getFormTitle(item.type, item.agent),
          From: item.from,
          'Submitted On': new Date(item.createdAt).toLocaleDateString() + ' ' + new Date(item.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
          // Content: Object.keys(item.data).length && Object.keys(item.data).map(key => `${key} : ${item.data[key]}`),
          // Content: JSON.stringify(item.data),
        };
        data.push(tempData);
      });
    }
    return data;
  };

  return (
    <Content currentMenu='website-forms' headerTitle='Website Forms'>
      <div className={classes.content}>
        <div className={classes.right_content}>
          <SearchBox placeholder='Search' value={searchText} onChange={e => setSearchText(e.target.value)} />
          <div className={classes.right_content_middle}>
            <ExportButton exportData={exportAdViewCountData()} exportTitle='Website Forms' />
          </div>
          <div className={classes.right_content_data}>
            {allFormsLoading || deleteFormLoading ? (
              <WebsiteFormUserSkeleton />
            ) : allFormsData && allFormsData.data && allFormsData.data.data.length > 0 ? (
              allFormsData.data.data.map(form => (
                <FormCard
                  key={form._id}
                  selected={selectedForm?._id === form._id}
                  data={form}
                  onSelect={() => {
                    setSelectedForm(form);
                  }}
                  onDelete={() => setSelectedFormDelete(form)}
                />
              ))
            ) : (
              <NoData src={'/assets/no-data-found.png'} title='We could not find any submitted forms.' textStyle={{ marginTop: '1rem' }} />
            )}
          </div>
        </div>

        {selectedForm?._id ? (
          <>
            {/* <WebsiteFormDataSkeleton data={selectedForm} /> */}
            <FormData
              data={selectedForm}
              onPDFDownload={e => {
                e.stopPropagation();
                if (selectedForm.isAttachment) setShowAttachmentWarning(true);
                else toPDF();
              }}
              onDelete={() => setSelectedFormDelete(selectedForm)}
            />
            <div style={{ opacity: '0', position: 'absolute', top: '-300vh', left: '-100vw', zIndex: '-1' }}>
              <div ref={targetRef} style={{ width: '100vw', minHeight: '100vh', backgroundColor: '#f1f1f1', padding: '2rem' }}>
                <FormData noIcon data={selectedForm} contentStyle={{ height: '100%' }} />
              </div>
            </div>
          </>
        ) : null}
      </div>

      {showAttachmentWarning ? (
        <DownloadFormModal
          show={showAttachmentWarning}
          selectedForm={selectedForm}
          handleDownload={() => {
            toPDF();
            setShowAttachmentWarning(false);
          }}
          handleCancel={() => setShowAttachmentWarning(false)}
        />
      ) : null}

      {selectedFormDelete ? (
        <DeleteFormModal selectedFormDelete={selectedFormDelete} handleDeleteModalClose={() => setSelectedFormDelete(undefined)} handleDelete={deleteFormData} />
      ) : null}
    </Content>
  );
};

export default WebsiteFormsScreen;
