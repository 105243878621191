import React from 'react';
import { Link } from 'react-router-dom';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const RecentFile = ({ file }) => {
  const getFileIcon = fileName => {
    const fileSplit = fileName.split('.');
    const ext = fileSplit[fileSplit.length - 1];
    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'tif' || ext === 'gif') return 'file-image';
    else if (ext === 'doc' || ext === 'docx') return 'file-word';
    else if (ext === 'csv' || ext === 'xlsx') return 'file-excel';
    else if (ext === 'mp4' || ext === 'webm') return 'file-video';
    else if (ext === 'mp3') return 'file-audio';
    else if (ext === 'pdf') return 'file-pdf';
    else if (ext === 'zip') return 'file-zip';
    else return 'file-unknown';
  };

  return (
    <Link
      to={`/files/${file._id}`}
      target='_blank'
      className={FileExchangeStyles.recentUpload}

    // onClick={() => setDownloadFileModal({ file, status: true })}
    >
      <svg className={FileExchangeStyles.recentUploadIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-${getFileIcon(file.name)}`} />
      </svg>
      <div className={FileExchangeStyles.recentUploadData}>
        <h3 className={FileExchangeStyles.recentUploadName}>{file.name}</h3>
        {file.uploadedBy && file.uploadedBy.name && (
          <p className={FileExchangeStyles.recentUploadDate}>
            Uploaded in{' '}
            <span>
              {file?.folder?.isSubFolder
                ? file?.folder?.parentFolder?.isSubFolder
                  ? file?.folder?.parentFolder?.parentFolder?.isSubFolder
                    ? file?.folder?.parentFolder?.parentFolder?.parentFolder?.isSubFolder
                      ? file?.folder?.parentFolder?.parentFolder?.parentFolder?.parentFolder?.name +
                      '/' +
                      file?.folder?.parentFolder?.parentFolder?.parentFolder?.name +
                      '/' +
                      file?.folder?.parentFolder?.parentFolder?.name +
                      '/' +
                      file?.folder?.parentFolder?.name +
                      '/'
                      : file?.folder?.parentFolder?.parentFolder?.parentFolder?.name +
                      '/' +
                      file?.folder?.parentFolder?.parentFolder?.name +
                      '/' +
                      file?.folder?.parentFolder?.name +
                      '/'
                    : file?.folder?.parentFolder?.parentFolder?.name + '/' + file?.folder?.parentFolder?.name + '/'
                  : file?.folder?.parentFolder?.name + '/'
                : ''}
              {file?.folder?.name}
            </span>{' '}
            by {file.uploadedBy.name}
          </p>
        )}
        <p className={FileExchangeStyles.recentUploadDate}>{new Date(file.createdAt).toDateString()}</p>
      </div>
    </Link>
  );
};

export default RecentFile;
