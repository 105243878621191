import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';

import axios from 'axios';
// import { BASE_URL } from '../../../redux/actions/ip';

import TextInput from '../../../components/Modal/InputBox';
import Button from '../../../components/Modal/Button';

import DownloadScreenStyles from './DownloadScreen.module.css';

const DownloadFileScreen = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { search } = useLocation();
  let data = search.replaceAll("'", '');
  const dataParams = new URLSearchParams(data);
  const id = dataParams.get('file');
  const link = dataParams.get('link');

  const [fileDownloading, setFileDownloading] = useState(false);
  const [fileData, setFileData] = useState({});
  const [password, setPassword] = useState({ value: '', error: '' });

  const getFileIcon = fileName => {
    const fileSplit = fileName.split('.');
    const ext = fileSplit[fileSplit.length - 1];
    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'tif' || ext === 'gif') return 'file-image';
    else if (ext === 'doc' || ext === 'docx') return 'file-word';
    else if (ext === 'ppt' || ext === 'pptx') return 'file-ppt';
    else if (ext === 'csv' || ext === 'xlsx') return 'file-excel';
    else if (ext === 'mp4' || ext === 'webm') return 'file-video';
    else if (ext === 'mp3') return 'file-audio';
    else if (ext === 'pdf') return 'file-pdf';
    else if (ext === 'zip') return 'file-zip';
    else return 'file-unknown';
  };

  useEffect(() => {
    const getFile = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(`${BASE_URL}/fileExchange/shared`, { id, link }, config);
      if (data && data.status === 'success') {
        setFileData(data.data.data);
      }
    };

    getFile();
  }, [id]);

  const handleDownload = async () => {
    if (fileData.protected && (password.value === '' || password.value.trim() === '')) {
      setPassword({ ...password, error: 'Please enter password' });
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const formData = { id, link: decodeURIComponent(link) };
      if (fileData.protected) formData.password = password.value;

      // axios({
      // 	url: `${BASE_URL}/fileExchange/download-file`, //your url
      // 	method: 'POST',
      // 	data: formData,
      // 	headers: config.headers,
      // 	responseType: 'blob' // important
      // })
      // 	.then((response) => {
      // 		const url = window.URL.createObjectURL(new Blob([ response.data ]));
      // 		const link = document.createElement('a');
      // 		link.href = url;
      // 		link.setAttribute('download', 'download.zip'); //or any other extension
      // 		link.setAttribute('target', '_blank'); //or any other extension
      // 		document.body.appendChild(link);
      // 		link.click();
      // 		toast.success('File download success');
      // 	})
      // 	.catch((err) => {
      // 		toast.error('Incorrect password. Please try again');
      // 	});
      setFileDownloading(true);
      axios({
        url: `${BASE_URL}/fileExchange/download-file`, //your url
        method: 'POST',
        data: formData,
        headers: config.headers,
        responseType: 'blob', // important
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileData.name); //or any other extension
          link.setAttribute('target', '_blank'); //or any other extension
          document.body.appendChild(link);
          link.click();
          setFileDownloading(false);
          toast.success('File download success');
        })
        .catch(err => {
          setFileDownloading(false);
          toast.error('Invalid file or incorrect password. Please try again');
        });
    }
  };

  return (
    <div className={DownloadScreenStyles.container}>
      <img src='/assets/logo-black.png' alt='Logo' className={DownloadScreenStyles.logo} />
      <svg className={DownloadScreenStyles.zipIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-${fileData.name ? getFileIcon(fileData.name) : 'file-unknown'}`} />
      </svg>
      <div>
        <h1 className={DownloadScreenStyles.fileName}>{fileData.name}</h1>
        <p className={DownloadScreenStyles.date}>Uploaded on {new Date(fileData.updatedAt).toLocaleString()}</p>
        {/* <p className={DownloadScreenStyles.date}>Date Modified 2022 jan 22</p> */}
        {fileData.protected && (
          <div style={{ margin: '2rem 0', width: '30rem' }}>
            <TextInput
              type='password'
              placeholder='Enter password to download this file'
              value={password.value}
              onChange={e => setPassword({ value: e.target.value })}
              errorMessage={password.error}
            />
          </div>
        )}
      </div>
      {fileDownloading ? <Button label='Downloading File...' /> : <Button label='Download File' onClick={handleDownload} />}
    </div>
  );
};

export default DownloadFileScreen;
