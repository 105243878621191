import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteClinicModal = ({ deleteClinicModal, loading, handleDeleteClinicModalClose, handleDeleteClinic, data }) => {
	const { deleteEmail, setDeleteEmail, deleteClinicData } = data;

	return (
		<Modal show={deleteClinicModal}>
			<ModalHeading heading={`Delete ${deleteClinicData.name}`} />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						<InputBox
							label={`Enter ${deleteClinicData.email} to delete this client`}
							placeholder={deleteClinicData.email}
							value={deleteEmail.value}
							onChange={(event) => {
								setDeleteEmail({ value: event.target.value, error: '' });
							}}
							errorMessage={deleteEmail.error}
							style={{ width: '30rem' }}
						/>
						<p className='dangerText'>
							Please note that this will delete client entire data and will not be restored in future.
						</p>
					</InputsSection>
					<InputsSection style={{ width: '34rem' }}>
						<p className='primaryText'>
							If your intension is to restrict this client from logging in, we recommend to change active status to
							false instead of deleting.
						</p>
					</InputsSection>
					<ModalButtons
						submitButtonLabel='Delete Client'
						onSubmit={handleDeleteClinic}
						cancelButtonLabel='Cancel'
						onCancel={handleDeleteClinicModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default DeleteClinicModal;
