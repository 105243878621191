import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';

const SignSuccessModal = ({ showSuccessModal }) => {
  const history = useHistory();

  return (
    <Modal show={showSuccessModal}>
      {/* <ModalHeading heading='Congratulations' /> */}
      <InputsSection style={{ marginTop: '3rem' }}>
        <p className='primaryText' style={{ fontSize: '1.3rem' }}>
          Congratulations, you have successfully signed the document.
          <br />
          Login now to view your signed doucment.
        </p>
      </InputsSection>
      <ModalButtons submitButtonLabel='Login' onSubmit={() => history.replace('/login')} />
    </Modal>
  );
};

export default SignSuccessModal;
