import React from 'react';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteTranscript = ({
  loading,
  deleteTranscriptModal,
  handleDeleteTranscript,
  handleDeleteTranscriptModalClose,
}) => {
  return (
    <Modal show={deleteTranscriptModal.status}>
      <ModalHeading heading={`Delete Transcript`} />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection style={{ width: '34rem' }}>
            <p className='primaryText'>
              Are you sure, you want to delete transcipt between{' '}
              {(deleteTranscriptModal.data.transcriptOneUser &&
                deleteTranscriptModal.data.transcriptOneUser.name) +
                ' and ' +
                (deleteTranscriptModal.data.transcriptTwoUser &&
                  deleteTranscriptModal.data.transcriptTwoUser.name)}{' '}
              ?
            </p>
            <p className='dangerText'>
              Please note that this will delete this video call transcript
              permanently and will not be restored in future.
            </p>
          </InputsSection>

          <ModalButtons
            submitButtonLabel='Delete Transcript'
            onSubmit={handleDeleteTranscript}
            cancelButtonLabel='Cancel'
            onCancel={handleDeleteTranscriptModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default DeleteTranscript;
