import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import TableSkeleton from './TableSkeleton';
import { useSelector } from 'react-redux';
import NoContent from '../../components/NoContent';

const LogTable = ({ logData, logDataLoading, setLogDataLoading, setLogDetails }) => {
  const [userRole, setUserRole] = useState(true);
  const userLogin = useSelector(state => state.userLogin);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLogDataLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [logDataLoading, setLogDataLoading]);

  React.useEffect(() => {
    if (userLogin && userLogin?.userInfo && userLogin?.userInfo?.data && userLogin?.userInfo?.data?.user?.role && userLogin?.userInfo?.data?.user?.role) {
      setUserRole(userLogin.userInfo.data.user.role);
    }
  }, [userLogin]);

  const convertTimestamp = timestamp => {
    const timestampInSeconds = parseInt(timestamp, 10);
    if (!isNaN(timestampInSeconds)) {
      return new Date(timestampInSeconds * 1000).toLocaleString();
    }
  };

  const convertEpochToDateTime = timestamp => {
    const timestampInSeconds = parseInt(timestamp, 10);

    if (!isNaN(timestampInSeconds)) {
      const date = new Date(timestampInSeconds * 1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

      // Determine AM/PM
      const ampm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12-hour format

      const formattedDateTime = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;
      return formattedDateTime;
    } else {
      return 'Invalid timestamp';
    }
  };

  return (
    <div>
      <Table
        thead={
          logData?.userInfo?.data && logData?.userInfo?.data && logData?.userInfo?.data.length < 0 ? (
            <TableSkeleton columns={9} rows={10} />
          ) : (
            <tr>
              <th style={{ background: 'none', marginLeft: '1rem', display: 'block' }}>Tag</th>
              {(userRole === 'admin') | (userRole === 'fileAdmin') | (userRole === 'clientAdmin') | (userRole === 'clinic') ? (
                <th style={{ background: 'none' }}>Action Performed By</th>
              ) : null}
              {userRole === 'admin' ? <th style={{ background: 'none' }}>Client</th> : null}
              <th style={{ background: 'none' }}>Action Performed On</th>
              <th style={{ background: 'none' }}>Action Performed At</th>
              <th style={{ background: 'none' }}>IP</th>
              <th style={{ background: 'none' }}>Device Info</th>
            </tr>
          )
        }
        tbody={
          logDataLoading || (logData && logData.loading) ? (
            <TableSkeleton columns={9} rows={10} />
          ) : logData && logData.userInfo && logData.userInfo.status === 'success' && logData.userInfo.data && logData.userInfo.data.result.length > 0 ? (
            logData.userInfo.data.result.map(log => (
              <tr key={log._id}>
                <td style={{ marginLeft: '1rem', display: 'block' }}>{log.actionTags && log.actionTags.length > 0 ? log.actionTags.join(', ') : '-'}</td>
                {(userRole === 'admin') | (userRole === 'fileAdmin') | (userRole === 'clientAdmin') | (userRole === 'clinic') ? (
                  <td>{log.user ? (log.user.name ? log.user.name : '-') : '-'}</td>
                ) : null}
                {userRole === 'admin' ? <td>{log.client ? (log.client.name ? log.client.name : '-') : '-'}</td> : null}
                <td>{log.action}</td>
                <td>{log.timestamp ? convertTimestamp(log.timestamp) : '-'}</td>
                <td>{log.userIp ? log.userIp : '-'}</td>
                <td>{log.loginInfo ? log.loginInfo : '-'}</td>
                {/* <td className='table__row_title'>
                  {' '}
                  {log.module ? log.module.replaceAll(`'`, '').replaceAll(`-`, ` `).replaceAll(`_`, ` `).replace(log.module[0], log.module[0].toUpperCase()) : '-'}
                </td>
                <td className='table__row_title'>
                  {log.type ? log.type.replaceAll(`'`, '').replaceAll(`-`, ` `).replaceAll(`_`, ` `).replace(log.type[0], log.type[0].toUpperCase()) : '-'}
                </td> */}
              </tr>
            ))
          ) : (
            <tr className='noContent_table_row'>
              <td colSpan={8}>
                <NoContent title1='No audit logs found.' title2=' ' />
              </td>
            </tr>
          )
        }
      />
    </div>
  );
};

export default LogTable;
