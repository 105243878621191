import React from 'react';
import { useDispatch } from 'react-redux';

import Content from '../../components/Content';
import {
  deleteTranscriptAction,
  getAllTranscriptsActions,
  updateTranscriptAction,
} from '../../redux/actions/transcriptActions';
import DeleteTranscript from './DeleteTranscript';
import TranscriptCard from './TranscriptCard';
import TranscriptDetail from './TranscriptDetail';
import classes from './VideoTranscript.module.css';

const VideoTranscript = () => {
  const dispatch = useDispatch();

  const [allTranscriptsLoading, setAllTranscriptsLoading] =
    React.useState(false);
  const [allTranscripts, setAllTranscripts] = React.useState({
    totalResults: 0,
    data: [],
  });
  const [selectedTranscript, setSelectedTranscript] = React.useState({
    status: false,
    data: {},
  });
  const [deleteTranscriptModal, setDeleteTranscriptModal] = React.useState({
    status: false,
    data: {},
  });
  const [selectedTranscriptText, setSelectedTranscriptText] = React.useState({
    status: false,
    text: '',
    user: '',
  });

  const getTranscriptData = React.useCallback(async () => {
    setAllTranscriptsLoading(true);
    const data = await dispatch(getAllTranscriptsActions());
    if (data && data.data && data.status === 'success') {
      setAllTranscripts({ totalResults: data.results, data: data.data.data });
      setAllTranscriptsLoading(false);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getTranscriptData();
  }, [getTranscriptData]);

  const handleEditTranscript = async (transcriptId, formData) => {
    const data = await dispatch(updateTranscriptAction(transcriptId, formData));

    if (data && data.status) {
      getTranscriptData();

      if (selectedTranscript.status === true) {
        setSelectedTranscript({
          ...selectedTranscript,
          data: {
            ...selectedTranscript.data,
            transcriptOneText: data.data.data.transcriptOneText,
            transcriptTwoText: data.data.data.transcriptTwoText,
          },
        });
        setSelectedTranscriptText({ status: false, text: '', user: '' });
      }
    }
  };

  const handleDeleteTranscript = async () => {
    if (deleteTranscriptModal.data) {
      const data = await dispatch(
        deleteTranscriptAction(deleteTranscriptModal.data._id)
      );

      if (data && data.status === 'success') {
        setDeleteTranscriptModal({ status: false, data: {} });
      }
    }
  };

  return (
    <Content
      currentMenu='transcript'
      headerTitle='Video Transcripts'
      addBtn={false}
      searchBox={false}
      totalReult={
        allTranscripts.totalResults !== 0
          ? allTranscripts.totalResults + ' transcripts'
          : ''
      }
    >
      <div className={classes.transcriptCards}>
        {allTranscriptsLoading ? (
          <h1>Loading Transcript Data</h1>
        ) : allTranscripts &&
          allTranscripts.totalResults &&
          allTranscripts.data &&
          allTranscripts.data.length > 0 ? (
          allTranscripts.data.map((transcript, index) => (
            <TranscriptCard
              key={transcript._id}
              index={index}
              date={transcript.createdAt}
              userOne={
                transcript.transcriptOneUser &&
                transcript.transcriptOneUser.name
              }
              userTwo={
                transcript.transcriptTwoUser &&
                transcript.transcriptTwoUser.name
              }
              onButtonClick={() =>
                setSelectedTranscript({ status: true, data: transcript })
              }
              onDeleteTranscript={() =>
                setDeleteTranscriptModal({ status: true, data: transcript })
              }
            />
          ))
        ) : (
          <p>No data</p>
        )}
      </div>

      <TranscriptDetail
        transcript={selectedTranscript}
        handleEditTranscript={handleEditTranscript}
        handleCloseTranscript={() =>
          setSelectedTranscript({ status: false, data: {} })
        }
        selectedTranscriptText={selectedTranscriptText}
        setSelectedTranscriptText={setSelectedTranscriptText}
      />

      <DeleteTranscript
        loading={false}
        deleteTranscriptModal={deleteTranscriptModal}
        handleDeleteTranscript={handleDeleteTranscript}
        handleDeleteTranscriptModalClose={() =>
          setDeleteTranscriptModal({ status: false, data: {} })
        }
      />
    </Content>
  );
};

export default VideoTranscript;
