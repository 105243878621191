import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Sidebar from '../SideBar';
import Header from './Header';
import './Content.css';

const Content = ({
  children,
  style,
  noSidebar = false,
  soloUser,
  customHeader,
  customHeaderComponent,
  headerTitle,
  currentMenu,
  addBtn,
  addBtnTitle,
  addBtnClick,
  addBtnIcon,
  searchBox,
  searchPlaceholder,
  handleSearchChange,
  totalReult,
  dropdownFilter,
  dropdownName,
  dropdownOnChange,
  dropdownOptions,
  dropdownFilter2,
  dropdownName2,
  dropdownOnChange2,
  dropdownOptions2,
  socket,
}) => {
  const history = useHistory();
  const userLogin = useSelector(state => state.userLogin);
  React.useEffect(() => {
    // if (!(userLogin && userLogin.userInfo && userLogin.userInfo.token)) {
    if (!(userLogin?.userInfo?.status === 'success')) {
      history.push('/login');
    }
  }, [userLogin, history]);

  const [notificationCheck, setNotificationCheck] = React.useState(false);

  return (
    <main className='container' onClick={() => setNotificationCheck(false)}>
      {headerTitle !== 'View File' && (
        <Helmet>
          <title>Continia Dashboard</title>
        </Helmet>
      )}
      {!noSidebar && <Sidebar currentMenu={currentMenu} soloUser={soloUser} socket={socket} />}

      <div className={`${noSidebar ? 'mainContentFull' : 'mainContent'}`}>
        <Header
          notificationCheck={notificationCheck}
          setNotificationCheck={setNotificationCheck}
          customHeader={customHeader}
          customHeaderComponent={customHeaderComponent}
          headerTitle={headerTitle}
          addBtn={addBtn}
          addBtnTitle={addBtnTitle}
          addBtnClick={addBtnClick}
          addBtnIcon={addBtnIcon}
          searchBox={searchBox}
          searchPlaceholder={searchPlaceholder}
          handleSearchChange={handleSearchChange}
          totalReult={totalReult}
          dropdownFilter={dropdownFilter}
          dropdownName={dropdownName}
          dropdownOptions={dropdownOptions}
          dropdownOnChange={dropdownOnChange}
          dropdownFilter2={dropdownFilter2}
          dropdownName2={dropdownName2}
          dropdownOptions2={dropdownOptions2}
          dropdownOnChange2={dropdownOnChange2}
        />
        <div style={style ? style : null} className='content'>
          {children}
        </div>

        <div className='footerText'>
          {currentMenu === 'email' && (
            <div className='security_text'>
              <svg className='headerLinkBtn__icons'>
                <use xlinkHref={`/assets/sprite.svg#shield-keyhole`} />
              </svg>
              All emails and data in continia are encripted and secure
            </div>
          )}
        </div>
        <div className='bottom_logo'>
          <img src='/assets/logo-black.png' alt='Continia Logo' className='bottom_logo_image' />
          <p className='bottom_logo_powered'>By The Algorithm</p>
        </div>
      </div>
    </main>
  );
};

export const HeaderLink = ({ type = 'button', isSpecial = false, to, onClick, style, label, active }) => {
  return type === 'link' ? (
    <Link to={to} onClick={onClick} className={active ? 'headerLink headeractive' : 'headerLink'} style={style}>
      {label}
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={active ? (isSpecial ? 'headerLinkBtnSpl headeractive' : 'headerLinkBtn headeractive') : isSpecial ? 'headerLinkBtnSpl' : 'headerLinkBtn'}
      style={style}
    >
      {label}
      {isSpecial && (
        <svg className='headerLinkBtn__icon'>
          <use xlinkHref={`/assets/sprite.svg#icon-sign-out`} />
        </svg>
      )}
    </button>
  );
};

export default Content;
