import React from 'react';
import Table from '../../components/Table';
import NoContent from '../../components/NoContent';
import TableSkeleton from '../../components/Skeletons/TableSkeleton';

const ClinicListTable = ({
  clinicsList,
  searchKey,
  handleToggleButtonClick,
  handleResetPasswordButtonClick,
  handleEditClinicButtonClick,
  handleDeleteClinicButtonClick,
}) => {
  return (
    <Table
      thead={
        <tr>
          <th />
          <th>Name</th>
          <th>E-mail</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Space Used</th>
          <th>File Admins</th>
          <th>File Users</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      }
      tbody={
        clinicsList && clinicsList.loading ? (
          <TableSkeleton rows={10} columns={10} />
        ) : clinicsList &&
          clinicsList.clinics &&
          clinicsList.clinics.status === 'success' &&
          clinicsList.clinics.data &&
          clinicsList.clinics.data.data &&
          clinicsList.clinics.data.data.length > 0 ? (
          clinicsList.clinics.data.data
            .filter(clinic => clinic.name.toLowerCase().includes(searchKey.toLowerCase()))
            .map(clinic => {
              const totalSpace = clinic.customClientDetails ? clinic.customClientDetails.space : '0';
              const usedSpace = clinic.usedSpace ? clinic.usedSpace?.toFixed(3) : '0';
              const totalAdmins = clinic.customClientDetails ? clinic.customClientDetails.adminUsers : '0';
              const usedAdmins = clinic.fileAdminCount ? clinic.fileAdminCount : '0';
              const totalUsers = clinic.customClientDetails ? clinic.customClientDetails.downloadUsers : '0';
              const usedUsers = clinic.fileUserCount ? clinic.fileUserCount : '0';

              return (
                <tr key={clinic._id}>
                  <td className='table__profile_container'>
                    <img src='/assets/profile.png' alt={clinic.name} className='table__profile_image' />
                  </td>
                  <td className='table__row_title'>{clinic.name}</td>
                  <td>{clinic.email}</td>
                  <td>{clinic.phone}</td>
                  <td>{clinic.address}</td>
                  <td>{usedSpace + ' / ' + totalSpace + ' GB'}</td>
                  <td>{usedAdmins + ' / ' + totalAdmins}</td>
                  <td>{usedUsers + ' / ' + totalUsers}</td>
                  <td>
                    <div className='flex'>
                      {clinic.active ? (
                        <p className='table__status'>
                          <span>•</span> Active
                        </p>
                      ) : (
                        <p className='table__status_deactive'>
                          <span>•</span> Deactive
                        </p>
                      )}
                      <label className='switch'>
                        <input
                          id={clinic._id}
                          checked={clinic.active}
                          onChange={() => handleToggleButtonClick(clinic)}
                          type='checkbox'
                          className='checkbox'
                          name='active'
                        />
                        <span className='slider round' />
                      </label>
                    </div>
                  </td>
                  <td className='table__buttons'>
                    <button className='table__button' onClick={() => handleResetPasswordButtonClick(clinic)}>
                      <svg className='table__button_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
                      </svg>
                    </button>
                    <button className='table__button' onClick={() => handleEditClinicButtonClick(clinic)}>
                      <svg className='table__button_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-edit`} />
                      </svg>
                    </button>
                    <button className='table__button_delete' onClick={() => handleDeleteClinicButtonClick(clinic)}>
                      <svg className='table__button_delete_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            })
        ) : (
          <tr className='noContent_table_row'>
            <td colSpan={8}>
              <NoContent title1="Sorry, we couldn't find any client data." />
            </td>
          </tr>
        )
      }
    />
  );
};

export default ClinicListTable;
