import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLogsAction } from '../../redux/actions/userActions';

import Content from '../../components/Content';
import Pagination from '../../components/Pagination';

import LogStyles from './Logs.module.css';
import LogTable from './LogTable';
import { clinicsListAction } from '../../redux/actions/clinicActions';
import ViewLogModal from './ViewLogModal';
import Filters from './Filters';

const LogsScreen = () => {
  const dispatch = useDispatch();

  const [logDataLoading, setLogDataLoading] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  const [logDetails, setLogDetails] = React.useState({ status: false, data: {} });

  const [totalPageSize, setTotalPageSize] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [selectedClient, setSelectedClient] = React.useState('');

  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedRange, setSlectedRange] = React.useState('all');
  const [dateRange, setDateRange] = React.useState({
    startDate: '',
    endDate: '',
  });

  const pageLimit = 10;

  const userLogin = useSelector(state => state.userLogin);
  const logsList = useSelector(state => state.logsList);
  const clinicsList = useSelector(state => state.clinicsList);

  const formatDate = inputDate => {
    if (!inputDate) return '';
    const [year, month, day] = inputDate.split('-');
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };

  React.useEffect(() => {
    dispatch(
      getAllLogsAction(pageNumber, selectedTags.join(',').toLowerCase(), selectedClient, searchKey, formatDate(dateRange.startDate), formatDate(dateRange.endDate))
    );

    if (dateRange.startDate && dateRange.endDate) {
      dispatch(
        getAllLogsAction(pageNumber, selectedTags.join(',').toLowerCase(), selectedClient, searchKey, formatDate(dateRange.startDate), formatDate(dateRange.endDate))
      );
    } else {
      dispatch(getAllLogsAction(pageNumber, selectedTags.join(',').toLowerCase(), selectedClient, searchKey, '', ''));
    }
  }, [dispatch, pageNumber, searchKey, selectedTags, selectedClient, dateRange]);

  React.useEffect(() => {
    if (logsList && logsList.userInfo && logsList.userInfo.status === 'success' && logsList.userInfo.data && logsList.userInfo.data) {
      setTotalPageSize(logsList.userInfo.data.totalResults);
    }
  }, [logsList]);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'admin') {
      dispatch(clinicsListAction());
    }
  }, [dispatch, userLogin]);

  const handlePageChange = async currentPage => {
    setPageNumber(currentPage.selected + 1);
  };

  const refreshLogs = () => {
    setPageNumber(1);
    setSlectedRange('all');
    setSelectedTags([]);
    setSearchKey('');
    setDateRange({ startDate: '', endDate: '' });
    dispatch(getAllLogsAction(1, [].join(',').toLowerCase(), selectedClient));
  };

  return (
    <Content
      dropdownFilter={
        userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'admin' ? true : false
      }
      dropdownName='select-client'
      dropdownOptions={
        clinicsList && clinicsList.loading ? (
          <option value=''>Loading</option>
        ) : (
          <Fragment>
            <option value=''>Show all users</option>
            {clinicsList && clinicsList.clinics && clinicsList.clinics.data && clinicsList.clinics.data.data && clinicsList.clinics.data.data.length > 0 ? (
              clinicsList.clinics.data.data.map(clinic => (
                <option value={clinic._id} key={clinic._id}>
                  {clinic.name}
                </option>
              ))
            ) : (
              <option value=''>No clinics found</option>
            )}
          </Fragment>
        )
      }
      dropdownOnChange={e => {
        setSelectedClient(e.target.value);
        setLogDataLoading(true);
      }}
      currentMenu='logs'
      headerTitle='Audit Logs'
      addBtn={true}
      addBtnIcon='refresh'
      addBtnTitle='Refresh Logs'
      addBtnClick={refreshLogs}
      searchBox={false}
    >
      <Filters
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setPageNumber={setPageNumber}
        selectedRange={selectedRange}
        setSlectedRange={setSlectedRange}
      />
      <div className={LogStyles.content}>
        <LogTable logData={logsList} setLogDataLoading={setLogDataLoading} logDataLoading={logDataLoading} setLogDetails={setLogDetails} />
      </div>
      <div className='table__footer'>
        <p />
        <Pagination onPageChange={handlePageChange} rowsPerPage={pageLimit} totalPageSize={totalPageSize} pageNumber={pageNumber} />
      </div>

      {logDetails.status && <ViewLogModal logDetails={logDetails} handleCloseLogModal={() => setLogDetails({ status: false, data: {} })} />}
    </Content>
  );
};

export default LogsScreen;
