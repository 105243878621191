import React, { useEffect, useState } from 'react'
import styles from './UserGroupAddCard.module.css'

const UserGroupAddCard = ({ key, setCheckboxes, checkboxes, isChecked, setIsChecked, name, email, handleCheckboxToggle, id, loading }) => {
	const [checked, setChecked] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const handleCheckboxChange = () => {
		handleCheckboxToggle({
			name: name,
			email: email,
			id: id
		});
		setChecked(!checked);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, [loading]);

	return (
		<div className={styles.card} key={id}>
			{isLoading ? (
				<div className={`${styles.cards}`}>
					<div className={`${styles.card_title} ${styles.loading}`}></div>
				</div>
			) :
				<>
					<div className={styles.chatLists_user_container}>
						<div className={styles.chatLists_user_imageBox}>
							<img src='/assets/indviual.png' alt='User' className={styles.chatLists_user_image} />
						</div>
					</div>
					<div className={styles.radioContainer}>
						<div className={styles.nameContainer}>
							<span className={styles.userName}>{name}</span>
							<span className={styles.emailName}>{email}</span>
						</div>
						<div
							className={`${styles.circular_checkbox} ${checked ? styles.checkbox : ''}`}
							onClick={handleCheckboxChange}
						>
							{checked && <span className={styles.checkmark}>&#x2714;</span>}
						</div>
					</div>
				</>}
		</div>
	)
}

export default UserGroupAddCard
