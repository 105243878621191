export const LIST_CLINIC_REQUEST = 'LIST_CLINIC_REQUEST';
export const LIST_CLINIC_SUCCESS = 'LIST_CLINIC_SUCCESS';
export const LIST_CLINIC_FAIL = 'LIST_CLINIC_FAIL';
export const LIST_CLINIC_RESET = 'LIST_CLINIC_RESET';

export const GET_CLINIC_STATUS_REQUEST = 'GET_CLINIC_STATUS_REQUEST';
export const GET_CLINIC_STATUS_SUCCESS = 'GET_CLINIC_STATUS_SUCCESS';
export const GET_CLINIC_STATUS_FAIL = 'GET_CLINIC_STATUS_FAIL';
export const GET_CLINIC_STATUS_RESET = 'GET_CLINIC_STATUS_RESET';

export const ADD_CLINIC_REQUEST = 'ADD_CLINIC_REQUEST';
export const ADD_CLINIC_SUCCESS = 'ADD_CLINIC_SUCCESS';
export const ADD_CLINIC_FAIL = 'ADD_CLINIC_FAIL';
export const ADD_CLINIC_RESET = 'ADD_CLINIC_RESET';

export const SELECT_CLINIC_REQUEST = 'SELECT_CLINIC_REQUEST';
export const SELECT_CLINIC_SUCCESS = 'SELECT_CLINIC_SUCCESS';
export const SELECT_CLINIC_FAIL = 'SELECT_CLINIC_FAIL';
export const SELECT_CLINIC_RESET = 'SELECT_CLINIC_RESET';

export const UPDATE_CLINIC_REQUEST = 'UPDATE_CLINIC_REQUEST';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAIL = 'UPDATE_CLINIC_FAIL';
export const UPDATE_CLINIC_RESET = 'UPDATE_CLINIC_RESET';

export const DELETE_CLINIC_REQUEST = 'DELETE_CLINIC_REQUEST';
export const DELETE_CLINIC_SUCCESS = 'DELETE_CLINIC_SUCCESS';
export const DELETE_CLINIC_FAIL = 'DELETE_CLINIC_FAIL';
export const DELETE_CLINIC_RESET = 'DELETE_CLINIC_RESET';

export const DOCTOR_SPECIALITY_REQUEST = 'DOCTOR_SPECIALITY_REQUEST';
export const DOCTOR_SPECIALITY_SUCCESS = 'DOCTOR_SPECIALITY_SUCCESS';
export const DOCTOR_SPECIALITY_FAIL = 'DOCTOR_SPECIALITY_FAIL';
export const DOCTOR_SPECIALITY_RESET = 'DOCTOR_SPECIALITY_RESET';

export const GET_TIME_REQUEST = 'GET_TIME_REQUEST';
export const GET_TIME_SUCCESS = 'GET_TIME_SUCCESS';
export const GET_TIME_FAIL = 'GET_TIME_FAIL';
export const GET_TIME_RESET = 'GET_TIME_RESET';