import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { useGetIpAddress, useSignPDF } from '../../hooks/FileExchange';

import PdfViewerComponent from '../../components/PdfViewerComponent';
import SignatureArea from '../../components/SignatureArea';
import { viewDownloadFileAction, viewFileAction } from '../../redux/actions/folderActions';
import ViewFileSkeleton from '../../components/Skeletons/ViewFileSkeleton';
import SignSuccessModal from './SignSuccessModal';
import classes from './SIgnPDF.module.css';

const SignPDFScreen = () => {
  const { search } = useLocation();
  let data = search.replaceAll("'", '');
  const dataParams = new URLSearchParams(search);
  const link = dataParams.get('link');
  const dispatch = useDispatch();

  const [fileIdEmail, setFileIdEmail] = React.useState('');
  const [fileData, setFileData] = React.useState({
    clinic: '',
    contentKey: '',
    createdAt: '',
    fileSize: '',
    fileUrl: '',
    folder: '',
    mimetype: '',
    name: '',
    protected: false,
    sharedLink: '',
    sharedOn: '',
    updatedAt: '',
    uploadedBy: '',
    zipSize: '',
    __v: 0,
    _id: '',
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [signatureImage, setSignatureImage] = React.useState('');
  const [signaturePosition, setSignaturePosition] = React.useState({ x: 0, y: 0 });
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const [fileDataTemp, setFileDataTemp] = React.useState('');
  const [fileDataTempType, setFileDataTempType] = React.useState('');
  const [newFileUrl, setNewFileUrl] = React.useState('');

  const uploadFile = useSelector(state => state.uploadFile);
  const { signPDFLoading, signPDF } = useSignPDF();
  const { myIPAddress } = useGetIpAddress();

  // React.useEffect(() => {
  //   if (uploadFile) console.log(uploadFile);
  // }, [uploadFile]);

  const b64toBlob = url => {
    fetch(url)
      .then(res => res.blob())
      .then(URL.createObjectURL)
      .then(ret => {
        // newFileUrl = ret;
        // return newFileUrl;
        setNewFileUrl(ret);
      });
    // .then(console.log);
  };

  const getDataTemp = React.useCallback(async () => {
    const response = await dispatch(
      viewDownloadFileAction({
        contentKey: fileData.contentKey,
        passwordProtected: false,
      })
    );
    // console.log(response.headers);
    const contentType = response.headers['Content-Type'] ? response.headers['Content-Type'] : response.headers['content-type'];
    setFileDataTempType(contentType);
    var reader = new FileReader();
    reader.onload = event => {
      // console.log(event.target.result);
      setFileDataTemp(event.target.result);
    };
    reader.readAsDataURL(response.data);
  }, [dispatch, fileData]);

  React.useEffect(() => {
    if (link) {
      const newLink = link.replace(/ /g, '+');
      const decryptedId = CryptoJS.AES.decrypt(newLink, process.env.REACT_APP_LOGIN_PASSWORD_SALT);
      var decryptedData = JSON.parse(decryptedId.toString(CryptoJS.enc.Utf8));

      setFileIdEmail({ id: decryptedData.id, email: decryptedData.email });
      setFileData(decryptedData.pdfData);
    }
  }, [link]);

  React.useEffect(() => {
    if (fileData && fileData.contentKey !== '') {
      getDataTemp();
    }
  }, [fileData, getDataTemp]);

  React.useEffect(() => {
    if (fileDataTempType && fileDataTemp) {
      b64toBlob(fileDataTemp);
    }
  }, [fileDataTempType, fileDataTemp]);

  const handleSignPDF = async () => {
    // const formData = new FormData();
    // formData.append('x', signaturePosition.x);
    // formData.append('y', signaturePosition.y);
    // formData.append('width', 150);
    // formData.append('height', 50);
    // formData.append('pageNumber', pageNumber);
    // formData.append('image', signatureImage);
    // formData.append('pdf', fileDataTemp);
    const formData = {
      signatures: [
        {
          x: signaturePosition.x,
          y: signaturePosition.y,
          width: 150,
          height: 50,
          pageNumber,
        },
      ],
      id: fileIdEmail.id,
      email: fileIdEmail.email,
      name: fileIdEmail.email,
      pdfBase64: fileDataTemp,
      signatureBase64: signatureImage,
      fileData,
    };
    const data = await signPDF(formData);
    if (data.status === 'success') {
      setShowSuccessModal(true);
    }
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{fileData.name ? 'Sign ' + fileData.name + ' - Continia Dashboard' : 'Loading - Continia Dashboard'}</title>
      </Helmet>

      <h1>Sign Document</h1>
      {(uploadFile && uploadFile.loading) || (fileData && !fileData.status && !fileDataTemp && !newFileUrl) ? (
        <ViewFileSkeleton />
      ) : (
        <div className={classes.content}>
          <SignatureArea
            signatureImage={signatureImage}
            setSignatureImage={setSignatureImage}
            handleSignPDF={handleSignPDF}
            signPDFLoading={signPDFLoading}
            myIPAddress={myIPAddress}
          />
          <PdfViewerComponent
            document={newFileUrl}
            fileData={fileData}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            signatureImage={signatureImage}
            setSignaturePosition={setSignaturePosition}
          />
        </div>
      )}

      <SignSuccessModal showSuccessModal={showSuccessModal} />
    </div>
  );
};

export default SignPDFScreen;
