import React from 'react';
import { useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import FileUpload from '../../components/Modal/FileUpload';
import FileExchangeStyles from './FileExchangeScreen.module.css';
import ModalButtonsSkeleton from '../../components/Skeletons/ModalButtonsSkeleton';

const UploadFileModal = ({ uploadFileModal, handleUpload, handleUploadModalClose, data }) => {
  const { uploadFileData, setUploadFileData, selectedFolder } = data;

  const [imageList, setImageList] = React.useState([]);
  const [imageNameList, setImageNameList] = React.useState([]);
  const uploadFile = useSelector(state => state.uploadFile);

  const onFileSelected = acceptedFiles => {
    let tempArray = [...imageList];
    // tempArray = [...new Set([...tempArray, ...e.target.files])];
    tempArray = [...new Set([...tempArray, ...acceptedFiles])];
    setImageList(tempArray);
    setUploadFileData({ value: tempArray, error: '' });

    var array = [];
    // for (let file of e.target.files) {
    for (let file of acceptedFiles) {
      //Only pics
      if (file.type.match('image')) {
        var picReader = new FileReader();
        picReader.addEventListener('load', function (event) {
          var picFile = event.target;
          const imageData = {
            img: picFile.result,
            isImage: true,
          };
          array.push(imageData);
          let tempImageName = [...imageNameList];
          tempImageName = [...new Set([...tempImageName, ...array])];
          setImageNameList(tempImageName);
        });

        //Read the image
        picReader.readAsDataURL(file);
      } else {
        // console.log(file);
        const imageData = {
          img: file,
          isImage: false,
        };
        array.push(imageData);
        let tempImageName = [...imageNameList];
        tempImageName = [...new Set([...tempImageName, ...array])];
        setImageNameList(tempImageName);
      }
    }
  };

  const removeImage = (array, index) => {
    const temp = [...imageNameList];
    temp.splice(index, 1);
    setImageNameList(temp);

    const tempImgList = [...imageList];
    tempImgList.splice(index, 1);
    setImageList(tempImgList);
    setUploadFileData({ value: tempImgList, error: '' });
  };

  return (
    <Modal show={uploadFileModal}>
      <ModalHeading heading={`Upload File to ${selectedFolder.name}`} />

      <ModalForm
        style={{
          paddingRight: '2rem',
          marginBottom: '2.5rem',
          maxHeight: '75vh',
          width: '60vw',
          overflow: 'scroll',
        }}
      >
        <InputsSectionColumn style={{ alignItems: 'center', padding: '0rem' }}>
          <FileUpload
            icon='upload'
            onChange={onFileSelected}
            label='Upload Files'
            label2='You can select multiple files.'
            label2Span='Drag and drop,or Browse files'
            errorMessage={uploadFileData.error}
          />
        </InputsSectionColumn>

        <div className={FileExchangeStyles.advertise_right_container}>
          {imageNameList &&
            imageNameList.map((imgData, ind) => {
              return (
                <div className={[FileExchangeStyles.advertise_right_container_table_body_tr, FileExchangeStyles.imageBox].join(' ')} key={ind + 1}>
                  {imgData.isImage ? <img src={imgData.img} /> : <p>{imgData.img.name}</p>}

                  <svg
                    className={FileExchangeStyles.advertise_img_delete_icon}
                    onClick={e => {
                      removeImage(imageNameList, ind);
                    }}
                  >
                    <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
                  </svg>
                </div>
              );
            })}
        </div>

        {uploadFile &&
          uploadFile.loading &&
          uploadFile.files &&
          (uploadFile.files.loaded === uploadFile.files.total ? (
            <div className={FileExchangeStyles.fileupload_progress}>
              <p className={FileExchangeStyles.fileupload_progress_text}>{Math.floor((uploadFile.files.loaded * 100) / uploadFile.files.total)}% uploaded</p>
              <p className={FileExchangeStyles.fileupload_progress_text}>Encrypting uploaded file(s). Please wait...</p>
            </div>
          ) : (
            <div className={FileExchangeStyles.fileupload_progress}>
              <p className={FileExchangeStyles.fileupload_progress_text}>{Math.floor((uploadFile.files.loaded * 100) / uploadFile.files.total)}% uploaded</p>
              <div className={FileExchangeStyles.fileupload_progress_bar}>
                <div style={{ width: Math.floor((uploadFile.files.loaded * 100) / uploadFile.files.total) + '%' }}></div>
              </div>
            </div>
          ))}

        {uploadFile && uploadFile.loading ? (
          <ModalButtonsSkeleton />
        ) : (
          <ModalButtons submitButtonLabel='Upload File' onSubmit={handleUpload} cancelButtonLabel='Cancel' onCancel={handleUploadModalClose} />
        )}
      </ModalForm>
    </Modal>
  );
};

export default UploadFileModal;
