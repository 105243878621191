import React from 'react';
import classes from './WebsiteForms.module.css';
import { getFormTitle } from '.';

const FormData = ({ noIcon, data, onPDFDownload, onDelete, contentStyle }) => {
  const [formTime, setFormTime] = React.useState('');

  React.useEffect(() => {
    const emailDate =
      new Date(data.createdAt).getDate() === new Date(Date.now()).getDate()
        ? 'Today ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')
        : new Date(data.createdAt).getDate() === new Date(Date.now()).getDate() - 1
        ? 'Yesterday ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')
        : new Date(data.createdAt).toLocaleDateString() + ' ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

    setFormTime(emailDate);
  }, [data]);

  const getFileNameFromLink = link => {
    const linkSplitted = link.split('/');
    const linkSplittedLength = link.split('/').length;

    return linkSplitted[linkSplittedLength - 1] ? linkSplitted[linkSplittedLength - 1] : '';
  };

  return (
    <div className={classes.form_data} id='form_data'>
      <h2 className={classes.form_data_title}>{getFormTitle(data.type, data.agent)}</h2>
      <div className={classes.form_data_content} style={contentStyle}>
        <div className={classes.form_data_header}>
          <div className={classes.form_data_header_left}>
            {!noIcon ? (
              <div className={classes.user_card_icon_box}>
                <svg className={classes.user_card_icon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-user-circle-o`} />
                </svg>
              </div>
            ) : null}
            <div className={classes.form_data_header_data}>
              <h6>{data.from}</h6>
              <p>To: {data.toEmail.join(', ')}</p>
            </div>
          </div>

          <div className={classes.form_data_header_right}>
            {!noIcon ? (
              <div className={classes.user_card_btns}>
                {onPDFDownload && (
                  <button type='button' className={classes.user_card_button} onClick={onPDFDownload}>
                    <svg className={classes.user_card_button_icon}>
                      <use xlinkHref={`/assets/sprite.svg#icon-download_pdf`} />
                    </svg>
                  </button>
                )}
                {onDelete && (
                  <button type='button' className={classes.user_card_button} onClick={onDelete}>
                    <svg className={classes.user_card_button_icon}>
                      <use xlinkHref={`/assets/sprite.svg#icon-delete`} />
                    </svg>
                  </button>
                )}
              </div>
            ) : null}
            <p className={classes.form_data_header_date}>{formTime}</p>
          </div>
        </div>

        <div className={classes.form_data_content_data}>
          <div className={classes.form_data_content_data_attachments}>
            {data.attachments.length ? (
              <>
                <p className={classes.form_data_content_data_text}>
                  {data.attachments.length > 1 ? data.attachments.length + ' attachemnts' : data.attachments.length + ' attachemnt'}
                </p>
                <div className={classes.form_data_content_data_attachment_data}>
                  {data.attachments.map(attachment => (
                    <button key={attachment.location} className={classes.form_data_content_data_attachment} onClick={() => window.open(attachment.location)}>
                      {noIcon ? null : (
                        <svg className={classes.form_data_content_data_attachment_icon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-attachment`} />
                        </svg>
                      )}
                      <p className={classes.form_data_content_data_attachment_text}>{getFileNameFromLink(attachment.location)}</p>
                    </button>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <p className={classes.form_data_content_data_text}>Form Content</p>
          {/* <h4>Below mentioned are the details of "JohnSmith@Continia.com" requesting quote:</h4> */}
          {/* {typeof data.data === 'object'
            ? Object.keys(data.data).length &&
              Object.keys(data.data).map((key, ind) => (
                <p key={key + ind}>
                  <span>{`${key}: `}</span>
                  {data.data[key]}
                </p>
              ))
            : null} */}
          {data.data.length
            ? data.data.map((item, ind) => (
                <p key={item.key + item.value + ind}>
                  <span>{`${item.key}: `}</span>
                  {item.value}
                </p>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default FormData;
