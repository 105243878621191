import React, { Fragment, useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import SelectBox from '../../components/Modal/SelectBox';
import SelectMultiple from '../../components/Modal/SelectMultiple';
import AddUserSkeleton from '../../components/Skeletons/AddUserSkeleton';
import RangeInput from '../../components/Modal/RangeInput';
import { useSelector } from 'react-redux';

const AddUserModal = ({ addUserModal, handleAddModalClose, handleAddUser, handleAddFolder, createUserLoading, updateUserLoading, role, totalSize, data }) => {
  const {
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    userPhone,
    setUserPhone,
    userAddress,
    setUserAddress,
    userRole,
    setUserRole,
    userSpace,
    setUserSpace,
    userPassword,
    setUserPassword,
    userClinic,
    setUserClinic,
    userFolders,
    clinicsList,
    foldersList,
    editUserData,
  } = data;
  // console.log("🚀 ~ AddUserModal ~ foldersList:", foldersList)

  const clinicDetails = useSelector(state => state.clinicDetails);
  const [clientAdmin, setClientAdmin] = useState(false);

  useEffect(() => {
    if (userRole.value === '' || userRole.value === 'clientAdmin' || userRole.value === 'fileAdmin') setClientAdmin(true);
    else setClientAdmin(false);
  }, [userRole]);

  return (
    <Modal show={addUserModal}>
      <ModalHeading heading={editUserData && editUserData._id ? 'Update User Data' : 'Add New User'} />
      {createUserLoading || updateUserLoading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection>
            <InputBox
              label='User Full Name'
              placeholder='eg, John Doe'
              value={userName.value}
              onChange={event => {
                setUserName({ value: event.target.value, error: '' });
              }}
              errorMessage={userName.error}
            />
            <InputBox
              label='User Email'
              placeholder='eg, johndoe@example.com'
              value={userEmail.value}
              onChange={event => {
                setUserEmail({ value: event.target.value, error: '' });
              }}
              errorMessage={userEmail.error}
              disabled={editUserData && editUserData._id}
            />
            <InputBox
              label='User Phone'
              placeholder='eg, 123-456-789'
              value={userPhone.value}
              onChange={event => {
                setUserPhone({ value: event.target.value, error: '' });
              }}
              errorMessage={userPhone.error}
            />
          </InputsSection>

          <InputsSection>
            <InputBox
              label='User Address'
              placeholder='692 SW. 53rd St. Irwin, PA 15642'
              value={userAddress.value}
              onChange={event => {
                setUserAddress({ value: event.target.value, error: '' });
              }}
              errorMessage={userAddress.error}
            />
            <SelectBox
              label='User Role'
              value={userRole.value}
              onChange={event => {
                setUserRole({ value: event.target.value, error: '' });
                setUserSpace({ value: 0, error: '' });
              }}
              errorMessage={userRole.error}
            >
              <option value=''>Select User Role</option>
              {/* {role === 'admin' && (
								<Fragment>
									<option value='manager'>Manager</option>
									<option value='receptionist'>Receptionist</option>
								</Fragment>
							)} */}
              {role === 'admin' && (
                <Fragment>
                  <option value='fileUser'>File User</option>
                </Fragment>
              )}
              <option value='fileAdmin'>File Admin</option>
              <option value='clientAdmin'>Client Admin</option>
              {/* <option value='fileUserOnlyDownload'>File User (Read Only)</option> */}
              <option value='fileUserOnlyDownload'>File User (Read Only)</option>
            </SelectBox>

            {!(editUserData && editUserData._id) && (
              <InputBox
                type='password'
                label='Password'
                placeholder='Set Password'
                value={userPassword.value}
                onChange={event => {
                  setUserPassword({ value: event.target.value, error: '' });
                }}
                errorMessage={userPassword.error}
              />
            )}
          </InputsSection>

          <InputsSection>
            {role === 'admin' && (
              <SelectBox
                label='Select Client'
                value={userClinic.value}
                onChange={event => {
                  setUserClinic({ value: event.target.value, error: '' });
                }}
                errorMessage={userClinic.error}
                disabled={editUserData && editUserData._id}
              >
                <option value=''>Select Client</option>
                {clinicsList && clinicsList.clinics && clinicsList.clinics.data && clinicsList.clinics.data.data && clinicsList.clinics.data.data.length > 0 ? (
                  clinicsList.clinics.data.data.map(clinic => (
                    <option value={clinic._id} key={clinic._id}>
                      {clinic.name}
                    </option>
                  ))
                ) : (
                  <option value=''>No Clients found</option>
                )}
              </SelectBox>
            )}

            {!clientAdmin && userClinic.value && (
              <SelectMultiple
                label='Select Folders'
                handleAddFolder={handleAddFolder}
                userFolders={userFolders}
                loading={foldersList && foldersList.loading}
                loadingText='Loading folders...'
                data={foldersList && foldersList.folders && foldersList.folders.data && foldersList.folders.data.data}
                errorMessage={userFolders.error}
              />
            )}
          </InputsSection>

          {!clinicDetails.loading && userRole.value === 'fileAdmin' && userClinic.value && (
            <InputsSection>
              <RangeInput
                label={`Allocate space (${(totalSize - clinicDetails?.details?.totalAssignedSpace).toFixed(2)}/${totalSize} GB available)`}
                value={userSpace.value * 1024}
                displayValue={userSpace.value.toFixed(2) + ' GB'}
                onChange={event => {
                  setUserSpace({ value: event.target.value / 1024, error: '' });
                }}
                max={
                  editUserData.maxStorageAllowed
                    ? Number(totalSize + editUserData.maxStorageAllowed - clinicDetails?.details?.totalAssignedSpace) * 1024
                    : Number(totalSize - clinicDetails?.details?.totalAssignedSpace) * 1024
                }
                step={256}
                errorMessage={userSpace.error}
              />
            </InputsSection>
          )}

          <ModalButtons
            submitButtonLabel={clinicDetails.loading ? 'Please wait...' : editUserData && editUserData._id ? 'Update User' : 'Add User'}
            submitButtonDisabled={clinicDetails.loading}
            onSubmit={handleAddUser}
            cancelButtonLabel='Cancel'
            onCancel={handleAddModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddUserModal;
