import React from 'react';
import SelectBoxStyles from '../InputBox/TextBox.module.css';

const SelectBox = ({ children, name, label, icon, value, onChange, disabled, errorMessage, style, containerStyle }) => {
  return (
    <div className={SelectBoxStyles.form_input}>
      {label && (
        <label htmlFor={label} className={SelectBoxStyles.form_input__label}>
          {label}
        </label>
      )}
      <div className={SelectBoxStyles.form_input__container} style={containerStyle}>
        {icon && (
          <svg className={SelectBoxStyles.form_input__icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
          </svg>
        )}
        <select value={value} name={name} id={name} className={SelectBoxStyles.form_input__box} onChange={onChange} style={style} disabled={disabled}>
          {children}
        </select>
        {disabled && (
          <svg className={SelectBoxStyles.form_input__icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
          </svg>
        )}
      </div>

      {errorMessage && <p className={SelectBoxStyles.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default SelectBox;
