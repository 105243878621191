import React, { Fragment } from 'react';
import ChatStyles from '../../screens/ChatScreenNew/Chat.module.css';
import SkeletonStyles from './Skeletons.module.css';

const ChatsSkeleton = () => {
	return (
		<Fragment>
			<div className={ChatStyles.chatBox_inc_chat}>
				<div
					className={[ SkeletonStyles.chatBox_chat_inc_msg, SkeletonStyles.skeleton ].join(' ')}
					style={{ height: '7rem', width: '25vw' }}
				/>
				<div className={[ SkeletonStyles.chatBox_chat_time, SkeletonStyles.skeleton ].join(' ')} />
			</div>
			<div className={ChatStyles.chatBox_inc_chat}>
				<div
					className={[ SkeletonStyles.chatBox_chat_inc_msg, SkeletonStyles.skeleton ].join(' ')}
					style={{ width: '20vw' }}
				/>
				<div className={[ SkeletonStyles.chatBox_chat_time, SkeletonStyles.skeleton ].join(' ')} />
			</div>
			<div className={SkeletonStyles.chatBox_out_chat}>
				<div
					className={[ SkeletonStyles.chatBox_chat_out_msg, SkeletonStyles.skeleton ].join(' ')}
					style={{ width: '15vw' }}
				/>
				<div className={[ SkeletonStyles.chatBox_chat_time_out, SkeletonStyles.skeleton ].join(' ')} />
			</div>
			<div className={SkeletonStyles.chatBox_out_chat}>
				<div
					className={[ SkeletonStyles.chatBox_chat_out_msg, SkeletonStyles.skeleton ].join(' ')}
					style={{ width: '20vw' }}
				/>
				<div className={[ SkeletonStyles.chatBox_chat_time_out, SkeletonStyles.skeleton ].join(' ')} />
			</div>

			<div className={SkeletonStyles.chatBox_out_chat}>
				<div
					className={[ SkeletonStyles.chatBox_chat_out_msg, SkeletonStyles.skeleton ].join(' ')}
					style={{ height: '7rem', width: '10vw' }}
				/>
				<div className={[ SkeletonStyles.chatBox_chat_time_out, SkeletonStyles.skeleton ].join(' ')} />
			</div>
		</Fragment>
	);
};

export default ChatsSkeleton;
