/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import VideoContext from './VideoContext';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CALL_INCOMING,
  CALL_RESET,
} from '../../../redux/constants/userConstants';

// const URL = "https://fathomless-tundra-67025.herokuapp.com/";

// export const socket = io(SERVER_URL);

const VideoState = ({ children, socket }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState(null);
  const [chat, setChat] = useState([]);
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [call, setCall] = useState({});
  const [me, setMe] = useState('');
  const [userName, setUserName] = useState('');
  const [otherUser, setOtherUser] = useState('');
  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState();
  const [msgRcv, setMsgRcv] = useState('');
  const [screenShare, setScreenShare] = useState(false);
  const [parmas, setParams] = useState(null);

  const myVideo = useRef();
  const myVideo2 = useRef();
  const userVideo = useRef();
  const userVideo2 = useRef();
  const connectionRef = useRef();
  const screenTrackRef = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const callReducer = useSelector((state) => state.callReducer);

  useEffect(() => {
    if (
      window.location &&
      (window.location.hash.includes('#/make/call/video') ||
        window.location.hash.includes('#/make/receive/video'))
    ) {
      // this code shows our own camera stream to us. this is local video stream.
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((currentStream) => {
          setStream(currentStream);
          if (myVideo && myVideo.current)
            myVideo.current.srcObject = currentStream;
          if (myVideo2 && myVideo2.current)
            myVideo2.current.srcObject = currentStream;
        });
      console.warn("My Own Stream is ready")
    } else {
      if (stream) {
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.data &&
      userLogin.userInfo.data.user
    ) {
      setName(userLogin.userInfo.data.user.name);
      setUserId(userLogin.userInfo.data.user._id);
    }
  }, []);

  useEffect(() => {
    if (connectionRef.current) {
      connectionRef.current.on('stream', (currentStream) => {
        //console.log('Got remote user Stream');
        if (userVideo) userVideo.current.srcObject = currentStream;
        if (userVideo2) userVideo2.current.srcObject = currentStream;
      });
    }
  }, [connectionRef.current]);

  useEffect(() => {
    if (callReducer.status) {
      let { from, name: callerName, signal, socketId } = callReducer.data;
      if (callReducer.status === 'Answer') {
        history.push(`/make/receive/video/${from}/${callerName}/${socketId}`);
      }
      if (callReducer.status === 'Decline' && socket) {
        socket.emit('callDecline', { id: socketId });
      }
    }
  }, [callReducer]);

  useEffect(() => {
    if (parmas && stream) {
      if (parmas.call === 'call') {
        console.log('Initiating video call request');
        callUser(parmas);
      } else if (parmas.call === 'receive') {
        console.log('Received video call request');
        if (call.signal) answerCall();
      }
    }
  }, [parmas, stream]);


  useEffect(() => {
    if (socket) {
      socket.on('me', (id) => setMe(id));
      socket.on('endCall', () => {
        endCallAndReset();
      });

      socket.on('updateUserMedia', ({ type, currentMediaStatus }) => {
        if (currentMediaStatus !== null || !currentMediaStatus.length) {
          switch (type) {
            case 'video':
              setUserVdoStatus(currentMediaStatus);
              break;
            case 'mic':
              setUserMicStatus(currentMediaStatus);
              break;
            default:
              setUserMicStatus(currentMediaStatus[0]);
              setUserVdoStatus(currentMediaStatus[1]);
              break;
          }
        }
      });

      socket.on('callUser', ({ from, name: callerName, signal, socketId }) => {
        console.warn('Received call request over socket')
        setCall({
          isReceivingCall: true,
          from,
          name: callerName,
          signal,
          socketId,
        });
        dispatch({
          type: CALL_INCOMING,
          payload: { from, name: callerName, signal, socketId },
        });
      });
      socket.on('callDecline', (user) => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        setCallEnded(true);
        setTimeout(() => {
          history.goBack();
        }, 1000);
      });
    }
  }, [socket]);

  // useEffect(() => {
  //   //console.log(chat);
  // }, [chat]);

  const answerCall = () => {
    //console.log('Call answered', stream);
    setCallAccepted(true);
    setOtherUser(call.from);
    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on('signal', (signal) => {
      if (socket)
        socket.emit('answerCall', {
          signal: signal,
          to: call.from,
          toSocket: call.socketId,
          userName: name,
          type: 'both',
          myMediaStatus: [myMicStatus, myVdoStatus],
        });
    });

    peer.on('stream', (currentStream) => {
      //console.log('Got remote user Stream');
      userVideo.current.srcObject = currentStream;
      userVideo2.current.srcObject = currentStream;
    });
    peer.on('connect', () => console.log('Answer peer Connected'));
    // peer.on('data', (data) => console.log('Answer peer data'))
    peer.on('error', (error) => console.log('Answer peer has error', error));
    peer.on('close', () => console.log('Answer peer has closed'));
    // //console.log('Called to answer Signal', new Date().toTimeString() + ":" + new Date().getMilliseconds());
    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (remoteUser) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      // config: {
      //   iceServers: [
      //     { urls: 'stun:stun.l.google.com:19302' },
      //     { urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
      //   ],
      // },
    });
    setOtherUser(remoteUser.userId);
    // //console.log(remoteUser.name);
    setUserName(remoteUser.name);
    peer.on('signal', (data) => {     
      if (
        socket &&
        userLogin &&
        userLogin.userInfo &&
        userLogin.userInfo.data &&
        userLogin.userInfo.data.user
      ) {
        socket.emit('callUser', {
          userToCall: remoteUser.socketId,
          signalData: data,
          fromSocket: socket.id,
          from: userLogin.userInfo.data.user._id,
          name: userLogin.userInfo.data.user.name,
        });
      }
    });

    peer.on('stream', (currentStream) => {
      //console.log('Got remote user Stream');
      userVideo.current.srcObject = currentStream;
      // userVideo2.current.srcObject = currentStream;
    });
    peer.on('connect', () => console.warn('caller peer Connected'));
    // peer.on('data', (data) => console.log('caller peer data'))
    peer.on('error', (error) => console.log('caller peer has error', error));
    peer.on('close', () => console.log('caller peer has closed'));
    if (socket)
      socket.on('callAccepted', ({ signal, userName }) => {
        //console.log('Call accepted');
        setCallAccepted(true);
        setUserName(userName);
      
        peer.signal(signal);
        socket.emit('updateMyMedia', {
          type: 'both',
          currentMediaStatus: [myMicStatus, myVdoStatus],
        });
      });

    connectionRef.current = peer;
    // //console.log(connectionRef.current);
  };

  const updateVideo = () => {
    //console.log('Video Updated');
    setMyVdoStatus((currentStatus) => {
      socket.emit('updateMyMedia', {
        type: 'video',
        currentMediaStatus: !currentStatus,
      });
      stream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  const updateMic = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit('updateMyMedia', {
        type: 'mic',
        currentMediaStatus: !currentStatus,
      });
      stream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };

  //SCREEN SHARING
  const handleScreenSharing = () => {
    // if (!myVdoStatus) {
    //   message.error('Turn on your video to share the content', 2);
    //   return;
    // }

    if (!screenShare) {
      navigator.mediaDevices
        .getDisplayMedia({ cursor: true })
        .then((currentStream) => {
          const screenTrack = currentStream.getTracks()[0];

          // replaceTrack (oldTrack, newTrack, oldStream);
          connectionRef.current.replaceTrack(
            connectionRef.current.streams[0]
              .getTracks()
              .find((track) => track.kind === 'video'),
            screenTrack,
            stream
          );

          // Listen click end
          screenTrack.onended = () => {
            connectionRef.current.replaceTrack(
              screenTrack,
              connectionRef.current.streams[0]
                .getTracks()
                .find((track) => track.kind === 'video'),
              stream
            );
            if (myVideo && myVideo.current) myVideo.current.srcObject = stream;
            setScreenShare(false);
          };
          if (myVideo && myVideo.current)
            myVideo.current.srcObject = currentStream;
          screenTrackRef.current = screenTrack;
          setScreenShare(true);
        })
        .catch((error) => {
          //console.log('No stream for sharing');
        });
    } else {
      screenTrackRef.current.onended();
    }
  };
  const handleStopScreenSharing = () => {
    screenTrackRef.current.onended();
  };

  //full screen
  const fullScreen = (e) => {
    const elem = e.target;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const leaveCall = () => {
    if (callAccepted) {
      if (socket) {
        socket.emit('endCall', {
          id: parmas.call === 'call' ? parmas.socketId : call.socketId,
        });
      }
      endCallAndReset();
    } else {
      if (socket) {
        socket.emit('endCallNotAccepted', {
          id: parmas.call === 'call' ? parmas.socketId : call.socketId,
        });

        endCallAndReset();
      }
    }
  };
  const endCallAndReset = () => {
    setCallEnded(true);

    if (stream) {
      //console.log('CAMERA OFF', stream.getTracks().length);
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    } else {
      //console.log('CAMERA STREAM NOT FOUND');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1500);

      // const mediaStream = myVideo.current.srcObject;
      // mediaStream.getTracks().forEach((track) => {
      //   track.stop();
      // });
    }
    // dispatch({ type: CALL_RESET }).then(rs => {

    // })
    // setTimeout(() => {
      connectionRef.current.destroy();
      history.replace('/chat');
      setTimeout(() =>{
        window.location.reload();
      }, 1000)
      // window.close();
    // }, 1000);
  };
  const leaveCall1 = () => {
    socket.emit('endCall', { id: otherUser });
  };
  const sendMsg = (value) => {
    socket.emit('msgUser', { name, to: otherUser, msg: value, sender: name });
    let msg = {};
    msg.msg = value;
    msg.type = 'sent';
    msg.timestamp = Date.now();
    msg.sender = name;
    setChat([...chat, msg]);
  };

  return (
    <VideoContext.Provider
      value={{
        call,
        callAccepted,
        myVideo,
        myVideo2,
        userVideo,
        userVideo2,
        stream,
        name,
        setName,
        userId,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        sendMsg,
        msgRcv,
        chat,
        setChat,
        setMsgRcv,
        setOtherUser,
        leaveCall1,
        userName,
        myVdoStatus,
        setMyVdoStatus,
        userVdoStatus,
        setUserVdoStatus,
        updateVideo,
        myMicStatus,
        userMicStatus,
        updateMic,
        screenShare,
        handleScreenSharing,
        handleStopScreenSharing,
        fullScreen,
        parmas,
        setParams,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoState;
