import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import VideoContext from '../context/VideoContext';
import { Card, Modal, Input, notification, Avatar } from 'antd';
import { UserOutlined, MessageOutlined } from '@ant-design/icons';

import ModalContainer from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import ModalForm from '../../../components/Modal/ModalForm';
import InputsSection from '../../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../../components/Modal/ModalButtons';

import ChatStyles from '../../ChatScreenNew/Chat.module.css';
import './Video.css';

import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../../components/Modal/TextArea';
import Button from '../../../components/Button';
import VideoNotesModal from '../../ChatScreenNew/VideoNotesModal';
import {
  createVideoNotesAction,
  deleteVideoNotesAction,
  getAllVideoNotesAction,
  updateVideoNotesAction,
} from '../../../redux/actions/videoNoteActions';

import { toast } from 'react-hot-toast';
import SelectBox from '../../../components/SelectBoxButton';
import DeleteVideoNoteModal from '../../ChatScreenNew/DeleteVideoNoteModal';

// import { socket } from "../context/VideoState";

// const socket = io()
const { Search } = Input;
const Video = ({ data, socket }) => {
  const {
    call,
    callAccepted,
    myVideo,
    myVideo2,
    userVideo,
    userVideo2,
    stream,
    name,
    setName,
    callEnded,
    me,
    callUser,
    leaveCall,
    answerCall,
    sendMsg: sendMsgFunc,
    msgRcv,
    chat,
    setChat,
    userName,
    myVdoStatus,
    screenShare,
    fullScreen,
    handleScreenSharing,
    handleStopScreenSharing,
    userVdoStatus,
    updateVideo,
    myMicStatus,
    userMicStatus,
    updateMic,
    parmas,
    setParams,
  } = useContext(VideoContext);

  const [sendMsg, setSendMsg] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [callEndModalStatus, setCallEndModalStatus] = useState(false);
  const [screenShared, setScreenShared] = useState(false);

 

  if (socket)
    socket.on('msgRcv', ({ name, msg: value, sender }) => {
      let msg = {};
      msg.msg = value;
      msg.type = 'rcv';
      msg.sender = sender;
      msg.timestamp = Date.now();
      setChat([...chat, msg]);
    });

  const dummy = useRef();

  useEffect(() => {
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);

  useEffect(() => {
    console.log('Stream Change', stream);
    if (data) {
      setParams(data);
    }
  }, [data]);

  const onSearch = (value) => {
    if (value && value.length) sendMsgFunc(value);
    setSendMsg('');
  };

  useEffect(() => {
    if (msgRcv.value && !isModalVisible) {
      notification.open({
        message: '',
        description: `${msgRcv.sender}: ${msgRcv.value}`,
        icon: <MessageOutlined style={{ color: '#108ee9' }} />,
      });
    }
  }, [msgRcv]);

  return (
    <div className='main_video_container'>
      <div className='main_video_frame'>
        {callAccepted && !callEnded && userVideo ? (
          <video
            playsInline
            ref={userVideo}
            onDoubleClick={fullScreen}
            autoPlay
            className='local_video_player'
            style={{
              opacity: `${userVdoStatus ? '1' : '0'}`,
            }}
          />
        ) : (
          <div className='bouncing-loader'>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <div className='local_video'>
          {!screenShare ? (
            <video
              playsInline
              muted
              onDoubleClick={fullScreen}
              ref={myVideo}
              autoPlay
              className='local_video_player'
              style={{
                opacity: `${myVdoStatus ? '1' : '0'}`,
              }}
            />
          ) : (
            <div>
              <video
                playsInline
                muted
                onDoubleClick={fullScreen}
                ref={myVideo2}
                autoPlay
                className='local_video_player'
                style={{
                  opacity: `${myVdoStatus ? '1' : '0'}`,
                }}
              />
              {/* {userVideo2 && (
                <video
                  playsInline
                  muted
                  onDoubleClick={fullScreen}
                  ref={userVideo2}
                  autoPlay
                  className='local_video_player'
                  style={{
                    opacity: ${myVdoStatus ? '1' : '0'},
                  }}
                />
              )} */}
            </div>
          )}
        </div>

       
        <div className='remote_user_name'>
          <p>{call?.name ? call?.name : parmas?.name}</p>
          {!userMicStatus && (
            <svg className='icon_circle_white'>
              <use xlinkHref={`/assets/sprite.svg#icon-mic-off`} />
            </svg>
          )}
          {!userVdoStatus && (
            <svg className='icon_circle_white'>
              <use xlinkHref={`/assets/sprite.svg#icon-video-off`} />
            </svg>
          )}
        </div>
        <div className='main_video_actions'>
          <div className='main_video_action_btns'>
            <div className='icon_circle' onClick={() => updateMic()}>
              <svg>
                <use
                  xlinkHref={`/assets/sprite.svg#icon-${
                    myMicStatus ? 'unmute' : 'mute'
                  }`}
                />
              </svg>
            </div>
            <div className='icon_circle' onClick={() => updateVideo()}>
              <svg>
                <use
                  xlinkHref={`/assets/sprite.svg#icon-video-${
                    myVdoStatus ? 'on' : 'off'
                  }`}
                />
              </svg>
            </div>
            <div
              className='icon_circle'
              onClick={() => {
                handleScreenSharing();
                // setScreenShared(true);
              }}
            >
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-share-screen`} />
              </svg>
            </div>
            <div
              className='icon_circle icon_red'
              onClick={() => {
                if (callAccepted && !callEnded && userVideo) {
                  setCallEndModalStatus(true);
                } else {
                  leaveCall();
                }
              }}
            >
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-hang-up`} />
              </svg>
            </div>
          </div>

          {/* {screenShare && (
            <p className='shareScreenStatus'>
              Your screen is currently being shared */}
              {/* <button onClick={handleStopScreenSharing}>Stop Sharing</button> */}
            {/* </p>
          )} */}
        </div>
      </div>
      <Dictaphone
        show={callAccepted && !callEnded && userVideo}
        data={data}
        socket={socket}
        callEndModalStatus={callEndModalStatus}
        setCallEndModalStatus={setCallEndModalStatus}
      />
    </div>
  );
};

export const Dictaphone = ({
  show,
  data,
  socket,
  callEndModalStatus,
  setCallEndModalStatus,
}) => {
  const { leaveCall, userId } = useContext(VideoContext);
  const dispatch = useDispatch();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const transcriptEndRef = React.useRef(null);
  const transcriptReceivedEndRef = React.useRef(null);
  const [transcriptText, setTranscriptText] = React.useState('');
  const [transcriptTextRecived, setTranscriptTextRecived] = React.useState('');

  const [videoNotesModal, setVideoNotesModal] = React.useState(false);
  const [videoNotesData, setVideoNotesData] = React.useState({
    loading: false,
    data: [],
  });
  const [newVideoNote, setNewVideoNote] = React.useState({
    value: '',
    error: '',
  });
  const [noteType, setNoteType] = React.useState({
    value: 'internal',
    error: '',
  });
  const [videoNoteSelected, setVideoNoteSelected] = React.useState({});
  const [newVideoNoteText, setNewVideoNoteText] = React.useState({
    value: '',
    error: '',
  });
  const [newVideoNoteType, setNewVideoNoteType] = React.useState({
    value: '',
    error: '',
  });
  const [videoNotesDeleteModal, setVideoNotesDeleteModal] = React.useState({
    status: false,
    data: {},
  });
  // console.log(data);

  const userLogin = useSelector((state) => state.userLogin);

  const scrollToBottom = () => {
    transcriptEndRef &&
      transcriptEndRef.current &&
      transcriptEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollReceivedToBottom = () => {
    transcriptReceivedEndRef &&
      transcriptReceivedEndRef.current &&
      transcriptReceivedEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (show && browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
      scrollToBottom();
      scrollReceivedToBottom();
    }
  }, [show, browserSupportsSpeechRecognition]);

  React.useEffect(() => {
    if (transcript !== '') {
      setTranscriptText(transcript);
      scrollToBottom();
    }
  }, [transcript]);

  React.useEffect(() => {
    if (socket && data)
      socket.emit('videoTranscript', {
        toSocketId: data.socketId,
        transcriptText,
      });
  }, [socket, data, transcriptText]);

  React.useEffect(() => {
    if (socket && data) {
      socket.on('videoTranscriptText', async ({ transcriptText, userId }) => {
        // console.log(transcriptText, userId, data.userId);
        // setTranscriptTextRecived(transcriptText);
        if (userId === data.userId) setTranscriptTextRecived(transcriptText);
        scrollReceivedToBottom();
      });
    }
  }, [socket, data]);

  const handleTranscriptSave = () => {
    if (socket)
      socket.emit('saveVideoTranscript', {
        transcriptData: {
          transcriptOneUser: userId,
          transcriptOneText: transcriptText,
          transcriptTwoUser: data.userId,
          transcriptTwoText: transcriptTextRecived,
          // transcript: [
          //   {
          //     user: userId,
          //     transcriptText: transcriptText,
          //   },
          //   {
          //     user: data.userId,
          //     transcriptText: transcriptTextRecived,
          //   },
          // ],
        },
      });

    SpeechRecognition.stopListening();
    setCallEndModalStatus(false);
    leaveCall();
  };

  const getAllVideoNotes = React.useCallback(async () => {
    if (data && data.userId && videoNotesModal) {
      setVideoNotesData({ loading: true, data: [] });
      const responseData = await dispatch(getAllVideoNotesAction(data.userId));

      if (responseData && responseData.data && responseData.data.data) {
        setVideoNotesData({ loading: false, data: responseData.data.data });
      } else {
        setVideoNotesData({ loading: false, data: [] });
      }
    }
  }, [dispatch, data, videoNotesModal]);

  const handleEditVideoNote = async () => {
    if (videoNoteSelected && videoNoteSelected._id) {
      if (
        newVideoNoteText.value === '' ||
        newVideoNoteText.value.trim() === ''
      ) {
        setNewVideoNoteText({
          ...newVideoNoteText,
          error: 'Please enter video note',
        });
      } else if (
        newVideoNoteType.value === '' ||
        newVideoNoteType.value.trim() === ''
      ) {
        setNewVideoNoteType({
          ...newVideoNoteType,
          error: 'Please select video note type',
        });
      } else {
        setVideoNotesData({ ...videoNotesData, loading: true });
        const formData = {
          noteText: newVideoNoteText.value,
          noteType: newVideoNoteType.value,
        };

        const data = await dispatch(
          updateVideoNotesAction(videoNoteSelected._id, formData)
        );

        if (data && data.status === 'success') {
          getAllVideoNotes();
          setNewVideoNoteText({ value: '', error: '' });
          setNewVideoNoteType({ value: '', error: '' });
          setVideoNoteSelected({});
          toast.success('Video Note Updated Successfully!');
        } else {
          setVideoNotesData({ ...videoNotesData, loading: false });
          setNewVideoNoteType({
            ...newVideoNoteType,
            error: 'Something went wrong.',
          });
        }
      }
    } else {
      alert('Please select video note');
    }
  };

  const handleDeleteNote = async () => {
    if (videoNotesDeleteModal.data && videoNotesDeleteModal.data._id) {
      const data = await dispatch(
        deleteVideoNotesAction(videoNotesDeleteModal.data._id)
      );

      if (data && data.status === 'success') {
        getAllVideoNotes();
        setVideoNotesDeleteModal({ status: false, data: {} });
        toast.error('Video Note Deleted Successfully!');
      } else {
        setVideoNotesData({ ...videoNotesData, loading: false });
        setNewVideoNoteType({
          ...newVideoNoteType,
          error: 'Something went wrong.',
        });
      }
    } else {
      alert('Please select video note');
    }
  };

  React.useEffect(() => {
    getAllVideoNotes();
  }, [getAllVideoNotes]);

  React.useEffect(() => {
    if (videoNoteSelected && Object.keys(videoNoteSelected).length > 0) {
      setNewVideoNoteText({ value: videoNoteSelected.noteText, error: '' });
      setNewVideoNoteType({ value: videoNoteSelected.noteType, error: '' });
    }
  }, [videoNoteSelected]);

  const handleSubmitVideoNote = async () => {
    if (
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.data &&
      userLogin.userInfo.data.user &&
      data &&
      data.userId
    ) {
      if (newVideoNote.value === '' || newVideoNote.value.trim === '') {
        setNewVideoNote({
          ...newVideoNote,
          error: 'Please enter note ',
        });
      } else {
        const formData = {
          noteBy: userLogin.userInfo.data.user._id,
          noteText: newVideoNote.value,
          noteType: noteType.value,
          noteOn: data.userId,
        };

        const responseData = await dispatch(createVideoNotesAction(formData));

        if (responseData && responseData.status === 'success') {
          toast.success('Note saved successfully!');
          setNewVideoNote({
            value: '',
            error: '',
          });
        } else {
          setNewVideoNote({
            ...newVideoNote,
            error: 'Something went wrong ',
          });
        }
      }
    }
  };

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser doesn't support speech recognition.</span>;
  // }

  return (
    <div className='video_chat_text'>
      {!browserSupportsSpeechRecognition ? (
        <span>Browser doesn't support speech recognition.</span>
      ) : (
        <Fragment>
          {(transcriptText || transcriptTextRecived) && (
            <p className='video_chat_chat_heading'>
              {/* Microphone: {listening ? 'on' : 'off'} */}
              Video Transcript Data
            </p>
          )}

          {transcriptText && (
            <div className='video_chat_chats'>
              <div className='video_chat_chats_scroll'>
                <div className='video_chat_chats_transcriptText'>
                  <p>{transcriptText}</p>
                </div>
                <p className='video_chat_chats_transcriptUser'>You</p>
                <div ref={transcriptEndRef} />
              </div>
              {/* <div className={ChatStyles.chatBox_out_chat}>
            <div className={ChatStyles.chatBox_chat_msgs}>
              <div className={ChatStyles.chatBox_chat_out_msg}>
                <p>{transcriptText}</p>
              </div>
            </div>
            <p className={ChatStyles.chatBox_chat_time}>
              You
            </p>
          </div> */}
            </div>
          )}
        </Fragment>
      )}

      {transcriptTextRecived && (
        <div className='video_chat_chats'>
          <div className='video_chat_chats_scroll'>
            <div className='video_chat_chats_transcriptText'>
              <p>{transcriptTextRecived}</p>
            </div>
            <p className='video_chat_chats_transcriptUser'>
              {data && data.name}
            </p>
            <div ref={transcriptReceivedEndRef} />
          </div>

          {/* <div className={ChatStyles.chatBox_inc_chat}>
            <div className={ChatStyles.chatBox_chat_msgs}>
              <div className={ChatStyles.chatBox_chat_inc_msg}>
                <p>{transcriptTextRecived}</p>
              </div>
            </div>
            <p className={ChatStyles.chatBox_chat_time}>
              {data && data.name}
            </p>
          </div> */}
        </div>
      )}

      <div className='video_chat_chats'>
        <div className='video_chat_chats_scroll' style={{ height: '28vh' }}>
          <br />
          <TextArea
            placeholder='Write note here...'
            rows={5}
            value={newVideoNote.value}
            onChange={(e) =>
              setNewVideoNote({ value: e.target.value, error: '' })
            }
            errorMessage={newVideoNote.error}
          />
          {/* <SelectBox
            // label='Note Type'
            value={noteType.value}
            onChange={(e) => setNoteType({ value: e.target.value, error: '' })}
            errorMessage={noteType.error}
          >
            <option value='internal'>Internal Note</option>
            <option value='public'>Shared with user</option>
          </SelectBox> */}

          <SelectBox
            buttons={[
              {
                label: 'Internal Note',
                value: 'internal',
                onClick: () => setNoteType({ value: 'internal', error: '' }),
              },
              {
                label: 'Shared with user',
                value: 'public',
                onClick: () => setNoteType({ value: 'public', error: '' }),
              },
            ]}
            value={noteType.value}
          />

          <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <Button name='Save' onClick={handleSubmitVideoNote} />
            <Button
              name='Previous Notes'
              onClick={() => setVideoNotesModal(true)}
            />
          </div>
        </div>
      </div>

      {/* {true && (
        <div className='video_chat_chat_btns'>
          <button
            // onClick={SpeechRecognition.startListening}
            onClick={() =>
              SpeechRecognition.startListening({ continuous: true })
            }
            className='video_chat_chat_btn'
          >
            Start
          </button>
          <button
            onClick={SpeechRecognition.stopListening}
            className='video_chat_chat_btn'
          >
            Stop
          </button>
          <button onClick={resetTranscript} className='video_chat_chat_btn'>
            Reset
          </button>
        </div>
      )} */}

      {callEndModalStatus && (
        <ModalContainer show={callEndModalStatus}>
          <ModalHeading heading='Save Transcript Data' />

          <ModalForm style={{ marginBottom: '2.5rem' }}>
            <InputsSection style={{ width: '34rem' }}>
              <p className='primaryText'>
                Do you want to save transcript of this call which can be used
                for the future use?
              </p>
            </InputsSection>

            <ModalButtons
              submitButtonLabel='Yes'
              onSubmit={handleTranscriptSave}
              cancelButtonLabel='No'
              onCancel={() => {
                SpeechRecognition.stopListening();
                setCallEndModalStatus(false);
                leaveCall();
              }}
            />
          </ModalForm>
        </ModalContainer>
      )}

      {videoNotesModal && (
        <VideoNotesModal
          videoNotesModal={videoNotesModal}
          loading={videoNotesData.loading}
          videoNotes={videoNotesData.data}
          selectedUser={data}
          handleModalClose={() => setVideoNotesModal(false)}
          data={{
            videoNoteSelected,
            setVideoNoteSelected,
            newVideoNoteText,
            setNewVideoNoteText,
            newVideoNoteType,
            setNewVideoNoteType,
            handleEditVideoNote,
            setVideoNotesDeleteModal,
          }}
        />
      )}

      {videoNotesDeleteModal.status && (
        <DeleteVideoNoteModal
          loading={false}
          handleSubmit={handleDeleteNote}
          videoNotesDeleteModal={videoNotesDeleteModal}
          handleModalClose={() =>
            setVideoNotesDeleteModal({ status: false, data: {} })
          }
        />
      )}
    </div>
  );
};

export default Video;
