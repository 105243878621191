import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';
import ComposeButton from './ComposeButton/ComposeButton';
import styles from "./SecureEmail.module.css"
const DeleteMailModal = ({ deleteMailModal, loading, handleDeleteMailModalClose, handleDeleteMail }) => {
	return (
		<Modal show={deleteMailModal.status} style={{backgroundColor: "var(--primary-color)" , minWidth: "40rem"}}>
			<ModalHeading heading={`Delete Email ?`} style={{color:"white" , marginLeft: "2rem"}} />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' ,  backgroundColor: "transparent", width:"100%" }}>
						<p className='primaryText' style={{color: "white" , fontSize: "2rem" }}>Are you sure you want to delete this email?</p>
						{/* <p className='dangerText'>Please note that email once deleted will not be restored back.</p> */}
					</InputsSection>
                    <div className={styles.Buttons}>
                    <ComposeButton label="Yes, Delete" style={{backgroundColor: "var(--white)" , color: "var(--primary-color)"}} icon="trash"/>
                    <ComposeButton label="Cancel" style={{backgroundColor: "var(--white)" , color: "var(--primary-color)"}} icon="cross" onClick={handleDeleteMailModalClose}/>
                    </div>
				</ModalForm>
			)}
		</Modal>
	);
};

export default DeleteMailModal;