import {
	LIST_CLINIC_REQUEST,
	LIST_CLINIC_SUCCESS,
	LIST_CLINIC_FAIL,
	LIST_CLINIC_RESET,
	GET_CLINIC_STATUS_REQUEST,
	GET_CLINIC_STATUS_SUCCESS,
	GET_CLINIC_STATUS_FAIL,
	GET_CLINIC_STATUS_RESET,
	ADD_CLINIC_REQUEST,
	ADD_CLINIC_SUCCESS,
	ADD_CLINIC_FAIL,
	ADD_CLINIC_RESET,
	UPDATE_CLINIC_REQUEST,
	UPDATE_CLINIC_SUCCESS,
	UPDATE_CLINIC_FAIL,
	UPDATE_CLINIC_RESET,
	DELETE_CLINIC_REQUEST,
	DELETE_CLINIC_SUCCESS,
	DELETE_CLINIC_FAIL,
	DELETE_CLINIC_RESET
} from '../constants/clinicConstants';

export const clinicsListReducer = (state = {}, action) => {
	switch (action.type) {
		case LIST_CLINIC_REQUEST:
			return { loading: true };

		case LIST_CLINIC_SUCCESS:
			return { clinics: action.payload, loading: false };

		case LIST_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case LIST_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CLINIC_STATUS_REQUEST:
			return { loading: true };

		case GET_CLINIC_STATUS_SUCCESS:
			return { clinics: action.payload, loading: false };

		case GET_CLINIC_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case GET_CLINIC_STATUS_RESET:
			return {};

		default:
			return state;
	}
};

export const addClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_CLINIC_REQUEST:
			return { loading: true };

		case ADD_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case ADD_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const updateClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_CLINIC_REQUEST:
			return { loading: true };

		case UPDATE_CLINIC_SUCCESS:
			return { clinic: action.payload, loading: false };

		case UPDATE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_CLINIC_REQUEST:
			return { ...state, loading: true };

		case DELETE_CLINIC_SUCCESS:
			return { clinic: action.payload, loading: false };

		case DELETE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};
