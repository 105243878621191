import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeactivateUserModalSkeleton from '../../components/Skeletons/DeactivateUserModalSkeleton';

const DeactiveAccountModal = ({
	deactivateUserModal,
	loading,
	handleDeactivateUserModalClose,
	handleDeactivateUser
}) => {
	return (
		<Modal show={deactivateUserModal}>
			<ModalHeading heading='Deactivate Account' />
			{loading ? (
				<DeactivateUserModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						<p className='primaryText'>
							Are you sure you want to deactivate your account? <br /> Remember you will not be able to login after
							deactivating account
						</p>
					</InputsSection>
					<ModalButtons
						submitButtonLabel='Deactivate'
						onSubmit={handleDeactivateUser}
						cancelButtonLabel='Cancel'
						onCancel={handleDeactivateUserModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default DeactiveAccountModal;
