import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordAction, verifyPasswordOtpAction } from '../../redux/actions/userActions';
import { validateEmail } from '../../utils/validators';
import { FORGOT_PASSWORD_RESET, VERIFY_PASSWORD_OTP_RESET } from '../../redux/constants/userConstants';

import Button from '../../components/Modal/ButtonLight';
import TextInput from '../LoginScreen/TextInput';
import PromotionalCards from '../LoginScreen/PromotionalCards';
import LoginFormSkeleton from '../../components/Skeletons/LoginFormSkeleton';

import LoginStyles from '../LoginScreen/LoginScreen.module.css';

const ForgotPasswordScreen = ({ history }) => {
	const dispatch = useDispatch();

	const [ step, setStep ] = React.useState(1);
	const [ email, setEmail ] = React.useState({ value: '', error: '', validated: false });
	const [ OTP, setOTP ] = React.useState({ value: '', error: '', validated: false });
	const [ password, setPassword ] = React.useState({ value: '', error: '', validated: false });

	const forgotPassword = useSelector((state) => state.forgotPassword);
	const verifyPasswordOTP = useSelector((state) => state.verifyPasswordOTP);

	React.useEffect(
		() => {
			if (
				forgotPassword &&
				!forgotPassword.loading &&
				forgotPassword.userInfo &&
				forgotPassword.userInfo.status === 'success'
			) {
				toast.success('An email is sent to your email. Please check');
				dispatch({ type: FORGOT_PASSWORD_RESET });
				setStep(2);
			}

			if (forgotPassword && !forgotPassword.loading && forgotPassword.error) {
				setEmail((prevMobData) => ({ ...prevMobData, error: forgotPassword.error, validated: false }));
			}
		},
		[ forgotPassword, dispatch ]
	);

	React.useEffect(
		() => {
			if (
				verifyPasswordOTP &&
				!verifyPasswordOTP.loading &&
				verifyPasswordOTP.userInfo &&
				verifyPasswordOTP.userInfo.status === 'success'
			) {
				dispatch({ type: VERIFY_PASSWORD_OTP_RESET });
				toast.success('Your password successfully changed.');
				setStep(1);
				setEmail({ value: '', error: '', validated: false });
				setOTP({ value: '', error: '', validated: false });
				setPassword({ value: '', error: '', validated: false });
				history.push('/login');
			}

			if (verifyPasswordOTP && !verifyPasswordOTP.loading && verifyPasswordOTP && verifyPasswordOTP.error) {
				toast.error(verifyPasswordOTP.error);
				setOTP((prevMobData) => ({ ...prevMobData, error: 'Please enter valid OTP', validated: false }));
			}
		},
		[ verifyPasswordOTP, dispatch, history ]
	);

	const handleSendEmail = () => {
		if (email.value === '' || email.value.trim() === '') {
			setEmail({ ...email, error: 'Email is required', validated: false });
		} else if (!validateEmail(email.value)) {
			setEmail({ ...email, error: 'Invalid email entered', validated: false });
		} else {
			dispatch(forgotPasswordAction(email.value));
		}
	};

	const onVerifyOtpHandler = () => {
		if (email.value === '' || email.value.trim() === '') {
			setEmail({ ...email, error: 'Email is required', validated: false });
		} else if (!validateEmail(email.value)) {
			setEmail({ ...email, error: 'Invalid email entered', validated: false });
		} else if (OTP.value === '' || OTP.value.trim() === '') {
			setOTP({ ...OTP, error: 'Please enter code sent to your email', validated: false });
		} else if (OTP.value.length < 6) {
			setOTP({ ...OTP, error: 'Invalid code entered', validated: false });
		} else if (password.value === '' || password.value.trim() === '') {
			setPassword({ ...password, error: 'Please enter your new password', validated: false });
		} else if (password.value.length < 8) {
			setPassword({ ...password, error: 'Password must have atleast 8 characters', validated: false });
		} else {
			dispatch(verifyPasswordOtpAction(email.value, OTP.value, password.value));
		}
	};

	return (
		<div
			className={LoginStyles.container}
			style={{
				// backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .95)), url(/assets/login-bg.jpg)'
				backgroundImage: 'url(/assets/login-bg-1.jpg)'
			}}>
			<div className={LoginStyles.loginContainer}>
				<h1 className={LoginStyles.loginContainer_heading}>Forgot Password!</h1>
				{(forgotPassword && forgotPassword.loading) || (verifyPasswordOTP && verifyPasswordOTP.loading) ? (
					<LoginFormSkeleton />
				) : (
					<Fragment>
						{(step === 1 || step === 2) && (
							<TextInput
								type='text'
								label='Email Address'
								placeholder='Your e-mail address'
								icon='email'
								value={email.value}
								onChange={(e) => {
									if (validateEmail(e.target.value)) {
										setEmail({ value: e.target.value, validated: true });
									} else {
										setEmail({ value: e.target.value, validated: false });
									}
								}}
								validated={email.validated}
								errorMessage={email.error}
							/>
						)}
						{step === 2 && (
							<Fragment>
								<TextInput
									type='text'
									label='Code sent to your email'
									placeholder='Code'
									icon='key'
									value={OTP.value}
									onChange={(e) => {
										if (e.target.value.length > 5) {
											setOTP({ value: e.target.value, validated: true });
										} else {
											setOTP({ value: e.target.value, validated: false });
										}
									}}
									validated={OTP.validated}
									errorMessage={OTP.error}
								/>
								<TextInput
									type='password'
									label='Password'
									placeholder='Your password'
									icon='key'
									value={password.value}
									onChange={(e) => {
										if (e.target.value.length > 7) {
											setPassword({ value: e.target.value, validated: true });
										} else {
											setPassword({ value: e.target.value, validated: false });
										}
									}}
									validated={password.validated}
									errorMessage={password.error}
								/>
							</Fragment>
						)}

						<div className={LoginStyles.button_container}>
							{step === 1 && <Button label='Send Email' onClick={handleSendEmail} />}
							{step === 2 && <Button label='Change Password' onClick={onVerifyOtpHandler} />}
							<Link to='/login' className={LoginStyles.login__forgot_btn}>
								Back to login
							</Link>
						</div>
					</Fragment>
				)}

				<div className={LoginStyles.companyInfo}>
					{/* <p>&copy; 2022. Continia</p> */}
					<p>Powered By: The Algorithm</p>
				</div>
			</div>

			<PromotionalCards />

			<div className={LoginStyles.bottom_logo}>
				<p className={LoginStyles.bottom_logo_copyright}>
					&copy; {new Date(Date.now()).getFullYear()}. Continia | All Rights Reserved
				</p>
				<img src='/assets/logo.png' alt='Continia Logo' className={LoginStyles.bottom_logo_image} />
			</div>
		</div>
	);
};

export default ForgotPasswordScreen;
