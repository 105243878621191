import axios from 'axios';
import {
  LIST_CLINIC_REQUEST,
  LIST_CLINIC_SUCCESS,
  LIST_CLINIC_FAIL,
  GET_CLINIC_STATUS_REQUEST,
  GET_CLINIC_STATUS_SUCCESS,
  GET_CLINIC_STATUS_FAIL,
  ADD_CLINIC_REQUEST,
  ADD_CLINIC_SUCCESS,
  ADD_CLINIC_FAIL,
  UPDATE_CLINIC_REQUEST,
  UPDATE_CLINIC_SUCCESS,
  UPDATE_CLINIC_FAIL,
  DELETE_CLINIC_REQUEST,
  DELETE_CLINIC_SUCCESS,
  DELETE_CLINIC_FAIL,
} from '../constants/clinicConstants';
// import { BASE_URL } from './ip';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const clinicsListAction =
  (page = 1, limit = 100) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_CLINIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/clinic?page=${page}&limit=${limit}`,
        config
      );

      dispatch({
        type: LIST_CLINIC_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: LIST_CLINIC_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const clinicStatusAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CLINIC_STATUS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/gyms/getStatus`, config);

    dispatch({
      type: GET_CLINIC_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLINIC_STATUS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const addClinicAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${BASE_URL}/users/custom-onboard`,
      formData,
      config
    );

    dispatch({
      type: ADD_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADD_CLINIC_FAIL,
      payload: err.response && err.response.data ? err.response.data : err,
    });
  }
};

export const updateClinicAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_CLINIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.patch(
        `${BASE_URL}/clinic/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_CLINIC_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_CLINIC_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const updateClinicLogoAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/users/upload-logo/${id}`,
        formData,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    }
  };

export const deleteClinicAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/clinic/${id}`, config);

    dispatch({ type: DELETE_CLINIC_SUCCESS, payload: { status: 'success' } });
  } catch (err) {
    dispatch({
      type: DELETE_CLINIC_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
