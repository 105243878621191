import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import FileExchangeStyles from '../FileExchangeScreen.module.css';
// import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

const File = ({ file }) => {
  const getFileIcon = (fileName) => {
    const fileSplit = fileName.split('.');
    const ext = fileSplit[fileSplit.length - 1];
    if (
      ext === 'png' ||
      ext === 'jpg' ||
      ext === 'jpeg' ||
      ext === 'tif' ||
      ext === 'gif'
    )
      return 'file-image';
    else if (ext === 'doc' || ext === 'docx') return 'file-word';
    else if (ext === 'ppt' || ext === 'pptx') return 'file-ppt';
    else if (ext === 'csv' || ext === 'xlsx') return 'file-excel';
    else if (ext === 'mp4' || ext === 'webm') return 'file-video';
    else if (ext === 'mp3') return 'file-audio';
    else if (ext === 'pdf') return 'file-pdf';
    else if (ext === 'zip') return 'file-zip';
    else return 'file-unknown';
  };

  return (
    <Fragment>
      {/* <ContextMenuTrigger id={`contextmenu${file._id}`}> */}
      <Link
        to={`/files/${file._id}`}
        target='_blank'
        className={FileExchangeStyles.file}
        // onClick={() =>
        //   setDownloadFileModal({ file, status: true })
        // }
      >
        <svg className={FileExchangeStyles.fileIcon}>
          <use
            xlinkHref={`/assets/sprite.svg#icon-${getFileIcon(file.name)}`}
          />
        </svg>
        <h4 className={FileExchangeStyles.fileNmme}>
          {file.name.length > 10 ? file.name.slice(0, 10) + '..' : file.name}
        </h4>
        {file.name.length > 10 && (
          <p className={FileExchangeStyles.fileFullNmme}>{file.name}</p>
        )}
      </Link>
      {/* </ContextMenuTrigger> */}
      {/* <ContextMenu id={`contextmenu${file._id}}`}>
        {menuItem?.length > 0 &&
          menuItem.map(
            (item) =>
              item.show && (
                <MenuItem key={item.id} onClick={item.onClick}>
                  <svg className='watchlist'>
                    <use xlinkHref={`/assets/sprite.svg#icon-${item.icon}`} />
                  </svg>
                  <span>{item.label}</span>
                </MenuItem>
              )
          )}
      </ContextMenu> */}
      {/* <File
        key={file._id}
        file={file}
        menuItem={[
          {
            id: file._id + 1,
            onClick: () => {
              setDownloadFileModal({ file, status: true });
            },
            icon: 'file_download',
            label: 'Download',
            show: true,
          },
          {
            id: file._id + 2,
            onClick: () => {
              setShareFileModal({ file, status: true });
            },
            icon: 'folder-share',
            label: 'Share',
            show: true,
          },
          {
            id: file._id + 3,
            onClick: () => {
              setCopyFileModal({ file, status: true });
            },
            icon: 'file_copy',
            label: 'Copy',
            show: userRole === 'fileUserOnlyDownload',
          },
          {
            id: file._id + 4,
            onClick: () => {
              setCutFileModal({ file, status: true });
            },
            icon: 'content_cut',
            label: 'Move',
            show: userRole !== 'fileUserOnlyDownload',
          },
          {
            id: file._id + 5,
            onClick: () => {
              setRenameFileModal({ file, status: true });
              setRenameFilePasswordInput({
                value: file.name,
                error: '',
              });
            },
            icon: 'edit-2',
            label: 'Rename',
            show: userRole !== 'fileUserOnlyDownload',
          },
          {
            id: file._id + 6,
            onClick: () => {
              setDeleteFileModal({ file, status: true });
            },
            icon: 'file-minus',
            label: 'Delete',
            show:
              (userRole === 'fileAdmin' || userRole === 'fileUser') &&
              userLogin &&
              userLogin.userInfo &&
              userLogin.userInfo.data &&
              file.uploadedBy &&
              userLogin.userInfo.data.user._id === file.uploadedBy._id,
          },
          {
            id: file._id + 7,
            onClick: () => {
              setDeleteFileModal({ file, status: true });
            },
            icon: 'file-minus',
            label: 'Delete',
            show: !(userRole === 'fileAdmin' || userRole === 'fileUser'),
          },
        ]}
      /> */}
    </Fragment>
  );
};

export default File;
