import React from 'react';
import Button from '../../../../../components/Modal/Button';
import CancelButton from '../../../../../components/Modal/CancelButtonNew';

import ModalButtonsStyles from './ModalButtons.module.css';

const ModalButtons = ({ submitButtonLabel, onSubmit, cancelButtonLabel, onCancel }) => {
	return (
		<div className={ModalButtonsStyles.buttons}>
			{cancelButtonLabel && (
				<CancelButton
				label={cancelButtonLabel}
				onClick={(e) => {
					e.preventDefault();
					onCancel();
				}}
				/>
				)}
				{submitButtonLabel && (
					<Button
						label={submitButtonLabel}
						onClick={(e) => {
							e.preventDefault();
							onSubmit();
						}}
					/>
				)}
		</div>
	);
};

export default ModalButtons;
