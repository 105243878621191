export const CLIENT_LOGO_REQUEST = 'CLIENT_LOGO_REQUEST';
export const CLIENT_LOGO_SUCCESS = 'CLIENT_LOGO_SUCCESS';
export const CLIENT_LOGO_FAIL = 'CLIENT_LOGO_FAIL';
export const CLIENT_LOGO_RESET = 'CLIENT_LOGO_RESET';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';
export const ONLINE_USERS = 'ONLINE_USERS';

export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAIL = 'CREATE_NEW_USER_FAIL';
export const CREATE_NEW_USER_RESET = 'CREATE_NEW_USER_RESET';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SELECT_USER_REQUEST = 'SELECT_USER_REQUEST';
export const SELECT_USER_SUCCESS = 'SELECT_USER_SUCCESS';
export const SELECT_USER_FAIL = 'SELECT_USER_FAIL';
export const SELECT_USER_RESET = 'SELECT_USER_RESET';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET';

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAIL = 'UPDATE_USER_STATUS_FAIL';
export const UPDATE_USER_STATUS_RESET = 'UPDATE_USER_STATUS_RESET';

export const GET_LOGGED_USER_DETAILS_REQUEST =
  'GET_LOGGED_USER_DETAILS_REQUEST';
export const GET_LOGGED_USER_DETAILS_SUCCESS =
  'GET_LOGGED_USER_DETAILS_SUCCESS';
export const GET_LOGGED_USER_DETAILS_FAIL = 'GET_LOGGED_USER_DETAILS_FAIL';
export const GET_LOGGED_USER_DETAILS_RESET = 'GET_LOGGED_USER_DETAILS_RESET';

export const SET_LOGGED_USER_DETAILS_IN_USER_LOGIN = "SET_LOGGED_USER_DETAILS_IN_USER_LOGIN";

export const SELECT_LOGGED_USER_DETAILS_REQUEST =
  'SELECT_LOGGED_USER_DETAILS_REQUEST';
export const SELECT_LOGGED_USER_DETAILS_SUCCESS =
  'SELECT_LOGGED_USER_DETAILS_SUCCESS';
export const SELECT_LOGGED_USER_DETAILS_FAIL =
  'SELECT_LOGGED_USER_DETAILS_FAIL';
export const SELECT_LOGGED_USER_DETAILS_RESET =
  'SELECT_LOGGED_USER_DETAILS_RESET';

export const UPDATE_LOGGED_USER_DETAILS_REQUEST =
  'UPDATE_LOGGED_USER_DETAILS_REQUEST';
export const UPDATE_LOGGED_USER_DETAILS_SUCCESS =
  'UPDATE_LOGGED_USER_DETAILS_SUCCESS';
export const UPDATE_LOGGED_USER_DETAILS_FAIL =
  'UPDATE_LOGGED_USER_DETAILS_FAIL';
export const UPDATE_LOGGED_USER_DETAILS_RESET =
  'UPDATE_LOGGED_USER_DETAILS_RESET';

export const CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST =
  'CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST';
export const CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS =
  'CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS';
export const CHANGE_LOGGED_IN_USER_PASSWORD_FAIL =
  'CHANGE_LOGGED_IN_USER_PASSWORD_FAIL';
export const CHANGE_LOGGED_IN_USER_PASSWORD_RESET =
  'CHANGE_LOGGED_IN_USER_PASSWORD_RESET';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAIL = 'RESET_USER_PASSWORD_FAIL';
export const RESET_USER_PASSWORD_RESET = 'RESET_USER_PASSWORD_RESET';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

export const VERIFY_PASSWORD_OTP_REQUEST = 'VERIFY_PASSWORD_OTP_REQUEST';
export const VERIFY_PASSWORD_OTP_SUCCESS = 'VERIFY_PASSWORD_OTP_SUCCESS';
export const VERIFY_PASSWORD_OTP_FAIL = 'VERIFY_PASSWORD_OTP_FAIL';
export const VERIFY_PASSWORD_OTP_RESET = 'VERIFY_PASSWORD_OTP_RESET';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const GET_ALL_USERS_RESET = 'GET_ALL_USERS_RESET';

export const GET_ALL_LOGS_REQUEST = 'GET_ALL_LOGS_REQUEST';
export const GET_ALL_LOGS_SUCCESS = 'GET_ALL_LOGS_SUCCESS';
export const GET_ALL_LOGS_FAIL = 'GET_ALL_LOGS_FAIL';
export const GET_ALL_LOGS_RESET = 'GET_ALL_LOGS_RESET';

export const DELETE_ME_REQUEST = 'DELETE_ME_REQUEST';
export const DELETE_ME_SUCCESS = 'DELETE_ME_SUCCESS';
export const DELETE_ME_FAIL = 'DELETE_ME_FAIL';
export const DELETE_ME_RESET = 'DELETE_ME_RESET';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const ALL_NOTIFICATIONS_REQUEST = 'ALL_NOTIFICATIONS_REQUEST';
export const ALL_NOTIFICATIONS_REQUEST_LOAD_MORE =
  'ALL_NOTIFICATIONS_REQUEST_LOAD_MORE';
export const ALL_NOTIFICATIONS_SUCCESS = 'ALL_NOTIFICATIONS_SUCCESS';
export const ALL_NOTIFICATIONS_FAIL = 'ALL_NOTIFICATIONS_FAIL';
export const ALL_NOTIFICATIONS_RESET = 'ALL_NOTIFICATIONS_RESET';

export const CLINIC_USER_COUNT_REQUEST = 'CLINIC_USER_COUNT_REQUEST';
export const CLINIC_USER_COUNT_SUCCESS = 'CLINIC_USER_COUNT_SUCCESS';
export const CLINIC_USER_COUNT_FAIL = 'CLINIC_USER_COUNT_FAIL';
export const CLINIC_USER_COUNT_RESET = 'CLINIC_USER_COUNT_RESET';

export const CALL_INCOMING = 'CALL_INCOMING';
export const CALL_ANSWER = 'CALL_ANSWER';
export const CALL_DECLINE = 'CALL_DECLINE';
export const CALL_RESET = 'CALL_RESET';
