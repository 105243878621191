import React from 'react'

const ErrorBoundary = () => {
  return (
    <div>
      This Page dosent exist
    </div>
  )
}

export default ErrorBoundary
