import React, { useEffect, useState } from "react";
import UserCardStyles from "./UserCard.module.css";
import ButtonIcon from "../../../components/Modal/ButtonIcon/ButtonIcon";

const UserCard = ({
  user,
  active,
  onClick,
  selected,
  setdeleteMailModal,
  deleteMailModal,
}) => {
  // console.log(user , "data");
  // console.log(active )
  const [emailTime, setEmailTime] = useState("");

  useEffect(() => {
    if (user) {
      const emailDate =
        new Date(user.email.time).getDate() === new Date(Date.now()).getDate()
          ? "Today " +
            new Date(user.email.time)
              .toLocaleTimeString()
              .replace(/(.*)\D\d+/, "$1")
          : new Date(user.email.time).getDate() ===
            new Date(Date.now()).getDate() - 1
          ? "Yesterday " +
            new Date(user.email.time)
              .toLocaleTimeString()
              .replace(/(.*)\D\d+/, "$1")
          : new Date(user.email.time).toLocaleDateString() +
            " " +
            new Date(user.email.time)
              .toLocaleTimeString()
              .replace(/(.*)\D\d+/, "$1");

      setEmailTime(emailDate);
    }
  }, [user]);

  if (!user) {
    return null; // Render nothing if user is undefined
  }
  const handleDeleteClick = () => {
    // Show the delete modal when the delete button is clicked
    setdeleteMailModal({ data: user, status: true });
    // console.log("clicked")
  };
  return (
    <div
      className={`${UserCardStyles.card} ${
        user.email.isRead ? UserCardStyles.Emailread : UserCardStyles.unread
      }`}
      onClick={onClick}
    >
      <div
        className={`${
          active
            ? `${UserCardStyles.active} ${UserCardStyles.userCard}`
            : UserCardStyles.userCard
        }`}
      >
        <div className={UserCardStyles.userImageBox}>
          <img
            src="/assets/user-img.png"
            alt="User"
            className={UserCardStyles.user_image}
          />
          <div className={UserCardStyles.icons}>
           <ButtonIcon icon="star-bold" style={{width: "15" , height:"15" , fill: "var(--primary-color)"}}/>
          </div>
        </div>
        <div className={UserCardStyles.emailTexts}>
          <div className={UserCardStyles.emailText}>
            <h1
              className={
                user.email.isRead
                  ? UserCardStyles.name
                  : [UserCardStyles.nameUnread]
              }
            >
              {user.name}
            </h1>
            <h2
              className={
                user.email.isRead
                  ? UserCardStyles.mailTime
                  : UserCardStyles.mailTimeUnread
              }
            >
              {emailTime}
            </h2>
          </div>
          <div className={UserCardStyles.content}>
          <div className={UserCardStyles.subjects}>
            <p
              className={
                user.email.isRead
                  ? UserCardStyles.subject
                  : UserCardStyles.subjectUnread
              }
            >
              {user.email.subject && user.email.subject.length > 30
                ? user.email.subject.slice(0, 30) + "..."
                : user.email.subject}
            </p>
            <div className={UserCardStyles.bodyText}>
              <p className={UserCardStyles.emailBody}>
                {user.email.body && user.email.body.length > 70
                  ? user.email.body.slice(0, 70) + "..."
                  : user.email.body}
              </p>
            </div>
          </div>
          <div className={UserCardStyles.actions}>
            <div className={UserCardStyles.user_attachment}>
              {user.hasAttachment && (
                <svg width="12" height="12">
                  <use xlinkHref={`/assets/sprite.svg#icon-attachment`} />
                </svg>
              )}
            </div>
            <div className={UserCardStyles.icon}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginTop: "1rem",
                  color: "var(--primary-color)",
                }}
                onClick={() => handleDeleteClick({ selected, status: true })}
              >
                <svg width="18" height="18">
                  <use xlinkHref={`/assets/sprite.svg#full-trash`} />
                </svg>
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
