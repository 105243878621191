export const validateOnlyChacters = name => {
  const re = /^[a-zA-Z \s]+$/;
  return re.test(name);
};

export const validatePassword = password => {
  const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
  return re.test(password);
};

export const validateEmail = email => {
  const re = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
};

export const validatePhone = phone => {
  const re = /^[0-9]{10}$/;
  // const re = /^[0-9]{5,12}$/;
  return re.test(phone);
};

export const validatePrice = phone => {
  const re = /^[0-9]{1,3}(\.\d{1,3})?%?$/;
  // const re = /^[0-9]{5,12}$/;
  return re.test(phone);
};

export const validateMonth = phone => {
  const re = /^[1-9]{1,2}$/;
  // const re = /^[0-9]{5,12}$/;
  return re.test(phone);
};
export const inputPhoneMasking = e => {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.substring(0, 12);

  return value;
};
export const unMasking = e => {
  let value = e;
  value = value.replaceAll('-', '');

  return value;
};
export const returnOnlyNumber = data => {
  let value = data;
  value = value.replace(/\D/g, '');
  return value;
};
