import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import ToggleModalSkeleton from '../../components/Skeletons/ToggleModalSkeleton';

const ToggleClinicActive = ({
	toggleClinicModal,
	loading,
	deleteClinicData,
	handleToggleModalClose,
	handleClinicToggle
}) => {
	return (
		<Modal show={toggleClinicModal}>
			<ModalHeading
				heading={`${deleteClinicData.active ? 'Deactivate' : 'Activate'} ${deleteClinicData.name} Account`}
			/>
			{loading ? (
				<ToggleModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						{deleteClinicData.active ? (
							<p className='primaryText'>
								Deactivating will restrict {deleteClinicData.name} to log in to account.<br /> Are you sure you want to
								proceed?
							</p>
						) : (
							<p className='primaryText'>
								Activating will allow {deleteClinicData.name} to log in to account.<br /> Are you sure you want to
								proceed?
							</p>
						)}
					</InputsSection>
					<ModalButtons
						submitButtonLabel={`${deleteClinicData.active ? 'Deactivate Clinic' : 'Activate Clinic'}`}
						onSubmit={() => handleClinicToggle(deleteClinicData._id, deleteClinicData.active)}
						cancelButtonLabel='Cancel'
						onCancel={handleToggleModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default ToggleClinicActive;
