import React from 'react';
import ButtonSkeleton from './ButtonSkeleton';
import styles from '../../screens/FileExchangeScreen/ViewFile/ViewFile.module.css';
import SkeletonStyles from './Skeletons.module.css';

const ViewFileSkeleton = () => {
  return (
    <div className={styles.content}>
      <div
        className={[
          styles.left_content,
          SkeletonStyles.left_content,
          SkeletonStyles.skeleton,
        ].join(' ')}
      >
        <p>Loading file...</p>
      </div>
      <div className={styles.right_content}>
        <div
          className={[
            SkeletonStyles.right_content_title,
            SkeletonStyles.skeleton,
          ].join(' ')}
        ></div>
        <div
          className={[
            SkeletonStyles.right_content_desc,
            SkeletonStyles.skeleton,
          ].join(' ')}
        ></div>
        <ButtonSkeleton />
      </div>
    </div>
  );
};

export const ViewSkeleton = () => {
  return (
    <div
      className={[
        styles.left_content,
        SkeletonStyles.left_content,
        SkeletonStyles.skeleton,
      ].join(' ')}
    >
      <p>Loading file...</p>
    </div>
  );
};

export default ViewFileSkeleton;
