import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteForm = ({ id }) => {
  const { loading: deleteFormLoading, makeApiCall } = useApi({
    url: `/website-form?id=${id}`,
    method: 'DELETE',
  });

  const deleteForm = async () => {
    const { response, error } = await makeApiCall();
    if (response?.status === 'success') {
      return response;
    }

    if (!response?.status === 'fail' && response?.msg) {
      toast.error(response.message);
      return response;
    }
    if (error) {
      toast.error(error.msg);
      return error;
    }
  };

  return {
    deleteFormLoading,
    deleteForm,
  };
};

export default useDeleteForm;
