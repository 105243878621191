import axios from 'axios';
import {
  GET_SECURE_FILES_REQUEST,
  GET_SECURE_FILES_SUCCESS,
  GET_SECURE_FILES_FAIL,
  ADD_FILE_EXCHANGE_REQUEST,
  ADD_FILE_EXCHANGE_PROGRESS,
  ADD_FILE_EXCHANGE_SUCCESS,
  ADD_FILE_EXCHANGE_FAIL,
  DELETE_FILE_EXCHANGE_REQUEST,
  DELETE_FILE_EXCHANGE_SUCCESS,
  DELETE_FILE_EXCHANGE_FAIL,
  DOWNLOAD_FILE_EXCHANGE_REQUEST,
  DOWNLOAD_FILE_EXCHANGE_SUCCESS,
  DOWNLOAD_FILE_EXCHANGE_FAIL,
  SHARE_FILE_EXCHANGE_REQUEST,
  SHARE_FILE_EXCHANGE_SUCCESS,
  SHARE_FILE_EXCHANGE_FAIL,
  RENAME_FILE_EXCHANGE_REQUEST,
  RENAME_FILE_EXCHANGE_SUCCESS,
  RENAME_FILE_EXCHANGE_FAIL,
  COPY_FILE_EXCHANGE_REQUEST,
  COPY_FILE_EXCHANGE_SUCCESS,
  COPY_FILE_EXCHANGE_FAIL,
  CUT_FILE_EXCHANGE_REQUEST,
  CUT_FILE_EXCHANGE_SUCCESS,
  CUT_FILE_EXCHANGE_FAIL,
  GET_ALL_FOLDER_DATA_REQUEST,
  GET_ALL_FOLDER_DATA_SUCCESS,
  GET_ALL_FOLDER_DATA_FAIL,
  GET_FOLDER_DATA_REQUEST,
  GET_FOLDER_DATA_SUCCESS,
  GET_FOLDER_DATA_FAIL,
  GET_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  EDIT_FOLDER_REQUEST,
  EDIT_FOLDER_SUCCESS,
  EDIT_FOLDER_FAIL,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAIL,
  GET_FOLDER_REQUEST,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_FAIL,
  GET_SUB_FOLDER_REQUEST,
  GET_SUB_FOLDER_SUCCESS,
  GET_SUB_FOLDER_FAIL,
  GET_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_FOLDER_FAIL,
  GET_SUB_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_SUB_FOLDER_FAIL,
  GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_SUB_SUB_FOLDER_FAIL,
  DOWNLOAD_FILE_EXCHANGE_PROGRESS,
  DOWNLOAD_FILE_EXCHANGE_RESET,
  GET_ALL_LEVEL_FOLDER_DATA_REQUEST,
  GET_ALL_LEVEL_FOLDER_DATA_SUCCESS,
  GET_ALL_LEVEL_FOLDER_DATA_FAIL,
} from '../constants/folderConstants';
import toast from 'react-hot-toast';
// import { BASE_URL } from './ip';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const foldersListAction = (foldersType, clinicId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri;
    if (foldersType === 'data-folders') {
      uri = `${BASE_URL}/fileExchange/folder-wise-data`;
    } else {
      if (clinicId) uri = `${BASE_URL}/folders?sort=name&clinic=${clinicId}`;
      else uri = `${BASE_URL}/folders?sort=name`;
    }

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_ALL_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
export const foldersLevelListAction = (foldersType, clinicId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_LEVEL_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri;
    if (clinicId) uri = `${BASE_URL}/folders/level-folders?sort=name&clinic=${clinicId}`;
    else uri = `${BASE_URL}/folders/level-folders?sort=name`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_ALL_LEVEL_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_LEVEL_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
export const getSecureFilesAction =
  (page = 1, limit = 6) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_SECURE_FILES_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      let uri = `${BASE_URL}/fileExchange?page=${page}&limit=${limit}`; //&sort=name

      const { data } = await axios.get(uri, config);

      dispatch({
        type: GET_SECURE_FILES_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_SECURE_FILES_FAIL,
        payload: err.response && err.response.data.message ? err.response.data.message : err.message,
      });
    }
  };

export const getSearchSecureFilesAction =
  (searchText, page = 1, limit = 6) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      let uri = `${BASE_URL}/fileExchange/search?page=${page}&limit=${limit}&filter=${searchText}&sort=name`;

      const { data } = await axios.get(uri, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const folderDataAction = folderId => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange?folder=${folderId}&sort=name&limit=1000&page=1`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const subFolderDataAction = folderId => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange?folder=${folderId}&sort=name&limit=1000&page=1`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const subSubFolderDataAction = (folderId, pageNumber, pageLimit) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange?folder=${folderId}&sort=name&limit=1000&page=1`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const subSubSubFolderDataAction = folderId => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange?folder=${folderId}&sort=name&limit=1000&page=1`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_SUB_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const subSubSubSubFolderDataAction = folderId => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange?folder=${folderId}&sort=name&limit=1000&page=1`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createFolderAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders`;

    const { data } = await axios.post(uri, formData, config);

    dispatch({
      type: CREATE_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_FOLDER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const editFolderAction = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.patch(uri, formData, config);

    dispatch({
      type: EDIT_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_FOLDER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const deleteFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    await axios.delete(uri, config);

    dispatch({
      type: DELETE_FOLDER_SUCCESS,
      payload: { status: 'success' },
    });
  } catch (err) {
    dispatch({
      type: DELETE_FOLDER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_FOLDER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const getSubFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_FOLDER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const getSubSubFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_FOLDER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const getSubSubSubFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_SUB_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_SUB_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_SUB_FOLDER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const getSubSubSubSubFolderAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/folders/${id}`;

    const { data } = await axios.get(uri, config);

    dispatch({
      type: GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUB_SUB_SUB_SUB_FOLDER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const createFileAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_FILE_EXCHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
      // onUploadProgress: progressEvent => {
      //   dispatch({
      //     type: ADD_FILE_EXCHANGE_PROGRESS,
      //     payload: {
      //       data: { loaded: progressEvent.loaded, total: progressEvent.total },
      //       loaded: progressEvent.loaded,
      //       total: progressEvent.total,
      //     },
      //   });
      // console.log(progressEvent);
      // },
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
          dispatch({
            type: ADD_FILE_EXCHANGE_PROGRESS,
            payload: {
              data: { loaded: progressEvent.loaded, total: totalLength },
              loaded: progressEvent.loaded,
              total: totalLength,
            },
          });
        }
      },
    };

    const { data } = await axios.post(`${BASE_URL}/fileExchange`, formData, config);
    // toast.success('File is Uploaded')
    dispatch({
      type: ADD_FILE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response && error.response.data && error.response.data.msg) {
      toast.error(error.response.data.msg);
      dispatch({
        type: ADD_FILE_EXCHANGE_FAIL,
        payload: error.response.data.msg,
      });
    } else {
      toast.error('An error occurred');
      dispatch({
        type: ADD_FILE_EXCHANGE_FAIL,
        payload: 'An error occurred',
      });
    }
  }
};

export const deleteFileAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_FILE_EXCHANGE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/fileExchange/${id}`, config);

    dispatch({
      type: DELETE_FILE_EXCHANGE_SUCCESS,
      payload: { status: 'success' },
    });
  } catch (err) {
    dispatch({
      type: DELETE_FILE_EXCHANGE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const downloadFileAction =
  (formData, fileName = 'download.png') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: DOWNLOAD_FILE_EXCHANGE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      let uri = `${BASE_URL}/fileExchange/download?type='download'`;
      // const { data } = await axios.post(uri, formData, config);

      axios({
        url: uri, //your url
        method: 'POST',
        data: formData,
        headers: config.headers,
        responseType: 'blob', // important
        onDownloadProgress: progressEvent => {
          console.log(progressEvent);
          dispatch({
            type: DOWNLOAD_FILE_EXCHANGE_PROGRESS,
            payload: {
              data: {
                loaded: progressEvent.loaded,
                total: progressEvent.currentTarget.response && progressEvent.currentTarget.response.size ? progressEvent.currentTarget.response.size : 1,
              },
              loaded: progressEvent.loaded,
              total: progressEvent.currentTarget.response && progressEvent.currentTarget.response?.size ? progressEvent.currentTarget.response.size : 1,
            },
          });
        },
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          link.setAttribute('target', '_blank'); //or any other extension
          document.body.appendChild(link);
          link.click();
          dispatch({
            type: DOWNLOAD_FILE_EXCHANGE_SUCCESS,
            payload: { status: 'success', msg: 'Download Successful' },
          });
        })
        .catch(err => {
          dispatch({
            type: DOWNLOAD_FILE_EXCHANGE_FAIL,
            payload: 'Incorrect password. Please try again',
          });
        });
    } catch (err) {
      dispatch({
        type: DOWNLOAD_FILE_EXCHANGE_FAIL,
        payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
      });
    }
  };

export const viewDownloadFileAction = formData => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
      'Content-Type': 'application/json',
    },
  };

  let uri = `${BASE_URL}/fileExchange/download?type='view'`;

  try {
    const response = await axios({
      url: uri, //your url
      method: 'POST',
      data: formData,
      headers: config.headers,
      responseType: 'blob', // important
      onDownloadProgress: progressEvent => {
        dispatch({
          type: DOWNLOAD_FILE_EXCHANGE_PROGRESS,
          payload: {
            data: {
              loaded: progressEvent.loaded,
              total: progressEvent.currentTarget.response && progressEvent.currentTarget.response.size ? progressEvent.currentTarget.response.size : 10000,
            },
            loaded: progressEvent.loaded,
            total: progressEvent.currentTarget.response && progressEvent.currentTarget.response?.size ? progressEvent.currentTarget.response.size : 10000,
          },
        });
        // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length']);
        // const current = progressEvent.currentTarget.response.length;

        // let percentCompleted = Math.floor((current / total) * 100);
        // console.log('completed: ', percentCompleted);
      },
    });
    dispatch({
      type: DOWNLOAD_FILE_EXCHANGE_RESET,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const viewFileAction = id => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange/view-file`;
    const { data } = await axios.post(uri, { id }, config);
    return data;
  } catch (err) {
    return err.response && err.response.data.msg ? err.response.data.msg : err.message;
  }
};

export const shareFileAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: SHARE_FILE_EXCHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange/share-file`;
    const { data } = await axios.post(uri, formData, config);

    dispatch({
      type: SHARE_FILE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SHARE_FILE_EXCHANGE_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const renameFileAction = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: RENAME_FILE_EXCHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange/${id}`;
    const { data } = await axios.patch(uri, formData, config);

    dispatch({
      type: RENAME_FILE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: RENAME_FILE_EXCHANGE_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const copyFileAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: COPY_FILE_EXCHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange/copy`;
    const { data } = await axios.post(uri, formData, config);

    dispatch({
      type: COPY_FILE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: COPY_FILE_EXCHANGE_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const cutFileAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: CUT_FILE_EXCHANGE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/fileExchange/cut`;
    const { data } = await axios.post(uri, formData, config);

    dispatch({
      type: CUT_FILE_EXCHANGE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CUT_FILE_EXCHANGE_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};
