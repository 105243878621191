import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addClinicAction, clinicsListAction, deleteClinicAction, updateClinicAction, updateClinicLogoAction } from '../../redux/actions/clinicActions';
import { ADD_CLINIC_RESET, DELETE_CLINIC_RESET, UPDATE_CLINIC_RESET } from '../../redux/constants/clinicConstants';

import Content from '../../components/Content';
import ClinicListTable from './ClinicListTable';
import Pagination from '../../components/Pagination';
import AddClinicModal from './AddClinicModal';
import DeleteClinicModal from './DeleteClinicModal';
import ResetPasswordModal from './ResetPasswordModal';
import ToggleClinicActive from './ToggleClinicActive';
import { resetPasswordAction, toggleUserActive } from '../../redux/actions/userActions';
import { RESET_USER_PASSWORD_RESET, UPDATE_USER_STATUS_RESET } from '../../redux/constants/userConstants';

const ClinicsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = React.useState('');
  // const [ startValue, setStartValue ] = React.useState(1);
  // const [ endValue, setEndValue ] = React.useState(1);
  const [totalPageSize, setTotalPageSize] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const pageLimit = 15;

  // Add or update clinic states
  const [addClinicModal, setAddClinicModal] = React.useState(false);
  const [editClinicData, setEditClinicData] = React.useState({});
  const [clinicName, setClinicName] = React.useState({ value: '', error: '' });
  const [clinicEmail, setClinicEmail] = React.useState({
    value: '',
    error: '',
  });
  const [clinicPhone, setClinicPhone] = React.useState({
    value: '',
    error: '',
  });
  const [clinicAddress, setClinicAddress] = React.useState({
    value: '',
    error: '',
  });
  const [clinicLogo, setClinicLogo] = React.useState({ value: '', error: '' });
  const [customClientData, setCustomClientData] = React.useState({
    space: {
      value: '',
      error: '',
    },
    adminUsers: {
      value: '',
      error: '',
    },
    downloadUsers: {
      value: '',
      error: '',
    },
  });
  const [clinicModules, setClinicModules] = React.useState({
    fileSharing: true,
    chat: false,
    adminOnlyChat: false,
    videoCall: false,
    websiteForms: false,
  });

  // Reset password states
  const [resetPasswordModal, setResetPasswordModal] = React.useState(false);
  const [resetPasswordData, setResetPasswordData] = React.useState({});
  const [resetPassword, setResetPassword] = React.useState({
    value: '',
    error: '',
  });
  const [resetPasswordConfirm, setResetPasswordConfirm] = React.useState({
    value: '',
    error: '',
  });

  // Delete Clinic states
  const [toggleClinicModal, setToggleClinicModal] = React.useState(false);
  const [deleteClinicModal, setDeleteClinicModal] = React.useState(false);
  const [deleteClinicData, setDeleteClinicData] = React.useState({});
  const [deleteEmail, setDeleteEmail] = React.useState({
    value: '',
    error: '',
  });

  const userLogin = useSelector(state => state.userLogin);
  const clinicsList = useSelector(state => state.clinicsList);
  const addClinic = useSelector(state => state.addClinic);
  const updateClinic = useSelector(state => state.updateClinic);
  const deleteClinic = useSelector(state => state.deleteClinic);
  const updateUserStatus = useSelector(state => state.updateUserStatus);
  const resetUserPassword = useSelector(state => state.resetUserPassword);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role !== 'admin') {
      history.push('/');
    }
  }, [userLogin, history]);

  // ============================== Get Clients End ======================================
  React.useEffect(() => {
    dispatch(clinicsListAction(pageNumber, pageLimit));
  }, [dispatch, pageNumber]);

  React.useEffect(() => {
    if (clinicsList && clinicsList.clinics && clinicsList.clinics.status === 'success' && clinicsList.clinics.data && clinicsList.clinics.data.data) {
      setTotalPageSize(clinicsList.clinics.totalResults);
    }
  }, [clinicsList]);

  const handlePageChange = async currentPage => {
    setPageNumber(currentPage.selected + 1);
    // setStartValue(currentPage.selected + 1 * pageNumber);
    // setEndValue(startValue + pageLimit);
  };

  // ============================== Get Clients End ======================================

  // ============================== ADD/UPDATE Client Start ======================================
  React.useEffect(() => {
    if (editClinicData && editClinicData._id) {
      setClinicName({ value: editClinicData.name });
      setClinicEmail({ value: editClinicData.email });
      setClinicPhone({ value: editClinicData.phone });
      setClinicAddress({ value: editClinicData.address });
      editClinicData.modules &&
        setClinicModules({
          fileSharing: editClinicData.modules.fileSharing,
          chat: editClinicData.modules.chat,
          videoCall: editClinicData.modules.videoCall,
          adminOnlyChat: editClinicData.modules.adminOnlyChat,
          websiteForms: editClinicData.modules.websiteForms,
        });
      if (editClinicData.customClient && editClinicData.customClientDetails) {
        setCustomClientData({
          space: { value: editClinicData.customClientDetails.space, error: '' },
          adminUsers: {
            value: editClinicData.customClientDetails.adminUsers,
            error: '',
          },
          downloadUsers: {
            value: editClinicData.customClientDetails.downloadUsers,
            error: '',
          },
        });
      }
    }
  }, [editClinicData]);

  const uploadClinicLogo = React.useCallback(
    async id => {
      if (clinicLogo.value !== '') {
        const formData = new FormData();
        formData.append('logo', clinicLogo.value);
        const data = await dispatch(updateClinicLogoAction(id, formData));
        if (data && data.status === 'success') {
          dispatch(clinicsListAction(pageNumber, pageLimit));
        }
      } else {
        dispatch(clinicsListAction(pageNumber, pageLimit));
      }
    },
    [dispatch, clinicLogo, pageNumber]
  );

  React.useEffect(
    () => {
      if (addClinic && addClinic.data && addClinic.data.status === 'success') {
        uploadClinicLogo(addClinic.data.data.data._id);
        toast.success(addClinic.data.message);
        handleAddModalClose();
        dispatch({ type: ADD_CLINIC_RESET });
      } else if (addClinic && addClinic.error && addClinic.error.status === 'fail') {
        toast.error(addClinic.error.msg);
        dispatch({ type: ADD_CLINIC_RESET });
        // } else {
        //   toast.error('Something went wrong, please try again later');
        //   dispatch({ type: ADD_CLINIC_RESET });
      }
    },
    //eslint-disable-next-line
    [addClinic, dispatch, clinicLogo, uploadClinicLogo]
  );

  React.useEffect(
    () => {
      if (updateClinic && updateClinic.clinic && updateClinic.clinic.status === 'success') {
        uploadClinicLogo(updateClinic.clinic.data.data._id);
        toast.success('Client update successfull!');
        handleAddModalClose();
        dispatch({ type: UPDATE_CLINIC_RESET });
      }
    },
    //eslint-disable-next-line
    [updateClinic, dispatch, uploadClinicLogo]
  );

  const handleEditClinicButtonClick = data => {
    setEditClinicData(data);
    setAddClinicModal(true);
  };

  const handleAddClinic = () => {
    if (clinicName.value === '' || clinicName.value.trim() === '') {
      setClinicName({ ...clinicName, error: 'Please enter clinic name' });
    } else if (clinicEmail.value === '' || clinicEmail.value.trim() === '') {
      setClinicEmail({ ...clinicEmail, error: 'Please enter clinic email' });
    } else if (clinicPhone.value === '' || clinicPhone.value.trim() === '') {
      setClinicPhone({ ...clinicPhone, error: 'Please enter clinic phone' });
    } else if (clinicAddress.value === '' || clinicAddress.value.trim() === '') {
      setClinicAddress({
        ...clinicAddress,
        error: 'Please enter clinic address',
      });
    } else {
      if (editClinicData && editClinicData._id) {
        const data = {
          name: clinicName.value,
          email: clinicEmail.value,
          phone: clinicPhone.value,
          address: clinicAddress.value,
          modules: clinicModules,
        };

        if (editClinicData.customClient && editClinicData.customClientDetails) {
          if (customClientData.space.value === '') {
            setCustomClientData({
              ...customClientData,
              space: {
                ...customClientData.space,
                error: 'Please allocate client some space to upload data.',
              },
            });
          } else if (customClientData.adminUsers.value === '') {
            setCustomClientData({
              ...customClientData,
              adminUsers: {
                ...customClientData.adminUsers,
                error: 'Please enter client admin users.',
              },
            });
          } else if (customClientData.downloadUsers.value === '') {
            setCustomClientData({
              ...customClientData,
              downloadUsers: {
                ...customClientData.downloadUsers,
                error: 'Please enter client file users.',
              },
            });
          } else {
            data.customClientDetails = {
              adminUsers: Number(customClientData.adminUsers.value),
              downloadUsers: Number(customClientData.downloadUsers.value),
              space: Number(customClientData.space.value),
            };
            dispatch(updateClinicAction(editClinicData._id, data));
          }
        } else {
          dispatch(updateClinicAction(editClinicData._id, data));
        }
      } else {
        if (customClientData.space.value === '') {
          setCustomClientData({
            ...customClientData,
            space: {
              ...customClientData.space,
              error: 'Please allocate client some space to upload data.',
            },
          });
        } else if (customClientData.adminUsers.value === '') {
          setCustomClientData({
            ...customClientData,
            adminUsers: {
              ...customClientData.adminUsers,
              error: 'Please enter client admin users.',
            },
          });
        } else if (customClientData.downloadUsers.value === '') {
          setCustomClientData({
            ...customClientData,
            downloadUsers: {
              ...customClientData.downloadUsers,
              error: 'Please enter client admin users.',
            },
          });
        } else {
          const data = {
            name: clinicName.value,
            email: clinicEmail.value,
            phone: clinicPhone.value,
            address: clinicAddress.value,
            modules: clinicModules,
            customClientDetails: {
              adminUsers: Number(customClientData.adminUsers.value),
              downloadUsers: Number(customClientData.downloadUsers.value),
              space: Number(customClientData.space.value),
            },
          };
          console.log(data, 'data');
          dispatch(addClinicAction(data));
        }
      }
    }
  };

  const handleAddModalClose = () => {
    setAddClinicModal(false);
    setEditClinicData({});
    setClinicName({ value: '', error: '' });
    setClinicEmail({ value: '', error: '' });
    setClinicPhone({ value: '', error: '' });
    setClinicAddress({ value: '', error: '' });
    setClinicLogo({ value: '', error: '' });
    setClinicModules({ fileSharing: false, chat: false, videoCall: false, adminOnlyChat: false });
    setCustomClientData({
      space: {
        value: '',
        error: '',
      },
      adminUsers: {
        value: '',
        error: '',
      },
      downloadUsers: {
        value: '',
        error: '',
      },
    });
  };
  // ============================== ADD/UPDATE Client End ======================================

  // ============================== Reset Client Password Start ======================================
  React.useEffect(() => {
    if (resetUserPassword && resetUserPassword.userInfo && resetUserPassword.userInfo.status === 'Success') {
      toast.success('Client password reset successfull.');
      handleResetPasswordModalClose();
      dispatch(clinicsListAction(pageNumber, pageLimit));
      dispatch({ type: RESET_USER_PASSWORD_RESET });
    }
  }, [resetUserPassword, dispatch, pageNumber]);

  const handleResetPasswordButtonClick = data => {
    setResetPasswordData(data);
    setResetPasswordModal(true);
  };

  const handleResetPassword = () => {
    if (resetPassword.value === '' || resetPassword.value.trim() === '') {
      setResetPassword({
        ...resetPassword,
        error: 'Please enter new password',
      });
    } else if (resetPassword.value.length < 8) {
      setResetPassword({
        ...resetPassword,
        error: 'Password must have atleast 8 characters',
      });
    } else if (resetPasswordConfirm.value === '' || resetPasswordConfirm.value.trim() === '') {
      setResetPasswordConfirm({
        ...resetPasswordConfirm,
        error: 'Please confirm new password',
      });
    } else if (resetPassword.value !== resetPasswordConfirm.value) {
      setResetPasswordConfirm({
        ...resetPasswordConfirm,
        error: 'Passwords do not match',
      });
    } else {
      if (resetPasswordData && resetPasswordData._id) dispatch(resetPasswordAction(resetPasswordData._id, resetPassword.value));
      else
        setResetPasswordConfirm({
          ...resetPasswordConfirm,
          error: 'Please select clinic to reset password',
        });
    }
  };

  const handleResetPasswordModalClose = () => {
    setResetPasswordModal(false);
    setResetPasswordData({});
    setResetPassword({ value: '', error: '' });
    setResetPasswordConfirm({ value: '', error: '' });
  };
  // ============================== Reset Client Password End ======================================

  // ============================== Toggle Client Start ======================================
  React.useEffect(() => {
    if (updateUserStatus && updateUserStatus.userInfo && updateUserStatus.userInfo.status === 'success') {
      toast.error('Client status change successfull.');
      handleToggleModalClose();
      dispatch(clinicsListAction(pageNumber, pageLimit));
      dispatch({ type: UPDATE_USER_STATUS_RESET });
    }
  }, [updateUserStatus, pageNumber, dispatch]);

  const handleToggleButtonClick = data => {
    setDeleteClinicData(data);
    setToggleClinicModal(true);
  };

  const handleClinicToggle = (id, active) => {
    dispatch(toggleUserActive(id, !active));
  };

  const handleToggleModalClose = () => {
    setToggleClinicModal(false);
    setDeleteClinicData({});
  };
  // ============================== Toggle Client End ======================================

  // ============================== Delete Client Start ======================================
  React.useEffect(
    () => {
      if (deleteClinic && deleteClinic.clinic && deleteClinic.clinic.status === 'success') {
        toast.error('Delete Client Successful');
        handleDeleteClinicModalClose();
        dispatch(clinicsListAction(pageNumber, pageLimit));
        dispatch({ type: DELETE_CLINIC_RESET });
      }
    },
    //eslint-disable-next-line
    [deleteClinic, dispatch, pageNumber]
  );

  const handleDeleteClinicButtonClick = data => {
    setDeleteClinicData(data);
    setDeleteClinicModal(true);
  };

  const handleDeleteClinic = () => {
    if (deleteEmail.value === '' || deleteEmail.value.trim() === '') {
      setDeleteEmail({
        ...deleteEmail,
        error: 'Please enter clinic email to delete',
      });
    } else if (deleteClinicData && deleteEmail.value !== deleteClinicData.email) {
      setDeleteEmail({ ...deleteEmail, error: 'Invalid email entered' });
    } else {
      if (deleteClinicData && deleteClinicData._id) dispatch(deleteClinicAction(deleteClinicData._id));
      else
        setDeleteEmail({
          ...deleteEmail,
          error: 'Please select clinic to delete',
        });
    }
  };

  const handleDeleteClinicModalClose = () => {
    setDeleteClinicModal(false);
    setDeleteClinicData({});
    setDeleteEmail({ value: '', error: '' });
  };
  // ============================== Delete Client End ======================================

  return (
    <Content
      headerTitle='Clients'
      currentMenu='clinics'
      addBtn={true}
      addBtnTitle='Add Client'
      addBtnClick={() => setAddClinicModal(true)}
      searchBox={true}
      searchPlaceholder='Search Clients'
      handleSearchChange={e => setSearchKey(e.target.value)}
      totalReult={totalPageSize !== 0 ? totalPageSize + ' clients' : ''}
    >
      <ClinicListTable
        clinicsList={clinicsList}
        searchKey={searchKey}
        handleToggleButtonClick={handleToggleButtonClick}
        handleResetPasswordButtonClick={handleResetPasswordButtonClick}
        handleEditClinicButtonClick={handleEditClinicButtonClick}
        handleDeleteClinicButtonClick={handleDeleteClinicButtonClick}
      />

      <div className='table__footer'>
        {/* <p className='table__footer_text'>
					Showing {startValue} - {endValue} results out of {totalPageSize} clinics
				</p> */}
        <p />
        <Pagination onPageChange={handlePageChange} rowsPerPage={pageLimit} totalPageSize={totalPageSize} pageNumber={pageNumber} />
      </div>

      <AddClinicModal
        addClinicModal={addClinicModal}
        handleAddClinic={handleAddClinic}
        handleAddModalClose={handleAddModalClose}
        createClinicLoading={addClinic && addClinic.loading}
        updateClinicLoading={updateClinic && updateClinic.loading}
        data={{
          clinicName,
          setClinicName,
          clinicEmail,
          setClinicEmail,
          clinicPhone,
          setClinicPhone,
          clinicAddress,
          setClinicAddress,
          clinicLogo,
          setClinicLogo,
          customClientData,
          setCustomClientData,
          editClinicData,
          clinicModules,
          setClinicModules,
        }}
      />

      <ResetPasswordModal
        resetPasswordModal={resetPasswordModal}
        loading={false}
        handleResetPassword={handleResetPassword}
        handleResetPasswordModalClose={handleResetPasswordModalClose}
        data={{
          resetPassword,
          setResetPassword,
          resetPasswordConfirm,
          setResetPasswordConfirm,
          resetPasswordData,
        }}
      />

      <ToggleClinicActive
        toggleClinicModal={toggleClinicModal}
        deleteClinicData={deleteClinicData}
        loading={false}
        handleToggleModalClose={handleToggleModalClose}
        handleClinicToggle={handleClinicToggle}
      />

      <DeleteClinicModal
        deleteClinicModal={deleteClinicModal}
        loading={deleteClinic && deleteClinic.loading}
        handleDeleteClinic={handleDeleteClinic}
        handleDeleteClinicModalClose={handleDeleteClinicModalClose}
        data={{
          deleteEmail,
          setDeleteEmail,
          deleteClinicData,
        }}
      />
    </Content>
  );
};

export default ClinicsScreen;
