import React from 'react';
import Modal from '../../../components/Modal';
// import ModalHeading from '../../components/Modal/ModalHeading';
import ModalHeading from '../../../components/Modal/ModalHeading';
import ModalForm from '../../../components/Modal/ModalForm';
import InputsSection from '../../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../../components/Modal/ModalButtonsNew';
import DeleteModalSkeleton from '../../../components/Skeletons/DeleteModalSkeleton';

const ConfirmationModal = ({
	showModal,
	setShowModal,
	loading,
	message,
	handleRemoveUser,
	buttonLabel,
	title
	}) => {
	return (
		<Modal show={showModal} style={{ padding: '0rem' }}>
			<ModalHeading style={{ marginTop: '1.5rem' }} heading={title ? title : 'Remove user'} />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '1rem', padding: '0rem' }}>
					<InputsSection style={{ width: '40rem' }}>
						<p className='primaryText' style={{ fontSize: '1.4rem' }}>{message}</p>
					</InputsSection>
					<div style={{ marginTop: '-1rem' }}>
						<ModalButtons
							submitButtonLabel={buttonLabel}
							onSubmit={handleRemoveUser}
							cancelButtonLabel='Cancel'
							onCancel={() => setShowModal(false)}
						/>
					</div>
				</ModalForm>
			)}
		</Modal>
	);
};

export default ConfirmationModal;