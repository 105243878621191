import React, { useState } from 'react';
import Style from './GroupInfoUserInput.module.css'

const GroupInfoUserInput = ({inputValue, setInputValue, handleInputChange, dynamicWidth, handleSubmit}) => {

    const inputWidth = inputValue.length * 3 + dynamicWidth;

    return (
        <div className={Style.inputContainer}>
            <input
                autoFocus={true}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className={Style.input}
                style={{ width: inputWidth }}
                placeholder='Enter group name'
            />
            {inputValue.length > 0 && <svg onClick={() => handleSubmit()} style={{marginLeft: '-1.4rem', marginTop: '-0.4rem'}} className={Style.group_name_edit_icon} /* onClick={() => setNameEdit(true)} */>
                    <use xlinkHref={`/assets/sprite.svg#icon-group-name-save`} />
                </svg>}
        </div>
    )
}

export default GroupInfoUserInput
