import React from 'react';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';
import classes from './ExportButton.module.css';

const ExportButton = ({ exportData, exportTitle }) => {
  const handleExport = () => {
    if (exportData.length > 0 && exportTitle) {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      XLSX.writeFile(workbook, `${exportTitle}.xlsx`);
    } else {
      toast.error('Failed to export data');
    }
  };

  return (
    <button type='button' className={classes.export_btn} onClick={() => handleExport()}>
      <p>
        <svg className={classes.export_btn_icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-export`} />
        </svg>
      </p>
      <span className={classes.export_btn_text}>Export</span>
    </button>
  );
};

export default ExportButton;
