import React from 'react';
import Button from '../Button';
import CancelButton from '../CancelButton';

import ModalButtonsStyles from './ModalButtons.module.css';

const ModalButtons = ({ submitButtonLabel, onSubmit, submitButtonDisabled, cancelButtonLabel, onCancel }) => {
  return (
    <div className={ModalButtonsStyles.buttons}>
      {submitButtonLabel && (
        <Button
          label={submitButtonLabel}
          disabled={submitButtonDisabled}
          onClick={e => {
            e.preventDefault();
            onSubmit();
          }}
        />
      )}
      {cancelButtonLabel && (
        <CancelButton
          label={cancelButtonLabel}
          onClick={e => {
            e.preventDefault();
            onCancel();
          }}
        />
      )}
    </div>
  );
};

export default ModalButtons;
