import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import DownloadFileSkeleton from '../../components/Skeletons/DownloadFileSkeleton';
import { DOWNLOAD_FILE_EXCHANGE_RESET } from '../../redux/constants/folderConstants';

const DownloadFileModal = ({ data }) => {
	const dispatch = useDispatch();
	const {
		downloadFileModal,
		setDownloadFileModal,
		downloadFilePasswordInput,
		setDownloadFilePasswordInput,
		handleDownloadFile
	} = data;

	const downloadFile = useSelector((state) => state.downloadFile);

	useEffect(
		() => {
			if (downloadFile && downloadFile.file && downloadFile.file.status === 'success') {
				toast.success('File download successfull');
				onModalClose();
				// window.open(downloadFile.file.link);
			}
		},
		// eslint-disable-next-line
		[ downloadFile ]
	);

	useEffect(
		() => {
			if (downloadFile && !downloadFile.loading && downloadFile.error) {
				toast.error('File download successfull');
				setDownloadFilePasswordInput({ ...downloadFilePasswordInput, error: downloadFile.error });
			}
		},
		// eslint-disable-next-line
		[ downloadFile ]
	);

	const onModalClose = () => {
		setDownloadFileModal({ file: {}, status: false });
		setDownloadFilePasswordInput({ value: '', error: '' });

		dispatch({ type: DOWNLOAD_FILE_EXCHANGE_RESET });
	};

	return (
		<Modal show={downloadFileModal.status}>
			<ModalHeading heading={`Download ${downloadFileModal.file.name}?`} />
			{downloadFile && downloadFile.loading ? (
				<DownloadFileSkeleton fileProtected={downloadFileModal.file.protected} />
			) : (
				<Fragment>
					<ModalForm style={{ marginBottom: '2.5rem' }}>
						<InputsSection style={{ width: '34rem' }}>
							{downloadFileModal.file.protected ? (
								<InputBox
									label={`Enter password to download file`}
									type='password'
									placeholder='••••••••••••••••'
									value={downloadFilePasswordInput.value}
									onChange={(event) => {
										setDownloadFilePasswordInput({ value: event.target.value, error: '' });
									}}
									errorMessage={downloadFilePasswordInput.error}
									style={{ width: '30rem' }}
								/>
							) : (
								<p className='modal__subtext' style={{ margin: '0', marginBottom: '0' }}>
									Please click on download button to download this file.
								</p>
							)}
						</InputsSection>
						<ModalButtons
							submitButtonLabel='Download File'
							onSubmit={handleDownloadFile}
							cancelButtonLabel='Cancel'
							onCancel={onModalClose}
						/>
					</ModalForm>
				</Fragment>
			)}
		</Modal>
	);
};

export default DownloadFileModal;
