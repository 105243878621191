import React from 'react';
import './button.css';

const Button = ({ name, onClick, icon, style }) => {
  return (
    <div>
      <button onClick={onClick} className='button' style={style}>
        {name}
        {icon ? (
          <svg className='iconDash'>
            <use xlinkHref={`/sprite.svg#icon-${icon}`} />
          </svg>
        ) : (
          ''
        )}
      </button>
    </div>
  );
};

export default Button;
