import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logout, deleteMeAction, getLoggedinUserAction, updateLoggedinUserDetails, changeLoggedInUserPasswordAction } from '../../redux/actions/userActions';
import { validateOnlyChacters, validateEmail, validatePhone } from '../../utils/validators';
import {
  CHANGE_LOGGED_IN_USER_PASSWORD_RESET,
  DELETE_ME_RESET,
  GET_LOGGED_USER_DETAILS_RESET,
  UPDATE_LOGGED_USER_DETAILS_RESET,
} from '../../redux/constants/userConstants';

import Content from '../../components/Content';
import TextInput from '../LoginScreen/TextInput';
import Button from '../../components/Modal/Button';

import MyProfileStyles from './MyProfileScreen.module.css';
import EditProfileModal from './EditProfileModal';
import { useHistory, useParams } from 'react-router-dom';
import DeactiveAccountModal from './DeactiveAccountModal';
// import CancelSubscription from './CancelSubscription';

const MyProfileScreen = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState({
    value: '',
    error: '',
    validated: false,
  });
  const [passwordNew, setPasswordNew] = React.useState({
    value: '',
    error: '',
    validated: false,
  });

  const [editProfileModal, setEditProfileModal] = React.useState(false);
  const [userName, setUserName] = React.useState({ value: '', error: '' });
  const [userEmail, setUserEmail] = React.useState({ value: '', error: '' });
  const [userPhone, setUserPhone] = React.useState({ value: '', error: '' });
  const [userAddress, setUserAddress] = React.useState({
    value: '',
    error: '',
  });

  const [deactivateUserModal, setDeactivateUserModal] = React.useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = React.useState(false);

  const loggedinUser = useSelector(state => state.loggedinUser);
  const clinicDetails = useSelector(state => state.clinicDetails);
  const loggedInUserPasswordChange = useSelector(state => state.loggedInUserPasswordChange);
  const updateLoggedInUser = useSelector(state => state.updateLoggedInUser);
  const deleteMe = useSelector(state => state.deleteMe);
  const history = useHistory();

  React.useEffect(() => {
    if (loggedInUserPasswordChange && loggedInUserPasswordChange.userInfo && loggedInUserPasswordChange.userInfo.status === 'success') {
      dispatch(logout());
      dispatch({ type: CHANGE_LOGGED_IN_USER_PASSWORD_RESET });
      toast.success('Password changed successfully');
    }
  }, [loggedInUserPasswordChange, dispatch]);

  React.useEffect(() => {
    if (loggedinUser && loggedinUser.userInfo && loggedinUser.userInfo.data && loggedinUser.userInfo.data.data) {
      setUserName({ value: loggedinUser.userInfo.data.data.name, error: '' });
      setUserEmail({ value: loggedinUser.userInfo.data.data.email, error: '' });
      setUserPhone({ value: loggedinUser.userInfo.data.data.phone, error: '' });
      setUserAddress({
        value: loggedinUser.userInfo.data.data.address,
        error: '',
      });
    }
    // history.replace('/chat');
    // setTimeout(() =>{
    //   window.location.reload();
    // }, 1000)
  }, [loggedinUser]);

  React.useEffect(() => {
    if (updateLoggedInUser && updateLoggedInUser.userInfo && updateLoggedInUser.userInfo.status === 'success') {
      handleModalClose();
      dispatch(getLoggedinUserAction());
      dispatch({ type: UPDATE_LOGGED_USER_DETAILS_RESET });
      toast.success('Your profile data successfully updated!');
    }
  }, [updateLoggedInUser, dispatch]);

  React.useEffect(() => {
    if (deleteMe && deleteMe.success) {
      dispatch({ type: DELETE_ME_RESET });
      dispatch(logout());
      toast.error('Account deactivated successfully!');
    }
  }, [deleteMe, dispatch]);

  const handleChangePassword = () => {
    if (password.value === '' || password.value.trim() === '') {
      setPassword({
        ...password,
        error: 'Please enter your current password',
        validated: false,
      });
    } else if (password.value.length < 8) {
      setPassword({
        ...password,
        error: 'Password must have atleast 8 characters',
        validated: false,
      });
    } else if (passwordNew.value === '' || passwordNew.value.trim() === '') {
      setPasswordNew({
        ...passwordNew,
        error: 'Please enter your new password',
        validated: false,
      });
    } else if (passwordNew.value.length < 8) {
      setPasswordNew({
        ...passwordNew,
        error: 'Password must have atleast 8 characters',
        validated: false,
      });
    } else {
      dispatch(changeLoggedInUserPasswordAction(password.value, passwordNew.value));
    }
  };

  const handleModalClose = () => {
    setEditProfileModal(false);
    // setUserName({ value: '', error: '' });
    // setUserEmail({ value: '', error: '' });
    // setUserPhone({ value: '', error: '' });
    // setUserAddress({ value: '', error: '' });
  };

  const handleEditProfile = () => {
    if (userName.value === '' || userName.value.trim() === '') {
      setUserName({ ...userName, error: 'Please enter your name' });
    } else if (!validateOnlyChacters(userName.value)) {
      setUserName({ ...userName, error: 'Please enter only alphabets' });
    } else if (userEmail.value === '' || userEmail.value.trim() === '' || !validateEmail(userEmail.value)) {
      setUserEmail({ ...userEmail, error: 'Please enter valid email address' });
    } else if (userPhone.value === '' || userPhone.value.trim() === '' || !validatePhone(userPhone.value)) {
      setUserPhone({ ...userPhone, error: 'Phone number must have 10 digits' });
    } else if (userAddress.value === '' || userAddress.value.trim() === '') {
      setUserAddress({ ...userAddress, error: 'Please enter your address' });
    } else {
      dispatch(
        updateLoggedinUserDetails({
          name: userName.value,
          email: userEmail.value,
          phone: userPhone.value,
          address: userAddress.value,
        })
      );
    }
  };

  const handleDeactivateUser = () => {
    if (loggedinUser && loggedinUser.userInfo && loggedinUser.userInfo.data && loggedinUser.userInfo.data.data && loggedinUser.userInfo.data.data.role === 'admin') {
      alert('You can not deactivate admin account.');
    } else {
      dispatch(deleteMeAction());
    }
  };

  // const handleUnsubscribeClient = () => {
  //   if (
  //     loggedinUser &&
  //     loggedinUser.userInfo &&
  //     loggedinUser.userInfo.data &&
  //     loggedinUser.userInfo.data.data &&
  //     (loggedinUser.userInfo.data.data.role === 'fileAdmin' || loggedinUser.userInfo.data.data.role === 'clinic')
  //   ) {
  //     dispatch(unsubscribeClientAction());
  //   } else {
  //     alert('You can not permission to perform this action.');
  //   }
  // };

  return (
    <Content headerTitle={'My Profile'} currentMenu='profile'>
      {loggedinUser && loggedinUser.loading
        ? null
        : loggedinUser &&
          loggedinUser.userInfo &&
          loggedinUser.userInfo.data &&
          loggedinUser.userInfo.data.data && (
            <div className={MyProfileStyles.content}>
              <div className={MyProfileStyles.content_left}>
                <div>
                  <svg className={MyProfileStyles.content_left_profile_image}>
                    <use xlinkHref={`/assets/sprite.svg#icon-user-circle-o`} />
                  </svg>
                  <h2 className={MyProfileStyles.content_left_profile_name}>{loggedinUser.userInfo.data.data.name}</h2>
                  <p className={MyProfileStyles.content_left_profile_role}>
                    Your are logged in with{' '}
                    {loggedinUser.userInfo.data.data.role && loggedinUser.userInfo.data.data.role === 'fileUserOnlyDownload'
                      ? 'File User (Read Only)'
                      : loggedinUser.userInfo.data.data.role.startsWith('file')
                      ? 'File ' + loggedinUser.userInfo.data.data.role.split('file')[1]
                      : loggedinUser.userInfo.data.data.role.slice(0, 1).toUpperCase() + loggedinUser.userInfo.data.data.role.slice(1)}{' '}
                    account.
                  </p>

                  <div className={MyProfileStyles.content_left_contacts}>
                    <div className={MyProfileStyles.content_left_contact}>
                      <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_red}>
                        <svg className={MyProfileStyles.content_left_contact_box_icon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-mobile`} />
                        </svg>
                      </div>
                      <div className={MyProfileStyles.content_left_contact_data}>
                        <p className={MyProfileStyles.content_left_contact_data_title}>Phone</p>
                        <p className={MyProfileStyles.content_left_contact_data_field}>{loggedinUser.userInfo.data.data.phone}</p>
                      </div>
                    </div>

                    <div className={MyProfileStyles.content_left_contact}>
                      <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_blue}>
                        <svg className={MyProfileStyles.content_left_contact_box_icon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-email`} />
                        </svg>
                      </div>
                      <div className={MyProfileStyles.content_left_contact_data}>
                        <p className={MyProfileStyles.content_left_contact_data_title}>Email</p>
                        <p className={MyProfileStyles.content_left_contact_data_field}>{loggedinUser.userInfo.data.data.email}</p>
                      </div>
                    </div>

                    <div className={MyProfileStyles.content_left_contact}>
                      <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_primary}>
                        <svg className={MyProfileStyles.content_left_contact_box_icon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-map-pin`} />
                        </svg>
                      </div>
                      <div className={MyProfileStyles.content_left_contact_data}>
                        <p className={MyProfileStyles.content_left_contact_data_title}>Address</p>
                        <p className={MyProfileStyles.content_left_contact_data_field}>{loggedinUser.userInfo.data.data.address}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={MyProfileStyles.content_left_bottom}>
                  <Button label='Edit My Profile' span={false} style={{ justifyContent: 'center' }} onClick={() => setEditProfileModal(true)} />

                  {loggedinUser &&
                    loggedinUser.userInfo &&
                    loggedinUser.userInfo.data &&
                    loggedinUser.userInfo.data.data &&
                    loggedinUser.userInfo.data.data.role !== 'admin' && (
                      <button className={MyProfileStyles.userDetails__deatctivateButton} onClick={() => setDeactivateUserModal(true)}>
                        Deactivate my account
                      </button>
                    )}
                </div>
              </div>

              <div className={MyProfileStyles.content_right}>
                <div className={MyProfileStyles.changePasswordSection}>
                  <div className={MyProfileStyles.changePasswordSection_left}>
                    <h2 className={MyProfileStyles.changePasswordSection_heading}>Change Password</h2>
                    <TextInput
                      type='password'
                      label='Current Password'
                      placeholder='Current password'
                      icon='key'
                      onChange={e => {
                        if (e.target.value.length > 7) {
                          setPassword({
                            value: e.target.value,
                            validated: true,
                          });
                        } else {
                          setPassword({
                            value: e.target.value,
                            validated: false,
                          });
                        }
                      }}
                      validated={password.validated}
                      errorMessage={password.error}
                      light={false}
                    />
                    <TextInput
                      type='password'
                      label='New Password'
                      placeholder='New password'
                      icon='key'
                      onChange={e => {
                        if (e.target.value.length > 7) {
                          setPasswordNew({
                            value: e.target.value,
                            validated: true,
                          });
                        } else {
                          setPasswordNew({
                            value: e.target.value,
                            validated: false,
                          });
                        }
                      }}
                      validated={passwordNew.validated}
                      errorMessage={passwordNew.error}
                      light={false}
                    />
                    <Button label='Change Password' style={{ alignSelf: 'flex-start' }} onClick={handleChangePassword} />
                  </div>

                  <div className={MyProfileStyles.changePasswordSection_right}>
                    <p className={MyProfileStyles.changePasswordSection_text}>We recommend to not share your password with anyone.</p>
                    <p className={MyProfileStyles.changePasswordSection_text}>
                      Also change your password frequently which adds an extra layer of security for your account.
                    </p>
                  </div>
                </div>

                {loggedinUser.userInfo.data.data.role === 'clinic' && (
                  <Fragment>
                    {loggedinUser.userInfo.data.data.customClient ? (
                      <div className={MyProfileStyles.planDetailsSection}>
                        <div>
                          <h2 className={MyProfileStyles.changePasswordSection_heading}>Your plan details</h2>

                          <div className={MyProfileStyles.planDetailsSection_content}>
                            <h5 className={MyProfileStyles.planDetailsSection_price}>{loggedinUser.userInfo.data.data.name}</h5>
                            <h5 className={MyProfileStyles.planDetailsSection_price}>
                              <span>Subscribed on: {new Date(loggedinUser.userInfo.data.data.onBoardOn).toDateString()}</span>
                            </h5>

                            <div className={MyProfileStyles.planDetailsSection_feature}>
                              <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                  <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                </svg>
                              </div>
                              <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                {loggedinUser.userInfo.data.data.customClientDetails?.space} GB dedicated storage
                              </p>
                            </div>

                            <div className={MyProfileStyles.planDetailsSection_feature}>
                              <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                  <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                </svg>
                              </div>
                              <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                {loggedinUser.userInfo.data.data.customClientDetails?.adminUsers} admin users
                              </p>
                            </div>

                            <div className={MyProfileStyles.planDetailsSection_feature}>
                              <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                  <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                </svg>
                              </div>
                              <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                {loggedinUser.userInfo.data.data.customClientDetails?.downloadUsers} read only users
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                )}
                {loggedinUser.userInfo.data.data.role === 'fileAdmin' && (
                  <Fragment>
                    {
                      loggedinUser.userInfo.data.data.clinic.customClient ? (
                        <div className={MyProfileStyles.planDetailsSection}>
                          <div>
                            <h2 className={MyProfileStyles.changePasswordSection_heading}>Your plan details</h2>

                            <div className={MyProfileStyles.planDetailsSection_content}>
                              <h5 className={MyProfileStyles.planDetailsSection_price}>{loggedinUser.userInfo.data.data.clinic.name}</h5>
                              <h5 className={MyProfileStyles.planDetailsSection_price}>
                                <span>Subscribed on: {new Date(loggedinUser.userInfo.data.data.clinic.onBoardOn).toDateString()}</span>
                              </h5>

                              <div className={MyProfileStyles.planDetailsSection_feature}>
                                <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                  <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                  </svg>
                                </div>
                                <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                  {loggedinUser.userInfo.data.data.clinic.customClientDetails?.space} GB dedicated storage
                                </p>
                              </div>

                              <div className={MyProfileStyles.planDetailsSection_feature}>
                                <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                  <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                  </svg>
                                </div>
                                <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                  {loggedinUser.userInfo.data.data.clinic.customClientDetails?.adminUsers} admin users
                                </p>
                              </div>

                              <div className={MyProfileStyles.planDetailsSection_feature}>
                                <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                                  <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                                  </svg>
                                </div>
                                <p className={MyProfileStyles.planDetailsSection_feature_text}>
                                  {loggedinUser.userInfo.data.data.clinic.customClientDetails?.downloadUsers} read only users
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                      //   (
                      //     clinicDetails &&
                      //     clinicDetails.details &&
                      //     clinicDetails.details.package &&
                      //     clinicDetails.details.package.metadata && (
                      //       <div className={MyProfileStyles.planDetailsSection}>
                      //         <div>
                      //           <h2 className={MyProfileStyles.changePasswordSection_heading}>Your plan details</h2>

                      //           <div className={MyProfileStyles.planDetailsSection_content}>
                      //             <h5 className={MyProfileStyles.planDetailsSection_price}>
                      //               {clinicDetails.details.package.name}{' '}
                      //               <span>
                      //                 $ {clinicDetails.details.plan.amount} per {clinicDetails.details.plan.interval}
                      //               </span>
                      //             </h5>
                      //             <h5 className={MyProfileStyles.planDetailsSection_price}>
                      //               <span>Subscribed on: {new Date(clinicDetails.details.onBoardOn).toDateString()}</span>
                      //             </h5>

                      //             <div className={MyProfileStyles.planDetailsSection_feature}>
                      //               <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                      //                 <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                      //                   <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                      //                 </svg>
                      //               </div>
                      //               <p className={MyProfileStyles.planDetailsSection_feature_text}>{clinicDetails.details.package.description}</p>
                      //             </div>
                      //             <div className={MyProfileStyles.planDetailsSection_feature}>
                      //               <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                      //                 <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                      //                   <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                      //                 </svg>
                      //               </div>
                      //               <p className={MyProfileStyles.planDetailsSection_feature_text}>{clinicDetails.details.package.metadata.space} GB dedicated storage</p>
                      //             </div>
                      //             <div className={MyProfileStyles.planDetailsSection_feature}>
                      //               <div className={MyProfileStyles.planDetailsSection_feature_icon_box}>
                      //                 <svg className={MyProfileStyles.loginContainer_textBox_icon}>
                      //                   <use xlinkHref={`/assets/sprite.svg#icon-check`} />
                      //                 </svg>
                      //               </div>
                      //               <p className={MyProfileStyles.planDetailsSection_feature_text}>{clinicDetails.details.package.metadata.users} users</p>
                      //             </div>
                      //           </div>
                      //         </div>

                      //         <div style={{ alignSelf: 'flex-end' }}>
                      //           <p className={MyProfileStyles.planDetailsSection_cancel_btn}>
                      //             Plan expires on {new Date(clinicDetails.details.packageExpires).toDateString()}
                      //           </p>
                      //           <button className={MyProfileStyles.planDetailsSection_cancel_btn} onClick={() => setUnsubscribeModal(true)}>
                      //             Cancel your subscription
                      //           </button>
                      //         </div>
                      //       </div>
                      //     )
                      // )
                    }

                    <div className={MyProfileStyles.storageContainer}>
                      <h3 className={MyProfileStyles.changePasswordSection_heading}>Space Used</h3>
                      <div className={MyProfileStyles.storageContainerLines}>
                        <div className={MyProfileStyles.storageContainerLineFull} />
                        <div
                          className={MyProfileStyles.storageContainerLineUsed}
                          style={{
                            width: `${
                              loggedinUser.userInfo.data.data.usedSpace
                                ? (Number(loggedinUser.userInfo.data.data.usedSpace) / Number(loggedinUser.userInfo.data.data.maxStorageAllowed)) * 100
                                : 0
                            }% `,
                          }}
                        />
                      </div>
                      <h4 className={MyProfileStyles.storageContainerTitle}>
                        {loggedinUser.userInfo.data.data.usedSpace ? loggedinUser.userInfo.data.data.usedSpace.toFixed(3) : '0'}
                        GB of {loggedinUser.userInfo.data.data.maxStorageAllowed}
                        GB used
                      </h4>
                    </div>

                    {/* {loggedinUser.userInfo.data.data.role === 'clinic' &&
                      clinicDetails &&
                      clinicDetails.details &&
                      clinicDetails.details.package &&
                      clinicDetails.details.package.metadata && (
                        <div className={MyProfileStyles.storageContainer}>
                          <div className={MyProfileStyles.storageContainerLines}>
                            <div className={MyProfileStyles.storageContainerLineFull} />
                            <div
                              className={MyProfileStyles.storageContainerLineUsed}
                              style={{
                                width: `${(clinicDetails.details.usedSpace / clinicDetails.details.package.metadata.space) * 100}%`,
                              }}
                            />
                          </div>
                          <h4 className={MyProfileStyles.storageContainerTitle}>
                            {clinicDetails.details.usedSpace}GB out of {clinicDetails.details.package.metadata.space}GB used
                          </h4>
                        </div>
                      )} */}

                    {clinicDetails && clinicDetails.details && clinicDetails.details.package && clinicDetails.details.package.metadata && (
                      <div className={MyProfileStyles.user_used_container}>
                        <h3 className={MyProfileStyles.changePasswordSection_heading}>Total Users</h3>
                        <div className={MyProfileStyles.storageContainerLines}>
                          <div className={MyProfileStyles.storageContainerLineFull} />
                          {clinicDetails.details.package.metadata.users === 1 ? (
                            <div className={MyProfileStyles.storageContainerLineUsed} style={{ width: '100%' }} />
                          ) : (
                            <div
                              className={MyProfileStyles.storageContainerLineUsed}
                              style={{
                                width: `${(clinicDetails.details.totalResults / clinicDetails.details.package.metadata.users) * 100}%`,
                              }}
                            />
                          )}
                        </div>
                        <h4 className={MyProfileStyles.storageContainerTitle}>
                          {clinicDetails.details.package.metadata.users === 1
                            ? '1 of ' + clinicDetails.details.package.metadata.users + ' users'
                            : clinicDetails.details.totalResults + ' of ' + clinicDetails.details.package.metadata.users + ' users'}
                        </h4>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          )}

      <EditProfileModal
        editProfileModal={editProfileModal}
        handleModalClose={handleModalClose}
        handleEditProfile={handleEditProfile}
        loading={updateLoggedInUser && updateLoggedInUser.loading}
        data={{
          userName,
          setUserName,
          userEmail,
          setUserEmail,
          userPhone,
          setUserPhone,
          userAddress,
          setUserAddress,
        }}
      />

      <DeactiveAccountModal
        deactivateUserModal={deactivateUserModal}
        loading={deleteMe && deleteMe.loading}
        handleDeactivateUserModalClose={() => setDeactivateUserModal(false)}
        handleDeactivateUser={handleDeactivateUser}
      />

      {/* <CancelSubscription
        unsubscribeModal={unsubscribeModal}
        loading={deleteMe && deleteMe.loading}
        handleUnsubscribeClientModalClose={() => setUnsubscribeModal(false)}
        handleUnsubscribeClient={handleUnsubscribeClient}
      /> */}
    </Content>
  );
};

export default MyProfileScreen;
