import React from 'react';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';
import TextArea from '../../components/Modal/TextArea';
import SelectBox from '../../components/SelectBoxButton';

const AddVideoNoteModal = ({
  loading,
  handleSubmit,
  show,
  handleModalClose,
  data,
}) => {
  const { newVideoNote, setNewVideoNote, noteType, setNoteType } = data;

  return (
    <Modal show={show}>
      <ModalHeading heading={`Add Video Note`} />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection style={{ width: '34rem' }}>
            <TextArea
              placeholder='Write note here...'
              rows={5}
              value={newVideoNote.value}
              onChange={(e) =>
                setNewVideoNote({ value: e.target.value, error: '' })
              }
              errorMessage={newVideoNote.error}
            />
            {/* <SelectBox
              // label='Note Type'
              value={noteType.value}
              onChange={(e) =>
                setNoteType({ value: e.target.value, error: '' })
              }
              errorMessage={noteType.error}
            >
              <option value='internal'>Internal Note</option>
              <option value='public'>Shared with user</option>
            </SelectBox> */}

            <SelectBox
              buttons={[
                {
                  label: 'Internal Note',
                  value: 'internal',
                  onClick: () => setNoteType({ value: 'internal', error: '' }),
                },
                {
                  label: 'Shared with user',
                  value: 'public',
                  onClick: () => setNoteType({ value: 'public', error: '' }),
                },
              ]}
              value={noteType.value}
            />

            {/* <div>
              <button
                type='button'
                onClick={() => setNoteType({ value: 'internal', error: '' })}
              >
                Internal Note
              </button>
              <button
                type='button'
                onClick={() => setNoteType({ value: 'public', error: '' })}
              >
                Shared with user
              </button>
            </div> */}
          </InputsSection>

          <ModalButtons
            submitButtonLabel='Add Note'
            onSubmit={handleSubmit}
            cancelButtonLabel='Cancel'
            onCancel={handleModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddVideoNoteModal;
