import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ onPageChange, rowsPerPage, pageNumber, totalPageSize }) => {
  let pageNo;
  pageNo = totalPageSize === 0 ? 0 : Math.ceil(totalPageSize / rowsPerPage);

  return (
    pageNo > 1 && (
      <ReactPaginate
        nextClassName={''}
        previousLabel={'<'}
        nextLabel={'>'}
        pageCount={pageNo}
        onPageChange={onPageChange}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        containerClassName={'paginationBttns'}
        previousLinkClassName={'previousBttn'}
        nextLinkClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={'paginationActive'}
        forcePage={pageNumber - 1}
      />
    )
  );
};

export default Pagination;
