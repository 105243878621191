import React from 'react'
import Styles from "./Email.module.css"
const InputBox = ({ type = 'text', name, placeholder, value, onChange, Text }) => {
  return (
    <div className={Styles.textBox}>
        
			{type === 'textArea' ? (
				<textarea
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className={Styles.textArea}
				/>
			) : (<>
				<span className={Styles.Text}>{Text}</span>
				<input
					type={type}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className={Styles.textInput}
				/>
				</>
			)}
		</div>
  );
}

export default InputBox