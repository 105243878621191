import React, { Fragment } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  copyFileAction,
  createFileAction,
  createFolderAction,
  cutFileAction,
  deleteFileAction,
  deleteFolderAction,
  downloadFileAction,
  editFolderAction,
  folderDataAction,
  foldersListAction,
  getFolderAction,
  getSearchSecureFilesAction,
  getSecureFilesAction,
  getSubFolderAction,
  getSubSubFolderAction,
  getSubSubSubFolderAction,
  getSubSubSubSubFolderAction,
  renameFileAction,
  shareFileAction,
  subFolderDataAction,
  subSubFolderDataAction,
  subSubSubFolderDataAction,
  subSubSubSubFolderDataAction,
} from '../../redux/actions/folderActions';
import {
  GET_ALL_FOLDER_DATA_RESET,
  GET_FOLDER_DATA_RESET,
  GET_SUB_FOLDER_DATA_RESET,
  GET_SUB_FOLDER_RESET,
  CREATE_FOLDER_RESET,
  GET_FOLDER_RESET,
  EDIT_FOLDER_RESET,
  DELETE_FOLDER_RESET,
  ADD_FILE_EXCHANGE_RESET,
  GET_SECURE_FILES_RESET,
  DELETE_FILE_EXCHANGE_RESET,
  RENAME_FILE_EXCHANGE_RESET,
  CUT_FILE_EXCHANGE_RESET,
  COPY_FILE_EXCHANGE_RESET,
} from '../../redux/constants/folderConstants';

import Content from '../../components/Content';
import FileExchangeStyles from './FileExchangeScreen.module.css';
import UploadFileModal from './UploadFileModal';
import DeleteFolderModal from './DeleteFolderModal';
import DeleteFileModal from './DeleteFileModal';
import FolderListSkeleton from '../../components/Skeletons/FolderListSkeleton';
import RecentFileUploadsSkeleton from '../../components/Skeletons/RecentFileUploadsSkeleton';
import ShareFileModal from './ShareFileModal';
import DownloadFileModal from './DownloadFileModal';
import { getClinicUserCount, getLoggedinUserAction, refreshUserAction } from '../../redux/actions/userActions';
import Pagination from '../../components/Pagination';
import RenameFileModal from './RenameFileModal';
import CopyFileModal from './CopyFileModal';
import CutFileModal from './CutFileModal';
import SearchBox from '../../components/SearchBox';
import File from './components/File';
import RecentFile from './components/RecentFile';
import Folder from './components/Folder';
import AddEditFolder from './components/AddEditFolder';
import AddFolderButton from './components/AddFolderButton';
import NoFile from '../../components/NoFile';
import FileListView from './components/FileListView';
import ShareFileForESignModal from './ShareFileForESignModal';
import { useShareFileForESign } from '../../hooks/FileExchange';
import { unMasking } from '../../utils/validators';

const FileExchangeScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [userRole, setUserRole] = React.useState('');
  const [fileSearchText, setFileSearchText] = React.useState('');
  const [fileSearchResult, setFileSearchResult] = React.useState({
    loading: false,
    data: [],
    totalResults: 0,
    error: false,
  });
  const [currentPageSearch, setCurrentPageSearch] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageLimit = 7;

  const [currentSubSubFolderPage, setCurrentSubSubFolderPage] = React.useState(1);
  const pageFileLimit = 10;

  const [currentFileView, setCurrentFileView] = React.useState('grid');
  const [totalFolders, setTotalFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState({});
  const [selectedSubFolder, setSelectedSubFolder] = React.useState({});
  const [selectedSubSubFolder, setSelectedSubSubFolder] = React.useState({});
  const [selectedSubSubSubFolder, setSelectedSubSubSubFolder] = React.useState({});
  const [selectedSubSubSubSubFolder, setSelectedSubSubSubSubFolder] = React.useState({});
  const [isAddFolder, setIsAddFolder] = React.useState(false);
  const [folderName, setFolderName] = React.useState('');
  const [isEditFolder, setIsEditFolder] = React.useState({
    data: {},
    status: false,
  });
  const [editfolderName, setEditFolderName] = React.useState('');
  const [isAddSubFolder, setIsAddSubFolder] = React.useState(false);
  const [subFolderName, setSubFolderName] = React.useState('');

  const [isAddSubSubFolder, setIsAddSubSubFolder] = React.useState(false);
  const [subSubFolderName, setSubSubFolderName] = React.useState('');

  const [isAddSubSubSubFolder, setIsAddSubSubSubFolder] = React.useState(false);
  const [subSubSubFolderName, setSubSubSubFolderName] = React.useState('');

  const [isAddSubSubSubSubFolder, setIsAddSubSubSubSubFolder] = React.useState(false);
  const [subSubSubSubFolderName, setSubSubSubSubFolderName] = React.useState('');

  const [uploadFileModal, setUploadFileModal] = React.useState(false);
  const [uploadFileToSubFolder, setUploadFileToSubFolder] = React.useState(false);
  const [uploadFileName, setUploadFileName] = React.useState({
    value: '',
    error: '',
  });
  const [uploadFileData, setUploadFileData] = React.useState({
    value: '',
    error: '',
  });
  const [passwordProtected, setPasswordProtected] = React.useState(false);
  const [filePassword, setFilePassword] = React.useState({
    value: '',
    error: '',
  });
  const [filePasswordConfirm, setFilePasswordConfirm] = React.useState({
    value: '',
    error: '',
  });
  const [uploadNote, setUploadNote] = React.useState({ value: '' });

  // Delete folder
  const [deleteFolderModal, setDeleteFolderModal] = React.useState({
    folder: {},
    status: false,
  });
  const [deleteFolderInput, setDeleteFolderInput] = React.useState({
    value: '',
    error: '',
  });

  // Delete file
  const [deleteFileModal, setDeleteFileModal] = React.useState({
    file: {},
    status: false,
  });
  const [deleteFileInput, setDeleteFileInput] = React.useState({
    value: '',
    error: '',
  });

  // Share File
  const [shareFilePasswordInput, setShareFilePasswordInput] = React.useState({
    value: '',
    error: '',
  });
  const [shareFileModal, setShareFileModal] = React.useState({
    file: {},
    status: false,
  });
  const [shareFileForESignModal, setShareFileForESignModal] = React.useState({
    file: {},
    status: false,
  });
  const [shareFileForESignInputs, setShareFileForESignInputs] = React.useState({
    email: { value: '', error: '' },
    createAccount: false,
    fullName: { value: '', error: '' },
    phone: { value: '', error: '' },
  });

  // Rename File
  const [renameFilePasswordInput, setRenameFilePasswordInput] = React.useState({
    value: '',
    error: '',
  });
  const [renameFileModal, setRenameFileModal] = React.useState({
    file: {},
    status: false,
  });

  // Copy File
  const [copyFilePasswordInput, setCopyFilePasswordInput] = React.useState({
    value: '',
    error: '',
  });
  const [copyFileModal, setCopyFileModal] = React.useState({
    file: {},
    status: false,
  });

  // Cut File
  const [cutFilePasswordInput, setCutFilePasswordInput] = React.useState({
    value: '',
    error: '',
  });
  const [cutFileModal, setCutFileModal] = React.useState({
    file: {},
    status: false,
  });

  // Download File
  const [downloadFilePasswordInput, setDownloadFilePasswordInput] = React.useState({ value: '', error: '' });
  const [downloadFileModal, setDownloadFileModal] = React.useState({
    file: {},
    status: false,
  });

  const userLogin = useSelector(state => state.userLogin);
  const loggedinUser = useSelector(state => state.loggedinUser);
  const foldersList = useSelector(state => state.foldersList);
  const folderData = useSelector(state => state.folderData);
  const subFolderData = useSelector(state => state.subFolderData);
  const subSubFolderData = useSelector(state => state.subSubFolderData);
  const subSubSubFolderData = useSelector(state => state.subSubSubFolderData);
  const subSubSubSubFolderData = useSelector(state => state.subSubSubSubFolderData);
  const getSecureFiles = useSelector(state => state.getSecureFiles);
  const getFolder = useSelector(state => state.getFolder);
  const getSubFolder = useSelector(state => state.getSubFolder);
  const getSubSubFolder = useSelector(state => state.getSubSubFolder);
  const getSubSubSubFolder = useSelector(state => state.getSubSubSubFolder);
  // const getSubSubSubSubFolder = useSelector((state) => state.getSubSubSubSubFolder);
  const createFolder = useSelector(state => state.createFolder);
  const editFolder = useSelector(state => state.editFolder);
  const deleteFolder = useSelector(state => state.deleteFolder);
  const uploadFile = useSelector(state => state.uploadFile);
  const renameFile = useSelector(state => state.renameFile);
  const cutFile = useSelector(state => state.cutFile);
  const copyFile = useSelector(state => state.copyFile);
  const deleteFile = useSelector(state => state.deleteFile);
  const { shareForESignLoading, shareForESign } = useShareFileForESign();

  const [clinicTotalUsers, setClinicTotalUsers] = React.useState({});
  const [isSpaceFull, setIsSpaceFull] = React.useState(false);

  React.useEffect(() => {
    const getClinicTotalUsers = async clientId => {
      const data = await dispatch(getClinicUserCount(clientId));
      setClinicTotalUsers(data);
    };
    if (
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.data &&
      userLogin.userInfo.data.user &&
      userLogin.userInfo.data.user.role !== 'admin' &&
      userLogin.userInfo.data.user.clinic
    ) {
      getClinicTotalUsers(userLogin.userInfo.data.user.clinic);
    }

    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'clinic') {
      getClinicTotalUsers(userLogin.userInfo.data.user._id);
    }
  }, [userLogin, dispatch, uploadFile, deleteFile]);

  React.useEffect(() => {
    if (clinicTotalUsers && clinicTotalUsers.package && clinicTotalUsers.package.metadata && clinicTotalUsers.usedSpace >= clinicTotalUsers.package.metadata.space) {
      setIsSpaceFull(true);
    } else {
      setIsSpaceFull(false);
    }
  }, [clinicTotalUsers]);

  React.useEffect(() => {
    // if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role) {
      // if (loggedinUser && loggedinUser.userInfo && loggedinUser.userInfo.data && loggedinUser.userInfo.data.data && loggedinUser.userInfo.data.data.role) {
      // setUserRole(loggedinUser.userInfo.data.data.role);
      setUserRole(userLogin.userInfo.data.user.role);
      // }
    }
  }, [userLogin, history]);

  React.useEffect(() => {
    // if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
    dispatch(getSecureFilesAction(currentPage, pageLimit));
    // }
  }, [dispatch, currentPage, pageLimit]);

  React.useEffect(() => {
    if (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic') {
      dispatch(foldersListAction());
    } else {
      setTotalFolders(userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.uploadFolder);
    }
  }, [dispatch, userLogin, userRole]);

  React.useEffect(() => {
    if (foldersList && foldersList.folders && foldersList.folders.data && foldersList.folders.data.data && foldersList.folders.data.data.length > 0) {
      setTotalFolders(foldersList.folders.data.data);
    } else {
      setTotalFolders([]);
    }
  }, [foldersList]);

  React.useEffect(() => {
    if (totalFolders && totalFolders.length > 0) {
      setSelectedFolder(totalFolders[0]);
    }
  }, [totalFolders]);

  React.useEffect(() => {
    if (selectedFolder && selectedFolder._id) {
      dispatch(folderDataAction(selectedFolder._id));
      dispatch(getFolderAction(selectedFolder._id));
    }
  }, [selectedFolder, dispatch]);

  React.useEffect(() => {
    if (selectedSubFolder && selectedSubFolder._id) {
      dispatch(subFolderDataAction(selectedSubFolder._id));
      dispatch(getSubFolderAction(selectedSubFolder._id));
    }
  }, [selectedSubFolder, dispatch]);

  React.useEffect(() => {
    if (selectedSubSubFolder && selectedSubSubFolder._id) {
      dispatch(subSubFolderDataAction(selectedSubSubFolder._id));
      dispatch(getSubSubFolderAction(selectedSubSubFolder._id));
    }
  }, [selectedSubSubFolder, dispatch]);

  React.useEffect(() => {
    if (selectedSubSubSubFolder && selectedSubSubSubFolder._id) {
      dispatch(subSubSubFolderDataAction(selectedSubSubSubFolder._id));
      dispatch(getSubSubSubFolderAction(selectedSubSubSubFolder._id));
    }
  }, [selectedSubSubSubFolder, dispatch]);

  React.useEffect(() => {
    if (selectedSubSubSubSubFolder && selectedSubSubSubSubFolder._id) {
      dispatch(subSubSubSubFolderDataAction(selectedSubSubSubSubFolder._id));
      dispatch(getSubSubSubSubFolderAction(selectedSubSubSubSubFolder._id));
    }
  }, [selectedSubSubSubSubFolder, dispatch]);

  React.useEffect(() => {
    if (
      ((deleteFolder && deleteFolder.data && deleteFolder.data.status === 'success') ||
        (createFolder && createFolder.data && createFolder.data.status === 'success') ||
        (editFolder && editFolder.data && editFolder.data.status === 'success')) &&
      selectedSubFolder &&
      selectedSubFolder._id
    ) {
      dispatch(subFolderDataAction(selectedSubFolder._id));
      dispatch(getSubFolderAction(selectedSubFolder._id));
    }
  }, [createFolder, editFolder, deleteFolder, selectedSubFolder, dispatch]);

  React.useEffect(() => {
    if (
      ((deleteFolder && deleteFolder.data && deleteFolder.data.status === 'success') ||
        (createFolder && createFolder.data && createFolder.data.status === 'success') ||
        (editFolder && editFolder.data && editFolder.data.status === 'success')) &&
      selectedSubSubFolder &&
      selectedSubSubFolder._id
    ) {
      dispatch(subSubFolderDataAction(selectedSubSubFolder._id));
      dispatch(getSubSubFolderAction(selectedSubSubFolder._id));
    }
  }, [createFolder, editFolder, deleteFolder, selectedSubSubFolder, dispatch]);

  React.useEffect(() => {
    if (
      ((deleteFolder && deleteFolder.data && deleteFolder.data.status === 'success') ||
        (createFolder && createFolder.data && createFolder.data.status === 'success') ||
        (editFolder && editFolder.data && editFolder.data.status === 'success')) &&
      selectedSubSubSubFolder &&
      selectedSubSubSubFolder._id
    ) {
      dispatch(subSubSubFolderDataAction(selectedSubSubSubFolder._id));
      dispatch(getSubSubSubFolderAction(selectedSubSubSubFolder._id));
    }
  }, [createFolder, editFolder, deleteFolder, selectedSubSubSubFolder, dispatch]);

  React.useEffect(() => {
    if (createFolder && createFolder.data && createFolder.data.status === 'success') {
      toast.success('New Folder Created');
      setIsAddFolder(false);
      setFolderName('');
      setIsAddSubFolder(false);
      setSubFolderName('');
      setIsAddSubSubFolder(false);
      setSubSubFolderName('');
      setIsAddSubSubSubFolder(false);
      setSubSubSubFolderName('');
      setIsAddSubSubSubSubFolder(false);
      setSubSubSubSubFolderName('');
      dispatch(foldersListAction());
      dispatch({ type: CREATE_FOLDER_RESET });
    }
  }, [createFolder, dispatch]);

  React.useEffect(() => {
    if (editFolder && editFolder.data && editFolder.data.status === 'success') {
      toast.success('Folder name modified');
      setIsEditFolder({ data: {}, status: false });
      setEditFolderName('');
      dispatch(foldersListAction());
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      dispatch({ type: EDIT_FOLDER_RESET });
    }
  }, [editFolder, dispatch, currentPage, pageLimit]);

  React.useEffect(() => {
    if (deleteFolder && deleteFolder.data && deleteFolder.data.status === 'success') {
      toast.error('Folder deleted successfully.');
      setDeleteFolderModal({ folder: {}, status: false });
      setDeleteFolderInput({ value: '', error: '' });
      dispatch(foldersListAction());
      dispatch({ type: DELETE_FOLDER_RESET });
    }
  }, [deleteFolder, dispatch]);

  React.useEffect(() => {
    if (uploadFile && uploadFile.files && uploadFile.files.status === 'success') {
      toast.success('File upload successfull!');
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      // dispatch(getLoggedinUserAction());

      if (uploadFileToSubFolder) {
        if (selectedSubSubSubSubFolder && selectedSubSubSubSubFolder._id) {
          dispatch(subSubSubSubFolderDataAction(selectedSubSubSubSubFolder._id));
          dispatch(getSubSubSubSubFolderAction(selectedSubSubSubSubFolder._id));
        } else if (selectedSubSubSubFolder && selectedSubSubSubFolder._id) {
          dispatch(subSubSubFolderDataAction(selectedSubSubSubFolder._id));
          dispatch(getSubSubSubFolderAction(selectedSubSubSubFolder._id));
        } else if (selectedSubSubFolder && selectedSubSubFolder._id) {
          dispatch(subSubFolderDataAction(selectedSubSubFolder._id));
          dispatch(getSubSubFolderAction(selectedSubSubFolder._id));
        } else if (selectedSubFolder && selectedSubFolder._id) {
          dispatch(subFolderDataAction(selectedSubFolder._id));
          dispatch(getSubFolderAction(selectedSubFolder._id));
        }
      } else if (selectedFolder) {
        setSelectedSubFolder({});
        dispatch(folderDataAction(selectedFolder._id));
        dispatch(getFolderAction(selectedFolder._id));
      } else {
        dispatch(foldersListAction());
        setSelectedSubFolder({});
      }
      handleUploadModalClose();
      dispatch({ type: ADD_FILE_EXCHANGE_RESET });
    }
  }, [
    uploadFile,
    dispatch,
    uploadFileToSubFolder,
    selectedSubSubSubSubFolder,
    selectedSubSubSubFolder,
    selectedSubSubFolder,
    selectedSubFolder,
    selectedFolder,
    currentPage,
    pageLimit,
  ]);

  React.useEffect(() => {
    if (renameFile && !renameFile.loading && renameFile.file && renameFile.file.status === 'success') {
      toast.success('File rename successful!');
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      setRenameFileModal({ file: {}, status: false });
      setRenameFilePasswordInput({ value: '', error: '' });

      if (selectedSubSubSubSubFolder && selectedSubSubSubSubFolder._id) {
        dispatch(subSubSubSubFolderDataAction(selectedSubSubSubSubFolder._id));
        dispatch(getSubSubSubSubFolderAction(selectedSubSubSubSubFolder._id));
      } else if (selectedSubSubSubFolder && selectedSubSubSubFolder._id) {
        dispatch(subSubSubFolderDataAction(selectedSubSubSubFolder._id));
        dispatch(getSubSubSubFolderAction(selectedSubSubSubFolder._id));
      } else if (selectedSubSubFolder && selectedSubSubFolder._id) {
        dispatch(subSubFolderDataAction(selectedSubSubFolder._id));
        dispatch(getSubSubFolderAction(selectedSubSubFolder._id));
      } else if (selectedSubFolder && selectedSubFolder._id) {
        dispatch(subFolderDataAction(selectedSubFolder._id));
        dispatch(getSubFolderAction(selectedSubFolder._id));
      } else if (selectedFolder && selectedFolder._id) {
        setSelectedSubFolder({});
        dispatch(folderDataAction(selectedFolder._id));
        dispatch(getFolderAction(selectedFolder._id));
      } else {
        setSelectedSubFolder({});
        dispatch(foldersListAction());
      }
      dispatch({ type: RENAME_FILE_EXCHANGE_RESET });
    }
  }, [renameFile, dispatch, selectedSubFolder, selectedSubSubSubSubFolder, selectedSubSubSubFolder, selectedSubSubFolder, selectedFolder, currentPage, pageLimit]);

  React.useEffect(() => {
    if (cutFile && !cutFile.loading && cutFile.file && cutFile.file.status === 'success') {
      toast.success('File moved successfully!');
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      setCutFileModal({ file: {}, status: false });
      setCutFilePasswordInput({ value: '', error: '' });

      if (selectedSubFolder && selectedSubFolder._id) {
        dispatch(subFolderDataAction(selectedSubFolder._id));
        dispatch(getSubFolderAction(selectedSubFolder._id));
        dispatch(folderDataAction(selectedFolder._id));
      } else if (selectedFolder && selectedFolder._id) {
        setSelectedSubFolder({});
        dispatch(folderDataAction(selectedFolder._id));
        dispatch(getFolderAction(selectedFolder._id));
      } else {
        setSelectedSubFolder({});
        dispatch(foldersListAction());
      }
      dispatch({ type: CUT_FILE_EXCHANGE_RESET });
    }
  }, [cutFile, dispatch, selectedSubFolder, selectedFolder, currentPage, pageLimit]);

  React.useEffect(() => {
    if (copyFile && !copyFile.loading && copyFile.file && copyFile.file.status === 'success') {
      toast.success('File copied successfully!');
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      setCopyFileModal({ file: {}, status: false });
      setCopyFilePasswordInput({ value: '', error: '' });

      if (selectedSubFolder && selectedSubFolder._id) {
        dispatch(subFolderDataAction(selectedSubFolder._id));
        dispatch(getSubFolderAction(selectedSubFolder._id));
        dispatch(folderDataAction(selectedFolder._id));
      } else if (selectedFolder && selectedFolder._id) {
        setSelectedSubFolder({});
        dispatch(folderDataAction(selectedFolder._id));
        dispatch(getFolderAction(selectedFolder._id));
      } else {
        setSelectedSubFolder({});
        dispatch(foldersListAction());
      }
      dispatch({ type: COPY_FILE_EXCHANGE_RESET });
    }
  }, [copyFile, dispatch, selectedSubFolder, selectedFolder, currentPage, pageLimit]);

  React.useEffect(() => {
    if (deleteFile && deleteFile.file && deleteFile.file.status === 'success') {
      toast.error('File deleted successfully.');
      dispatch(getSecureFilesAction(currentPage, pageLimit));
      // dispatch(getLoggedinUserAction());
      setDeleteFileModal({ file: {}, status: false });
      setDeleteFileInput({ value: '', error: '' });

      if (selectedSubSubSubSubFolder && selectedSubSubSubSubFolder._id) {
        dispatch(subSubSubSubFolderDataAction(selectedSubSubSubSubFolder._id));
        dispatch(getSubSubSubSubFolderAction(selectedSubSubSubSubFolder._id));
      } else if (selectedSubSubSubFolder && selectedSubSubSubFolder._id) {
        dispatch(subSubSubFolderDataAction(selectedSubSubSubFolder._id));
        dispatch(getSubSubSubFolderAction(selectedSubSubSubFolder._id));
      } else if (selectedSubSubFolder && selectedSubSubFolder._id) {
        dispatch(subSubFolderDataAction(selectedSubSubFolder._id));
        dispatch(getSubSubFolderAction(selectedSubSubFolder._id));
      } else if (selectedSubFolder && selectedSubFolder._id) {
        dispatch(subFolderDataAction(selectedSubFolder._id));
        dispatch(getSubFolderAction(selectedSubFolder._id));
      } else if (selectedFolder && selectedFolder._id) {
        setSelectedSubFolder({});
        dispatch(folderDataAction(selectedFolder._id));
        dispatch(getFolderAction(selectedFolder._id));
      } else {
        setSelectedSubFolder({});
        dispatch(foldersListAction());
      }
      dispatch({ type: DELETE_FILE_EXCHANGE_RESET });
    }
  }, [deleteFile, dispatch, selectedSubFolder, selectedSubSubSubSubFolder, selectedSubSubSubFolder, selectedSubSubFolder, selectedFolder, currentPage, pageLimit]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: GET_SECURE_FILES_RESET });
      dispatch({ type: GET_ALL_FOLDER_DATA_RESET });
      dispatch({ type: GET_FOLDER_RESET });
      dispatch({ type: GET_SUB_FOLDER_RESET });
      dispatch({ type: GET_FOLDER_DATA_RESET });
      dispatch({ type: GET_SUB_FOLDER_DATA_RESET });
    };
  }, [dispatch]);

  const handleAddFolder = () => {
    if (folderName === '' || folderName.trim() === '') {
      alert('Enter folder name');
    } else if (folderName.includes('/')) {
      alert('Folder name should not contain "/" character');
    } else {
      dispatch(createFolderAction({ name: folderName }));
    }
  };

  const handleEditFolder = () => {
    if (editfolderName === '' || editfolderName.trim() === '') {
      alert('Enter folder name');
    } else if (editfolderName.includes('/')) {
      alert('Folder name should not contain "/" character');
    } else {
      dispatch(editFolderAction(isEditFolder.data._id, { name: editfolderName }));
    }
  };

  const handleDeleteFolder = () => {
    if (deleteFolderInput.value === '' || deleteFolderInput.value.trim() === '') {
      setDeleteFolderInput({
        ...deleteFolderInput,
        error: 'Please enter folder name inside text box',
      });
    } else if (deleteFolderInput.value !== deleteFolderModal.folder.name) {
      setDeleteFolderInput({
        ...deleteFolderInput,
        error: 'Incorrect folder name',
      });
    } else {
      dispatch(deleteFolderAction(deleteFolderModal.folder._id));
    }
  };

  const handleAddSubFolder = () => {
    if (subFolderName === '' || subFolderName.trim() === '') {
      alert('Enter sub folder name');
    } else if (subFolderName.includes('/')) {
      alert('Subfolder name should not contain "/" character');
    } else {
      dispatch(
        createFolderAction({
          name: subFolderName,
          parentFolder: selectedFolder._id,
        })
      );
    }
  };

  const handleAddSubSubFolder = () => {
    if (subSubFolderName === '' || subSubFolderName.trim() === '') {
      alert('Enter sub folder name');
    } else if (subSubFolderName.includes('/')) {
      alert('Subfolder name should not contain "/" character');
    } else {
      dispatch(
        createFolderAction({
          name: subSubFolderName,
          parentFolder: selectedSubFolder._id,
        })
      );
    }
  };

  const handleAddSubSubSubFolder = () => {
    if (subSubSubFolderName === '' || subSubSubFolderName.trim() === '') {
      alert('Enter sub folder name');
    } else if (subSubSubFolderName.includes('/')) {
      alert('Subfolder name should not contain "/" character');
    } else {
      dispatch(
        createFolderAction({
          name: subSubSubFolderName,
          parentFolder: selectedSubSubFolder._id,
        })
      );
    }
  };

  const handleAddSubSubSubSubFolder = () => {
    if (subSubSubSubFolderName === '' || subSubSubSubFolderName.trim() === '') {
      alert('Enter sub folder name');
    } else if (subSubSubSubFolderName.includes('/')) {
      alert('Subfolder name should not contain "/" character');
    } else {
      dispatch(
        createFolderAction({
          name: subSubSubSubFolderName,
          parentFolder: selectedSubSubSubFolder._id,
        })
      );
    }
  };

  const handleUploadModalClose = () => {
    setUploadFileModal(false);
    setUploadFileToSubFolder(false);
    setUploadFileName({ value: '', error: '' });
    setUploadFileData({ value: '', error: '' });
    setPasswordProtected(false);
    setFilePassword({ value: '', error: '' });
    setFilePasswordConfirm({ value: '', error: '' });
    setUploadNote({ value: '' });
  };

  const handleUpload = () => {
    // if (uploadFileName.value === '' || uploadFileName.value.trim() === '') {
    //   setUploadFileName({
    //     ...uploadFileName,
    //     error: 'Please enter upload file name.',
    //   });
    // } else
    if (!uploadFileData.value || uploadFileData.value.length === 0) {
      setUploadFileData({
        ...uploadFileData,
        error: 'Please select files to upload',
      });
      // } else if (
      //   passwordProtected &&
      //   (!filePassword.value || filePassword.value.trim() === '')
      // ) {
      //   setFilePassword({ ...filePassword, error: 'Please set password' });
      // } else if (passwordProtected && filePassword.value.length < 8) {
      //   setFilePassword({
      //     ...filePassword,
      //     error: 'Password must have atleast 8 characters',
      //   });
      // } else if (
      //   passwordProtected &&
      //   filePassword.value !== filePasswordConfirm.value
      // ) {
      //   setFilePasswordConfirm({
      //     ...filePasswordConfirm,
      //     error: 'Passwords do not match',
      //   });
    } else {
      let uploadFileSize = 0;
      for (let i = 0; i < uploadFileData.value.length; i++) {
        uploadFileSize += uploadFileData.value[i].size;
      }

      if (uploadFileSize > 50 * 1024 * 1024) {
        setUploadFileData({
          ...uploadFileData,
          error: 'Please upload file less than 50 MB',
        });
      } else if (
        loggedinUser &&
        loggedinUser.userInfo &&
        loggedinUser.userInfo.data &&
        loggedinUser.userInfo.data.data &&
        loggedinUser.userInfo.data.data.maxStorageAllowed - loggedinUser.userInfo.data.data.usedSpace < uploadFileSize / (1024 * 1024 * 1024)
      ) {
        setUploadFileData({
          ...uploadFileData,
          error: 'Insufficient storage',
        });
      } else {
        let fileData = new FormData();
        // fileData.append('name', uploadFileName.value);
        // passwordProtected && fileData.append('password', filePassword.value);
        // fileData.append('uploadNote', uploadNote.value);
        // fileData.append('protected', passwordProtected);
        fileData.append('fileSize', uploadFileSize);

        if (uploadFileToSubFolder) {
          if (Object.keys(selectedSubSubSubSubFolder).length > 0) fileData.append('folder', selectedSubSubSubSubFolder._id);
          else if (Object.keys(selectedSubSubSubFolder).length > 0) fileData.append('folder', selectedSubSubSubFolder._id);
          else if (Object.keys(selectedSubSubFolder).length > 0) fileData.append('folder', selectedSubSubFolder._id);
          else if (Object.keys(selectedSubFolder).length > 0) fileData.append('folder', selectedSubFolder._id);
        } else {
          if (selectedFolder) fileData.append('folder', selectedFolder._id);
        }

        for (let i = 0; i < uploadFileData.value.length; i++) {
          fileData.append('files', uploadFileData.value[i]);
        }

        !isSpaceFull && dispatch(createFileAction(fileData));
      }
    }
  };

  const handleDeleteFile = () => {
    if (deleteFileInput.value === '' || deleteFileInput.value.trim() === '') {
      setDeleteFileInput({
        ...deleteFileInput,
        error: 'Please enter file name inside text box',
      });
    } else if (deleteFileInput.value !== deleteFileModal.file.name) {
      setDeleteFileInput({ ...deleteFileInput, error: 'Incorrect file name' });
    } else {
      dispatch(deleteFileAction(deleteFileModal.file._id));
    }
  };

  const handleShareFile = () => {
    if (shareFileModal.file.protected) {
      if (shareFilePasswordInput.value === '' || shareFilePasswordInput.value.trim() === '') {
        setShareFilePasswordInput({
          ...shareFilePasswordInput,
          error: 'Please enter file password to share',
        });
      } else {
        dispatch(
          shareFileAction({
            id: shareFileModal.file._id,
            password: shareFilePasswordInput.value,
          })
        );
      }
    } else {
      dispatch(
        shareFileAction({
          id: shareFileModal.file._id,
        })
      );
    }
  };

  const handleShareFileForESign = async () => {
    if (shareFileForESignModal.file._id) {
      const formData = {
        id: shareFileForESignModal.file._id,
        email: shareFileForESignInputs.email.value,
        createAccount: shareFileForESignInputs.createAccount,
      };
      if (shareFileForESignInputs.createAccount) {
        formData.fullName = shareFileForESignInputs.fullName.value;
        formData.phone = unMasking(shareFileForESignInputs.phone.value);
      }
      const data = await shareForESign(formData);
      if (data.status === 'success') {
        setShareFileForESignModal({ file: {}, status: false });
        setShareFileForESignInputs({ email: { value: '', error: '' }, fullName: { value: '', error: '' }, phone: { value: '', error: '' } });
      }
    }
  };

  const handleRenameFile = () => {
    if (renameFilePasswordInput.value === '' || renameFilePasswordInput.value.trim() === '') {
      setRenameFilePasswordInput({
        ...renameFilePasswordInput,
        error: 'Please enter file name',
      });
    } else {
      dispatch(
        renameFileAction(renameFileModal.file._id, {
          name: renameFilePasswordInput.value + '.' + renameFileModal.file.name.split('.').pop(),
        })
      );
    }
  };

  const handleCopyFile = () => {
    if (copyFilePasswordInput.value === '' || copyFilePasswordInput.value.trim() === '') {
      setCopyFilePasswordInput({
        ...copyFilePasswordInput,
        error: 'Please select destination folder',
      });
    } else {
      dispatch(
        copyFileAction({
          fileId: copyFileModal.file._id,
          folderId: copyFilePasswordInput.value,
        })
      );
    }
  };

  const handleCutFile = () => {
    if (cutFilePasswordInput.value === '' || cutFilePasswordInput.value.trim() === '') {
      setCutFilePasswordInput({
        ...cutFilePasswordInput,
        error: 'Please select destination folder',
      });
    } else {
      dispatch(
        cutFileAction({
          fileId: cutFileModal.file._id,
          folderId: cutFilePasswordInput.value,
        })
      );
    }
  };

  const handleDownloadFile = e => {
    const fileName = downloadFileModal.file.mimetype ? downloadFileModal.file.name : downloadFileModal.file.name + '.zip';
    if (downloadFileModal.file.protected) {
      if (downloadFilePasswordInput.value === '' || downloadFilePasswordInput.value.trim() === '') {
        setDownloadFilePasswordInput({
          ...downloadFilePasswordInput,
          error: 'Please enter file password to download',
        });
      } else {
        dispatch(
          downloadFileAction(
            {
              contentKey: downloadFileModal.file.contentKey,
              password: downloadFilePasswordInput.value,
              passwordProtected: downloadFileModal.file.protected,
            },
            fileName
          )
        );
      }
    } else {
      dispatch(
        downloadFileAction(
          {
            contentKey: downloadFileModal.file.contentKey,
            passwordProtected: downloadFileModal.file.protected,
          },
          fileName
        )
      );
    }
  };

  const handlePageChange = async currentPage => {
    setCurrentPage(currentPage.selected + 1);
  };

  const setSearchCurrentPageSearch = async currentPage => {
    setCurrentPageSearch(currentPage.selected + 1);
    handleFileSearch(currentPage.selected + 1);
  };

  // const handleSubSubFolderPageChange = async currentPage => {
  //   setCurrentSubSubFolderPage(currentPage.selected + 1);
  // };

  const handleFileSearch = async (currentSearchPage = 1) => {
    if (fileSearchText.length >= 3) {
      setFileSearchResult(prevData => ({
        ...prevData,
        loading: true,
        error: false,
      }));
      const data = await dispatch(getSearchSecureFilesAction(fileSearchText, currentSearchPage, pageLimit));
      if (data?.status === 'success') {
        if (data && data.data && data.data.data && data.data.data.length > 0) {
          setFileSearchResult({
            loading: false,
            data: data.data.data,
            totalResults: data?.totalResults,
            error: false,
          });
        } else {
          setFileSearchResult({
            loading: false,
            data: [],
            totalResults: 0,
            error: true,
          });
        }
      } else {
        setFileSearchResult({
          loading: false,
          data: [],
          totalResults: 0,
          error: true,
        });
      }
      console.log(data);
    }
  };

  const clearFileSearch = () => {
    setFileSearchResult({
      loading: false,
      data: [],
      totalResults: 0,
      error: false,
    });
    setFileSearchText('');
  };

  const refreshUserData = async () => {
    const resData = await dispatch(refreshUserAction());

    if (resData.status === 'success') {
      dispatch(foldersListAction());
      toast.success(resData.msg);
    } else {
      toast.error(resData.msg);
    }
  };

  function removeExtension(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  return (
    <Content headerTitle={'Document Archive'} currentMenu='secure-files' addBtn addBtnIcon='refresh' addBtnTitle='Refresh' addBtnClick={refreshUserData}>
      <div className={FileExchangeStyles.container}>
        <div className={FileExchangeStyles.containerLeft}>
          <div
            className={FileExchangeStyles.folderContainer}
            style={{
              maxHeight: Object.keys(selectedSubSubFolder).length === 0 ? '19rem' : '0',
              padding: Object.keys(selectedSubSubFolder).length === 0 ? '2rem 3rem' : 0,
            }}
          >
            <div className={FileExchangeStyles.folderFilesTopFlex}>
              <h3 className={FileExchangeStyles.folderHeading}>Folders</h3>
              {/* <button className={FileExchangeStyles.uploadBtn} onClick={refreshUserData}>
                <svg className={FileExchangeStyles.uploadBtnIcon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-refresh`} />
                </svg>
              </button> */}
            </div>
            <div className={FileExchangeStyles.folders}>
              {foldersList && foldersList.loading ? (
                <FolderListSkeleton />
              ) : (
                totalFolders &&
                totalFolders.length > 0 &&
                totalFolders.map((folder, index) => (
                  <Fragment key={folder._id}>
                    {isEditFolder.status && isEditFolder.data._id === folder._id ? (
                      <AddEditFolder
                        inputPlaceholder='Folder Name'
                        inputValue={editfolderName}
                        inputOnChange={e => setEditFolderName(e.target.value)}
                        submitBtnClick={handleEditFolder}
                        cancelBtnClick={() => {
                          setIsEditFolder({ data: {}, status: false });
                          setEditFolderName('');
                        }}
                      />
                    ) : (
                      <Folder
                        folderId={folder._id}
                        name={folder.name}
                        onClick={() => {
                          setSelectedFolder(folder);
                          setSelectedSubFolder({});
                          setSelectedSubSubFolder({});
                          setSelectedSubSubSubFolder({});
                          setSelectedSubSubSubSubFolder({});
                          dispatch({ type: GET_SUB_FOLDER_DATA_RESET });
                        }}
                        active={selectedFolder._id === folder._id}
                        showMenu={userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic'}
                        client={userRole === 'admin' && folder.clinic && folder.clinic.name}
                        menuItem={[
                          {
                            id: folder._id + 1,
                            onClick: () => {
                              setIsEditFolder({ data: folder, status: true });
                              setEditFolderName(folder.name);
                            },
                            icon: 'edit-2',
                            label: 'Rename Folder',
                          },
                          {
                            id: folder._id + 2,
                            onClick: () => {
                              setDeleteFolderModal({ folder, status: true });
                            },
                            icon: 'folder-minus',
                            label: 'Delete Folder',
                          },
                        ]}
                      />
                    )}
                  </Fragment>
                ))
              )}
              {(userRole === 'fileAdmin' || userRole === 'clinic') &&
                (!isAddFolder ? (
                  <AddFolderButton label='Add New Folder' onClick={() => setIsAddFolder(true)} />
                ) : (
                  <AddEditFolder
                    inputPlaceholder='Folder Name'
                    inputValue={folderName}
                    inputOnChange={e => setFolderName(e.target.value)}
                    submitBtnClick={handleAddFolder}
                    cancelBtnClick={() => setIsAddFolder(false)}
                  />
                ))}
            </div>
          </div>

          <div
            className={FileExchangeStyles.folderFiles}
            style={{
              height: Object.keys(selectedSubSubFolder).length > 0 ? '100%' : '59vh',
              marginTop: Object.keys(selectedSubSubFolder).length > 0 ? '-2rem' : '0',
            }}
          >
            <div
              className={FileExchangeStyles.topsubfolderFIles}
              style={{
                maxHeight: Object.keys(selectedSubSubSubFolder).length > 0 ? '0' : '19rem',
              }}
            >
              <div className={FileExchangeStyles.folderFilesTop}>
                <h3 className={FileExchangeStyles.folderHeading}>
                  {userRole === 'admin' && selectedFolder && selectedFolder._id && selectedFolder.clinic && selectedFolder.clinic.name
                    ? selectedFolder.name + ' (' + selectedFolder.clinic.name + ')'
                    : selectedFolder && selectedFolder._id
                    ? selectedFolder.name
                    : ' '}
                </h3>
                {userRole === 'admin'
                  ? null
                  : Object.keys(selectedSubFolder).length === 0 &&
                    !isSpaceFull &&
                    userRole !== 'fileUserOnlyDownload' && (
                      <button className={FileExchangeStyles.uploadBtn} onClick={() => !isSpaceFull && setUploadFileModal(true)}>
                        Upload File
                        <svg className={FileExchangeStyles.uploadBtnIcon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
                        </svg>
                      </button>
                    )}
              </div>
              <h4 className={FileExchangeStyles.folderFilesSub}>Sub Folders</h4>
              <div className={FileExchangeStyles.folders}>
                {getFolder && getFolder.loading ? (
                  <FolderListSkeleton itmes={3} />
                ) : getFolder &&
                  getFolder.data &&
                  getFolder.data.data &&
                  getFolder.data.data.data &&
                  getFolder.data.data.data.childFolders &&
                  getFolder.data.data.data.childFolders.length > 0 ? (
                  getFolder.data.data.data.childFolders.map((folder, index) => (
                    <Fragment key={folder._id}>
                      {isEditFolder.status && isEditFolder.data._id === folder._id ? (
                        <AddEditFolder
                          inputPlaceholder='Folder Name'
                          inputValue={editfolderName}
                          inputOnChange={e => setEditFolderName(e.target.value)}
                          submitBtnClick={handleEditFolder}
                          cancelBtnClick={() => {
                            setIsEditFolder({ data: {}, status: false });
                            setEditFolderName('');
                          }}
                        />
                      ) : (
                        <Folder
                          folderId={folder._id}
                          name={folder.name}
                          onClick={() => {
                            setSelectedSubFolder(folder);
                            setSelectedSubSubFolder({});
                            setSelectedSubSubSubFolder({});
                            setSelectedSubSubSubSubFolder({});
                          }}
                          active={selectedSubFolder._id === folder._id}
                          showMenu={userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic'}
                          menuItem={[
                            {
                              id: folder._id + 1,
                              onClick: () => {
                                setIsEditFolder({ data: folder, status: true });
                                setEditFolderName(folder.name);
                              },
                              icon: 'edit-2',
                              label: 'Rename Folder',
                            },
                            {
                              id: folder._id + 2,
                              onClick: () => {
                                setDeleteFolderModal({ folder, status: true });
                              },
                              icon: 'folder-minus',
                              label: 'Delete Folder',
                            },
                          ]}
                        />
                      )}
                    </Fragment>
                  ))
                ) : userRole === 'fileAdmin' || userRole === 'clinic' ? null : (
                  <p>No sub-folders</p>
                )}

                {getFolder &&
                  !getFolder.loading &&
                  (userRole === 'fileAdmin' || userRole === 'clinic') &&
                  (!isAddSubFolder ? (
                    <AddFolderButton label='Add New Sub Folder' onClick={() => setIsAddSubFolder(true)} />
                  ) : (
                    <AddEditFolder
                      inputPlaceholder='Folder Name'
                      inputValue={subFolderName}
                      inputOnChange={e => setSubFolderName(e.target.value)}
                      submitBtnClick={handleAddSubFolder}
                      cancelBtnClick={() => setIsAddSubFolder(false)}
                    />
                  ))}
              </div>
            </div>

            {selectedSubFolder && selectedSubFolder._id ? (
              <Fragment>
                <div className={FileExchangeStyles.folderFilesTop} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                  <div className={FileExchangeStyles.folderFilesTopFlex}>
                    <button
                      className={FileExchangeStyles.uploadBtn}
                      onClick={() => {
                        setSelectedSubFolder({});
                        setSelectedSubSubFolder({});
                        setSelectedSubSubSubFolder({});
                        setSelectedSubSubSubFolder({});
                        setSelectedSubSubSubSubFolder({});
                      }}
                    >
                      <svg className={FileExchangeStyles.uploadBtnIcon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-back`} />
                      </svg>
                    </button>
                    <h4 className={FileExchangeStyles.folderFilesSub}>
                      {selectedSubFolder.name}{' '}
                      {userRole === 'admin' &&
                        Object.keys(selectedSubSubSubFolder).length > 0 &&
                        selectedFolder &&
                        selectedFolder._id &&
                        selectedFolder.clinic &&
                        selectedFolder.clinic.name &&
                        ' (' + selectedFolder.clinic.name + ')'}{' '}
                      subfolders and files
                    </h4>
                  </div>
                  {userRole === 'admin'
                    ? null
                    : Object.keys(selectedSubSubFolder).length === 0 &&
                      !isSpaceFull &&
                      userRole !== 'fileUserOnlyDownload' && (
                        <button
                          className={FileExchangeStyles.uploadBtn}
                          onClick={() => {
                            if (!isSpaceFull) {
                              setUploadFileModal(true);
                              setUploadFileToSubFolder(true);
                            }
                          }}
                        >
                          Upload File to {selectedSubFolder.name}
                          <svg className={FileExchangeStyles.uploadBtnIcon}>
                            <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
                          </svg>
                        </button>
                      )}
                </div>

                <div className={FileExchangeStyles.folders}>
                  {getSubFolder && getSubFolder.loading ? (
                    <FolderListSkeleton itmes={3} />
                  ) : getSubFolder &&
                    getSubFolder.data &&
                    getSubFolder.data.data &&
                    getSubFolder.data.data.data &&
                    getSubFolder.data.data.data.childFolders &&
                    getSubFolder.data.data.data.childFolders.length > 0 ? (
                    getSubFolder.data.data.data.childFolders.map((folder, index) => (
                      <Fragment key={folder._id}>
                        {isEditFolder.status && isEditFolder.data._id === folder._id ? (
                          <AddEditFolder
                            inputPlaceholder='Folder Name'
                            inputValue={editfolderName}
                            inputOnChange={e => setEditFolderName(e.target.value)}
                            submitBtnClick={handleEditFolder}
                            cancelBtnClick={() => {
                              setIsEditFolder({ data: {}, status: false });
                              setEditFolderName('');
                            }}
                          />
                        ) : (
                          <Folder
                            folderId={folder._id}
                            name={folder.name}
                            onClick={() => {
                              setSelectedSubSubFolder(folder);
                              setSelectedSubSubSubFolder({});
                              setSelectedSubSubSubSubFolder({});
                              setCurrentSubSubFolderPage(1);
                            }}
                            active={selectedSubSubFolder._id === folder._id}
                            showMenu={userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic'}
                            menuItem={[
                              {
                                id: folder._id + 1,
                                onClick: () => {
                                  setIsEditFolder({
                                    data: folder,
                                    status: true,
                                  });
                                  setEditFolderName(folder.name);
                                },
                                icon: 'edit-2',
                                label: 'Rename Folder',
                              },
                              {
                                id: folder._id + 2,
                                onClick: () => {
                                  setDeleteFolderModal({
                                    folder,
                                    status: true,
                                  });
                                },
                                icon: 'folder-minus',
                                label: 'Delete Folder',
                              },
                            ]}
                          />
                        )}
                      </Fragment>
                    ))
                  ) : userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic' ? null : (
                    <p>No sub-folders</p>
                  )}

                  {getSubFolder &&
                    !getSubFolder.loading &&
                    (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic') &&
                    (!isAddSubSubFolder ? (
                      <AddFolderButton label='Add New Sub Folder' onClick={() => setIsAddSubSubFolder(true)} />
                    ) : (
                      <AddEditFolder
                        inputPlaceholder='Folder Name'
                        inputValue={subSubFolderName}
                        inputOnChange={e => setSubSubFolderName(e.target.value)}
                        submitBtnClick={handleAddSubSubFolder}
                        cancelBtnClick={() => setIsAddSubSubFolder(false)}
                      />
                    ))}
                </div>

                {selectedSubSubFolder && selectedSubSubFolder._id ? (
                  <Fragment>
                    <div className={FileExchangeStyles.folderFilesTop} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                      <div className={FileExchangeStyles.folderFilesTopFlex}>
                        <button
                          className={FileExchangeStyles.uploadBtn}
                          onClick={() => {
                            setSelectedSubSubFolder({});
                            setSelectedSubSubSubFolder({});
                            setSelectedSubSubSubSubFolder({});
                          }}
                        >
                          <svg className={FileExchangeStyles.uploadBtnIcon}>
                            <use xlinkHref={`/assets/sprite.svg#icon-back`} />
                          </svg>
                        </button>
                        <h4 className={FileExchangeStyles.folderFilesSub}>{selectedSubSubFolder.name} subfolders and files</h4>
                      </div>
                      {userRole === 'admin'
                        ? null
                        : Object.keys(selectedSubSubSubFolder).length === 0 &&
                          !isSpaceFull &&
                          userRole !== 'fileUserOnlyDownload' && (
                            <button
                              className={FileExchangeStyles.uploadBtn}
                              onClick={() => {
                                if (!isSpaceFull) {
                                  setUploadFileModal(true);
                                  setUploadFileToSubFolder(true);
                                }
                              }}
                            >
                              Upload File to {selectedSubSubFolder.name}
                              <svg className={FileExchangeStyles.uploadBtnIcon}>
                                <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
                              </svg>
                            </button>
                          )}
                    </div>

                    <div className={FileExchangeStyles.folders}>
                      {getSubSubFolder && getSubSubFolder.loading ? (
                        <FolderListSkeleton itmes={3} />
                      ) : getSubSubFolder &&
                        getSubSubFolder.data &&
                        getSubSubFolder.data.data &&
                        getSubSubFolder.data.data.data &&
                        getSubSubFolder.data.data.data.childFolders &&
                        getSubSubFolder.data.data.data.childFolders.length > 0 ? (
                        getSubSubFolder.data.data.data.childFolders.map((folder, index) => (
                          <Fragment key={folder._id}>
                            {isEditFolder.status && isEditFolder.data._id === folder._id ? (
                              <AddEditFolder
                                inputPlaceholder='Folder Name'
                                inputValue={editfolderName}
                                inputOnChange={e => setEditFolderName(e.target.value)}
                                submitBtnClick={handleEditFolder}
                                cancelBtnClick={() => {
                                  setIsEditFolder({
                                    data: {},
                                    status: false,
                                  });
                                  setEditFolderName('');
                                }}
                              />
                            ) : (
                              <Folder
                                folderId={folder._id}
                                name={folder.name}
                                onClick={() => setSelectedSubSubSubFolder(folder)}
                                active={selectedSubSubSubFolder._id === folder._id}
                                showMenu={userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic'}
                                menuItem={[
                                  {
                                    id: folder._id + 1,
                                    onClick: () => {
                                      setIsEditFolder({
                                        data: folder,
                                        status: true,
                                      });
                                      setEditFolderName(folder.name);
                                    },
                                    icon: 'edit-2',
                                    label: 'Rename Folder',
                                  },
                                  {
                                    id: folder._id + 2,
                                    onClick: () => {
                                      setDeleteFolderModal({
                                        folder,
                                        status: true,
                                      });
                                    },
                                    icon: 'folder-minus',
                                    label: 'Delete Folder',
                                  },
                                ]}
                              />
                            )}
                          </Fragment>
                        ))
                      ) : userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic' ? null : (
                        <p>No sub-folders</p>
                      )}

                      {getSubSubFolder &&
                        !getSubSubFolder.loading &&
                        (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic') &&
                        (!isAddSubSubSubFolder ? (
                          <AddFolderButton label='Add New Sub Folder' onClick={() => setIsAddSubSubSubFolder(true)} />
                        ) : (
                          <AddEditFolder
                            inputPlaceholder='Folder Name'
                            inputValue={subSubSubFolderName}
                            inputOnChange={e => setSubSubSubFolderName(e.target.value)}
                            submitBtnClick={handleAddSubSubSubFolder}
                            cancelBtnClick={() => setIsAddSubSubSubFolder(false)}
                          />
                        ))}
                    </div>

                    {selectedSubSubSubFolder && selectedSubSubSubFolder._id ? (
                      <Fragment>
                        <div className={FileExchangeStyles.folderFilesTop} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                          <div className={FileExchangeStyles.folderFilesTopFlex}>
                            <button className={FileExchangeStyles.uploadBtn} onClick={() => setSelectedSubSubSubFolder({})}>
                              <svg className={FileExchangeStyles.uploadBtnIcon}>
                                <use xlinkHref={`/assets/sprite.svg#icon-back`} />
                              </svg>
                            </button>
                            <h4 className={FileExchangeStyles.folderFilesSub}>{selectedSubSubSubFolder.name} subfolders and files</h4>
                          </div>
                          {userRole === 'admin'
                            ? null
                            : Object.keys(selectedSubSubSubSubFolder).length === 0 &&
                              !isSpaceFull &&
                              userRole !== 'fileUserOnlyDownload' && (
                                <button
                                  className={FileExchangeStyles.uploadBtn}
                                  onClick={() => {
                                    if (!isSpaceFull) {
                                      setUploadFileModal(true);
                                      setUploadFileToSubFolder(true);
                                    }
                                  }}
                                >
                                  Upload File to {selectedSubSubSubFolder.name}
                                  <svg className={FileExchangeStyles.uploadBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
                                  </svg>
                                </button>
                              )}
                        </div>

                        <div className={FileExchangeStyles.folders}>
                          {getSubSubSubFolder && getSubSubSubFolder.loading ? (
                            <FolderListSkeleton itmes={3} />
                          ) : getSubSubSubFolder &&
                            getSubSubSubFolder.data &&
                            getSubSubSubFolder.data.data &&
                            getSubSubSubFolder.data.data.data &&
                            getSubSubSubFolder.data.data.data.childFolders &&
                            getSubSubSubFolder.data.data.data.childFolders.length > 0 ? (
                            getSubSubSubFolder.data.data.data.childFolders.map((folder, index) => (
                              <Fragment key={folder._id}>
                                {isEditFolder.status && isEditFolder.data._id === folder._id ? (
                                  <AddEditFolder
                                    inputPlaceholder='Folder Name'
                                    inputValue={editfolderName}
                                    inputOnChange={e => setEditFolderName(e.target.value)}
                                    submitBtnClick={handleEditFolder}
                                    cancelBtnClick={() => {
                                      setIsEditFolder({
                                        data: {},
                                        status: false,
                                      });
                                      setEditFolderName('');
                                    }}
                                  />
                                ) : (
                                  <Folder
                                    folderId={folder._id}
                                    name={folder.name}
                                    onClick={() => setSelectedSubSubSubSubFolder(folder)}
                                    active={selectedSubSubSubSubFolder._id === folder._id}
                                    showMenu={userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic'}
                                    menuItem={[
                                      {
                                        id: folder._id + 1,
                                        onClick: () => {
                                          setIsEditFolder({
                                            data: folder,
                                            status: true,
                                          });
                                          setEditFolderName(folder.name);
                                        },
                                        icon: 'edit-2',
                                        label: 'Rename Folder',
                                      },
                                      {
                                        id: folder._id + 2,
                                        onClick: () => {
                                          setDeleteFolderModal({
                                            folder,
                                            status: true,
                                          });
                                        },
                                        icon: 'folder-minus',
                                        label: 'Delete Folder',
                                      },
                                    ]}
                                  />
                                )}
                              </Fragment>
                            ))
                          ) : userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic' ? null : (
                            <p>No sub-folders</p>
                          )}

                          {getSubSubSubFolder &&
                            !getSubSubSubFolder.loading &&
                            (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'clinic') &&
                            (!isAddSubSubSubSubFolder ? (
                              <AddFolderButton label='Add New Sub Folder' onClick={() => setIsAddSubSubSubSubFolder(true)} />
                            ) : (
                              <AddEditFolder
                                inputPlaceholder='Folder Name'
                                inputValue={subSubSubSubFolderName}
                                inputOnChange={e => setSubSubSubSubFolderName(e.target.value)}
                                submitBtnClick={handleAddSubSubSubSubFolder}
                                cancelBtnClick={() => setIsAddSubSubSubSubFolder(false)}
                              />
                            ))}
                        </div>

                        {selectedSubSubSubSubFolder && selectedSubSubSubSubFolder._id ? (
                          <Fragment>
                            <div
                              className={FileExchangeStyles.folderFilesTop}
                              style={{
                                marginTop: '2rem',
                                marginBottom: '1rem',
                              }}
                            >
                              <div className={FileExchangeStyles.folderFilesTopFlex}>
                                <button className={FileExchangeStyles.uploadBtn} onClick={() => setSelectedSubSubSubSubFolder({})}>
                                  <svg className={FileExchangeStyles.uploadBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-back`} />
                                  </svg>
                                </button>
                                <h4 className={FileExchangeStyles.folderFilesSub}>{selectedSubSubSubSubFolder.name} subfolders and files</h4>
                              </div>
                              {userRole === 'admin'
                                ? null
                                : !isSpaceFull &&
                                  userRole !== 'fileUserOnlyDownload' && (
                                    <button
                                      className={FileExchangeStyles.uploadBtn}
                                      onClick={() => {
                                        if (!isSpaceFull) {
                                          setUploadFileModal(true);
                                          setUploadFileToSubFolder(true);
                                        }
                                      }}
                                    >
                                      Upload File to {selectedSubSubSubSubFolder.name}
                                      <svg className={FileExchangeStyles.uploadBtnIcon}>
                                        <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
                                      </svg>
                                    </button>
                                  )}
                            </div>

                            <div className={FileExchangeStyles.folderFilesTop} style={{ width: '100%' }}>
                              <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                              <div className={FileExchangeStyles.folderFilesTopFlex}>
                                <button
                                  className={currentFileView === 'grid' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                                  onClick={() => setCurrentFileView('grid')}
                                >
                                  <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-grid`} />
                                  </svg>
                                  Grid
                                </button>
                                <button
                                  className={currentFileView === 'list' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                                  onClick={() => setCurrentFileView('list')}
                                >
                                  <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-list`} />
                                  </svg>
                                  List
                                </button>
                              </div>
                            </div>

                            <div className={currentFileView === 'list' ? FileExchangeStyles.folders_filesList : FileExchangeStyles.folders_files}>
                              {subSubSubSubFolderData && subSubSubSubFolderData.loading ? (
                                <FolderListSkeleton itmes={3} type='file' />
                              ) : subSubSubSubFolderData.data &&
                                subSubSubSubFolderData.data.data &&
                                subSubSubSubFolderData.data.data.data &&
                                subSubSubSubFolderData.data.data.data.length > 0 ? (
                                subSubSubSubFolderData.data.data.data.map(file => (
                                  <Fragment key={file._id}>
                                    <ContextMenuTrigger id={`contextmenu${file._id}`}>
                                      {currentFileView === 'list' ? <FileListView file={file} /> : <File file={file} />}
                                    </ContextMenuTrigger>

                                    <ContextMenu id={`contextmenu${file._id}`}>
                                      <MenuItem
                                        onClick={() =>
                                          setDownloadFileModal({
                                            file,
                                            status: true,
                                          })
                                        }
                                      >
                                        <svg className='share'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
                                        </svg>
                                        <span>Download</span>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          setShareFileModal({
                                            file,
                                            status: true,
                                          })
                                        }
                                      >
                                        <svg className='share'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                        </svg>
                                        <span>Share</span>
                                      </MenuItem>
                                      {file.mimetype === 'application/pdf' ? (
                                        <MenuItem onClick={() => setShareFileForESignModal({ file, status: true })}>
                                          <svg className='share'>
                                            <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                          </svg>
                                          <span>Share File for e-sign</span>
                                        </MenuItem>
                                      ) : null}
                                      {userRole !== 'fileUserOnlyDownload' && (
                                        <Fragment>
                                          <MenuItem
                                            onClick={() =>
                                              setCopyFileModal({
                                                file,
                                                status: true,
                                              })
                                            }
                                          >
                                            <svg className='copy'>
                                              <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
                                            </svg>
                                            <span>Copy</span>
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              setCutFileModal({
                                                file,
                                                status: true,
                                              })
                                            }
                                          >
                                            <svg className='send'>
                                              <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
                                            </svg>
                                            <span>Move</span>
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setRenameFileModal({
                                                file,
                                                status: true,
                                              });
                                              setRenameFilePasswordInput({
                                                value: removeExtension(file.name),
                                                error: '',
                                              });
                                            }}
                                          >
                                            <svg className='watchlist'>
                                              <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
                                            </svg>
                                            <span>Rename</span>
                                          </MenuItem>

                                          {userRole === 'fileUser' ? (
                                            userLogin &&
                                            userLogin.userInfo &&
                                            userLogin.userInfo.data &&
                                            file.uploadedBy &&
                                            userLogin.userInfo.data.user._id === file.uploadedBy._id && (
                                              <MenuItem
                                                onClick={() =>
                                                  setDeleteFileModal({
                                                    file,
                                                    status: true,
                                                  })
                                                }
                                              >
                                                <svg className='delete'>
                                                  <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                                </svg>
                                                <span>Delete</span>
                                              </MenuItem>
                                            )
                                          ) : (
                                            <MenuItem
                                              onClick={() =>
                                                setDeleteFileModal({
                                                  file,
                                                  status: true,
                                                })
                                              }
                                            >
                                              <svg className='delete'>
                                                <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                              </svg>
                                              <span>Delete</span>
                                            </MenuItem>
                                          )}
                                        </Fragment>
                                      )}
                                    </ContextMenu>
                                  </Fragment>
                                ))
                              ) : (
                                <NoFile />
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <div className={currentFileView === 'list' ? FileExchangeStyles.folders_filesList : FileExchangeStyles.folders_files}>
                            <div className={FileExchangeStyles.folderFilesTop} style={{ width: '100%' }}>
                              <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                              <div className={FileExchangeStyles.folderFilesTopFlex}>
                                <button
                                  className={currentFileView === 'grid' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                                  onClick={() => setCurrentFileView('grid')}
                                >
                                  <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-grid`} />
                                  </svg>
                                  Grid
                                </button>
                                <button
                                  className={currentFileView === 'list' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                                  onClick={() => setCurrentFileView('list')}
                                >
                                  <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                    <use xlinkHref={`/assets/sprite.svg#icon-list`} />
                                  </svg>
                                  List
                                </button>
                              </div>
                            </div>

                            {subSubSubFolderData && subSubSubFolderData.loading ? (
                              <FolderListSkeleton itmes={3} type='file' />
                            ) : subSubSubFolderData.data &&
                              subSubSubFolderData.data.data &&
                              subSubSubFolderData.data.data.data &&
                              subSubSubFolderData.data.data.data.length > 0 ? (
                              subSubSubFolderData.data.data.data.map(file => (
                                <Fragment key={file._id}>
                                  <ContextMenuTrigger id={`contextmenu${file._id}`}>
                                    {currentFileView === 'list' ? <FileListView file={file} /> : <File file={file} />}
                                  </ContextMenuTrigger>

                                  <ContextMenu id={`contextmenu${file._id}`}>
                                    <MenuItem
                                      onClick={() =>
                                        setDownloadFileModal({
                                          file,
                                          status: true,
                                        })
                                      }
                                    >
                                      <svg className='share'>
                                        <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
                                      </svg>
                                      <span>Download</span>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        setShareFileModal({
                                          file,
                                          status: true,
                                        })
                                      }
                                    >
                                      <svg className='share'>
                                        <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                      </svg>
                                      <span>Share</span>
                                    </MenuItem>
                                    {file.mimetype === 'application/pdf' ? (
                                      <MenuItem onClick={() => setShareFileForESignModal({ file, status: true })}>
                                        <svg className='share'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                        </svg>
                                        <span>Share File for e-sign</span>
                                      </MenuItem>
                                    ) : null}
                                    {userRole !== 'fileUserOnlyDownload' && (
                                      <Fragment>
                                        <MenuItem
                                          onClick={() =>
                                            setCopyFileModal({
                                              file,
                                              status: true,
                                            })
                                          }
                                        >
                                          <svg className='copy'>
                                            <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
                                          </svg>
                                          <span>Copy</span>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            setCutFileModal({
                                              file,
                                              status: true,
                                            })
                                          }
                                        >
                                          <svg className='send'>
                                            <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
                                          </svg>
                                          <span>Move</span>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setRenameFileModal({
                                              file,
                                              status: true,
                                            });
                                            setRenameFilePasswordInput({
                                              value: removeExtension(file.name),
                                              error: '',
                                            });
                                          }}
                                        >
                                          <svg className='watchlist'>
                                            <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
                                          </svg>
                                          <span>Rename</span>
                                        </MenuItem>

                                        {userRole === 'fileUser' ? (
                                          userLogin &&
                                          userLogin.userInfo &&
                                          userLogin.userInfo.data &&
                                          file.uploadedBy &&
                                          userLogin.userInfo.data.user._id === file.uploadedBy._id && (
                                            <MenuItem
                                              onClick={() =>
                                                setDeleteFileModal({
                                                  file,
                                                  status: true,
                                                })
                                              }
                                            >
                                              <svg className='delete'>
                                                <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                              </svg>
                                              <span>Delete</span>
                                            </MenuItem>
                                          )
                                        ) : (
                                          <MenuItem
                                            onClick={() =>
                                              setDeleteFileModal({
                                                file,
                                                status: true,
                                              })
                                            }
                                          >
                                            <svg className='delete'>
                                              <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                            </svg>
                                            <span>Delete</span>
                                          </MenuItem>
                                        )}
                                      </Fragment>
                                    )}
                                  </ContextMenu>
                                </Fragment>
                              ))
                            ) : (
                              <NoFile />
                            )}
                          </div>
                        )}
                      </Fragment>
                    ) : (
                      <div className={currentFileView === 'list' ? FileExchangeStyles.folders_filesList : FileExchangeStyles.folders_files}>
                        <div className={FileExchangeStyles.folderFilesTop} style={{ width: '100%' }}>
                          <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                          <div className={FileExchangeStyles.folderFilesTopFlex}>
                            <button
                              className={currentFileView === 'grid' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                              onClick={() => setCurrentFileView('grid')}
                            >
                              <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                <use xlinkHref={`/assets/sprite.svg#icon-grid`} />
                              </svg>
                              Grid
                            </button>
                            <button
                              className={currentFileView === 'list' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                              onClick={() => setCurrentFileView('list')}
                            >
                              <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                                <use xlinkHref={`/assets/sprite.svg#icon-list`} />
                              </svg>
                              List
                            </button>
                          </div>
                        </div>
                        {subSubFolderData && subSubFolderData.loading ? (
                          <FolderListSkeleton itmes={3} type='file' />
                        ) : subSubFolderData.data && subSubFolderData.data.data && subSubFolderData.data.data.data && subSubFolderData.data.data.data.length > 0 ? (
                          <Fragment>
                            {subSubFolderData.data.data.data.map(file => (
                              <Fragment key={file._id}>
                                <ContextMenuTrigger id={`contextmenu${file._id}`}>
                                  {currentFileView === 'list' ? <FileListView file={file} /> : <File file={file} />}
                                </ContextMenuTrigger>

                                <ContextMenu id={`contextmenu${file._id}`}>
                                  <MenuItem onClick={() => setDownloadFileModal({ file, status: true })}>
                                    <svg className='share'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
                                    </svg>
                                    <span>Download</span>
                                  </MenuItem>
                                  <MenuItem onClick={() => setShareFileModal({ file, status: true })}>
                                    <svg className='share'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                    </svg>
                                    <span>Share</span>
                                  </MenuItem>
                                  {file.mimetype === 'application/pdf' ? (
                                    <MenuItem onClick={() => setShareFileForESignModal({ file, status: true })}>
                                      <svg className='share'>
                                        <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                      </svg>
                                      <span>Share File for e-sign</span>
                                    </MenuItem>
                                  ) : null}
                                  {userRole !== 'fileUserOnlyDownload' && (
                                    <Fragment>
                                      <MenuItem onClick={() => setCopyFileModal({ file, status: true })}>
                                        <svg className='copy'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
                                        </svg>
                                        <span>Copy</span>
                                      </MenuItem>
                                      <MenuItem onClick={() => setCutFileModal({ file, status: true })}>
                                        <svg className='send'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
                                        </svg>
                                        <span>Move</span>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setRenameFileModal({
                                            file,
                                            status: true,
                                          });
                                          setRenameFilePasswordInput({
                                            value: removeExtension(file.name),
                                            error: '',
                                          });
                                        }}
                                      >
                                        <svg className='watchlist'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
                                        </svg>
                                        <span>Rename</span>
                                      </MenuItem>

                                      {userRole === 'fileUser' ? (
                                        userLogin &&
                                        userLogin.userInfo &&
                                        userLogin.userInfo.data &&
                                        file.uploadedBy &&
                                        userLogin.userInfo.data.user._id === file.uploadedBy._id && (
                                          <MenuItem
                                            onClick={() =>
                                              setDeleteFileModal({
                                                file,
                                                status: true,
                                              })
                                            }
                                          >
                                            <svg className='delete'>
                                              <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                            </svg>
                                            <span>Delete</span>
                                          </MenuItem>
                                        )
                                      ) : (
                                        <MenuItem
                                          onClick={() =>
                                            setDeleteFileModal({
                                              file,
                                              status: true,
                                            })
                                          }
                                        >
                                          <svg className='delete'>
                                            <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                          </svg>
                                          <span>Delete</span>
                                        </MenuItem>
                                      )}
                                    </Fragment>
                                  )}
                                </ContextMenu>
                              </Fragment>
                            ))}
                            {/* <div style={{ marginTop: '1rem', width: '100%' }}>
                              <Pagination
                                onPageChange={handleSubSubFolderPageChange}
                                rowsPerPage={pageFileLimit}
                                totalPageSize={subSubFolderData.data.totalResults}
                                pageNumber={currentSubSubFolderPage}
                              />
                            </div> */}
                          </Fragment>
                        ) : (
                          <NoFile />
                        )}
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <div className={currentFileView === 'list' ? FileExchangeStyles.folders_filesList : FileExchangeStyles.folders_files}>
                    <div className={FileExchangeStyles.folderFilesTop} style={{ width: '100%' }}>
                      <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                      <div className={FileExchangeStyles.folderFilesTopFlex}>
                        <button
                          className={currentFileView === 'grid' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                          onClick={() => setCurrentFileView('grid')}
                        >
                          <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                            <use xlinkHref={`/assets/sprite.svg#icon-grid`} />
                          </svg>
                          Grid
                        </button>
                        <button
                          className={currentFileView === 'list' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                          onClick={() => setCurrentFileView('list')}
                        >
                          <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                            <use xlinkHref={`/assets/sprite.svg#icon-list`} />
                          </svg>
                          List
                        </button>
                      </div>
                    </div>
                    {subFolderData && subFolderData.loading ? (
                      <FolderListSkeleton itmes={3} type='file' />
                    ) : subFolderData.data && subFolderData.data.data && subFolderData.data.data.data && subFolderData.data.data.data.length > 0 ? (
                      <Fragment>
                        {subFolderData.data.data.data.map(file => (
                          <Fragment key={file._id}>
                            <ContextMenuTrigger id={`contextmenu${file._id}`}>
                              {currentFileView === 'list' ? <FileListView file={file} /> : <File file={file} />}
                            </ContextMenuTrigger>

                            <ContextMenu id={`contextmenu${file._id}`}>
                              <MenuItem onClick={() => setDownloadFileModal({ file, status: true })}>
                                <svg className='share'>
                                  <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
                                </svg>
                                <span>Download</span>
                              </MenuItem>
                              <MenuItem onClick={() => setShareFileModal({ file, status: true })}>
                                <svg className='share'>
                                  <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                </svg>
                                <span>Share</span>
                              </MenuItem>
                              {file.mimetype === 'application/pdf' ? (
                                <MenuItem onClick={() => setShareFileForESignModal({ file, status: true })}>
                                  <svg className='share'>
                                    <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                                  </svg>
                                  <span>Share File for e-sign</span>
                                </MenuItem>
                              ) : null}
                              {userRole !== 'fileUserOnlyDownload' && (
                                <Fragment>
                                  <MenuItem onClick={() => setCopyFileModal({ file, status: true })}>
                                    <svg className='copy'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
                                    </svg>
                                    <span>Copy</span>
                                  </MenuItem>
                                  <MenuItem onClick={() => setCutFileModal({ file, status: true })}>
                                    <svg className='send'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
                                    </svg>
                                    <span>Move</span>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setRenameFileModal({ file, status: true });
                                      setRenameFilePasswordInput({
                                        value: removeExtension(file.name),
                                        error: '',
                                      });
                                    }}
                                  >
                                    <svg className='watchlist'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
                                    </svg>
                                    <span>Rename</span>
                                  </MenuItem>

                                  {userRole === 'fileUser' ? (
                                    userLogin &&
                                    userLogin.userInfo &&
                                    userLogin.userInfo.data &&
                                    file.uploadedBy &&
                                    userLogin.userInfo.data.user._id === file.uploadedBy._id && (
                                      <MenuItem
                                        onClick={() =>
                                          setDeleteFileModal({
                                            file,
                                            status: true,
                                          })
                                        }
                                      >
                                        <svg className='delete'>
                                          <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                        </svg>
                                        <span>Delete</span>
                                      </MenuItem>
                                    )
                                  ) : (
                                    <MenuItem onClick={() => setDeleteFileModal({ file, status: true })}>
                                      <svg className='delete'>
                                        <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                      </svg>
                                      <span>Delete</span>
                                    </MenuItem>
                                  )}
                                </Fragment>
                              )}
                            </ContextMenu>
                          </Fragment>
                        ))}
                      </Fragment>
                    ) : (
                      <NoFile />
                    )}
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className={FileExchangeStyles.folderFilesTop} style={{ width: '100%' }}>
                  <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                  <div className={FileExchangeStyles.folderFilesTopFlex}>
                    <button
                      className={currentFileView === 'grid' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                      onClick={() => setCurrentFileView('grid')}
                    >
                      <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-grid`} />
                      </svg>
                      Grid
                    </button>
                    <button
                      className={currentFileView === 'list' ? FileExchangeStyles.fileListGridBtnActive : FileExchangeStyles.fileListGridBtn}
                      onClick={() => setCurrentFileView('list')}
                    >
                      <svg className={FileExchangeStyles.fileListGridBtnIcon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-list`} />
                      </svg>
                      List
                    </button>
                  </div>
                </div>

                <div className={currentFileView === 'list' ? FileExchangeStyles.folders_filesList : FileExchangeStyles.folders_files}>
                  {folderData && folderData.loading ? (
                    <FolderListSkeleton itmes={6} type='file' />
                  ) : folderData && folderData.data && folderData.data.data && folderData.data.data.data && folderData.data.data.data.length > 0 ? (
                    folderData.data.data.data.map(file => (
                      <Fragment key={file._id}>
                        <ContextMenuTrigger id={`contextmenu${file._id}`}>
                          {currentFileView === 'list' ? <FileListView file={file} /> : <File file={file} />}
                        </ContextMenuTrigger>

                        <ContextMenu id={`contextmenu${file._id}`}>
                          <MenuItem onClick={() => setDownloadFileModal({ file, status: true })}>
                            <svg className='copy'>
                              <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
                            </svg>
                            <span>Download File</span>
                          </MenuItem>
                          <MenuItem onClick={() => setShareFileModal({ file, status: true })}>
                            <svg className='share'>
                              <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                            </svg>
                            <span>Share File</span>
                          </MenuItem>
                          {file.mimetype === 'application/pdf' ? (
                            <MenuItem onClick={() => setShareFileForESignModal({ file, status: true })}>
                              <svg className='share'>
                                <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
                              </svg>
                              <span>Share File for e-sign</span>
                            </MenuItem>
                          ) : null}

                          {userRole !== 'fileUserOnlyDownload' && (
                            <Fragment>
                              <MenuItem onClick={() => setCopyFileModal({ file, status: true })}>
                                <svg className='copy'>
                                  <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
                                </svg>
                                <span>Copy</span>
                              </MenuItem>
                              <MenuItem onClick={() => setCutFileModal({ file, status: true })}>
                                <svg className='send'>
                                  <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
                                </svg>
                                <span>Move</span>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setRenameFileModal({ file, status: true });
                                  setRenameFilePasswordInput({
                                    value: removeExtension(file.name),
                                    error: '',
                                  });
                                }}
                              >
                                <svg className='watchlist'>
                                  <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
                                </svg>
                                <span>Rename</span>
                              </MenuItem>

                              {userRole === 'fileUser' ? (
                                userLogin &&
                                userLogin.userInfo &&
                                userLogin.userInfo.data &&
                                file.uploadedBy &&
                                userLogin.userInfo.data.user._id === file.uploadedBy._id && (
                                  <MenuItem onClick={() => setDeleteFileModal({ file, status: true })}>
                                    <svg className='delete'>
                                      <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                    </svg>
                                    <span>Delete</span>
                                  </MenuItem>
                                )
                              ) : (
                                <MenuItem onClick={() => setDeleteFileModal({ file, status: true })}>
                                  <svg className='delete'>
                                    <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
                                  </svg>
                                  <span>Delete</span>
                                </MenuItem>
                              )}
                            </Fragment>
                          )}
                        </ContextMenu>
                      </Fragment>
                    ))
                  ) : (
                    <NoFile />
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className={FileExchangeStyles.recentUploadsContainer}>
          <div className='header__left' style={{ flexWrap: 'wrap' }}>
            <SearchBox
              style={{
                width: fileSearchResult.data.length > 0 || fileSearchResult.error ? 'calc(100% - 10rem)' : 'calc(100% - 5rem)',
              }}
              placeholder='Search files..'
              value={fileSearchText}
              onChange={e => setFileSearchText(e.target.value)}
            />
            <button className='button_search' onClick={() => handleFileSearch(1)} disabled={fileSearchText.length < 3}>
              <svg className='button_icon'>
                <use xlinkHref={`/assets/sprite.svg#icon-search`} />
              </svg>
            </button>
            {(fileSearchResult.data.length > 0 || fileSearchResult.error) && (
              <button className='button_search_clear' onClick={clearFileSearch}>
                <svg className='button_icon'>
                  <use xlinkHref={`/assets/sprite.svg#icon-clear`} />
                </svg>
              </button>
            )}
          </div>
          <div className={FileExchangeStyles.recentUploads}>
            {(getSecureFiles && getSecureFiles.loading) || fileSearchResult.loading ? (
              <RecentFileUploadsSkeleton items={7} />
            ) : fileSearchResult.data.length > 0 ? (
              <Fragment>
                {fileSearchResult.data.map(file => (
                  <RecentFile file={file} key={file._id} />
                ))}
                <div style={{ marginTop: '1rem' }}>
                  <Pagination
                    onPageChange={setSearchCurrentPageSearch}
                    rowsPerPage={pageLimit}
                    totalPageSize={fileSearchResult.totalResults}
                    pageNumber={currentPageSearch}
                  />
                </div>
              </Fragment>
            ) : fileSearchResult.error ? (
              <p
                style={{
                  fontSize: '1.2rem',
                  color: 'var(--grey)',
                  fontWeight: '600',
                }}
              >
                No search results found
              </p>
            ) : getSecureFiles && getSecureFiles.files && getSecureFiles.files.data && getSecureFiles.files.data.data && getSecureFiles.files.data.data.length > 0 ? (
              <Fragment>
                {getSecureFiles.files.data.data.map(file => (
                  <RecentFile file={file} key={file._id} />
                ))}
                <div style={{ marginTop: '1rem' }}>
                  <Pagination onPageChange={handlePageChange} rowsPerPage={pageLimit} totalPageSize={getSecureFiles.files.totalResults} pageNumber={currentPage} />
                </div>
              </Fragment>
            ) : (
              <p
                style={{
                  fontSize: '1.2rem',
                  color: 'var(--grey)',
                  fontWeight: '600',
                }}
              >
                No files found.
              </p>
            )}
          </div>
        </div>

        {/* <div className={FileExchangeStyles.storageContainer}>
					<h3 className={FileExchangeStyles.folderHeading}>Your Storage</h3>
					<div className={FileExchangeStyles.storageContainerLines}>
						<div className={FileExchangeStyles.storageContainerLineFull} />
						<div className={FileExchangeStyles.storageContainerLineUsed} />
					</div>
					<h4 className={FileExchangeStyles.storageContainerTitle}>10GB of 13GB used</h4>
					<button className={FileExchangeStyles.storageContainerButton}>Upgrade Storage</button>
				</div> */}
      </div>

      {uploadFileModal && (
        <UploadFileModal
          uploadFileModal={uploadFileModal}
          handleUpload={handleUpload}
          handleUploadModalClose={handleUploadModalClose}
          data={{
            uploadFileName,
            setUploadFileName,
            uploadFileData,
            setUploadFileData,
            passwordProtected,
            setPasswordProtected,
            filePassword,
            setFilePassword,
            filePasswordConfirm,
            setFilePasswordConfirm,
            uploadNote,
            setUploadNote,
            selectedFolder,
          }}
        />
      )}

      <DeleteFolderModal
        loading={deleteFolder && deleteFolder.loading}
        data={{
          deleteFolderModal,
          setDeleteFolderModal,
          deleteFolderInput,
          setDeleteFolderInput,
          handleDeleteFolder,
        }}
      />

      <DeleteFileModal
        loading={deleteFile && deleteFile.loading}
        data={{
          deleteFileModal,
          setDeleteFileModal,
          deleteFileInput,
          setDeleteFileInput,
          handleDeleteFile,
        }}
      />

      <ShareFileModal
        data={{
          shareFileModal,
          setShareFileModal,
          shareFilePasswordInput,
          setShareFilePasswordInput,
          handleShareFile,
        }}
      />
      <ShareFileForESignModal
        data={{
          shareFileForESignModal,
          setShareFileForESignModal,
          shareFileForESignInputs,
          setShareFileForESignInputs,
          handleShareFileForESign,
        }}
      />

      <RenameFileModal
        data={{
          renameFileModal,
          setRenameFileModal,
          renameFilePasswordInput,
          setRenameFilePasswordInput,
          handleRenameFile,
        }}
      />

      <CopyFileModal
        data={{
          copyFileModal,
          setCopyFileModal,
          copyFilePasswordInput,
          setCopyFilePasswordInput,
          foldersList,
          handleCopyFile,
        }}
      />

      <CutFileModal
        data={{
          cutFileModal,
          setCutFileModal,
          cutFilePasswordInput,
          setCutFilePasswordInput,
          foldersList,
          handleCutFile,
        }}
      />

      <DownloadFileModal
        data={{
          downloadFileModal,
          setDownloadFileModal,
          downloadFilePasswordInput,
          setDownloadFilePasswordInput,
          handleDownloadFile,
        }}
      />
    </Content>
  );
};

export default FileExchangeScreen;
