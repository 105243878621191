import React, { useEffect, useState } from 'react';
import TabLink from './TabLink';
import ChatStyles from '../Chat.module.css';
import { useSelector } from 'react-redux';

const TabLinks = ({
	link1Label,
	link1To,
	link1Active,
	setShowAddGroupMoal,
	link1Notification,
	link2Label,
	link2To,
	link2Active,
	link2Notification
}) => {

	const [groupCheck, setGroupCheck] = React.useState(false);
	const [notificationCount, setNotificationCount] = React.useState();

	const userLogin = useSelector((state) => state.userLogin)


	useEffect(() => {
		setNotificationCount(link2Notification)
	}, [link2Notification])

	return (
		<div className={ChatStyles.chatLists_tabs}>
			<div className={ChatStyles.tabContainer}>
				<TabLink to={link1To} active={link1Active} label={link1Label} notification={link1Notification} />
				<TabLink to={link2To} active={link2Active} label={link2Label} notification={notificationCount} />
			</div>
			{!userLogin?.userInfo?.data?.user?.modules?.adminOnlyChat | userLogin?.userInfo?.data?.user?.role === 'clientAdmin' ? (
				<div
					className='dropdown'
					style={{ zIndex: '0' }}
					onMouseOver={() => setGroupCheck(!groupCheck)}
					onMouseOut={() => setGroupCheck(!groupCheck)}
				>
					<div className='dropdown-a'>
						<svg className='header_group_chat_icon'>
							<use xlinkHref={`/assets/sprite.svg#icon-group-chat`} />
						</svg>
					</div>
					<input
						checked={groupCheck}
						type='checkbox'
						className={ChatStyles.groupChat_input}
					/>
					<div className='dropdown-c'>
						<ul style={{
							width: '15rem', padding: '0rem 0.4rem', marginLeft: '20px',
							marginTop: '-2px'
						}}>
							<li
								style={{
									maxHeight: '50vh',
									overflowY: 'auto',
								}}
							>
								<button
									style={{ marginBottom: '0rem', borderBottom: 'none' }}
									onClick={() => setShowAddGroupMoal(true)}
									className='dropdown_link_btn dropdown_link'
								>
									New Group Chat
								</button>
							</li>
						</ul>
					</div>
				</div>
			):null}
		</div >
	);
};

export default TabLinks;
