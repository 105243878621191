import React from 'react';
import CancelButtonStyles from './CancelButton.module.css';

const CancelButton = ({ label, onClick }) => {
	return (
		<button onClick={onClick} className={CancelButtonStyles.button}>
			{label}
		</button>
	);
};

export default CancelButton;
