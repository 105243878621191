import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseModal from '../../../../components/BaseModal/BaseModal';
import Styles from './AddGroupUsersMoal.module.css'
import SearchBox from '../../User/SearchBox';
import UserGroupAddCard from '../../User/UserGroupAddCard';
import PressableButton from '../../../../components/NewButton';
import NoData from '../../../../components/NoData';
import { useDispatch, useSelector } from 'react-redux';
import Toaster from '../../../../components/Toast/SuccessToast';
import GroupAddUserInput from './GroupAddUserInput/GroupAddUserInput';
import CircularLoader from '../../../../components/CircularLoader/CircularLoader';
import { getAllChatUserAction, getAllChatUserActionByNumber, getAllChatUserActionBySearch } from '../../../../redux/actions/chatActions';

const AddGroupUsersMoal = ({ socket, acceptedUsers, showModal, setShowModal }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [circularLoader, setCircularLoader] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkboxes, setCheckboxes] = useState([]);

    const [searchResults, setSearchResults] = useState([]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [page, setPage] = useState(2);
    const pageSize = 10


    const [inputGrouNameValue, setInputGrouNameValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const onlineUsers = useSelector((state) => state.onlineUsers);
    const isButtonDisabled = selectedItems.length === 0 || inputGrouNameValue.length === 0;

    const [debouncedSearch, setDebouncedSearch] = useState('');
    const debounceDelay = 100; 
    const debounceTimeoutRef = useRef(null);


    const dispatch = useDispatch()

    useEffect(() => {
        setSearchResults(acceptedUsers)
    }, [acceptedUsers])

    const onClose = useCallback(() => {
        setShowModal(false);
        setLoading(false);
        setSearchResults(acceptedUsers)
        setSelectedItems([]);
        setSearchQuery('')
    }, [acceptedUsers, setShowModal])

    const search = useCallback(async (query) => {
        setIsSearching(true);
        if (query.length > 0) {
            setCircularLoader(true)
            const filteredData = acceptedUsers.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
            |
            item.email.toLowerCase().includes(query.toLowerCase())
        );
            setSearchResults(filteredData);
            const data = await dispatch(getAllChatUserActionBySearch('fresh', query));
            setTimeout(() => {
				setCircularLoader(false)
			}, 2000)
            setSearchResults(data.data);
        } else {
            setIsSearching(false);
            setSearchResults(acceptedUsers);
        }
    }, [acceptedUsers, dispatch]);
    
    useEffect(() => {
        search(debouncedSearch);
    }, [debouncedSearch, search]);

    const handleInputChange = useCallback((e) => {
        setSearchQuery(e.target.value);
        search(e.target.value);
    }, [search]);

    const handleCheckboxChange = useCallback((item) => {

        if (selectedItems.some((selected) => selected.id === item.id)) {
            setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    }, [selectedItems]);


    const handleInviteSubmit = useCallback((e) => {
        e.preventDefault();
        const userData = Object.values(onlineUsers.onlineUsers).find((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id);
        const clinicId = userLogin.userInfo.data.user.clinic;

        socket.emit('createGroup', {
            members: selectedItems,
            senderId: userData.userId,
            clinic: clinicId,
            mySocketId: userData.socketId,
            groupName: inputGrouNameValue
        },);

        setLoading(true);
        setTimeout(() => {
            onClose()
        }, 1000)
    }, [onlineUsers, selectedItems, socket, userLogin]);

    const handleGroupNameInputChange = (event) => {
        setInputGrouNameValue(event.target.value);
    };

    const fetchData = useCallback(async () => {
        if (!circularLoader && hasMoreData && !isSearching) {
            setCircularLoader(true);
            const data = await dispatch(getAllChatUserActionByNumber('fresh', page));
            const newData = await data.data;
            if (newData.length > 0) {
                setSearchResults((prevResults) => [...prevResults, ...newData]);
                setPage((prevPage) => prevPage + 1);
            } else {
                setHasMoreData(false);
            }

            setCircularLoader(false);
        }
    }, [circularLoader, dispatch, hasMoreData, isSearching, page]);

    const cardListRef = useRef(null);

    const handleScroll = useCallback(() => {
        const { scrollTop, clientHeight, scrollHeight } = cardListRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 2 && hasMoreData) {
            fetchData();
        }
    }, [fetchData, hasMoreData]);

    useEffect(() => {
        if (cardListRef.current) {
            cardListRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (cardListRef.current) {
                cardListRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, hasMoreData, page]);

    return (
        <BaseModal showModal={showModal} className={Styles.modal} style={acceptedUsers > 0 ? null : { height: '550px' }}>
            <div className={Styles.closeIconContainer}>
                <svg className={`header_group_chat_icon ${Styles.group_chat_close}`} onClick={onClose}>
                    <use xlinkHref={`/assets/sprite.svg#icon-close-group-modal`} />
                </svg>
            </div>
            <div className={Styles.iconContainer}>
                <div className={Styles.iconAvatarContainer}>
                    <svg className={Styles.iconAvatarIcon} onClick={() => setShowModal(false)}>
                        <use xlinkHref={`/assets/sprite.svg#icon-group-logo`} />
                    </svg>
                </div>
            </div>
            <div className={Styles.groupNameInput}>
                <GroupAddUserInput
                    handleInputChange={handleGroupNameInputChange}
                    inputValue={inputGrouNameValue}
                    setInputValue={setInputGrouNameValue}
                />
            </div>
            <div className={Styles.inputContainer}>
                <div className={Styles.input}>
                    <SearchBox
                        value={searchQuery}
                        onChange={(e)=>{
                            setSearchQuery(e.target.value);
                            clearTimeout(debounceTimeoutRef.current);
                            debounceTimeoutRef.current = setTimeout(() => {
                                setDebouncedSearch(e.target.value);
                            }, debounceDelay);
                        }}
                    />
                </div>
            </div>
            {acceptedUsers && acceptedUsers.length > 0 ?
                (<div className={Styles.cardList} ref={cardListRef}>
                    {
                        searchResults
                            ?.map((item) =>
                                <>
                                    <UserGroupAddCard
                                        key={item.id}
                                        name={item.name}
                                        id={item._id}
                                        email={item.email}
                                        isChecked={isChecked}
                                        setIsChecked={setIsChecked}
                                        handleCheckboxToggle={handleCheckboxChange}
                                        checkboxes={checkboxes}
                                        setCheckboxes={setCheckboxes}
                                    />

                                </>

                            )}
                    {circularLoader && <div className={Styles.circularLoader}>
                        <CircularLoader />
                    </div>}
                </div>)
                :
                <div>
                    <NoData title='No Users Found' imgStyle={{ height: '20rem', marginTop: '2rem' }} />
                </div>}
            {acceptedUsers && acceptedUsers.length > 0 &&
                <div className={Styles.buttonContainer}>
                    <PressableButton
                        name={loading ? 'SENDING........' : 'SEND REQUEST'}
                        icon={!loading ? 'dash' : null}
                        onClick={handleInviteSubmit}
                        disabled={isButtonDisabled}
                    />
                </div>}
        </BaseModal>
    )
}

export default AddGroupUsersMoal
