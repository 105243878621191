import React from 'react';
import ModalForm from '../Modal/ModalForm';
import InputsSection from '../Modal/InputsSectionColumn';
import InputBoxSkeleton from './InputBoxSkeleton';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const DownloadFileSkeleton = ({ fileProtected = false }) => {
	return (
		<ModalForm style={{ marginBottom: '2.5rem' }}>
			<InputsSection style={{ width: '34rem' }}>
				{fileProtected ? (
					<InputBoxSkeleton style={{ width: '100%' }} />
				) : (
					<div className='flexCol'>
						<div
							className={[ SkeletonStyles.form_input__text, SkeletonStyles.skeleton ].join(' ')}
							style={{ width: '30rem' }}
						/>
						<div className={[ SkeletonStyles.form_input__text, SkeletonStyles.skeleton ].join(' ')} />
					</div>
				)}
			</InputsSection>

			<ModalButtonsSkeleton />
		</ModalForm>
	);
};

export default DownloadFileSkeleton;
