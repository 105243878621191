import {
  ONLINE_USERS,
  CLIENT_LOGO_REQUEST,
  CLIENT_LOGO_SUCCESS,
  CLIENT_LOGO_FAIL,
  CLIENT_LOGO_RESET,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_RESET,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_RESET,
  GET_ALL_USERS_SUCCESS,
  SELECT_USER_FAIL,
  SELECT_USER_REQUEST,
  SELECT_USER_RESET,
  SELECT_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_RESET,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  RESET_USER_PASSWORD_RESET,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GET_LOGGED_USER_DETAILS_REQUEST,
  GET_LOGGED_USER_DETAILS_SUCCESS,
  GET_LOGGED_USER_DETAILS_FAIL,
  GET_LOGGED_USER_DETAILS_RESET,
  CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST,
  CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS,
  CHANGE_LOGGED_IN_USER_PASSWORD_FAIL,
  CHANGE_LOGGED_IN_USER_PASSWORD_RESET,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_RESET,
  VERIFY_PASSWORD_OTP_REQUEST,
  VERIFY_PASSWORD_OTP_SUCCESS,
  VERIFY_PASSWORD_OTP_FAIL,
  VERIFY_PASSWORD_OTP_RESET,
  UPDATE_LOGGED_USER_DETAILS_REQUEST,
  UPDATE_LOGGED_USER_DETAILS_SUCCESS,
  UPDATE_LOGGED_USER_DETAILS_FAIL,
  UPDATE_LOGGED_USER_DETAILS_RESET,
  DELETE_ME_REQUEST,
  DELETE_ME_SUCCESS,
  DELETE_ME_FAIL,
  DELETE_ME_RESET,
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  ALL_NOTIFICATIONS_RESET,
  ALL_NOTIFICATIONS_REQUEST_LOAD_MORE,
  CALL_INCOMING,
  CALL_ANSWER,
  CALL_DECLINE,
  CALL_RESET,
  CLINIC_USER_COUNT_REQUEST,
  CLINIC_USER_COUNT_SUCCESS,
  CLINIC_USER_COUNT_FAIL,
  CLINIC_USER_COUNT_RESET,
  SET_LOGGED_USER_DETAILS_IN_USER_LOGIN,
  GET_ALL_LOGS_REQUEST,
  GET_ALL_LOGS_SUCCESS,
  GET_ALL_LOGS_FAIL,
  GET_ALL_LOGS_RESET,
} from '../constants/userConstants';

export const onlineUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case ONLINE_USERS:
      return { onlineUsers: action.payload };

    default:
      return state;
  }
};

export const getClientLogoReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_LOGO_REQUEST:
      return { loading: true };

    case CLIENT_LOGO_SUCCESS:
      return { logo: action.payload, loading: false };

    case CLIENT_LOGO_FAIL:
      return { loading: false, error: action.payload };

    case CLIENT_LOGO_RESET:
      return {};

    default:
      return state;
  }
};

export const createNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_USER_REQUEST:
      return { loading: true };

    case CREATE_NEW_USER_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case CREATE_NEW_USER_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_NEW_USER_RESET:
      return {};

    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    case SET_LOGGED_USER_DETAILS_IN_USER_LOGIN: 
      // let newData = {
      //   ...state,
      // }
      // newData.userInfo.data = {
      //   user: action.payload.data.data
      // }
      return {
        userInfo: {
          ...state.userInfo,
          data: {
            user: action.payload.data.data
          }
        },
        loading: false
      }
    default:
      return state;
  }
};

export const usersListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { loading: true };

    case GET_ALL_USERS_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case GET_ALL_USERS_FAIL:
      return { loading: false, error: action.payload };

    case GET_ALL_USERS_RESET:
      return {};

    default:
      return state;
  }
};

export const usersLogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_LOGS_REQUEST:
      return { loading: true };

    case GET_ALL_LOGS_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case GET_ALL_LOGS_FAIL:
      return { loading: false, error: action.payload };

    case GET_ALL_LOGS_RESET:
      return {};

    default:
      return state;
  }
};

export const selectUserReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_USER_REQUEST:
      return { loading: true };

    case SELECT_USER_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case SELECT_USER_FAIL:
      return { loading: false, error: action.payload };

    case SELECT_USER_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { loading: true };

    case DELETE_USER_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case DELETE_USER_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_USER_RESET:
      return {};

    default:
      return state;
  }
};

export const resetUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_USER_PASSWORD_REQUEST:
      return { loading: true };

    case RESET_USER_PASSWORD_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case RESET_USER_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case RESET_USER_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true };

    case UPDATE_USER_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_USER_RESET:
      return {};

    default:
      return state;
  }
};

export const updateUserStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_STATUS_REQUEST:
      return { loading: true };

    case UPDATE_USER_STATUS_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case UPDATE_USER_STATUS_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_USER_STATUS_RESET:
      return {};

    default:
      return state;
  }
};

export const loggedinUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LOGGED_USER_DETAILS_REQUEST:
      return { loading: true };

    case GET_LOGGED_USER_DETAILS_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case GET_LOGGED_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case GET_LOGGED_USER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateloggedinUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LOGGED_USER_DETAILS_REQUEST:
      return { loading: true };

    case UPDATE_LOGGED_USER_DETAILS_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case UPDATE_LOGGED_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_LOGGED_USER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteMeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ME_REQUEST:
      return { ...state, loading: true };

    case DELETE_ME_SUCCESS:
      return { success: true, loading: false };

    case DELETE_ME_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_ME_RESET:
      return { loading: false, success: undefined };

    default:
      return state;
  }
};

export const changeLoggedInUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST:
      return { loading: true };

    case CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case CHANGE_LOGGED_IN_USER_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case CHANGE_LOGGED_IN_USER_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { loading: true };

    case FORGOT_PASSWORD_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case FORGOT_PASSWORD_RESET:
      return {};

    default:
      return state;
  }
};

export const verifyPasswordOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_PASSWORD_OTP_REQUEST:
      return { loading: true };

    case VERIFY_PASSWORD_OTP_SUCCESS:
      return { userInfo: action.payload, loading: false };

    case VERIFY_PASSWORD_OTP_FAIL:
      return { loading: false, error: action.payload };

    case VERIFY_PASSWORD_OTP_RESET:
      return {};

    default:
      return state;
  }
};
export const clinicUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CLINIC_USER_COUNT_REQUEST:
      return { loading: true };

    case CLINIC_USER_COUNT_SUCCESS:
      return { details: action.payload, loading: false };

    case CLINIC_USER_COUNT_FAIL:
      return { loading: false, error: action.payload };

    case CLINIC_USER_COUNT_RESET:
      return {};

    default:
      return state;
  }
};

export const allNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_NOTIFICATIONS_REQUEST:
      return { loading: true };
    case ALL_NOTIFICATIONS_REQUEST_LOAD_MORE:
      return { ...state, loadingMore: true };

    case ALL_NOTIFICATIONS_SUCCESS:
      return {
        notifications: action.payload,
        loading: false,
        loadingMore: false,
      };

    case ALL_NOTIFICATIONS_FAIL:
      return { loading: false, error: action.payload, loadingMore: false };

    case ALL_NOTIFICATIONS_RESET:
      return {};

    default:
      return state;
  }
};
export const callReducer = (state = {}, action) => {
  switch (action.type) {
    case CALL_INCOMING:
      return { status: 'Incoming', data: action.payload };
    case CALL_ANSWER:
      return { ...state, status: 'Answer' };

    case CALL_DECLINE:
      return { ...state, status: 'Decline' };

    case CALL_RESET:
      return {};

    default:
      return state;
  }
};
