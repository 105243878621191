import React from 'react';

const ModalHeading = (props) => {
	return (
		<div className='modal__heading--container' style={props.style}>
			<h2 className='modal__heading2'>{props.heading}</h2>
			{/* <button onClick={onClose} className='modal__heading--btn'>
				<svg className='modal__heading--icon'>
					<use xlinkHref={`/assets/sprite.svg#icon-cancel`} />
				</svg>
			</button> */}
		</div>
	);
};

export default ModalHeading;
