import React from 'react';
import ModalForm from '../Modal/ModalForm';
import InputsSection from '../Modal/InputsSection';
import InputBoxSkeleton from './InputBoxSkeleton';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';

const UpdateMyProfileSkeleton = () => {
	return (
		<ModalForm style={{ marginBottom: '2.5rem' }}>
			<InputsSection>
				<InputBoxSkeleton />
				<InputBoxSkeleton />
			</InputsSection>
			<InputsSection>
				<InputBoxSkeleton />
				<InputBoxSkeleton />
			</InputsSection>
			<ModalButtonsSkeleton />
		</ModalForm>
	);
};

export default UpdateMyProfileSkeleton;
