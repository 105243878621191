import React, { useEffect } from 'react';
import ChatStyles from '../../Chat.module.css';

const SelectedUser = ({ data, requestVideoCallToSelectedUser }) => {
  const {
    chatUserLoading,
    checkOnlineAndGetData,
    checkOnline,
    selectedNewUser,
    selectedUser,
    setShowSecurityKey,
    showSecurityKey,
    setVideoNotesModal,
    setGroupName
  } = data;

  const [lastSeenTime, setLastSeenTime] = React.useState(0);
  const [subMenu, setSubMenu] = React.useState(false);

  React.useEffect(() => {
    if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length > 0 &&
      selectedNewUser.lastSeenTime &&
      selectedNewUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedNewUser.lastSeenTime);
    } else if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length === 0 &&
      selectedUser &&
      selectedUser.lastSeenTime &&
      selectedUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedUser.lastSeenTime);
    }
  }, [selectedNewUser, selectedUser]);

  return (
    <div className={ChatStyles.chatBox_user}>
      <div className={ChatStyles.chatBox_user_data}>
        <div className={ChatStyles.chatLists_user_imageBox}>
          <img
            src='/assets/indviual.png'
            alt='User'
            className={ChatStyles.chatLists_user_image}
          />
          {checkOnline(selectedUser._id) ? (
            <div className={ChatStyles.chatLists_user_active} />
          ) : (
            <div className={ChatStyles.chatLists_user_deactive} />
          )}
        </div>
        <div>
          <h3 className={ChatStyles.chatLists_user_name}>
            {selectedUser && selectedUser.name ? selectedUser.name : ''}
          </h3>
          <p className={ChatStyles.chatLists_user_msg}>
            {checkOnline(selectedUser._id) ? (
              'Online'
            ) : lastSeenTime > 0 ? (
              <span>
                last seen on{' '}
                {new Date(lastSeenTime).getDate() ===
                  new Date(Date.now()).getDate()
                  ? 'today'
                  : new Date(lastSeenTime).getDate() ===
                    new Date(Date.now()).getDate() - 1
                    ? 'yesterday'
                    : new Date(lastSeenTime).toLocaleDateString()}{' '}
                at{' '}
                {new Date(lastSeenTime)
                  .toLocaleTimeString()
                  .replace(/(.*)\D\d+/, '$1')}
              </span>
            ) : (
              'Offline'
            )}
          </p>
        </div>
      </div>

      {!chatUserLoading && (
        <div className={ChatStyles.chatBox_user_right_container}>
          {checkOnlineAndGetData(selectedUser._id) ? (
            <button
              onClick={() => requestVideoCallToSelectedUser(selectedUser)}
              className={ChatStyles.video_call_button}
            >
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-video-call`} />
              </svg>
            </button>
          ) : (
            <div onClick={() => requestVideoCallToSelectedUser(selectedUser)} className={ChatStyles.video_call_button_disabled}>
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-video-call`} />
              </svg>
            </div>
          )}

          <div
            className='dropdown'
            onMouseOver={() => setSubMenu(!subMenu)}
            onMouseOut={() => setSubMenu(!subMenu)}
          >
            <div className='dropdown-a'>
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-three_dots`} />
              </svg>
            </div>
            <input
              type='checkbox'
              checked={subMenu}
              onChange={() => {}}
            />
            <div className='dropdown-c'>
              <ul style={{ width: '15rem' }}>
                <li
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  {/* <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setShowSecurityKey(!showSecurityKey)}
                  >
                    Security key
                  </button> */}
                  <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setVideoNotesModal(true)}
                  >
                    Video Notes
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedUser;
