import React from 'react';
import { Link } from 'react-router-dom';
import ChatStyles from '../Chat.module.css';

const TabLink = ({ to, label, active, notification }) => {
	return (
		<Link
			to={to}
			className={
				active ? (
					[ ChatStyles.chatLists_tab_link, ChatStyles.chatLists_tab_link_active ].join(' ')
				) : (
					ChatStyles.chatLists_tab_link
				)
			}>
			{label}
			{notification && <span>{notification}</span>}
		</Link>
	);
};

export default TabLink;
