import React, { Fragment, useState } from "react";
import Content, { HeaderLink } from "../../components/Content";
import SecureEmailStyles from "./SecureEmail.module.css";
import SearchBox from "../../components/SearchBox";
import NoContent from "../../components/NoContent";
import UserCard from "./UserCard/UserCard";
import ComposeButton from "./ComposeButton/ComposeButton";
import MailBody from "./MailBody/MailBody";
import NoEmailSelected from "./MailBody/NoEmailSelected";
import ComposeMail from "./ComposeMail/ComposeMail";
// import DeleteMailModal from "../EmailClient/DeleteMailModal";
import DeleteMailModal from "./DeleteMailModal";
const SecureEmail = () => {
  const data = [
    {
      _id: 1,
      name: "John Doe",
      email: {
        subject:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa",
        time: "2022-08-19T13:59:08.645Z",
        isRead: false,
        body: `Hello John,\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      },
      hasAttachment: true,
      attachments: [
        {
          fileName: "ScreenShots1.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
        {
          fileName: "Screenshot2.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
        {
          fileName: "screenshots3.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
      ],
      attachmentCount: 3,
      isFavorite: false,
    },
    {
      _id: 2,
      name: "Martina",
      email: {
        subject: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        time: "2022-08-18T15:59:08.645Z",
        isRead: true,
        body: `Hello John,\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      },
      hasAttachment: false,
      isFavorite: true,
    },
    {
      _id: 3,
      name: "Robert Jack",
      email: {
        subject: "Lorem ipsum dolor sit amet consectetur",
        time: "2022-08-18T14:59:08.645Z",
        isRead: false,
        body: `Hello John,\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      },
      hasAttachment: true,
      attachments: [
        {
          fileName: "ScreenShots1.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
        {
          fileName: "Screenshot2.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
        {
          fileName: "screenshots3.jpg",
          fileUrl: "https://via.placeholder.com/200x100",
        },
      ],
      attachmentCount: 3,
      isFavorite: true,
    },
    {
      _id: 4,
      name: "Daniel Right",
      email: {
        subject: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        time: "2022-08-17T01:59:08.645Z",
        isRead: true,
        body: `Hello John,\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      },
      hasAttachment: false,
      isFavorite: false,
    },
    {
      _id: 5,
      name: "Mark Henry",
      email: {
        subject: "Lorem ipsum dolor sit",
        time: "2022-08-16T13:59:08.645Z",
        isRead: true,
        body: `Hello John,\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.\nEt dolore magna aliqua. Ut ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      },
      hasAttachment: false,
      isFavorite: false,
    },
  ];

  const [selected, setSelected] = useState({});
  const [composeMail, setComposeMail] = useState(false);
  const [deleteMailModal, setdeleteMailModal] = React.useState({
    data: {},
    status: false,
  });
  return (
    <Content
      currentMenu="email"
      customHeader={true}
      customHeaderComponent={
        <Fragment>
          <HeaderLink label="Inbox" type="button" active />
          <HeaderLink label="Starred" type="button" />
          <HeaderLink label="Sent" type="button" />
          <HeaderLink label="Draft" type="button" />
          {/* <HeaderLink label='Compose Email' type='button' isSpecial onClick={() => setComposeMailModal(true)} /> */}
        </Fragment>
      }
    >
      <div className={SecureEmailStyles.content}>
        <div className={SecureEmailStyles.mailUsers}>
          <SearchBox placeholder="Search Emails" style={{width: "34rem"}}/>
          <div className={SecureEmailStyles.filter}>
            <div className={SecureEmailStyles.filterUser}>
              <input type="checkbox" />
            </div>

            <div className={SecureEmailStyles.filterUser}>
              <p className={SecureEmailStyles.filterUser_text}>Today</p>
            </div>
          </div>
          {data.map((d) => (
            <UserCard
              key={d._id}
              user={d}
              active={selected && selected._id === d._id}
              onClick={() => setSelected(d)}
              selected={selected}
              setdeleteMailModal={setdeleteMailModal}
            />
          ))}
        </div>
        <div className={SecureEmailStyles.mail}>
          <ComposeButton
            label="Compose Email"
            icon="email"
            onClick={() => setComposeMail(true)}
          />
          {composeMail ? (
            <ComposeMail onClose={() => setComposeMail(false)} />
          ) : selected && Object.keys(selected).length > 0 ? (
            <MailBody
              selected={selected}
              deleteMailModal={deleteMailModal}
              setdeleteMailModal={setdeleteMailModal}
              handleDeleteMailModalClose={() =>
                setdeleteMailModal({ data: {}, status: false })
              }
            />
          ) : (
            <NoEmailSelected />
          )}
            {/* <DeleteMailModal
              deleteMailModal={deleteMailModal}
              setdeleteMailModal={setdeleteMailModal}
              handleDeleteMailModalClose={() =>
                setdeleteMailModal({ data: {}, status: false })
              }
            /> */}

            <DeleteMailModal deleteMailModal={deleteMailModal}
              setdeleteMailModal={setdeleteMailModal}
              handleDeleteMailModalClose={() =>
                setdeleteMailModal({ data: {}, status: false })
              }/>
        </div>
      </div>
    </Content>
  );
};

export default SecureEmail;
