import axios from 'axios';
import { ALL_NOTIFICATIONS_FAIL, ALL_NOTIFICATIONS_REQUEST, ALL_NOTIFICATIONS_REQUEST_LOAD_MORE, ALL_NOTIFICATIONS_SUCCESS } from '../constants/userConstants';
// import { BASE_URL } from './ip';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllSecurityKeyAction = channelId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/keys/${channelId}/key`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const createSecurityKeyAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(`${BASE_URL}/keys/generate-key?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const makeDefaultSecurityKeyAction = keyID => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(`${BASE_URL}/keys/default-key/${keyID}`, {}, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getAllChatUserAction =
  (type = 'accepted') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        // `${BASE_URL}/channel/${type}/user`,
        `${BASE_URL}/channel/${type}/user`,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const getAllChatUserActionByNumber =
  (type = 'accepted', pageNumber, filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(`${BASE_URL}/channel/user/${type}?search=${filter}&pageNumber=${pageNumber}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const getAllChatUserActionBySearch =
  (type = 'accepted', filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(`${BASE_URL}/channel/user/${type}?search=${filter}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

// export const getAllGroupUsersAction =
// (groupId) =>
// async (dispatch, getState) => {
//   try {
//     const {
//       userLogin: { userInfo },
//     } = getState();
//     const config = {
//       headers: {
//         Authorization: `${userInfo.token}`,
//         'Content-Type': 'application/json',
//       },
//     };

//     const { data } = await axios.get(
//       `${BASE_URL}/group/users?groupId=${groupId}`,
//       config
//     );

//     return data;
//   } catch (err) {
//     return err.response && err.response.data.message
//       ? err.response.data.message
//       : err.message;
//   }
// };

export const getAllGroupUsersAction = groupId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.get(`${BASE_URL}/group/accepted-pending/users?groupId=${groupId}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getAllGroupUsersActionByNumber =
  (groupId, pageNumber, filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${BASE_URL}/group/accepted-pending/users?groupId=${groupId}&pageNumber=${pageNumber}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const getAllGroupUsersActionBySearch =
  (groupId, filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(`${BASE_URL}/group/accepted-pending/users?groupId=${groupId}&search=${filter}&pageNumber=${1}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const getNonGroupUsersAction = groupId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/group/non/users?groupId=${groupId}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getNonGroupUsersByNumberAction =
  (groupId, pageNumber, filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(`${BASE_URL}/group/non/users?groupId=${groupId}&search=${filter}&pageNumber=${pageNumber}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const getNonGroupUsersBySearchAction =
  (groupId, filter = '') =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(`${BASE_URL}/group/non/users?groupId=${groupId}&search=${filter}`, config);

      return data;
    } catch (err) {
      return err.response && err.response.data.message ? err.response.data.message : err.message;
    }
  };

export const sendRequestAction = userId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/channel?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, { user: userId }, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const acceptRequestAction = userId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(`${BASE_URL}/channel/${userId}/accept?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, {}, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const cancelRequestAction = userId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.delete(`${BASE_URL}/channel/${userId}/cancel?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const removeRequestAction = userId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.delete(`${BASE_URL}/channel/${userId}/remove?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getSecurityKeyAction = relationId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/keys/${relationId}/encrypt-symmetric-key/channel`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getSecurityKeyByIdAction = keyId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    // Old code
    // const { data } = await axios.get(
    // 	`${BASE_URL}/keys/${keyId}/encrypt-symmetric-key/id`,
    // 	config
    // );

    // return data;

    // New code

    // Because of the absence of a proper keyId, there are continuous failed attempts at API calls, so I have implemented a check to only call the API if the keyId is present
    let data;

    if (keyId) {
      data = await axios.get(`${BASE_URL}/keys/${keyId}/encrypt-symmetric-key/id`, config);
    }

    return data.data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getChatsAction = (chatId, lastMessageId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/chat?chatId=${chatId}&prev=${lastMessageId + 1}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const postChatAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/chat?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const replyChatAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/chat/reply-to-chat?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const reactChatAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/chat/react-to-chat?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getAllNotificationsAction =
  (type = 'get', notificationId, pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
        allNotifications,
      } = getState();
      const prevNotifications =
        allNotifications && allNotifications.notifications && allNotifications.notifications.data && allNotifications.notifications.data.length > 0 && pageNumber !== 1
          ? allNotifications.notifications.data
          : [];

      pageNumber === 1 ? dispatch({ type: ALL_NOTIFICATIONS_REQUEST }) : dispatch({ type: ALL_NOTIFICATIONS_REQUEST_LOAD_MORE });

      const config = {
        headers: {
          Authorization: `${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const uri =
        type === 'read'
          ? axios.patch(`${BASE_URL}/generic-notification/read-notification/${notificationId}`, {}, config)
          : axios.get(`${BASE_URL}/generic-notification/get-unread-notification?pageNumber=${pageNumber}`, config);

      const { data } = await uri;

      const newData = data && data.data;
      const mergedData = [...prevNotifications, ...newData];
      const returnData = { ...data, data: mergedData };
      dispatch({
        type: ALL_NOTIFICATIONS_SUCCESS,
        payload: returnData,
      });
    } catch (err) {
      dispatch({
        type: ALL_NOTIFICATIONS_FAIL,
        payload: err.response && err.response.data.message ? err.response.data.message : err.message,
      });
    }
  };

export const readAllNotificationsAction = () => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: ALL_NOTIFICATIONS_REQUEST,
    // });

    const {
      userLogin: { userInfo },
      allNotifications,
    } = getState();
    const prevNotifications = allNotifications?.notifications;

    if (prevNotifications && prevNotifications.data && prevNotifications.data.length > 0) {
      const notificationIds = prevNotifications.data.map(notification => notification._id);
      const config = {
        headers: {
          Authorization: userInfo.token,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.patch(`${BASE_URL}/generic-notification/read-notification`, { notificationIds }, config);

      // const updatedNotifications = prevNotifications.data.map(notification => ({
      //   ...notification,
      //   isRead: true,
      // }));

      // const returnData = { ...prevNotifications, data: updatedNotifications, count: 0 };

      dispatch({
        type: ALL_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: ALL_NOTIFICATIONS_SUCCESS,
        payload: allNotifications,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_NOTIFICATIONS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
