import React, { useState } from "react";
import Styles from "./PressableButton.module.css";

const PressableButton = ({ name, onClick, icon, style, disabled }) => {
	const [isPressed, setIsPressed] = useState(false);

	const handlePress = () => {
		setIsPressed(true);
	};

	const handleRelease = () => {
		setIsPressed(false);
	};

	// console.log(disabled)

	return (
		<button
			className={`${Styles.pressable_button} ${
				isPressed ? Styles.pressed : ""
			} ${!!disabled ? Styles.disabled : null}`}
			onMouseDown={handlePress}
			onMouseUp={handleRelease}
			onMouseLeave={handleRelease}
			onClick={onClick}
			disabled={disabled}
			style={style}
		>
			{name}
			{icon ? (
				<svg className={"iconDash"} style={{ background: "white" }}>
					<use xlinkHref={`/sprite.svg#icon-${icon}`} />
				</svg>
			) : (
				""
			)}
		</button>
	);
};

export default PressableButton;
