import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../components/Skeletons/ShareFileSkeleton';
import { COPY_FILE_EXCHANGE_RESET } from '../../redux/constants/folderConstants';
import SelectBox from '../../components/Modal/SelectBox';

const CopyFileModal = ({ data }) => {
	const dispatch = useDispatch();
	const {
		copyFileModal,
		setCopyFileModal,
		copyFilePasswordInput,
		setCopyFilePasswordInput,
		foldersList,
		handleCopyFile
	} = data;

	const copyFile = useSelector((state) => state.copyFile);

	useEffect(
		() => {
			if (copyFile && !copyFile.loading && copyFile.error) {
				setCopyFilePasswordInput((prevData) => ({ ...prevData, error: copyFile.error }));
				toast.error(copyFile.error);
			}
		},
		[ copyFile, setCopyFilePasswordInput ]
	);

	const onModalClose = () => {
		setCopyFileModal({ file: {}, status: false });
		setCopyFilePasswordInput({ value: '', error: '' });

		dispatch({ type: COPY_FILE_EXCHANGE_RESET });
	};

	return (
		<Modal show={copyFileModal.status}>
			{copyFile && copyFile.loading ? (
				<ShareFileSkeleton fileProtected={copyFileModal.file.protected} />
			) : (
				<Fragment>
					<ModalHeading heading={`Copy ${copyFileModal.file.name}?`} />
					<ModalForm style={{ marginBottom: '2.5rem' }}>
						<InputsSection style={{ width: '34rem' }}>
							<SelectBox
								label='Select destination folder'
								value={copyFilePasswordInput.value}
								onChange={(event) => {
									setCopyFilePasswordInput({ value: event.target.value, error: '' });
								}}
								errorMessage={copyFilePasswordInput.error}
								style={{ width: '30rem' }}>
								<option value=''>Select destination folder</option>
								{foldersList &&
								foldersList.folders &&
								foldersList.folders.data &&
								foldersList.folders.data.data &&
								foldersList.folders.data.data.length > 0 ? (
									foldersList.folders.data.data.map((folder) => (
										<Fragment key={folder._id}>
											<option value={folder._id} style={{ fontWeight: '800' }}>
												{folder.name}
											</option>
											{folder.childFolders &&
												folder.childFolders.length > 0 &&
												folder.childFolders.map((childFolder) => (
													<option value={childFolder._id} key={childFolder._id}>
														{childFolder.name}
													</option>
												))}
										</Fragment>
									))
								) : (
									<option value=''>No folders created</option>
								)}
							</SelectBox>
						</InputsSection>
						<ModalButtons
							submitButtonLabel='Copy File'
							onSubmit={handleCopyFile}
							cancelButtonLabel='Cancel'
							onCancel={onModalClose}
						/>
					</ModalForm>
				</Fragment>
			)}
		</Modal>
	);
};

export default CopyFileModal;
