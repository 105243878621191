import React from 'react';
import ButtonStyles from './Button.module.css';

const Button = ({ label, onClick, style, span = true, disabled = false, active = false }) => {
	return (
		<button
			className={!active ? ButtonStyles.button : ButtonStyles.button_active}
			onClick={onClick}
			disabled={disabled}
			style={style}>
			{label} {span && <span />}
		</button>
	);
};

export default Button;
