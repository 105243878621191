import React from 'react'
import ComposeButtonstyles from "./ComposeButton.module.css"
import Button from '../../../components/Modal/Button'

const ComposeButton = ({label , icon , onClick, style}) => {
  return (
    <button className={ComposeButtonstyles.button} onClick={onClick} style={style}>
    {label} 
    <svg width="15" height= "15" className="icon-svg">
         <use xlinkHref={`/assets/sprite.svg#${icon}`} />
    </svg>
    </button>
  )
}

export default ComposeButton