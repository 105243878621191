import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../components/Skeletons/ShareFileSkeleton';
import { inputPhoneMasking, unMasking, validateEmail, validatePhone } from '../../utils/validators';

const ShareFileForESignModal = ({ data }) => {
  const dispatch = useDispatch();
  const { shareFileForESignModal, setShareFileForESignModal, shareFileForESignInputs, setShareFileForESignInputs, handleShareFileForESign } = data;

  const shareFile = useSelector(state => state.shareFile);

  const onModalClose = () => {
    setShareFileForESignModal({ file: {}, status: false });
    setShareFileForESignInputs({ email: { value: '', error: '' }, fullName: { value: '', error: '' }, phone: { value: '', error: '' } });
  };

  const handleShareFile = () => {
    if (!validateEmail(shareFileForESignInputs.email.value)) {
      setShareFileForESignInputs(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid email' } }));
    } else if (shareFileForESignInputs.createAccount && !shareFileForESignInputs.fullName.value) {
      setShareFileForESignInputs(prevVal => ({ ...prevVal, fullName: { ...prevVal.fullName, error: 'Please enter full name' } }));
    } else if (shareFileForESignInputs.createAccount && !validatePhone(unMasking(shareFileForESignInputs.phone.value))) {
      setShareFileForESignInputs(prevVal => ({ ...prevVal, phone: { ...prevVal.phone, error: 'Please enter phone number' } }));
    } else {
      handleShareFileForESign();
    }
  };

  return (
    <Modal show={shareFileForESignModal.status}>
      {shareFile && shareFile.loading ? (
        <ShareFileSkeleton fileProtected={shareFileForESignModal.file.protected} />
      ) : (
        <React.Fragment>
          <ModalHeading heading={`Share ${shareFileForESignModal.file.name} for e-signature?`} />
          <ModalForm style={{ marginBottom: '2.5rem' }}>
            <InputsSectionColumn style={{ width: '34rem' }}>
              <InputBox
                label={`Enter email to share file`}
                type='text'
                placeholder='email@example.com'
                value={shareFileForESignInputs.email.value}
                onChange={event => {
                  setShareFileForESignInputs(prevVal => ({ ...prevVal, email: { value: event.target.value, error: '' } }));
                }}
                errorMessage={shareFileForESignInputs.email.error}
                style={{ width: '30rem' }}
              />
              <div className='flex'>
                <label htmlFor='create-user'>Create account for this user</label>
                <label className='switch'>
                  <input
                    id='create-user'
                    checked={shareFileForESignInputs.createAccount}
                    onChange={e => {
                      setShareFileForESignInputs(prevVal => ({
                        ...prevVal,
                        createAccount: !prevVal.createAccount,
                        fullName: { value: '', error: '' },
                        phone: { value: '', error: '' },
                      }));
                    }}
                    type='checkbox'
                    className='checkbox'
                    name='create'
                  />
                  <span className='slider round' />
                </label>
              </div>
            </InputsSectionColumn>
            {shareFileForESignInputs.createAccount && (
              <InputsSectionColumn style={{ width: '34rem' }}>
                <InputBox
                  label='Full Name'
                  type='text'
                  placeholder='John Smith'
                  value={shareFileForESignInputs.fullName.value}
                  onChange={event => {
                    setShareFileForESignInputs(prevVal => ({ ...prevVal, fullName: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={shareFileForESignInputs.fullName.error}
                  style={{ width: '30rem' }}
                />
                <InputBox
                  label='Phone Number'
                  type='text'
                  placeholder='999-999-9999'
                  value={shareFileForESignInputs.phone.value}
                  onChange={event => {
                    setShareFileForESignInputs(prevVal => ({ ...prevVal, phone: { value: inputPhoneMasking(event.target.value), error: '' } }));
                  }}
                  errorMessage={shareFileForESignInputs.phone.error}
                  style={{ width: '30rem' }}
                />
              </InputsSectionColumn>
            )}

            <ModalButtons submitButtonLabel='Share for e-sign' onSubmit={handleShareFile} cancelButtonLabel='Cancel' onCancel={onModalClose} />
          </ModalForm>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default ShareFileForESignModal;
