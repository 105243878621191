import React from 'react';
import Dropzone from 'react-dropzone';
import fileUploadBox from './FileUpload.module.css';

const FileUpload = ({
  onChange,
  errorMessage,
  label,
  label2,
  label2Span,
  icon,
  image,
  style,
}) => {
  return (
    <Dropzone onDrop={onChange}>
      {({ getRootProps, getInputProps }) => (
        <div>
          <div className='modalContainer__form--sections'>
            <div className={fileUploadBox.form_input} style={style}>
              <label
                htmlFor='uploadFiles'
                className='btn-file'
                {...getRootProps()}
              >
                {image ? (
                  <img
                    alt='image'
                    src={image}
                    className='fileUpload__label--image'
                  />
                ) : (
                  <span>
                    <svg className='fileUpload__label--icon'>
                      <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
                    </svg>{' '}
                    <br />
                    {label}
                    {label2 && (
                      <p className={fileUploadBox.fileUpload__label_two}>
                        {label2}
                      </p>
                    )}
                    {label2Span && (
                      <p className={fileUploadBox.fileUpload__label_twoSpan}>
                        {label2Span &&
                          label2Span.split(',').length > 0 &&
                          label2Span
                            .split(',')
                            .map((text, index) => (
                              <span key={index + text}>{text}</span>
                            ))}
                      </p>
                    )}
                  </span>
                )}
                <input {...getInputProps()} />
              </label>
            </div>
          </div>
          {errorMessage && (
            <p className={fileUploadBox.form_input__error}>{errorMessage}</p>
          )}
        </div>
      )}
    </Dropzone>
  );
};

{
  /* <div className='modalContainer__form--sections'>
  <div className={fileUploadBox.form_input} style={style}>
    <label htmlFor='uploadFiles' className='btn-file'>
      {image ? (
        <img alt='image' src={image} className='fileUpload__label--image' />
      ) : (
        <span>
          <svg className='fileUpload__label--icon'>
            <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
          </svg>{' '}
          <br />
          {label}
          {label2 && (
            <p className={fileUploadBox.fileUpload__label_two}>{label2}</p>
          )}
          {label2Span && (
            <p className={fileUploadBox.fileUpload__label_twoSpan}>
              {label2Span &&
                label2Span.split(',').length > 0 &&
                label2Span
                  .split(',')
                  .map((text, index) => <span key={index + text}>{text}</span>)}
            </p>
          )}
        </span>
      )}
      <input
        type='file'
        name='uploadFiles'
        id='uploadFiles'
        accept={accept}
        onChange={onChange}
        multiple={multiple}
      />
    </label>
  </div>
</div>;
{
  errorMessage && (
    <p className={fileUploadBox.form_input__error}>{errorMessage}</p>
  );
} */
}

export default FileUpload;
