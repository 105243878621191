import React from 'react';
import ButtonSkeleton from './ButtonSkeleton';
import ModalButtonsStyles from '../Modal/ModalButtons/ModalButtons.module.css';
import SkeletonStyles from './Skeletons.module.css';

const ModalButtonsSkeleton = () => {
	return (
		<div className={ModalButtonsStyles.buttons}>
			<ButtonSkeleton />
			<div className={[ SkeletonStyles.buttonCancel, SkeletonStyles.skeleton ].join(' ')} />
		</div>
	);
};

export default ModalButtonsSkeleton;
