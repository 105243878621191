import React, { useCallback } from 'react';
import Styles from './Styles.module.css'

const VideoSection = ({
  headerTitle,
  showNotes
}) => {

  const videoUrl = 'https://www.w3schools.com/html/mov_bbb.mp4';
  return (
    <div className={`${Styles.videoSection} ${!showNotes ? Styles.fullWidth : ''}`}>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
<video width="320" height="160" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
   </div>
  );
};

export default VideoSection;
