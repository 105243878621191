import React, { useCallback } from 'react';
import Styles from './Styles.module.css'

const Header = ({
  headerTitle,
  showNotes,
  setShowNotes
}) => {


  return (
    <div className={Styles.headerMain}>
      <div className='header__left'>
        {headerTitle && (
          <div className={Styles.header}>
           <img
            src='/assets/group.png'
            alt='User'
            className={Styles.chatLists_user_image}
          />
            <h1 className='header__title'>{headerTitle}</h1>
          </div>
        )}

      </div>

      <div className='header__right'>
      <div className='bottom_logo' style={{position: 'inherit'}}>
          <img src='/assets/logo-black.png' alt='Continia Logo' className='bottom_logo_image' style={{height: '2.4rem'}}/>
          <p className='bottom_logo_powered'>By The Algorithm</p>
        </div>
      </div>
      
    </div>
  );
};

export default Header;
