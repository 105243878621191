export const GET_SECURE_FILES_REQUEST = 'GET_SECURE_FILES_REQUEST';
export const GET_SECURE_FILES_SUCCESS = 'GET_SECURE_FILES_SUCCESS';
export const GET_SECURE_FILES_FAIL = 'GET_SECURE_FILES_FAIL';
export const GET_SECURE_FILES_RESET = 'GET_SECURE_FILES_RESET';

export const ADD_FILE_EXCHANGE_REQUEST = 'ADD_FILE_EXCHANGE_REQUEST';
export const ADD_FILE_EXCHANGE_PROGRESS = 'ADD_FILE_EXCHANGE_PROGRESS';
export const ADD_FILE_EXCHANGE_SUCCESS = 'ADD_FILE_EXCHANGE_SUCCESS';
export const ADD_FILE_EXCHANGE_FAIL = 'ADD_FILE_EXCHANGE_FAIL';
export const ADD_FILE_EXCHANGE_RESET = 'ADD_FILE_EXCHANGE_RESET';

export const DELETE_FILE_EXCHANGE_REQUEST = 'DELETE_FILE_EXCHANGE_REQUEST';
export const DELETE_FILE_EXCHANGE_SUCCESS = 'DELETE_FILE_EXCHANGE_SUCCESS';
export const DELETE_FILE_EXCHANGE_FAIL = 'DELETE_FILE_EXCHANGE_FAIL';
export const DELETE_FILE_EXCHANGE_RESET = 'DELETE_FILE_EXCHANGE_RESET';

export const DOWNLOAD_FILE_EXCHANGE_REQUEST = 'DOWNLOAD_FILE_EXCHANGE_REQUEST';
export const DOWNLOAD_FILE_EXCHANGE_PROGRESS = 'DOWNLOAD_FILE_EXCHANGE_PROGRESS';
export const DOWNLOAD_FILE_EXCHANGE_SUCCESS = 'DOWNLOAD_FILE_EXCHANGE_SUCCESS';
export const DOWNLOAD_FILE_EXCHANGE_FAIL = 'DOWNLOAD_FILE_EXCHANGE_FAIL';
export const DOWNLOAD_FILE_EXCHANGE_RESET = 'DOWNLOAD_FILE_EXCHANGE_RESET';

export const SHARE_FILE_EXCHANGE_REQUEST = 'SHARE_FILE_EXCHANGE_REQUEST';
export const SHARE_FILE_EXCHANGE_SUCCESS = 'SHARE_FILE_EXCHANGE_SUCCESS';
export const SHARE_FILE_EXCHANGE_FAIL = 'SHARE_FILE_EXCHANGE_FAIL';
export const SHARE_FILE_EXCHANGE_RESET = 'SHARE_FILE_EXCHANGE_RESET';

export const RENAME_FILE_EXCHANGE_REQUEST = 'RENAME_FILE_EXCHANGE_REQUEST';
export const RENAME_FILE_EXCHANGE_SUCCESS = 'RENAME_FILE_EXCHANGE_SUCCESS';
export const RENAME_FILE_EXCHANGE_FAIL = 'RENAME_FILE_EXCHANGE_FAIL';
export const RENAME_FILE_EXCHANGE_RESET = 'RENAME_FILE_EXCHANGE_RESET';

export const COPY_FILE_EXCHANGE_REQUEST = 'COPY_FILE_EXCHANGE_REQUEST';
export const COPY_FILE_EXCHANGE_SUCCESS = 'COPY_FILE_EXCHANGE_SUCCESS';
export const COPY_FILE_EXCHANGE_FAIL = 'COPY_FILE_EXCHANGE_FAIL';
export const COPY_FILE_EXCHANGE_RESET = 'COPY_FILE_EXCHANGE_RESET';

export const CUT_FILE_EXCHANGE_REQUEST = 'CUT_FILE_EXCHANGE_REQUEST';
export const CUT_FILE_EXCHANGE_SUCCESS = 'CUT_FILE_EXCHANGE_SUCCESS';
export const CUT_FILE_EXCHANGE_FAIL = 'CUT_FILE_EXCHANGE_FAIL';
export const CUT_FILE_EXCHANGE_RESET = 'CUT_FILE_EXCHANGE_RESET';

export const GET_ALL_FOLDER_DATA_REQUEST = 'GET_ALL_FOLDER_DATA_REQUEST';
export const GET_ALL_FOLDER_DATA_SUCCESS = 'GET_ALL_FOLDER_DATA_SUCCESS';
export const GET_ALL_FOLDER_DATA_FAIL = 'GET_ALL_FOLDER_DATA_FAIL';
export const GET_ALL_FOLDER_DATA_RESET = 'GET_ALL_FOLDER_DATA_RESET';

export const GET_ALL_LEVEL_FOLDER_DATA_REQUEST = 'GET_ALL_LEVEL_FOLDER_DATA_REQUEST';
export const GET_ALL_LEVEL_FOLDER_DATA_SUCCESS = 'GET_ALL_LEVEL_FOLDER_DATA_SUCCESS';
export const GET_ALL_LEVEL_FOLDER_DATA_FAIL = 'GET_ALL_LEVEL_FOLDER_DATA_FAIL';
export const GET_ALL_LEVEL_FOLDER_DATA_RESET = 'GET_ALL_LEVEL_FOLDER_DATA_RESET';

export const GET_FOLDER_DATA_REQUEST = 'GET_FOLDER_DATA_REQUEST';
export const GET_FOLDER_DATA_SUCCESS = 'GET_FOLDER_DATA_SUCCESS';
export const GET_FOLDER_DATA_FAIL = 'GET_FOLDER_DATA_FAIL';
export const GET_FOLDER_DATA_RESET = 'GET_FOLDER_DATA_RESET';

export const GET_SUB_FOLDER_DATA_REQUEST = 'GET_SUB_FOLDER_DATA_REQUEST';
export const GET_SUB_FOLDER_DATA_SUCCESS = 'GET_SUB_FOLDER_DATA_SUCCESS';
export const GET_SUB_FOLDER_DATA_FAIL = 'GET_SUB_FOLDER_DATA_FAIL';
export const GET_SUB_FOLDER_DATA_RESET = 'GET_SUB_FOLDER_DATA_RESET';

export const GET_SUB_SUB_FOLDER_DATA_REQUEST = 'GET_SUB_SUB_FOLDER_DATA_REQUEST';
export const GET_SUB_SUB_FOLDER_DATA_SUCCESS = 'GET_SUB_SUB_FOLDER_DATA_SUCCESS';
export const GET_SUB_SUB_FOLDER_DATA_FAIL = 'GET_SUB_SUB_FOLDER_DATA_FAIL';
export const GET_SUB_SUB_FOLDER_DATA_RESET = 'GET_SUB_SUB_FOLDER_DATA_RESET';

export const GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST = 'GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST';
export const GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS = 'GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS';
export const GET_SUB_SUB_SUB_FOLDER_DATA_FAIL = 'GET_SUB_SUB_SUB_FOLDER_DATA_FAIL';
export const GET_SUB_SUB_SUB_FOLDER_DATA_RESET = 'GET_SUB_SUB_SUB_FOLDER_DATA_RESET';

export const GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST = 'GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST';
export const GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS = 'GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS';
export const GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL = 'GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL';
export const GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET = 'GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET';

export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL';
export const CREATE_FOLDER_RESET = 'CREATE_FOLDER_RESET';

export const GET_FOLDER_REQUEST = 'GET_FOLDER_REQUEST';
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS';
export const GET_FOLDER_FAIL = 'GET_FOLDER_FAIL';
export const GET_FOLDER_RESET = 'GET_FOLDER_RESET';

export const GET_SUB_FOLDER_REQUEST = 'GET_SUB_FOLDER_REQUEST';
export const GET_SUB_FOLDER_SUCCESS = 'GET_SUB_FOLDER_SUCCESS';
export const GET_SUB_FOLDER_FAIL = 'GET_SUB_FOLDER_FAIL';
export const GET_SUB_FOLDER_RESET = 'GET_SUB_FOLDER_RESET';

export const GET_SUB_SUB_FOLDER_REQUEST = 'GET_SUB_SUB_FOLDER_REQUEST';
export const GET_SUB_SUB_FOLDER_SUCCESS = 'GET_SUB_SUB_FOLDER_SUCCESS';
export const GET_SUB_SUB_FOLDER_FAIL = 'GET_SUB_SUB_FOLDER_FAIL';
export const GET_SUB_SUB_FOLDER_RESET = 'GET_SUB_SUB_FOLDER_RESET';

export const GET_SUB_SUB_SUB_FOLDER_REQUEST = 'GET_SUB_SUB_SUB_FOLDER_REQUEST';
export const GET_SUB_SUB_SUB_FOLDER_SUCCESS = 'GET_SUB_SUB_SUB_FOLDER_SUCCESS';
export const GET_SUB_SUB_SUB_FOLDER_FAIL = 'GET_SUB_SUB_SUB_FOLDER_FAIL';
export const GET_SUB_SUB_SUB_FOLDER_RESET = 'GET_SUB_SUB_SUB_FOLDER_RESET';

export const GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST = 'GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST';
export const GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS = 'GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS';
export const GET_SUB_SUB_SUB_SUB_FOLDER_FAIL = 'GET_SUB_SUB_SUB_SUB_FOLDER_FAIL';
export const GET_SUB_SUB_SUB_SUB_FOLDER_RESET = 'GET_SUB_SUB_SUB_SUB_FOLDER_RESET';

export const EDIT_FOLDER_REQUEST = 'EDIT_FOLDER_REQUEST';
export const EDIT_FOLDER_SUCCESS = 'EDIT_FOLDER_SUCCESS';
export const EDIT_FOLDER_FAIL = 'EDIT_FOLDER_FAIL';
export const EDIT_FOLDER_RESET = 'EDIT_FOLDER_RESET';

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAIL = 'DELETE_FOLDER_FAIL';
export const DELETE_FOLDER_RESET = 'DELETE_FOLDER_RESET';
