import React, { Fragment } from 'react';

import InputBoxSkeleton from './InputBoxSkeleton';
import ButtonSkeleton from './ButtonSkeleton';

const LoginFormSkeleton = () => {
	return (
		<Fragment>
			<InputBoxSkeleton style={{ width: '100%' }} />
			<InputBoxSkeleton style={{ width: '100%' }} />
			<ButtonSkeleton />
		</Fragment>
	);
};

export default LoginFormSkeleton;
