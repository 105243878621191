import React from "react";
import MailBodystyles from "./MailBody.module.css"
const NoEmailSelected = () => {
  return (
    <div className={MailBodystyles.noContent} >
      <svg
        width="230"
        height="230"
        viewBox="0 0 230 230"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2210_755)">
          <path
            d="M206.553 38.3329C205.682 38.2432 204.804 38.2432 203.933 38.3329H25.0443C23.8978 38.3506 22.7589 38.5225 21.6582 38.844L113.978 130.78L206.553 38.3329Z"
            fill="#3C0016"
          />
          <path
            d="M216.008 47.2139L122.986 139.853C120.592 142.233 117.353 143.569 113.978 143.569C110.602 143.569 107.363 142.233 104.969 139.853L12.7778 47.9166C12.4944 48.9583 12.3441 50.0317 12.3306 51.1111V178.889C12.3306 182.278 13.6768 185.528 16.0731 187.924C18.4694 190.321 21.7194 191.667 25.1083 191.667H203.997C207.386 191.667 210.636 190.321 213.032 187.924C215.428 185.528 216.774 182.278 216.774 178.889V51.1111C216.724 49.78 216.465 48.465 216.008 47.2139ZM33.8611 178.889H24.9805V169.753L71.4276 123.689L80.436 132.697L33.8611 178.889ZM203.869 178.889H194.925L148.35 132.697L157.358 123.689L203.805 169.753L203.869 178.889Z"
            fill="#3C0016"
            fill-opacity="0.63"
          />
        </g>
        <defs>
          <clipPath id="clip0_2210_755">
            <rect width="230" height="230" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h1 className={MailBodystyles.noContentText}>Select an email to read</h1>
    </div>
  );
};

export default NoEmailSelected;
