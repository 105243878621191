import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteFileModal = ({ loading, data }) => {
	const { deleteFileModal, setDeleteFileModal, deleteFileInput, setDeleteFileInput, handleDeleteFile } = data;

	return (
		<Modal show={deleteFileModal.status}>
			<ModalHeading heading={`Delete ${deleteFileModal.file.name}?`} />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={handleDeleteFile}>
					<InputsSection style={{ width: '34rem' }}>
						<InputBox
							label={`Enter ${deleteFileModal.file.name} below to delete file`}
							placeholder={deleteFileModal.file.name}
							value={deleteFileInput.value}
							onChange={(event) => {
								setDeleteFileInput({ value: event.target.value, error: '' });
							}}
							errorMessage={deleteFileInput.error}
							style={{ width: '30rem' }}
						/>
						<p className='dangerText'>Please note that this file will not be restored in future after you delete.</p>
					</InputsSection>

					<ModalButtons
						submitButtonLabel='Delete File'
						onSubmit={handleDeleteFile}
						cancelButtonLabel='Cancel'
						onCancel={() => {
							setDeleteFileModal({ file: {}, status: false });
							setDeleteFileInput({ value: '', error: '' });
						}}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default DeleteFileModal;
