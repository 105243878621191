import React, { useState, useCallback } from 'react';
import Styles from './Styles.module.css'

const Footer = ({
  headerTitle,
  setShowNotes
}) => {

  const [myVdoStatus, setMyVdoStatus] = useState(true);
  const [userVdoStatus, setUserVdoStatus] = useState();
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [userMicStatus, setUserMicStatus] = useState();
  const [msgRcv, setMsgRcv] = useState('');
  const [screenShare, setScreenShare] = useState(false);

  return (
    <div className={Styles.footer} >
      <div className={Styles.controlGrid}>
        <div className={Styles.shareScreenSection}>
          <div className={Styles.controlSection}>
            <div className={Styles.iconSection}>
              <svg className='headerTextInput_icon'>
                <use xlinkHref={`/assets/sprite.svg#icon-add-participant`} />
              </svg>
            </div>
            <span className={Styles.controlsLabel}>Add Participants</span>
          </div>
          <div className={Styles.controlSection} style={{marginTop: '2px'}}>
            <div className={Styles.iconSection}  style={{marginBottom: '-6px'}}>
              <svg className='headerTextInput_icon' style={{
                height: '1.6rem',
                width: '2rem',
                
              }}>
                <use xlinkHref={`/assets/sprite.svg#icon-share-screen`} />
              </svg>
            </div>
            <span className={Styles.controlsLabel} style={{marginTop: '2px'}}>Share Screen</span>
          </div>

        </div>
        <div className={Styles.videoCallSection}>
        <div className='main_video_actions' style={{position: 'inherit'}}>
          <div className={Styles.main_video_action_btns} style={{position: 'inherit'}}>
            <div className={Styles.icon_circle}
            style={{ padding: '0.7rem'  }}
             onClick={() => 
              // updateMic()
              setMyMicStatus((prev) => !prev)
            }
              >
              <svg>
                <use
                  xlinkHref={`/assets/sprite.svg#icon-${
                    // 'unmute'
                    myMicStatus ? 'unmute' : 'mute'
                  }`}
                />
              </svg>
            </div>
            <div className={Styles.icon_circle}
            style={{ padding: '0.7rem'  }}
             onClick={() => 
              // updateVideo()
            setMyVdoStatus((prev) => !prev)
          }
             >
              <svg>
                <use
                  xlinkHref={`/assets/sprite.svg#icon-video-${
                    // 'on'
                    myVdoStatus ? 'on' : 'off'
                  }`}
                />
              </svg>
            </div>
            <div
              className={'icon_circle icon_red'}
              style={{ padding: '0.7rem'  }}
              // onClick={() => {
              //   if (callAccepted && !callEnded && userVideo) {
              //     setCallEndModalStatus(true);
              //   } else {
              //     leaveCall();
              //   }
              // }}
            >
              <svg style={{
                height: '1.4rem',
                width: '1.4rem'
              }}>
                <use xlinkHref={`/assets/sprite.svg#icon-hang-up`} />
              </svg>
            </div>
          </div>

        </div>
        </div>
        <div className={Styles.shareScreenSection}  onClick={() => setShowNotes((prev) => !prev)}>
          <div className={Styles.controlSection}>
            <div className={Styles.iconSection}>
              <svg className='headerTextInput_icon'>
                <use xlinkHref={`/assets/sprite.svg#icon-notes`} />
              </svg>
            </div>
            <span className={Styles.controlsLabel}>Notes</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
