import React, { useEffect, useRef } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import ModalButtons from '../../components/Modal/ModalButtons';
import Teams from '../../assets/teams.mp3';
import { useDispatch, useSelector } from 'react-redux';
import { CALL_ANSWER, CALL_DECLINE } from '../../redux/constants/userConstants';

const CallModal = ({ callModal, onDecline }) => {
	// const { deleteEmail, setDeleteEmail, deleteUserData } = data;

	const callReducer = useSelector((state) => state.callReducer);
	const Audio = useRef();
	const dispatch = useDispatch()
	useEffect(() => {
		if (callModal)
			Audio?.current?.play();
	}, [callModal]);
	return (
		<Modal show={callModal}>
			<audio src={Teams} loop ref={Audio} />
			<ModalHeading heading={`Incoming call from ${(callReducer && callReducer.data) ? callReducer.data.name : ''}`} />
			<ModalButtons
				submitButtonLabel='Answer'
				onSubmit={() => {
					Audio?.current?.pause();
					dispatch({ type: CALL_ANSWER, payload: callReducer.data })
				}}
				cancelButtonLabel='Decline'
				onCancel={() => {
					Audio?.current?.pause();
					dispatch({ type: CALL_DECLINE, payload: {} });
					onDecline()
				}}
			/>
		</Modal>
	);
};

export default CallModal;
