import axios from 'axios';
// import { BASE_URL } from './ip';
import {
  CLIENT_LOGO_REQUEST,
  CLIENT_LOGO_SUCCESS,
  CLIENT_LOGO_FAIL,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  GET_LOGGED_USER_DETAILS_RESET,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  SELECT_USER_REQUEST,
  SELECT_USER_SUCCESS,
  SELECT_USER_FAIL,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  GET_LOGGED_USER_DETAILS_REQUEST,
  GET_LOGGED_USER_DETAILS_SUCCESS,
  GET_LOGGED_USER_DETAILS_FAIL,
  CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST,
  CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS,
  CHANGE_LOGGED_IN_USER_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  VERIFY_PASSWORD_OTP_REQUEST,
  VERIFY_PASSWORD_OTP_SUCCESS,
  VERIFY_PASSWORD_OTP_FAIL,
  UPDATE_LOGGED_USER_DETAILS_REQUEST,
  UPDATE_LOGGED_USER_DETAILS_SUCCESS,
  UPDATE_LOGGED_USER_DETAILS_FAIL,
  DELETE_ME_REQUEST,
  DELETE_ME_SUCCESS,
  DELETE_ME_FAIL,
  ONLINE_USERS,
  ALL_NOTIFICATIONS_RESET,
  CLINIC_USER_COUNT_REQUEST,
  CLINIC_USER_COUNT_SUCCESS,
  CLINIC_USER_COUNT_FAIL,
  SET_LOGGED_USER_DETAILS_IN_USER_LOGIN,
  GET_ALL_LOGS_SUCCESS,
  GET_ALL_LOGS_FAIL,
  GET_ALL_LOGS_REQUEST,
} from '../constants/userConstants';
import {
  GET_FOLDER_DATA_RESET,
  GET_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET,
} from '../constants/folderConstants';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log(BASE_URL);
axios.defaults.withCredentials = true;
export const multiUserLogin = (token, password) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/token-generated-by-email`, { token, ciperText: password }, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    // getLoggedinUserAction();
    localStorage.setItem('userInfo', JSON.stringify(data));
    return data;
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const login = (email, password, ciperText) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/login`, { ciperText }, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // getLoggedinUserAction();
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const getClientLogoAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_LOGO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/users/get-logo`, config);

    dispatch({
      type: CLIENT_LOGO_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CLIENT_LOGO_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createNewUserAction = userData => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_NEW_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/`, userData, config);

    dispatch({
      type: CREATE_NEW_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_NEW_USER_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};
export const allocateUserSpaceAction = (userId, allowedStorage) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.patch(`${BASE_URL}/users/update/${userId}/storage`, { allowedStorage }, config);

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const getAllUsers =
  (page, limit, selectedClient = '', selectedRole = '', searchText) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ALL_USERS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      let uri =
        selectedClient === ''
          ? selectedRole === ''
            ? `${BASE_URL}/users?page=${page}&limit=${limit}`
            : `${BASE_URL}/users?page=${page}&limit=${limit}&role=${selectedRole}`
          : selectedRole === ''
          ? `${BASE_URL}/users?page=${page}&limit=${limit}&clinic=${selectedClient}`
          : `${BASE_URL}/users?page=${page}&limit=${limit}&clinic=${selectedClient}&role=${selectedRole}`;

      if (searchText) uri = uri + `&search=${searchText}`;
      // if (role) {
      // 	uri = `${BASE_URL}/users?page=${page}&role=${role}`;
      // }
      const { data } = await axios.get(uri, config);

      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_USERS_FAIL,
        payload: err.response && err.response.data.message ? err.response.data.message : err.message,
      });
    }
  };

export const getClinicUserCount = clientId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    dispatch({ type: CLINIC_USER_COUNT_REQUEST });
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/clinic/${clientId}/total-users`;
    const { data } = await axios.get(uri, config);

    dispatch({
      type: CLINIC_USER_COUNT_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err) {
    dispatch({
      type: CLINIC_USER_COUNT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const selectUserAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: SELECT_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/users/${id}`, config);

    dispatch({
      type: SELECT_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SELECT_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const updateUserAction = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.patch(`${BASE_URL}/users/${id}`, formData, config);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.msg : err.message,
    });
  }
};

export const toggleUserActive = (id, active) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_STATUS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.patch(`${BASE_URL}/users/${id}/toggleActive`, { active }, config);
    dispatch({
      type: UPDATE_USER_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER_STATUS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const deleteUserAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/users/${id}`, config);

    dispatch({ type: DELETE_USER_SUCCESS, payload: { status: 'Success' } });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const resetPasswordAction = (id, newPassword) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESET_USER_PASSWORD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.patch(`${BASE_URL}/users/${id}/reset-password`, { password: newPassword }, config);

    dispatch({
      type: RESET_USER_PASSWORD_SUCCESS,
      payload: { status: 'Success' },
    });
  } catch (err) {
    dispatch({
      type: RESET_USER_PASSWORD_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getLoggedinUserAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LOGGED_USER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/users/get-user`, config);
    dispatch({
      type: GET_LOGGED_USER_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch({
      type: SET_LOGGED_USER_DETAILS_IN_USER_LOGIN,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_LOGGED_USER_DETAILS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const updateLoggedinUserDetails = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_LOGGED_USER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.patch(`${BASE_URL}/users/updateMe`, formData, config);

    dispatch({
      type: UPDATE_LOGGED_USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_LOGGED_USER_DETAILS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const deleteMeAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ME_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/users/deleteMe`, config);

    dispatch({ type: DELETE_ME_SUCCESS });
  } catch (err) {
    dispatch({
      type: DELETE_ME_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

// export const unsubscribeClientAction = () => async (dispatch, getState) => {
//   try {
//     dispatch({ type: DELETE_ME_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//         'Content-Type': 'application/json',
//       },
//     };

//     await axios.get(`${BASE_URL}/users/onboard/cancel-subscription`, config);

//     dispatch({ type: DELETE_ME_SUCCESS });
//   } catch (err) {
//     dispatch({
//       type: DELETE_ME_FAIL,
//       payload: err.response && err.response.data.message ? err.response.data.message : err.message,
//     });
//   }
// };

export const changeLoggedInUserPasswordAction = (password, newPassword) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHANGE_LOGGED_IN_USER_PASSWORD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/updatePassword`, { password, newPassword }, config);

    dispatch({
      type: CHANGE_LOGGED_IN_USER_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CHANGE_LOGGED_IN_USER_PASSWORD_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const forgotPasswordAction = email => async dispatch => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/forget-password`, { email }, config);

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const verifyPasswordOtpAction = (email, otp, password) => async dispatch => {
  try {
    dispatch({ type: VERIFY_PASSWORD_OTP_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL}/users/verify-password`, { email, otp, password }, config);

    dispatch({
      type: VERIFY_PASSWORD_OTP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VERIFY_PASSWORD_OTP_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message,
    });
  }
};

export const checkTokenAction = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const { loggedinUser } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
        'Content-Type': 'application/json',
      },
    };

    let { data } = await axios.get(`${BASE_URL}/users/check-token`, config);
    dispatch(getLoggedinUserAction());
    data.user = loggedinUser;
    return data;
  } catch (err) {
    // dispatch(logout());
    return err.response && err.response.data ? err.response.data : err;
  }
};

export const getAllLogsAction =
  (page, tags = [], selectedClient = '', searchText, startDate, endDate) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ALL_LOGS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      let uri = `${BASE_URL}/logs?pageNumber=${page}`;

      if (tags.length) uri = uri + `&tags=${tags}`;
      if (selectedClient) uri = uri + `&client=${selectedClient}`;
      if (searchText) uri = uri + `&search=${searchText}`;
      if (startDate && endDate) uri = uri + `&startDate=${startDate}&endDate=${endDate}`;

      // console.log(startDate, endDate);

      const { data } = await axios.get(uri, config);

      dispatch({
        type: GET_ALL_LOGS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_LOGS_FAIL,
        payload: err.response && err.response.data.message ? err.response.data.message : err.message,
      });
    }
  };

export const getLogDetailsAction = id => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/logs/${id}`;
    const { data } = await axios.get(uri, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.msg ? err.response.data.msg : err.message;
  }
};

export const getAllLogsActionByModule = query => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.get(`${BASE_URL}/logs??module=file&search=${query}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getAllFilters = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/logs/filter`, config);
    return data?.data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const refreshUserAction = userId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const uri = userId ? `${BASE_URL}/folders/update-folder-id?id=${userId}` : `${BASE_URL}/folders/update-folder-id`;

    const { data } = await axios.put(uri, {}, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.msg ? err.response.data : err.message;
  }
};

const useLogout = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await axios.put(`${BASE_URL}/users/logout`, config);
    localStorage.clear();
  } catch (err) {
    return console.error(err);
  }
};

export const logout = () => dispatch => {
  useLogout();
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: GET_FOLDER_DATA_RESET });
  dispatch({ type: GET_SUB_FOLDER_DATA_RESET });
  dispatch({ type: GET_SUB_SUB_FOLDER_DATA_RESET });
  dispatch({ type: GET_SUB_SUB_SUB_FOLDER_DATA_RESET });
  dispatch({ type: GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET });
  dispatch({ type: GET_LOGGED_USER_DETAILS_RESET });
  dispatch({ type: ONLINE_USERS, payload: {} });
  dispatch({ type: ALL_NOTIFICATIONS_RESET });
};
