import React from 'react'
import TextInput from '../../../components/TextInput'
import styles from "./ComposeMail.module.css";
import InputBox from '../../../components/Modal/Email/InputBox';

const ComposeMail = () => {
  return (
    <div className={styles.Container}>
        <InputBox Text={"TO:"} customStyle={{backgroundColor : "white" , height: "3rem"}} />
        <InputBox Text={"CC:"} customStyle={{backgroundColor: "white", height: "3rem" }}/>
        <InputBox Text={"Subject:"} customStyle={{backgroundColor: "white" , height: "3rem"}}/>
        <InputBox type='textArea' placeholder={"Type your email...."}/>
    </div>
  )
}

export default ComposeMail