import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const useApi = ({ url, method = 'GET', headerContentType = 'application/json' }) => {
  const [loading, setLoading] = useState(false);

  const makeApiCall = useCallback(
    async input => {
      setLoading(true);
      try {
        const config = {
          url: `${process.env.REACT_APP_BASE_URL + url}`,
          method,
          data: input,
          headers: {
            'Content-Type': headerContentType,
          },
        };

        // console.log(config);
        const response = await axios(config);

        if (response) {
          return { response: response.data, loading, error: undefined };
        } else {
          return {
            response: undefined,
            loading: false,
            error: { message: 'Error fetching Data', statusCode: 404 },
          };
        }
      } catch (e) {
        console.log(e);

        return {
          response: undefined,
          loading,
          error: e.response && e.response && e.response.data ? e.response.data : e,
        };
      } finally {
        setLoading(false);
      }
    },
    [loading, method, url, headerContentType]
  );

  return { loading, makeApiCall };
};

export default useApi;
