import React, { useState } from 'react'
import BaseModal from '../../../../components/BaseModal/BaseModal'
import Modal from '../../../../components/Modal'
import Style from './InviteUserModal.module.css'
import ModalHeading from '../../../../components/Modal/ModalHeading'
import InviteUserInput from './InviteUserInput/InviteUserInput'
import Button from '../../../../components/Button'
import ModalButtons from './ModalButtons/ModalButtons'

const InviteUserModal = ({ showModal, setShowModal }) => {

    const [selectedOption, setSelectedOption] = useState('email');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <BaseModal showModal={showModal} className={Style.inviteModal} overlayStyle={Style.overlayStyle}>
            <div className={Style.modalHeading}>
                <span className={Style.heading}>Send Group Invite for <span style={{ fontWeight: '800' }}>“Cliniq“</span></span>
                <svg className={Style.closeIcon} onClick={() => setShowModal(false)}>
                    <use xlinkHref={`/assets/sprite.svg#${'icon-invite-user-close'}`} />
                </svg>
            </div>
            <div className={Style.modalContainer}>
                <div className={Style.optionContainer}>
                    <label className={Style.radio_label}>
                        <input
                            type="radio"
                            value="email"
                            checked={selectedOption === 'email'}
                            onChange={handleOptionChange}
                        />
                        <span className={Style.radio_custom}></span>
                        Email
                    </label>

                    <label className={Style.radio_label}>
                        <input
                            type="radio"
                            value="textMessage"
                            checked={selectedOption === 'textMessage'}
                            onChange={handleOptionChange}
                        />
                        <span className={Style.radio_custom}></span>
                        Text Message
                    </label>

                </div>
                <div>
                    <InviteUserInput email={selectedOption === 'email' ? true : false} />
                </div>
                <div>
                <ModalButtons
							submitButtonLabel={'SEND INVITE'}
							// onSubmit={handleRemoveUser}
							cancelButtonLabel='Cancel'
							onCancel={() => setShowModal(false)}
						/>
                </div>
            </div>
        </BaseModal >
    )
}

export default InviteUserModal
