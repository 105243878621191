import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import LogStyles from './Logs.module.css';
import { useDispatch } from 'react-redux';
import { getLogDetailsAction } from '../../redux/actions/userActions';

const ViewLogModal = ({ logDetails, handleCloseLogModal }) => {
  const dispatch = useDispatch();
  const [logData, setLogData] = React.useState();

  const getLogData = async id => {
    const data = await dispatch(getLogDetailsAction(id));
    setLogData(data);
  };

  // React.useEffect(() => {
  //   console.log(logData);
  // }, [logData]);

  React.useEffect(() => {
    if (logDetails.data._id) {
      getLogData(logDetails.data._id);
    }
  }, [logDetails.data]);

  return (
    <Modal show={logDetails.status}>
      <ModalHeading heading='Log Content' />
      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <InputsSection style={{ width: '45rem' }}>
          <div className={LogStyles.logData}>
            {logData && logData.data && logData.data.content && (
              <>
                {logData.data.type === 'login' || logData.data.type === 'login-by-token' ? (
                  <div>
                    <p>
                      Email: <span>{logData.data.content.email}</span>
                    </p>
                    <p>
                      Name: <span>{logData.data.content.name}</span>
                    </p>
                    <p>
                      Role: <span>{logData.data.content.role}</span>
                    </p>
                  </div>
                ) : logData.data.type === 'download-file' ? (
                  logData.data.content.file && (
                    <div>
                      <p>
                        File Name: <span>{logData.data.content.file.name}</span>
                      </p>
                      <p>
                        File Uploaded On: <span>{new Date(logData.data.content.file.updatedAt).toLocaleString()}</span>
                      </p>
                    </div>
                  )
                ) : logData.data.type === 'reset-password' ? (
                  <div>
                    <p>
                      Action Performed: <span>{logDetails.data.action}</span>
                    </p>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </InputsSection>
        <ModalButtons cancelButtonLabel='Cancel' onCancel={handleCloseLogModal} />
      </ModalForm>
    </Modal>
  );
};

export default ViewLogModal;
