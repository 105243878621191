import axios from 'axios';
import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

const useGetIpAddress = () => {
  const [myIPAddress, setMyIPAddress] = React.useState();

  const { loading: shareForESignLoading, makeApiCall } = useApi({
    url: `/sign-pdf/get-my-ip`,
    method: 'GET',
  });

  const getMyIP = async () => {
    const { response, error } = await makeApiCall();
    if (response?.status === 'success') {
      setMyIPAddress(response.data.ip);
    }

    if (!response?.status === 'fail' && response?.msg) {
      toast.error(response.message);
      setMyIPAddress(undefined);
    }
    if (error) {
      toast.error(error.msg);
      setMyIPAddress(undefined);
    }
  };

  React.useEffect(() => {
    getMyIP();
  }, []);

  return { shareForESignLoading, myIPAddress, getMyIP };
};

export default useGetIpAddress;
