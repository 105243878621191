import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Styles from './/VideoCall.module.css'
import Header from './Header/Header';
import Footer from './Footer/Footer';
import VideoSection from './VideoSection/VideoSection';
import NotesSection from './NotesSection/NotesSection';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { BASE_SOCKET_URL } from '../../redux/actions/ip';

const GroupVideoCallScreen = ({ socket }) => {
  const params = useParams();
  const [showNotes, setShowNotes] = useState(false);
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);

  const roomID = '';

  const videoConstraints = {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2
  };

  useEffect(() => {
    socketRef.current = io.connect(BASE_SOCKET_URL);
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      userVideo.current.srcObject = stream;
      socketRef.current.emit("join room", roomID);
      socketRef.current.on("all users", users => {
        const peers = [];
        users.forEach(userID => {
          const peer = createPeer(userID, socketRef.current.id, stream);
          peersRef.current.push({
            peerID: userID,
            peer,
          });
          peers.push(peer);
        });
        setPeers(peers);
      });

      socketRef.current.on("user joined", payload => {
        const peer = addPeer(payload.signal, payload.callerID, stream);
        peersRef.current.push({
          peerID: payload.callerID,
          peer,
        });
        setPeers(users => [...users, peer]);
      });

      socketRef.current.on("receiving returned signal", payload => {
        const item = peersRef.current.find(p => p.peerID === payload.id);
        item.peer.signal(payload.signal);
      });
    }).catch(err => {
      console.error('Error accessing media devices:', err);
    });;
  }, []);

  const createPeer = (userToSignal, callerID, stream) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      socketRef.current.emit("sending signal", { userToSignal, callerID, signal });
    });

    return peer;
  };

  const addPeer = (incomingSignal, callerID, stream) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      socketRef.current.emit("returning signal", { signal, callerID });
    });

    peer.signal(incomingSignal);

    return peer;
  };

  return (
    <div className={Styles.mainContent}>
      <Header
        setShowNotes={setShowNotes}
        showNotes={showNotes}
        headerTitle={'Product Scrum'}
      />
      <div className={Styles.videoNotesContainer}>
        <VideoSection showNotes={showNotes} />
        {showNotes &&
          <NotesSection
              setShowNotes={setShowNotes}
              showNotes={showNotes}
          />}
      </div>
      <Footer headerTitle={'Product Scrum'} setShowNotes={setShowNotes} />
    </div>
  );
};

export default GroupVideoCallScreen;
