import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../components/Skeletons/ShareFileSkeleton';
import { RENAME_FILE_EXCHANGE_RESET } from '../../redux/constants/folderConstants';

const RenameFileModal = ({ data }) => {
	const dispatch = useDispatch();
	const {
		renameFileModal,
		setRenameFileModal,
		renameFilePasswordInput,
		setRenameFilePasswordInput,
		handleRenameFile
	} = data;

	const renameFile = useSelector((state) => state.renameFile);

	useEffect(
		() => {
			if (renameFile && !renameFile.loading && renameFile.error) {
				setRenameFilePasswordInput((prevData) => ({ ...prevData, error: renameFile.error }));
				toast.error(renameFile.error);
			}
		},
		[ renameFile, setRenameFilePasswordInput ]
	);

	const onModalClose = () => {
		setRenameFileModal({ file: {}, status: false });
		setRenameFilePasswordInput({ value: '', error: '' });

		dispatch({ type: RENAME_FILE_EXCHANGE_RESET });
	};

	return (
		<Modal show={renameFileModal.status}>
			{renameFile && renameFile.loading ? (
				<ShareFileSkeleton fileProtected={renameFileModal.file.protected} />
			) : (
				<Fragment>
					<ModalHeading heading={`Rename ${renameFileModal.file.name}?`} />
					<ModalForm style={{ marginBottom: '2.5rem' }}>
						<InputsSection style={{ width: '34rem' }}>
							<InputBox
								label='File name'
								type='text'
								placeholder='File name'
								value={renameFilePasswordInput.value}
								onChange={(event) => {
									setRenameFilePasswordInput({ value: event.target.value, error: '' });
								}}
								errorMessage={renameFilePasswordInput.error}
								style={{ width: '30rem' }}
							/>
						</InputsSection>
						<ModalButtons
							submitButtonLabel='Rename File'
							onSubmit={handleRenameFile}
							cancelButtonLabel='Cancel'
							onCancel={onModalClose}
						/>
					</ModalForm>
				</Fragment>
			)}
		</Modal>
	);
};

export default RenameFileModal;
