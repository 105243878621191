import React from 'react';
import TextInputStyles from './TextInput.module.css';

const TextInput = ({
	type = 'text',
	label,
	icon,
	placeholder,
	value,
	onChange,
	validated = false,
	errorMessage = '',
	light = true,
	minLength = '0',
	maxLength = '50'
}) => {
	const [ inputType, setInputType ] = React.useState(type);

	const togglePassword = (e) => {
		e.preventDefault();
		if (inputType === 'text') {
			setInputType('password');
		} else {
			setInputType('text');
		}
	};
	return (
		<div className={TextInputStyles.loginContainer_textBox}>
			<label
				className={
					light ? TextInputStyles.loginContainer_textBox_label : TextInputStyles.loginContainer_textBox_label_dark
				}
				htmlFor={label}>
				{label}
			</label>
			<div
				className={
					light ? TextInputStyles.loginContainer_textBox_inputBox : TextInputStyles.loginContainer_textBox_inputBox_dark
				}>
				{icon && (
					<svg className={TextInputStyles.loginContainer_textBox_icon}>
						<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
					</svg>
				)}
				<input
					type={inputType}
					name={label}
					id={label}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className={TextInputStyles.loginContainer_textBox_input}
					minLength={minLength}
					maxLength={maxLength}
				/>
				{type === 'password' && (
					<button onClick={togglePassword} className={TextInputStyles.loginContainer_textBox_show_password}>
						<svg className={TextInputStyles.loginContainer_textBox_show_password_icon}>
							<use xlinkHref={`/assets/sprite.svg#icon-${inputType === 'text' ? 'visibility_off' : 'visibility'}`} />
						</svg>
					</button>
				)}
				{validated && (
					<svg className={TextInputStyles.loginContainer_textBox_icon_check}>
						<use xlinkHref={`/assets/sprite.svg#icon-check-alt`} />
					</svg>
				)}
			</div>
			{errorMessage && <p className={TextInputStyles.loginContainer_textBox_error}>{errorMessage}</p>}
		</div>
	);
};

export default TextInput;
