import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../components/Skeletons/ShareFileSkeleton';
import { CUT_FILE_EXCHANGE_RESET } from '../../redux/constants/folderConstants';
import SelectBox from '../../components/Modal/SelectBox';

const CutFileModal = ({ data }) => {
	const dispatch = useDispatch();
	const {
		cutFileModal,
		setCutFileModal,
		cutFilePasswordInput,
		setCutFilePasswordInput,
		foldersList,
		handleCutFile
	} = data;

	const cutFile = useSelector((state) => state.cutFile);

	useEffect(
		() => {
			if (cutFile && !cutFile.loading && cutFile.error) {
				setCutFilePasswordInput((prevData) => ({ ...prevData, error: cutFile.error }));
				toast.error(cutFile.error);
			}
		},
		[ cutFile, setCutFilePasswordInput ]
	);

	const onModalClose = () => {
		setCutFileModal({ file: {}, status: false });
		setCutFilePasswordInput({ value: '', error: '' });

		dispatch({ type: CUT_FILE_EXCHANGE_RESET });
	};

	return (
		<Modal show={cutFileModal.status}>
			{cutFile && cutFile.loading ? (
				<ShareFileSkeleton fileProtected={cutFileModal.file.protected} />
			) : (
				<Fragment>
					<ModalHeading heading={`Move ${cutFileModal.file.name}?`} />
					<ModalForm style={{ marginBottom: '2.5rem' }}>
						<InputsSection style={{ width: '34rem' }}>
							<SelectBox
								label='Select destination folder'
								value={cutFilePasswordInput.value}
								onChange={(event) => {
									setCutFilePasswordInput({ value: event.target.value, error: '' });
								}}
								errorMessage={cutFilePasswordInput.error}
								style={{ width: '30rem' }}>
								<option value=''>Select destination folder</option>
								{foldersList &&
								foldersList.folders &&
								foldersList.folders.data &&
								foldersList.folders.data.data &&
								foldersList.folders.data.data.length > 0 ? (
									foldersList.folders.data.data.map((folder) => (
										<Fragment key={folder._id}>
											<option
												value={folder._id}
												style={{ fontWeight: '800' }}
												disabled={
													folder._id === cutFileModal.file && cutFileModal.file.folder && cutFileModal.file.folder._id
												}>
												{folder.name}
											</option>
											{folder.childFolders &&
												folder.childFolders.length > 0 &&
												folder.childFolders.map((childFolder) => (
													<option
														value={childFolder._id}
														key={childFolder._id}
														disabled={
															childFolder._id === cutFileModal.file &&
															cutFileModal.file.folder &&
															cutFileModal.file.folder._id
														}>
														{childFolder.name}
													</option>
												))}
										</Fragment>
									))
								) : (
									<option value=''>No folders created</option>
								)}
							</SelectBox>
						</InputsSection>
						<ModalButtons
							submitButtonLabel='Move File'
							onSubmit={handleCutFile}
							cancelButtonLabel='Cancel'
							onCancel={onModalClose}
						/>
					</ModalForm>
				</Fragment>
			)}
		</Modal>
	);
};

export default CutFileModal;
