import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/userActions';
import { getAllNotificationsAction, readAllNotificationsAction } from '../../redux/actions/chatActions';
import { ALL_NOTIFICATIONS_SUCCESS } from '../../redux/constants/userConstants';

const Header = ({
  customHeader = false,
  customHeaderComponent,
  headerTitle,
  addBtn = false,
  addBtnTitle = 'Add',
  addBtnClick,
  addBtnIcon = 'plus-circle',
  searchBox = false,
  searchPlaceholder = 'Search here...',
  handleSearchChange,
  totalReult,
  dropdownFilter,
  dropdownName,
  dropdownOptions,
  dropdownOnChange,
  dropdownFilter2,
  dropdownName2,
  dropdownOptions2,
  dropdownOnChange2,
  setNotificationCheck,
  notificationCheck,
}) => {
  const dispatch = useDispatch();
  // const [notificationCheck, setNotificationCheck] = React.useState(false);
  const [totalNotificationPage, setTotalNotificationPage] = React.useState(1);
  const [currentNotificationPage, setCurrentNotificationPage] = React.useState(1);
  const allNotifications = useSelector(state => state.allNotifications);
  const userLogin = useSelector(state => state.userLogin);

  const handleFocus = () => {
    const headerTextInput = document.querySelector('.headerTextInput');
    const headerTextInputIcon = document.querySelector('.headerTextInput_icon');
    headerTextInput.style.border = '1px solid var(--grey)';
    headerTextInputIcon.style.fill = 'var(--black)';
  };

  const handleFocusOut = () => {
    const headerTextInput = document.querySelector('.headerTextInput');
    const headerTextInputIcon = document.querySelector('.headerTextInput_icon');
    headerTextInput.style.border = '1px solid var(--light-grey)';
    headerTextInputIcon.style.fill = 'var(--light-grey)';
  };

  React.useEffect(() => {
    if (allNotifications && allNotifications.notifications && allNotifications.notifications.data && allNotifications.notifications.data.length > 0) {
      const totalPages = Math.ceil(allNotifications.notifications.totalRecordCount / 20);
      setTotalNotificationPage(totalPages);
    }
  }, [allNotifications]);

  React.useEffect(() => {
    if (currentNotificationPage > 1) {
      dispatch(getAllNotificationsAction('get', '', currentNotificationPage));
    }
  }, [dispatch, currentNotificationPage]);

  const markasAllRead = useCallback(e => {
    e.stopPropagation();
    dispatch(readAllNotificationsAction());
    const prevNotifications = allNotifications?.notifications;
    const updatedNotifications =
      prevNotifications?.data &&
      prevNotifications.data.length &&
      prevNotifications.data.map(notification => ({
        ...notification,
        isRead: true,
      }));
    const returnData = { ...prevNotifications, data: updatedNotifications, count: 0 };
    dispatch({
      type: ALL_NOTIFICATIONS_SUCCESS,
      payload: returnData,
    });
  }, []);

  return (
    <div className='header'>
      <div className='header__left'>
        {headerTitle && (
          <div>
            <h1 className='header__title'>{headerTitle}</h1>
            {totalReult && <p className='headerResult'>{totalReult}</p>}
          </div>
        )}

        {customHeader && customHeaderComponent}

        {addBtn && (
          <button className='header__button' onClick={addBtnClick}>
            {addBtnTitle}
            <svg className='header__button_icon'>
              <use xlinkHref={`/assets/sprite.svg#icon-${addBtnIcon}`} />
            </svg>
          </button>
        )}
        {searchBox && (
          <div className='headerTextInput'>
            <svg className='headerTextInput_icon'>
              <use xlinkHref={`/assets/sprite.svg#icon-search`} />
            </svg>
            <input type='text' className='headerTextBox' placeholder={searchPlaceholder} onChange={handleSearchChange} onFocus={handleFocus} onBlur={handleFocusOut} />
          </div>
        )}

        {dropdownFilter && (
          <div className='headerTextInput'>
            <select name={dropdownName} id={dropdownName} className='headerTextBox' onChange={dropdownOnChange}>
              {dropdownOptions}
            </select>
          </div>
        )}
        {dropdownFilter2 && (
          <div className='headerTextInput'>
            <select name={dropdownName2} id={dropdownName2} className='headerTextBox' onChange={dropdownOnChange2}>
              {dropdownOptions2}
            </select>
          </div>
        )}
      </div>
      <div className='header__right'>
        <Link to='/my-profile' className='header__link'>
          {userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.name}
        </Link>
        {/* <Link to='/app-version' className='header__link'>
					App Version
				</Link> */}
        <div
          className='dropdown'
          onClick={e => {
            e.stopPropagation();
            setNotificationCheck(prev => !prev);
          }}
        >
          <div className='dropdown-a'>
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-bell`} />
            </svg>
            {allNotifications && allNotifications.notifications && allNotifications.notifications.count && allNotifications.notifications.count > 0 ? (
              <span>{allNotifications.notifications.count}</span>
            ) : null}
          </div>
          <input
            type='checkbox'
            checked={notificationCheck}
            onChange={() => {}}
            // onFocus={() => setNotificationCheck(!notificationCheck)}
          />
          <div className='dropdown-c'>
            <ul>
              <li className='downdown-c_heading'>
                <span className='downdown-c_head'>Notifications</span>
                <button onClick={markasAllRead}>Mark all as read</button>
              </li>
              {allNotifications && allNotifications.loading ? (
                <li>
                  <p className='dropdown_link'>
                    Loading...
                    <span className='dropdown_link_msg'>Please wait, we are fetching notfications</span>
                  </p>
                </li>
              ) : allNotifications && allNotifications.notifications && allNotifications.notifications.data && allNotifications.notifications.data.length > 0 ? (
                <li
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  {allNotifications.notifications.data.map((notification, index) => (
                    <Link
                      to='/chat-requests'
                      onClick={() => dispatch(getAllNotificationsAction('read', notification._id))}
                      className={notification.isRead ? 'dropdown_link' : 'dropdown_link unread'}
                      key={notification._id + index}
                    >
                      {notification.body}
                      {/* <span className='dropdown_link_msg'>Hi Anjani, How are you</span> */}
                      <span className='dropdown_link_time'>
                        {new Date(notification.createdAt).getDate() === new Date(Date.now()).getDate()
                          ? 'today'
                          : new Date(notification.createdAt).getDate() === new Date(Date.now()).getDate() - 1
                          ? 'yesterday'
                          : new Date(notification.createdAt).toLocaleDateString()}{' '}
                        at {new Date(notification.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')}
                      </span>
                    </Link>
                  ))}
                  {allNotifications && allNotifications.loadingMore ? (
                    <div className='dropdown_show_more'>
                      <div className='dot-stretching_dark' />
                    </div>
                  ) : (
                    totalNotificationPage > currentNotificationPage && (
                      <div
                        className='dropdown_show_more'
                        onClick={e => {
                          e.stopPropagation();
                          setCurrentNotificationPage(currentNotificationPage + 1);
                        }}
                      >
                        <button>Show More</button>
                      </div>
                    )
                  )}
                </li>
              ) : (
                <li>
                  <p className='dropdown_link'>No notifications</p>
                </li>
              )}
            </ul>
          </div>
        </div>

        <button className='header__logout' onClick={() => dispatch(logout())}>
          Logout
          <svg className='header__logout_icon'>
            <use xlinkHref={`/assets/sprite.svg#icon-sign-out`} />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Header;
