import React from 'react';
import { Link } from 'react-router-dom';

const FileUserSidebar = ({ currentMenu, clinicDetails, clientLogoLink, userLogin }) => {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link' style={{ marginTop: '2rem', width: '13rem', height: '5rem' }}>
          {clientLogoLink ? <img src={clientLogoLink} alt='logo' className='navigation__logo' /> : null}
        </li>

        {clinicDetails && clinicDetails.details && clinicDetails.details.modules && clinicDetails.details.modules.fileSharing && (
          <li className='navigation__list--item'>
            <Link to='/' className={currentMenu === 'secure-files' ? 'navigation__list--link-active' : 'navigation__list--link'}>
              <svg className={currentMenu === 'secure-files' ? 'navigation__icon-active' : 'navigation__icon'}>
                <use xlinkHref={`/assets/sprite.svg#icon-secure-file2`} />
              </svg>
              <span>
                Document
                <br /> Archive
              </span>
            </Link>
          </li>
        )}

        <li className='navigation__list--item'>
          <Link to='/audit-logs' className={currentMenu === 'logs' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'logs' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-document-text`} />
            </svg>
            <span>Audit Logs</span>
          </Link>
        </li>

        {userLogin &&
          userLogin.userInfo &&
          userLogin?.userInfo?.data &&
          userLogin?.userInfo?.data.user &&
          userLogin?.userInfo?.data.user.modules &&
          userLogin?.userInfo?.data.user.modules.chat && (
            <li className='navigation__list--item'>
              <Link to='/chat' className={currentMenu === 'chat' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'chat' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-chat-alt-stroke`} />
                </svg>
                <span>Chat</span>
              </Link>
            </li>
          )}

        {userLogin &&
          userLogin.userInfo &&
          userLogin?.userInfo?.data &&
          userLogin?.userInfo?.data.user &&
          userLogin?.userInfo?.data.user.modules &&
          userLogin?.userInfo?.data.user.modules.websiteForms && (
            <li className='navigation__list--item'>
              <Link to='/website-forms' className={currentMenu === 'website-forms' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'website-forms' ? 'navigation__icon-active' : 'navigation__icon'} style={{ height: '2.5rem', width: '2.5rem' }}>
                  <use xlinkHref={`/assets/sprite.svg#icon-file-text`} />
                </svg>
                <span>Website Forms</span>
              </Link>
            </li>
          )}

        {/* <li className='navigation__list--item'>
            <Link
              to='/email'
              className={
                currentMenu === 'chat'
                  ? 'navigation__list--link-active'
                  : 'navigation__list--link'
              }
            >
              <svg
                className={
                  currentMenu === 'chat'
                    ? 'navigation__icon-active'
                    : 'navigation__icon'
                }
              >
                <use xlinkHref={`/assets/sprite.svg#icon-email`} />
              </svg>
              <span>Email</span>
            </Link>
          </li> */}
        <li className='navigation__list--item'>
          <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-user`} />
            </svg>
            <span>My Profile</span>
          </Link>
        </li>
      </ul>

      <div>
        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. Continia
        </p>
      </div>
    </nav>
  );
};

export default FileUserSidebar;
