import React from 'react';
import NoDataStyles from './NoData.module.css';

const NoData = ({ title, imgStyle, src, textStyle }) => {
  return (
    <div className={NoDataStyles.noData}>
      <img style={imgStyle} src={src ? src : '/assets/no-data.png'} alt='No data' />
      <p style={textStyle}>{title}</p>
    </div>
  );
};

export default NoData;
