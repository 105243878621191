import React from 'react';
import ButtonStyles from './Button.module.css';

const Button = ({ label, onClick, span = true, disabled = false, style }) => {
	return (
		<button className={ButtonStyles.button} onClick={onClick} disabled={disabled} style={style}>
			{label} {span && <span />}
		</button>
	);
};

export default Button;
