import React, { Fragment } from "react";
import MailBodystyles from "./MailBody.module.css";
import ButtonIcon from "../../../components/Modal/ButtonIcon/ButtonIcon";
const MailBody = ({user, selected ,onClick, setdeleteMailModal}) => {
  const handleDeleteClick = () => {
    // Show the delete modal when the delete button is clicked
    setdeleteMailModal({ data : user, status: true });
    // console.log("clicked")
  };
  return (
    <Fragment>
      <div className={MailBodystyles.subject}>
        <h2 className="">{selected.email.subject}</h2>
      </div>
      <div className={MailBodystyles.emailBody}>
        <div className={MailBodystyles.content}>
          <div className={MailBodystyles.userDetails}>
            <img
              src="/assets/user-img.png"
              alt="User"
              className={MailBodystyles.user_image}
            />
            <div className={MailBodystyles.mail_recipents}>
              <h2>{selected.name}</h2>
              <h2 className={MailBodystyles.mail_recipent}>To: jane doe</h2>
            </div>
          </div>
          <div className={MailBodystyles.actions}>
            <ButtonIcon onClick={()=> handleDeleteClick({selected , status: true})} icon='trash'/>
            <ButtonIcon  icon="reply" />
            <ButtonIcon  icon="reply-all"/>
            <ButtonIcon icon="forward" />
          </div>
        </div>
        { selected.attachments && 
        <div className={MailBodystyles.attachment}>
          {selected.attachments && (
            <div className={MailBodystyles.attachmentCount}>
              {selected.attachmentCount} Attachments
            </div>
          )}
          {selected.attachments && (
            <div className={MailBodystyles.attachmentImages}>
              {selected.attachments.map((attachment, index) => (
                <div key={index} className={MailBodystyles.attachmentContainer}>
                  <img src={attachment.fileUrl} alt={attachment.fileName} />
                  <p className={MailBodystyles.attachmentFileName}>{attachment.fileName}</p>
                </div>
              ))}
            </div>
            
          )}
        </div>
        }
        <div className={MailBodystyles.emailBody}>
        {selected.email.body}
        </div>
      </div>
    </Fragment>
  );
};

export default MailBody;
