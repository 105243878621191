import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteFolderModal = ({ loading, data }) => {
	const { deleteFolderModal, setDeleteFolderModal, deleteFolderInput, setDeleteFolderInput, handleDeleteFolder } = data;

	return (
		<Modal show={deleteFolderModal.status}>
			<ModalHeading heading={`Delete ${deleteFolderModal.folder.name}?`} />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						<InputBox
							label={`Enter ${deleteFolderModal.folder.name} below to delete folder`}
							placeholder={deleteFolderModal.folder.name}
							value={deleteFolderInput.value}
							onChange={(event) => {
								setDeleteFolderInput({ value: event.target.value, error: '' });
							}}
							errorMessage={deleteFolderInput.error}
							style={{ width: '30rem' }}
						/>
						<p className='dangerText'>
							Please note that this will delete this folder entire data and will not be restored in future.
						</p>
					</InputsSection>

					<ModalButtons
						submitButtonLabel='Delete Folder'
						onSubmit={handleDeleteFolder}
						cancelButtonLabel='Cancel'
						onCancel={() => {
							setDeleteFolderModal({ folder: {}, status: false });
							setDeleteFolderInput({ value: '', error: '' });
						}}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default DeleteFolderModal;
