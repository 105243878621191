import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	// acceptRequestAction,
	// sendRequestAction,
	cancelRequestAction,
	getAllChatUserAction,
	getAllChatUserActionByNumber,
	getAllChatUserActionBySearch,
	getAllNotificationsAction,
	removeRequestAction,
} from "../../redux/actions/chatActions";

import Content from "../../components/Content";
import PendingChatUserSkeleton from "../../components/Skeletons/PendingChatUserSkeleton";
import PrevChatUserSkeleton from "../../components/Skeletons/PrevChatUserSkeleton";
import NoData from "../../components/NoData";
import ChatStyles from "./Chat.module.css";
import GroupSentList from "./User/GroupSentList";
import { successToast } from "../../utils/toast";
import { useHistory } from "react-router-dom";
import SuccessToast from "../../components/Toast/SuccessToast";
import ErrorToast from "../../components/Toast/ErrorToast";
import CircularLoader from "../../components/CircularLoader/CircularLoader";

const ChatRequestsScreen = ({ socket }) => {
	const dispatch = useDispatch();
	const [mySocketData, setMySocketData] = React.useState({});

	const [chatUserLoading, setChatUserLoading] = React.useState(false);
	const [chatUsers, setChatUsers] = React.useState([]);
	const [chatUserSearch, setChatUserSearch] = React.useState("");

	const [cancelUserId, setCancelUserId] = useState("");
	const [cancelRequestStatus, setCancelRequestStatus] = useState(false);

	const [pendingCircularLoader, setPendingCircularLoader] = useState(false);
	const [notAvailableCircularLoader, setNotAvailableCircularLoader] =
		useState(false);
	const [notAcceptedCircularLoader, setNotAcceptedCircularLoader] =
		useState(false);

	const [hasMorePendingData, setHasMorePendingData] = useState(true);
	const [isPendingSearching, setIsPendingSearching] = useState(false);
	const [pendingPage, setPendingPage] = useState(2);

	const [hasMoreAvailableData, setHasMoreAvailableData] = useState(true);
	const [isAvailableSearching, setIsAvailableSearching] = useState(false);
	const [availablePage, setAvailablePage] = useState(2);

	const [hasMoreNotAcceptedData, setHasMoreNotAcceptedData] = useState(true);
	const [isNotAcceptedSearching, setIsNotAcceptedSearching] = useState(false);
	const history = useHistory();
	const [notAcceptedPage, setNotAcceptedPage] = useState(2);

	const [showErrorToast, setShowErrorToast] = useState(false);
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");

	const [pendingChatUserLoading, setPendingChatUserLoading] =
		React.useState(false);
	const [pendingChatUsers, setPendingChatUsers] = React.useState({
		data: [],
	});
	const [pendingChatSearch, setPendingChatSearch] = React.useState("");
	const [pendingGroupChatSearch, setPendingGroupChatSearch] =
		React.useState("");

	const [prevSentRequestLoading, setPrevSentRequestLoading] =
		React.useState(false);
	const [prevSentRequests, setPrevSentRequests] = React.useState([]);
	const [prevSentSearch, setPrevSentSearch] = React.useState("");

	const [debouncedSearch, setDebouncedSearch] = useState("");
	const debounceDelay = 100;
	const debounceTimeoutRef = useRef(null);
	const [groupChatRequest, setGroupChatRequest] = React.useState([
		{
			name: "User 1",
		},
		{
			name: "User 2",
		},
		{
			name: "User 3",
		},
		{
			name: "User 4",
		},
	]);

	const userLogin = useSelector((state) => state.userLogin);
	const onlineUsers = useSelector((state) => state.onlineUsers);

	const clinicDetails = useSelector((state) => state.clinicDetails);

	const cardListRef1 = useRef(null);
	const cardListRef2 = useRef(null);
	const cardListRef3 = useRef(null);

	const getChatUsers = React.useCallback(async () => {
		setChatUserLoading(true);
		const data = await dispatch(
			getAllChatUserActionByNumber("notavailable", 1)
		);
		setChatUserLoading(false);
		setChatUsers(data);
	}, [dispatch]);

	const getPendingChatUsers = React.useCallback(async () => {
		setPendingChatUserLoading(true);
		const data = await dispatch(getAllChatUserActionByNumber("pending", 1));
		setPendingChatUserLoading(false);
		setPendingChatUsers(data);
	}, [dispatch]);

	const getPrevSendRequestData = React.useCallback(async () => {
		setPrevSentRequestLoading(true);
		const data = await dispatch(
			getAllChatUserActionByNumber("notaccepted", 1)
		);
		setPrevSentRequestLoading(false);
		setPrevSentRequests(data);
	}, [dispatch]);

	React.useEffect(() => {
		getChatUsers();
		getPendingChatUsers();
		getPrevSendRequestData();
	}, []);

	React.useEffect(() => {
		const mySocket =
			onlineUsers.onlineUsers &&
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			Object.values(onlineUsers.onlineUsers).find(
				(onlineUser) =>
					onlineUser["userId"] === userLogin.userInfo.data.user._id
			);
		// onlineUsers.onlineUsers.filter((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id)[0];
		setMySocketData(mySocket);
	}, [onlineUsers, userLogin]);

	React.useEffect(() => {
		if (socket) {
			socket.on("sendRequest", (response) => {
				getPendingChatUsers();
				getChatUsers();
			});

			socket.on("sendRequestSuccess", (response) => {
				getChatUsers();
				getPendingChatUsers();
				getPrevSendRequestData();
			});
			socket.on("acceptRequest", () => {
				getPrevSendRequestData();
			});
			socket.on("acceptRequestSuccess", () => {
				getPendingChatUsers();
			});
			socket.on("removeRequest", () => {
				getPendingChatUsers();
			});
			socket.on("cancelRequest", () => {
				getPrevSendRequestData();
				getChatUsers();
			});

			//group request events
			socket.on("acceptGroupRequestSuccess", (response) => {
				if (response.data.status) {
					setToastMessage("Request Accepted");
					setShowSuccessToast(true);
				} else {
					setToastMessage("Request not accepted try again !");
					setShowErrorToast(true);
				}
			});

			socket.on("cancelPendingRequestSelfSuccess", (response) => {
				if (response.data.status) {
					setToastMessage("Request Canceled");
					setShowSuccessToast(true);
				} else {
					setToastMessage("Request not accepted try again !");
					setShowErrorToast(true);
				}
			});

			socket.on("GroupRequestSendResponse", (response) => {
				if (response.data.status) {
					getChatUsers();
					getPendingChatUsers();
				}
			});
		}
	}, [
		socket,
		getChatUsers,
		getPrevSendRequestData,
		getPendingChatUsers,
		cancelUserId,
	]);

	const handleSendReqest = async (userId) => {
		if (
			socket &&
			mySocketData &&
			Object.keys(mySocketData).length > 0 &&
			mySocketData.socketId &&
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			onlineUsers &&
			onlineUsers.onlineUsers
		) {
			const toSocketId = Object.values(onlineUsers.onlineUsers).find(
				(onlineUser) => onlineUser["userId"] === userId
			);

			const clinic = userLogin.userInfo.data.user.clinic;

			const formData = {
				user: userId,
				myName: userLogin.userInfo.data.user.name,
				clinic,
				mySocketId: mySocketData.socketId,
			};
			if (toSocketId && toSocketId.socketId) {
				formData.toSocketId = toSocketId.socketId;
			}
			socket.emit("channelSendRequest", formData);
		} else {
			setToastMessage("Request not sent try again !");
			setShowErrorToast(true);
		}
		// const data = await dispatch(sendRequestAction(userId));

		// if (data && data.status === 'success') {
		// 	getChatUsers();
		// 	getPrevSendRequestData();
	};

	const handleAcceptRequest = async (userId) => {
		if (
			socket &&
			mySocketData &&
			Object.keys(mySocketData).length > 0 &&
			mySocketData.socketId &&
			onlineUsers &&
			onlineUsers.onlineUsers &&
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user
		) {
			// const userSocketId = onlineUsers.onlineUsers.filter((onlineUser) => onlineUser['userId'] === userId)[0];
			const toSocketId = Object.values(onlineUsers.onlineUsers).find(
				(onlineUser) => onlineUser["userId"] === userId
			);

			const formData = {
				user: userId,
				myName: userLogin.userInfo.data.user.name,
				mySocketId: mySocketData.socketId,
			};

			if (toSocketId && toSocketId.socketId) {
				formData.toSocketId = toSocketId.socketId;
			}
			socket.emit("channelAcceptRequest", formData);
		} else {
			setToastMessage("Request not accepted try again !");
			setShowErrorToast(true);
		}

		// const data = await dispatch(acceptRequestAction(userId));

		// if (data && data.status === 'success') {
		// 	getPendingChatUsers();
		// }
	};

	const handleAcceptGroupRequest = useCallback(
		async (groupId) => {
			if (
				socket &&
				mySocketData &&
				Object.keys(mySocketData).length > 0 &&
				mySocketData.socketId &&
				onlineUsers &&
				onlineUsers.onlineUsers &&
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.data &&
				userLogin.userInfo.data.user
			) {
				const formData = {
					groupId: groupId,
					mySocketId: mySocketData.socketId,
				};

				socket.emit("acceptGroupRequest", formData);
			}
			const updatedData = pendingChatUsers.data.filter(
				(dataObj) => dataObj._id !== groupId
			);
			setPendingChatUsers({ ...pendingChatUsers, data: updatedData });
		},
		[mySocketData, onlineUsers, pendingChatUsers, socket, userLogin]
	);

	const handleCancelGroupRequest = useCallback(
		async (userId) => {
			if (
				socket &&
				mySocketData &&
				Object.keys(mySocketData).length > 0 &&
				mySocketData.socketId &&
				onlineUsers &&
				onlineUsers.onlineUsers &&
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.data &&
				userLogin.userInfo.data.user
			) {
				const formData = {
					groupId: userId,
					mySocketId: mySocketData.socketId,
				};
				socket.emit("cancelPendingRequestSelf", formData);
			}
			const updatedData = pendingChatUsers.data.filter(
				(dataObj) => dataObj._id !== userId
			);
			setPendingChatUsers({ ...pendingChatUsers, data: updatedData });
		},
		[mySocketData, onlineUsers, pendingChatUsers, socket, userLogin]
	);

	const handleRemoveRequest = async (userId) => {
		const data = await dispatch(removeRequestAction(userId));

		if (onlineUsers && onlineUsers.onlineUsers) {
			const toSocketId = Object.values(onlineUsers.onlineUsers).find(
				(onlineUser) => onlineUser["userId"] === userId
			);

			if (toSocketId && toSocketId.socketId) {
				socket.emit("channelRemoveRequest", {
					toSocketId: toSocketId.socketId,
				});
			}
		}

		if (data && data.status === "success") {
			getChatUsers();
			getPrevSendRequestData();
		}
	};

	const handleCancelRequest = async (userId) => {
		const data = await dispatch(cancelRequestAction(userId));
		dispatch(getAllNotificationsAction("get", "", 1));

		if (onlineUsers && onlineUsers.onlineUsers) {
			const userSocketId = onlineUsers.onlineUsers.filter(
				(onlineUser) => onlineUser["userId"] === userId
			)[0];
			if (userSocketId && userSocketId.socketId) {
				socket.emit("channelCancelRequest", {
					toSocketId: userSocketId.socketId,
				});
			}
		}

		if (data && data.status === "success") {
			getPendingChatUsers();
			getChatUsers();
		}
	};

	const pendingSearch = useCallback(
		async (query) => {
			setIsPendingSearching(true);
			if (query.length > 0) {
				setPendingCircularLoader(true);
				const data = await dispatch(
					getAllChatUserActionBySearch("pending", query)
				);
				setTimeout(() => {
					setPendingCircularLoader(false);
				}, 2000);
				setPendingChatUsers(data);
			} else {
				getPendingChatUsers();
				setIsPendingSearching(false);
			}
		},
		[dispatch, getPendingChatUsers]
	);

	const notAvailableusersSearch = useCallback(
		async (query) => {
			setIsAvailableSearching(true);
			if (query.length > 0) {
				setNotAvailableCircularLoader(true);
				const data = await dispatch(
					getAllChatUserActionBySearch("notavailable", query)
				);
				setTimeout(() => {
					setNotAvailableCircularLoader(false);
				}, 2000);
				setChatUsers(data);
			} else {
				getChatUsers();
				setIsAvailableSearching(false);
			}
		},
		[dispatch, getChatUsers]
	);

	const notAcceptedUsersSearch = useCallback(
		async (query) => {
			setIsNotAcceptedSearching(true);
			if (query.length > 0) {
				setNotAcceptedCircularLoader(true);
				const data = await dispatch(
					getAllChatUserActionBySearch("notaccepted", query)
				);
				setTimeout(() => {
					setNotAcceptedCircularLoader(false);
				}, 2000);
				setPrevSentRequests(data);
			} else {
				getPrevSendRequestData();
				setIsNotAcceptedSearching(false);
			}
		},
		[dispatch, getPrevSendRequestData]
	);

	const fetchPendingData = useCallback(async () => {
		if (
			!pendingCircularLoader &&
			hasMorePendingData &&
			!isPendingSearching
		) {
			setPendingCircularLoader(true);
			const data = await dispatch(
				getAllChatUserActionByNumber("pending", pendingPage)
			);
			const newData = await data.data;
			if (newData.length > 0) {
				setPendingChatUsers((prevResults) => {
					const updatedData = {
						...prevResults,
						data: [...prevResults.data, ...newData],
					};
					return updatedData;
				});
				setPendingPage((prevPage) => prevPage + 1);
			}

			setPendingCircularLoader(false);
		}
	}, [
		pendingCircularLoader,
		dispatch,
		hasMorePendingData,
		isPendingSearching,
		pendingPage,
	]);

	const fetchNotAvailableData = useCallback(async () => {
		if (
			!notAvailableCircularLoader &&
			hasMoreAvailableData &&
			!isAvailableSearching
		) {
			setNotAvailableCircularLoader(true);
			const data = await dispatch(
				getAllChatUserActionByNumber("notavailable", availablePage)
			);
			const newData = await data.data;
			if (newData.length > 0) {
				setChatUsers((prevResults) => {
					const updatedData = {
						...prevResults,
						data: [...prevResults.data, ...newData],
					};
					return updatedData;
				});
				setAvailablePage((prevPage) => prevPage + 1);
			}

			setNotAvailableCircularLoader(false);
		}
	}, [
		notAvailableCircularLoader,
		hasMoreAvailableData,
		isAvailableSearching,
		dispatch,
		availablePage,
	]);

	const fetchNotAcceptedData = useCallback(async () => {
		if (
			!notAcceptedCircularLoader &&
			hasMoreNotAcceptedData &&
			!isNotAcceptedSearching
		) {
			setNotAcceptedCircularLoader(true);
			const data = await dispatch(
				getAllChatUserActionByNumber("notaccepted", pendingPage)
			);
			const newData = await data.data;
			if (newData.length > 0) {
				setPrevSentRequests((prevResults) => {
					const updatedData = {
						...prevResults,
						data: [...prevResults.data, ...newData],
					};
					return updatedData;
				});
				setNotAcceptedPage((prevPage) => prevPage + 1);
			}

			setNotAcceptedCircularLoader(false);
		}
	}, [
		notAcceptedCircularLoader,
		hasMoreNotAcceptedData,
		isNotAcceptedSearching,
		dispatch,
		pendingPage,
	]);

	const handlePendingScroll = useCallback(() => {
		if (cardListRef1.current) {
			const { scrollTop, clientHeight, scrollHeight } =
				cardListRef1.current;
			if (
				scrollTop + clientHeight >= scrollHeight - 1 &&
				hasMorePendingData
			) {
				fetchPendingData();
			}
		}
	}, [fetchPendingData, hasMorePendingData]);

	const handleNotAvailableUsersScroll = useCallback(() => {
		if (cardListRef2.current) {
			const { scrollTop, clientHeight, scrollHeight } =
				cardListRef2.current;
			if (
				scrollTop + clientHeight >= scrollHeight - 2 &&
				hasMoreAvailableData
			) {
				fetchNotAvailableData();
			}
		}
	}, [fetchNotAvailableData, hasMoreAvailableData]);

	const handleNotAcceptedUsersScroll = useCallback(() => {
		if (cardListRef3.current) {
			const { scrollTop, clientHeight, scrollHeight } =
				cardListRef3.current;
			if (
				scrollTop + clientHeight >= scrollHeight - 2 &&
				hasMoreNotAcceptedData
			) {
				fetchNotAcceptedData();
			}
		}
	}, [fetchNotAcceptedData, hasMoreNotAcceptedData]);

	React.useEffect(() => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			userLogin.userInfo.data.user.modules &&
			!userLogin.userInfo.data.user.modules.chat
		) {
			history.push("/");
		}
	}, [history, userLogin]);

	useEffect(() => {
		if (cardListRef1.current) {
			cardListRef1.current.addEventListener(
				"scroll",
				handlePendingScroll
			);
		}
		if (cardListRef2.current) {
			cardListRef2.current.addEventListener(
				"scroll",
				handleNotAvailableUsersScroll
			);
		}
		if (cardListRef3.current) {
			cardListRef3.current.addEventListener(
				"scroll",
				handleNotAcceptedUsersScroll
			);
		}

		return () => {
			if (cardListRef1.current) {
				cardListRef1.current.removeEventListener(
					"scroll",
					handlePendingScroll
				);
			}
			if (cardListRef2.current) {
				cardListRef2.current.removeEventListener(
					"scroll",
					handleNotAvailableUsersScroll
				);
			}
			if (cardListRef3.current) {
				cardListRef3.current.removeEventListener(
					"scroll",
					handleNotAcceptedUsersScroll
				);
			}
		};
	}, [
		hasMorePendingData,
		hasMoreNotAcceptedData,
		hasMoreAvailableData,
		handlePendingScroll,
		handleNotAvailableUsersScroll,
		handleNotAcceptedUsersScroll,
	]);

	return (
		<Content
			headerTitle="Chat"
			currentMenu="chat"
			addBtn={false}
			searchBox={false}
		>
			<div className={ChatStyles.content}>
				<div className={ChatStyles.chatLists}>
					<div className={ChatStyles.chatLists_tabs}>
						<Link
							to="/chat"
							className={ChatStyles.chatLists_tab_link}
						>
							All Chats
						</Link>
						<Link
							to="/chat-requests"
							className={[
								ChatStyles.chatLists_tab_link,
								ChatStyles.chatLists_tab_link_active,
							].join(" ")}
						>
							Chat Requests
						</Link>
					</div>
					<p className={ChatStyles.chatLists_req}>Chat Invites</p>
					<div className="headerTextInput">
						<svg className="headerTextInput_icon">
							<use xlinkHref={`/assets/sprite.svg#icon-search`} />
						</svg>
						<input
							type="text"
							className="headerTextBox"
							placeholder="Search"
							value={pendingChatSearch}
							onChange={(e) => {
								setPendingChatSearch(e.target.value);
								pendingSearch(e.target.value);
							}}
						/>
					</div>

					<div
						className={ChatStyles.chatLists_users}
						ref={cardListRef1}
					>
						{pendingChatUserLoading ? (
							<PendingChatUserSkeleton />
						) : pendingChatUsers &&
						  pendingChatUsers.data &&
						  pendingChatUsers.data.length === 0 ? (
							<NoData title="No pending requests" />
						) : pendingChatUsers &&
						  pendingChatUsers.data &&
						  pendingChatUsers.data.length === 0 ? (
							<NoData title="No results found" />
						) : (
							pendingChatUsers &&
							pendingChatUsers.data &&
							pendingChatUsers.data
								.filter((user) => {
									return user.name
										.toLowerCase()
										.includes(
											pendingChatSearch.toLowerCase()
										);
								})
								.map((user) =>
									user.type !== "group" ? (
										<div
											key={user._id}
											className={
												ChatStyles.chatLists_user
											}
										>
											<div
												className={
													ChatStyles.chatLists_user_data
												}
											>
												<div
													className={
														ChatStyles.chatLists_user_imageBox
													}
												>
													<img
														src="/assets/indviual.png"
														alt="User"
														className={
															ChatStyles.chatLists_user_image
														}
													/>
												</div>
												<div>
													<h3
														className={
															ChatStyles.chatLists_user_name
														}
													>
														{user.name}
													</h3>
													<div
														className={
															ChatStyles.chatLists_user_req_btns
														}
													>
														<button
															className={
																ChatStyles.chatLists_user_acc_btn
															}
															onClick={() =>
																handleAcceptRequest(
																	user._id
																)
															}
														>
															Accept Request
														</button>
														<button
															className={
																ChatStyles.chatLists_user_can_btn
															}
															onClick={() =>
																handleCancelRequest(
																	user._id
																)
															}
														>
															Cancel Request
														</button>
													</div>
												</div>
											</div>
										</div>
									) : (
										<div
											key={user._id}
											className={
												ChatStyles.chatLists_user
											}
										>
											<div
												className={
													ChatStyles.chatLists_user_data
												}
											>
												<div
													className={
														ChatStyles.chatLists_user_imageBox
													}
												>
													<img
														src="/assets/icon-group-logo.jpg"
														alt="User"
														className={
															ChatStyles.chatLists_user_image
														}
													/>
												</div>
												<div>
													<h3
														className={
															ChatStyles.chatLists_user_name
														}
													>
														{user.name}
													</h3>
													<div
														className={
															ChatStyles.chatLists_user_req_btns
														}
													>
														<button
															className={
																ChatStyles.chatLists_user_acc_btn
															}
															onClick={() =>
																handleAcceptGroupRequest(
																	user?._id
																)
															}
														>
															Accept Request
														</button>
														<button
															className={
																ChatStyles.chatLists_user_can_btn
															}
															onClick={() =>
																handleCancelGroupRequest(
																	user?._id
																)
															}
														>
															Cancel Request
														</button>
													</div>
												</div>
											</div>
										</div>
									)
								)
						)}
						{pendingCircularLoader && (
							<div
								className={ChatStyles.circularLoader}
								style={{ marginBottom: "1rem" }}
							>
								<CircularLoader />
							</div>
						)}
					</div>
				</div>
				{!userLogin?.userInfo?.data?.user?.modules?.adminOnlyChat |
				(userLogin?.userInfo?.data?.user?.role === "clientAdmin") |
				(userLogin?.userInfo?.data?.user?.role === "fileAdmin") ? (
					<>
						<div
							className={ChatStyles.chatLists}
							style={{ marginTop: "7rem" }}
						>
							<p className={ChatStyles.chatLists_req}>
								Send chat requests
							</p>

							<div className="headerTextInput">
								<svg className="headerTextInput_icon">
									<use
										xlinkHref={`/assets/sprite.svg#icon-search`}
									/>
								</svg>
								<input
									type="text"
									className="headerTextBox"
									placeholder="Search"
									value={chatUserSearch}
									onChange={(e) => {
										setChatUserSearch(e.target.value);
										notAvailableusersSearch(e.target.value);
									}}
								/>
							</div>

							<div
								className={ChatStyles.chatLists_users}
								ref={cardListRef2}
							>
								{chatUserLoading ? (
									<PrevChatUserSkeleton />
								) : chatUsers &&
								  chatUsers.data &&
								  chatUsers.data.length === 0 ? (
									<NoData title="No users to send requests" />
								) : chatUsers &&
								  chatUsers.data &&
								  chatUsers.data.filter((user) =>
										user.name
											.toLowerCase()
											.includes(
												chatUserSearch.toLowerCase()
											)
								  ).length === 0 ? (
									<NoData title="No results found" />
								) : (
									chatUsers &&
									chatUsers.data &&
									chatUsers.data
										.filter((user) =>
											user.name
												.toLowerCase()
												.includes(
													chatUserSearch.toLowerCase()
												)
										)
										.map((user) => (
											<>
												<div
													key={user._id}
													className={
														ChatStyles.chatLists_user
													}
												>
													<div
														className={
															ChatStyles.chatLists_user_data
														}
													>
														<div
															className={
																ChatStyles.chatLists_user_imageBox
															}
														>
															<img
																src="/assets/indviual.png"
																alt="User"
																className={
																	ChatStyles.chatLists_user_image
																}
															/>
														</div>
														<div>
															<h3
																className={
																	ChatStyles.chatLists_user_name
																}
															>
																{user.name}
															</h3>
															<p
																className={
																	ChatStyles.chatLists_user_email
																}
															>
																{user.email}
															</p>
															<div
																className={
																	ChatStyles.chatLists_user_req_btns
																}
															>
																<button
																	className={
																		ChatStyles.chatLists_user_acc_btn
																	}
																	onClick={() =>
																		handleSendReqest(
																			user._id
																		)
																	}
																>
																	Send Request
																</button>
															</div>
														</div>
													</div>
												</div>
											</>
										))
								)}
								{notAvailableCircularLoader && (
									<div
										className={ChatStyles.circularLoader}
										style={{ marginBottom: "1rem" }}
									>
										<CircularLoader />
									</div>
								)}
							</div>
						</div>

						<div
							className={ChatStyles.chatLists}
							style={{ marginTop: "7rem" }}
						>
							<p className={ChatStyles.chatLists_req}>
								Sent Requests
							</p>

							<div className="headerTextInput">
								<svg className="headerTextInput_icon">
									<use
										xlinkHref={`/assets/sprite.svg#icon-search`}
									/>
								</svg>
								<input
									type="text"
									className="headerTextBox"
									placeholder="Search"
									value={prevSentSearch}
									onChange={(e) => {
										setPrevSentSearch(e.target.value);
										notAcceptedUsersSearch(e.target.value);
									}}
								/>
							</div>

							<div
								className={ChatStyles.chatLists_users}
								ref={cardListRef3}
							>
								{prevSentRequestLoading ? (
									<PrevChatUserSkeleton />
								) : prevSentRequests &&
								  prevSentRequests.data &&
								  prevSentRequests.data.length === 0 ? (
									<NoData title="No requests found" />
								) : prevSentRequests &&
								  prevSentRequests.data &&
								  prevSentRequests.data.length === 0 ? (
									<NoData title="No results found" />
								) : (
									prevSentRequests &&
									prevSentRequests.data &&
									prevSentRequests.data
										.filter((user) =>
											user.name
												.toLowerCase()
												.includes(
													prevSentSearch.toLowerCase()
												)
										)
										.map((user) => (
											<div
												key={user._id}
												className={
													ChatStyles.chatLists_user
												}
											>
												<div
													className={
														ChatStyles.chatLists_user_data
													}
												>
													<div
														className={
															ChatStyles.chatLists_user_imageBox
														}
													>
														<img
															src="/assets/indviual.png"
															alt="User"
															className={
																ChatStyles.chatLists_user_image
															}
														/>
													</div>
													<div>
														<h3
															className={
																ChatStyles.chatLists_user_name
															}
														>
															{user.name}
														</h3>
														<p
															className={
																ChatStyles.chatLists_user_email
															}
														>
															{user.email}
														</p>
														<div
															className={
																ChatStyles.chatLists_user_req_btns
															}
														>
															<button
																className={
																	ChatStyles.chatLists_user_acc_btn
																}
																onClick={() =>
																	handleRemoveRequest(
																		user._id
																	)
																}
															>
																Remove Request
															</button>
														</div>
													</div>
												</div>
											</div>
										))
								)}
								{notAcceptedCircularLoader && (
									<div
										className={ChatStyles.circularLoader}
										style={{ marginBottom: "1rem" }}
									>
										<CircularLoader />
									</div>
								)}
							</div>
						</div>
					</>
				) : null}
				<ErrorToast
					message={toastMessage}
					showToast={showErrorToast}
					onClose={() => setShowErrorToast(false)}
					duration={3500}
				/>
				<SuccessToast
					message={toastMessage}
					showToast={showSuccessToast}
					onClose={() => setShowSuccessToast(false)}
					duration={3500}
				/>
			</div>
		</Content>
	);
};

export default ChatRequestsScreen;
