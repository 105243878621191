import React from 'react';
import classes from '../Logs.module.css';
import TagButton from './TagButton';

const Tags = ({ selectedTags, setSelectedTags, setPageNumber }) => {
  const tags = [
    { id: 1, label: 'Login' },
    { id: 2, label: 'Folder' },
    { id: 3, label: 'File' },
    { id: 4, label: 'User' },
    { id: 5, label: 'Client' },
    { id: 6, label: 'Create' },
    { id: 7, label: 'Edit' },
    { id: 8, label: 'Delete' },
    { id: 9, label: 'Copy' },
    { id: 10, label: 'Move' },
    { id: 11, label: 'Share' },
    { id: 12, label: 'Download' },
    { id: 13, label: 'Password' },
  ];

  const handleAddTag = item => {
    if (!selectedTags.includes(item)) {
      setSelectedTags(prevVal => [...prevVal, item]);
    } else {
      setSelectedTags(prevVal => prevVal.filter(d => d !== item));
    }
    setPageNumber(1);
  };

  return (
    <div className={classes.filter_tags_box}>
      <p className={classes.filter_text_box_label}>Tags:</p>
      <div className='flex' style={{ flexWrap: 'wrap' }}>
        {tags
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
          .map(tag => (
            <TagButton key={tag.id} label={tag.label} active={selectedTags.includes(tag.label)} onClick={() => handleAddTag(tag.label)} />
          ))}
      </div>
    </div>
  );
};

export default Tags;
