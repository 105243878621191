import React from 'react';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const AddEditFolder = ({
  inputPlaceholder,
  inputValue,
  inputOnChange,
  submitBtnClick,
  cancelBtnClick,
}) => {
  return (
    <div className={FileExchangeStyles.addFolderInput}>
      <svg className={FileExchangeStyles.addFolderInputIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-folder`} />
      </svg>
      <input
        type='text'
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={inputOnChange}
        className={FileExchangeStyles.addFolderInputBox}
      />
      <button
        className={FileExchangeStyles.addFolderInputSubmitBtn}
        onClick={submitBtnClick}
      >
        <svg className={FileExchangeStyles.addFolderInputSubmitBtnIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-check`} />
        </svg>
      </button>
      <button
        className={FileExchangeStyles.addFolderInputCloseBtn}
        onClick={cancelBtnClick}
      >
        <svg className={FileExchangeStyles.addFolderInputCloseBtnIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-clear`} />
        </svg>
      </button>
    </div>
  );
};

export default AddEditFolder;
