import React from 'react';
import TextBoxStyles from '../InputBox/TextBox.module.css';

const TextArea = ({
  placeholder,
  value,
  onChange,
  errorMessage,
  label,
  style,
  disabled,
  defaultValue,
  rows = 2,
}) => {
  return (
    <div className={TextBoxStyles.form_input}>
      {label && (
        <label htmlFor={label} className={TextBoxStyles.form_input__label}>
          {label}
        </label>
      )}
      <div className={TextBoxStyles.form_input__container} style={style}>
        <textarea
          id={label}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={TextBoxStyles.form_input__box}
          rows={rows}
        />
      </div>
      {errorMessage && (
        <p className={TextBoxStyles.form_input__error}>{errorMessage}</p>
      )}
    </div>
  );
};

export default TextArea;
