import React from 'react';
import LoginScreenStyles from './LoginScreen.module.css';

const PromotionalCards = () => {
  const cardData = [
    {
      id: 1,
      icon: 'check-big',
      comingsoon: false,
      title: 'Secure File Transfer',
      subTitle:
        'Securely store, sync and share all your files. Everything you need is available on any device with access to the internet',
    },
    {
      id: 2,
      icon: 'chat-alt-stroke',
      comingsoon: false,
      title: 'Safe Messaging',
      subTitle:
        'Secure messaging platform where you create the perfect environment for sending, receiving or forwarding content.',
    },
    {
      id: 3,
      icon: 'video-camera',
      comingsoon: false,
      title: 'Encrypted Video Calling',
      subTitle:
        'Secure video calling with dynamic and automatic transcription.',
    },
  ];

  return (
    <div className={LoginScreenStyles.loginCards}>
      {cardData &&
        cardData.map((data) => (
          <div className={LoginScreenStyles.loginCard} key={data.id}>
            {data.comingsoon && (
              <p className={LoginScreenStyles.loginCard_coming_soon}>
                coming soon
              </p>
            )}
            <svg className={LoginScreenStyles.loginCard__icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${data.icon}`} />
            </svg>

            <h3 className={LoginScreenStyles.loginCard__title}>{data.title}</h3>
            <p className={LoginScreenStyles.loginCard__sub_title}>
              {data.subTitle}
            </p>
          </div>
        ))}
    </div>
  );
};

export default PromotionalCards;
