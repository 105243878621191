import React from 'react';
import classes from '../Logs.module.css';

const SelectBox = ({ id, label, value, onChange, children }) => {
  return (
    <div className={classes.filter_text_box}>
      <label htmlFor={label} className={classes.filter_text_box_label}>
        {label}
      </label>
      <div className={classes.filter_text_box_select_box}>
        <select name={label} id={id} value={value} onChange={onChange} className={classes.filter_text_box_select}>
          {children}
        </select>
      </div>
    </div>
  );
};

export default SelectBox;
