import React from 'react';
import classes from '../Logs.module.css';

const TagButton = ({ label, active, onClick }) => {
  return (
    <button className={active ? classes.tag_button_active : classes.tag_button} onClick={onClick}>
      {label}
    </button>
  );
};

export default TagButton;
