import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';

const UserQuotaReachedModal = ({ maxUserQuotaModal, handleMaxUserQuotaModalClose }) => {
	return (
		<Modal show={maxUserQuotaModal}>
			<ModalHeading heading='Reached Maximum User Limit' />
			<ModalForm style={{ marginBottom: '2.5rem' }}>
				<InputsSection style={{ width: '34rem' }}>
					<p className='primaryText'>
						You have reached maximum user quota in your account. Please delete any user or update your package to add
						more user.
					</p>
				</InputsSection>
				<ModalButtons cancelButtonLabel='Close' onCancel={handleMaxUserQuotaModalClose} />
			</ModalForm>
		</Modal>
	);
};

export default UserQuotaReachedModal;
