import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Content from '../../components/Content';
import VideoState from './context/VideoState';
import VideoScreen from './Video/Video';

const VideoCallScreen = ({ socket }) => {
  const params = useParams();
  useEffect(() => {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }, []);
  useEffect(() => {
    console.log(params);
  }, [params]);
  return (
    <Content
      noSidebar={true}
      headerTitle={'Video Call'}
      addBtn={false}
      addBtnTitle='New Call'
    >
      <VideoScreen data={params} socket={socket} />
    </Content>
  );
};

export default VideoCallScreen;
