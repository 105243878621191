import React from 'react';
import { Link } from 'react-router-dom';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const FileListView = ({ file }) => {
  const getFileIcon = fileName => {
    const fileSplit = fileName.split('.');
    const ext = fileSplit[fileSplit.length - 1];
    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'tif' || ext === 'gif') return 'file-image';
    else if (ext === 'doc' || ext === 'docx') return 'file-word';
    else if (ext === 'ppt' || ext === 'pptx') return 'file-ppt';
    else if (ext === 'csv' || ext === 'xlsx') return 'file-excel';
    else if (ext === 'mp4' || ext === 'webm') return 'file-video';
    else if (ext === 'mp3') return 'file-audio';
    else if (ext === 'pdf') return 'file-pdf';
    else if (ext === 'zip') return 'file-zip';
    else return 'file-unknown';
  };

  return (
    <Link to={`/files/${file._id}`} target='_blank' className={FileExchangeStyles.fileList}>
      <svg className={FileExchangeStyles.fileIconList}>
        <use xlinkHref={`/assets/sprite.svg#icon-${getFileIcon(file.name)}`} />
      </svg>
      <h4 className={FileExchangeStyles.fileNmme}>{file.name.length > 100 ? file.name.slice(0, 100) + '..' : file.name}</h4>
      {file.name.length > 10 && <p className={FileExchangeStyles.fileFullNmme}>{file.name}</p>}
    </Link>
  );
};

export default FileListView;
