import React from 'react';
import Styles from './BaseModal.module.css'

const BaseModal = ({ showModal, overlayStyle, onClose, children, className, style }) => {
  if (!showModal) return null;

  return (
    <div className={overlayStyle ? overlayStyle :  Styles.modal_overlay} >
      <div style={style} className={className ? className : Styles.modal_content} >
        {children}
      </div>
    </div>
  );
};

export default BaseModal;
