import {
  GET_SECURE_FILES_REQUEST,
  GET_SECURE_FILES_SUCCESS,
  GET_SECURE_FILES_FAIL,
  GET_SECURE_FILES_RESET,
  ADD_FILE_EXCHANGE_REQUEST,
  ADD_FILE_EXCHANGE_PROGRESS,
  ADD_FILE_EXCHANGE_SUCCESS,
  ADD_FILE_EXCHANGE_FAIL,
  ADD_FILE_EXCHANGE_RESET,
  DELETE_FILE_EXCHANGE_REQUEST,
  DELETE_FILE_EXCHANGE_SUCCESS,
  DELETE_FILE_EXCHANGE_FAIL,
  DELETE_FILE_EXCHANGE_RESET,
  DOWNLOAD_FILE_EXCHANGE_REQUEST,
  DOWNLOAD_FILE_EXCHANGE_SUCCESS,
  DOWNLOAD_FILE_EXCHANGE_FAIL,
  DOWNLOAD_FILE_EXCHANGE_RESET,
  SHARE_FILE_EXCHANGE_REQUEST,
  SHARE_FILE_EXCHANGE_SUCCESS,
  SHARE_FILE_EXCHANGE_FAIL,
  SHARE_FILE_EXCHANGE_RESET,
  RENAME_FILE_EXCHANGE_REQUEST,
  RENAME_FILE_EXCHANGE_SUCCESS,
  RENAME_FILE_EXCHANGE_FAIL,
  RENAME_FILE_EXCHANGE_RESET,
  COPY_FILE_EXCHANGE_REQUEST,
  COPY_FILE_EXCHANGE_SUCCESS,
  COPY_FILE_EXCHANGE_FAIL,
  COPY_FILE_EXCHANGE_RESET,
  CUT_FILE_EXCHANGE_REQUEST,
  CUT_FILE_EXCHANGE_SUCCESS,
  CUT_FILE_EXCHANGE_FAIL,
  CUT_FILE_EXCHANGE_RESET,
  GET_ALL_FOLDER_DATA_REQUEST,
  GET_ALL_FOLDER_DATA_SUCCESS,
  GET_ALL_FOLDER_DATA_FAIL,
  GET_ALL_FOLDER_DATA_RESET,
  GET_FOLDER_DATA_REQUEST,
  GET_FOLDER_DATA_SUCCESS,
  GET_FOLDER_DATA_FAIL,
  GET_FOLDER_DATA_RESET,
  GET_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_FOLDER_DATA_FAIL,
  GET_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_SUB_FOLDER_DATA_RESET,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL,
  GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAIL,
  CREATE_FOLDER_RESET,
  EDIT_FOLDER_REQUEST,
  EDIT_FOLDER_SUCCESS,
  EDIT_FOLDER_FAIL,
  EDIT_FOLDER_RESET,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAIL,
  DELETE_FOLDER_RESET,
  GET_FOLDER_REQUEST,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_FAIL,
  GET_FOLDER_RESET,
  GET_SUB_FOLDER_REQUEST,
  GET_SUB_FOLDER_SUCCESS,
  GET_SUB_FOLDER_FAIL,
  GET_SUB_FOLDER_RESET,
  GET_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_FOLDER_FAIL,
  GET_SUB_SUB_FOLDER_RESET,
  GET_SUB_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_SUB_FOLDER_FAIL,
  GET_SUB_SUB_SUB_FOLDER_RESET,
  GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST,
  GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS,
  GET_SUB_SUB_SUB_SUB_FOLDER_FAIL,
  GET_SUB_SUB_SUB_SUB_FOLDER_RESET,
  DOWNLOAD_FILE_EXCHANGE_PROGRESS,
  GET_ALL_LEVEL_FOLDER_DATA_FAIL,
  GET_ALL_LEVEL_FOLDER_DATA_REQUEST,
  GET_ALL_LEVEL_FOLDER_DATA_SUCCESS,
  GET_ALL_LEVEL_FOLDER_DATA_RESET,
} from '../constants/folderConstants';

export const getSecureFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SECURE_FILES_REQUEST:
      return { loading: true };

    case GET_SECURE_FILES_SUCCESS:
      return { files: action.payload, loading: false };

    case GET_SECURE_FILES_FAIL:
      return { loading: false, error: action.payload };

    case GET_SECURE_FILES_RESET:
      return {};

    default:
      return state;
  }
};

export const uploadFileReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case ADD_FILE_EXCHANGE_PROGRESS:
      return { files: action.payload, loading: true };

    case ADD_FILE_EXCHANGE_SUCCESS:
      return { files: action.payload, loading: false };

    case ADD_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case ADD_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case DELETE_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case DELETE_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const downloadFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case DOWNLOAD_FILE_EXCHANGE_PROGRESS:
      return { files: action.payload, loading: true };

    case DOWNLOAD_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case DOWNLOAD_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case DOWNLOAD_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const shareFileReducer = (state = {}, action) => {
  switch (action.type) {
    case SHARE_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case SHARE_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case SHARE_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case SHARE_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const renameFileReducer = (state = {}, action) => {
  switch (action.type) {
    case RENAME_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case RENAME_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case RENAME_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case RENAME_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const copyFileReducer = (state = {}, action) => {
  switch (action.type) {
    case COPY_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case COPY_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case COPY_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case COPY_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const cutFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CUT_FILE_EXCHANGE_REQUEST:
      return { loading: true };

    case CUT_FILE_EXCHANGE_SUCCESS:
      return { file: action.payload, loading: false };

    case CUT_FILE_EXCHANGE_FAIL:
      return { loading: false, error: action.payload };

    case CUT_FILE_EXCHANGE_RESET:
      return {};

    default:
      return state;
  }
};

export const foldersListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_ALL_FOLDER_DATA_SUCCESS:
      return { folders: action.payload, loading: false };

    case GET_ALL_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_ALL_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const foldersLevelListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_LEVEL_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_ALL_LEVEL_FOLDER_DATA_SUCCESS:
      return { folders: action.payload, loading: false };

    case GET_ALL_LEVEL_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_ALL_LEVEL_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const folderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_FOLDER_DATA_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const subFolderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_SUB_FOLDER_DATA_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const subSubFolderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_FOLDER_DATA_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const subSubSubFolderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_SUB_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_SUB_FOLDER_DATA_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_SUB_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_SUB_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const subSubSubSubFolderDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_SUB_SUB_FOLDER_DATA_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_SUB_SUB_FOLDER_DATA_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_SUB_SUB_FOLDER_DATA_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_SUB_SUB_FOLDER_DATA_RESET:
      return {};

    default:
      return state;
  }
};

export const createFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_FOLDER_REQUEST:
      return { loading: true };

    case CREATE_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case CREATE_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const editFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_FOLDER_REQUEST:
      return { loading: true };

    case EDIT_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case EDIT_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FOLDER_REQUEST:
      return { loading: true };

    case DELETE_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case DELETE_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const getFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FOLDER_REQUEST:
      return { loading: true };

    case GET_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case GET_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const getSubFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_FOLDER_REQUEST:
      return { loading: true };

    case GET_SUB_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const getSubSubFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_FOLDER_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};

export const getSubSubSubFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_SUB_FOLDER_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_SUB_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_SUB_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_SUB_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};
export const getSubSubSubSubFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUB_SUB_SUB_SUB_FOLDER_REQUEST:
      return { loading: true };

    case GET_SUB_SUB_SUB_SUB_FOLDER_SUCCESS:
      return { data: action.payload, loading: false };

    case GET_SUB_SUB_SUB_SUB_FOLDER_FAIL:
      return { loading: false, error: action.payload };

    case GET_SUB_SUB_SUB_SUB_FOLDER_RESET:
      return {};

    default:
      return state;
  }
};
