import React from 'react';
import classes from '../Logs.module.css';

const DateInput = ({ label, value, onChange, min, max }) => {
  return (
    <div className={classes.filter_text_box}>
      <label htmlFor={label} className={classes.filter_text_box_label}>
        {label}
      </label>
      <div className={classes.filter_text_box_select_box}>
        <input
          type='date'
          name={label}
          min={min ? min : undefined}
          max={max ? max : undefined}
          className={classes.filter_text_box_date}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default DateInput;
