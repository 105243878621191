import React from 'react';
import SkeletonStyles from './Skeletons.module.css';

const TableSkeleton = ({ columns = 10, rows = 8 }) => {
	const [ tableColumns, setTableColumns ] = React.useState([]);
	const [ tableRows, setTableRows ] = React.useState([]);

	React.useEffect(
		() => {
			const cols = [];

			for (let i = 0; i < columns; i++) {
				cols.push(i + 1);
			}

			setTableColumns(cols);
		},
		[ columns ]
	);

	React.useEffect(
		() => {
			const rowss = [];

			for (let i = 0; i < rows; i++) rowss.push(i + 1);

			setTableRows(rowss);
		},
		[ rows ]
	);

	return (
		tableColumns &&
		tableColumns.length > 0 &&
		tableColumns.map((column) => (
			<tr key={column}>
				{tableRows &&
					tableRows.length > 0 &&
					tableRows.map(
						(row, index) =>
							index === 0 ? (
								<td key={index + row}>
									<div className={[ SkeletonStyles.table__row_title, SkeletonStyles.skeleton ].join(' ')} />
								</td>
							) :
								<td key={index + row}>
									<div className={[ SkeletonStyles.table__row_title, SkeletonStyles.skeleton ].join(' ')} />
								</td>
						
					)}
			</tr>
		))
	);
};

export default TableSkeleton;
