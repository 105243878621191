import React from 'react';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const DeleteVideoNoteModal = ({
  loading,
  handleSubmit,
  videoNotesDeleteModal,
  handleModalClose,
}) => {
  return (
    <Modal show={videoNotesDeleteModal.status}>
      <ModalHeading heading={`Delete Video Note`} />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection style={{ width: '34rem' }}>
            <p className='primaryText'>Are you sure, you want to delete?</p>
            <p className='dangerText'>
              Please note that this will delete this note permanently and will
              not be restored in future.
            </p>
          </InputsSection>

          <ModalButtons
            submitButtonLabel='Delete Note'
            onSubmit={handleSubmit}
            cancelButtonLabel='Cancel'
            onCancel={handleModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default DeleteVideoNoteModal;
