import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

const useSignPDF = () => {
  const { loading: signPDFLoading, makeApiCall } = useApi({
    url: `/sign-pdf/addSignature`,
    method: 'POST',
  });

  const signPDF = async formData => {
    const { response, error } = await makeApiCall(formData);
    console.log(response, error);

    if (response?.status === 'success') {
      toast.success(response.msg);
      return response;
    }

    if (!response?.status === 'fail' && response?.msg) {
      toast.error(response.message);
      return response;
    }
    if (error) {
      toast.error(error.msg);
      return { status: 'fail' };
    }
  };

  return {
    signPDFLoading,
    signPDF,
  };
};

export default useSignPDF;
