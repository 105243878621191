import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Content from '../../../components/Content';
import { downloadFileAction, viewDownloadFileAction, viewFileAction } from '../../../redux/actions/folderActions';
import styles from './ViewFile.module.css';
import Button from '../../../components/Modal/Button';
import ViewFileSkeleton, { ViewSkeleton } from '../../../components/Skeletons/ViewFileSkeleton';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const ViewFile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [fileId, setFileId] = React.useState('');
  const [fileData, setFileData] = React.useState({
    status: false,
    type: '',
    url: '',
    name: '',
    contentKey: '',
    folder: '',
    uploadedBy: '',
    createdAt: new Date(),
  });

  const [fileDataTemp, setFileDataTemp] = React.useState('');
  const [fileDataTempType, setFileDataTempType] = React.useState('');
  const [fileDataLoading, setFileDataLoading] = React.useState(true);

  const downloadFile = useSelector(state => state.downloadFile);

  const getData = React.useCallback(
    async id => {
      const data = await dispatch(viewFileAction(id));
      // console.log("🚀 ~ ViewFile ~ data:", data)
      
      setFileData(data);
    },
    [dispatch]
  );

  const getDataTemp = React.useCallback(async () => {
    const response = await dispatch(
      viewDownloadFileAction({
        contentKey: fileData.contentKey,
        passwordProtected: false,
      })
    );
    // console.log(response.headers);
    const contentType = response.headers['Content-Type'] ? response.headers['Content-Type'] : response.headers['content-type'];
    setFileDataTempType(contentType);
    var reader = new FileReader();
    reader.onload = event => {
      // console.log(event.target.result);
      setFileDataTemp(event.target.result);
    };
    reader.readAsDataURL(response.data);
  }, [dispatch, fileData]);

  React.useEffect(() => {
    if (params.id) {
      setFileId(params.id);
    }
  }, [params]);

  React.useEffect(() => {
    if (fileId) {
      getData(fileId);
    }
  }, [fileId, getData]);

  React.useEffect(() => {
    if (fileData && fileData.contentKey !== '') {
      getDataTemp();
    }
  }, [fileData, getDataTemp]);

  const handleDownloadFile = React.useCallback(() => {
    const fileName = fileData.type ? fileData.name : fileData.name + '.zip';
    // console.log("🚀 ~ handleDownloadFile ~ fileName:", fileData.contentKey)
    dispatch(
      downloadFileAction(
        {
          contentKey: fileData.contentKey,
          passwordProtected: false,
        },
        fileName
      )
    );
  }, [dispatch, fileData]);

  const [newFileUrl, setNewFileUrl] = React.useState('');

  const b64toBlob = url => {
    fetch(url)
      .then(res => res.blob())
      .then(URL.createObjectURL)
      .then(ret => {
        // newFileUrl = ret;
        // return newFileUrl;
        setNewFileUrl(ret);
      });
    // .then(console.log);
  };

  React.useEffect(() => {
    if (fileDataTempType && fileDataTemp) {
      b64toBlob(fileDataTemp);
    }
  }, [fileDataTempType, fileDataTemp]);

  return (
    <Content headerTitle={'View File'} currentMenu='secure-files'>
      <Helmet>
        <title>{fileData.name ? fileData.name + ' - Continia Dashboard' : 'Loading - Continia Dashboard'}</title>
      </Helmet>
      {/* {downloadFile && downloadFile.loading && downloadFile.files && (
        <div className={FileExchangeStyles.fileupload_progress}>
          <p className={FileExchangeStyles.fileupload_progress_text}>{Math.floor((downloadFile.files.loaded * 100) / downloadFile.files.total)}% downloaded</p>
          <div className={FileExchangeStyles.fileupload_progress_bar}>
            <div style={{ width: Math.floor((downloadFile.files.loaded * 100) / downloadFile.files.total) + '%' }}></div>
          </div>
        </div>
      )} */}

      {fileData && !fileData.status ? (
        <ViewFileSkeleton />
      ) : (
        <div className={styles.content}>
          <div className={styles.left_content}>
            {fileDataTemp &&
              newFileUrl &&
              (fileData.type && fileDataTempType.startsWith('image/') ? (
                <img
                  src={fileDataTemp}
                  alt={fileData.name}
                  className={styles.imageFile}
                  onLoadStart={() => setFileDataLoading(true)}
                  onLoad={() => setFileDataLoading(false)}
                />
              ) : fileData.type && (fileDataTempType.startsWith('application/pdf') || fileDataTempType.startsWith('audio/')) ? (
                <iframe
                  // src={fileDataTemp}
                  src={newFileUrl}
                  type={fileDataTempType}
                  className={styles.card_pdf_frame}
                  download='Continia.pdf'
                  onLoadStart={() => setFileDataLoading(true)}
                  onLoad={() => setFileDataLoading(false)}
                />
              ) : fileData.type && fileDataTempType.startsWith('video/') ? (
                <video className={styles.card_video} controls onLoadedData={() => setFileDataLoading(false)}>
                  <source src={fileDataTemp} type={fileDataTempType} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                // ) : fileData.type && fileDataTempType.startsWith('audio/') ? (
                //   <audio controls onLoadedData={() => setFileDataLoading(false)}>
                //     <source src={fileDataTemp} type={fileDataTempType} />
                //     Your browser does not support the audio tag.
                //   </audio>
                <div className={styles.no_preview}>
                  <img src='/assets/no-file.png' alt='No preview' onLoad={() => setFileDataLoading(false)} />
                  <p>No Preview available</p>
                </div>
              ))}

            {fileDataLoading && <ViewSkeleton />}

            {/* <iframe
              src={fileDataTemp}
              type={fileDataTempType}
              className={styles.card_pdf_frame}
              title='Continia'
            /> */}
          </div>
          <div className={styles.right_content}>
            <h2 className={styles.right_content_title}>{fileData.name}</h2>
            <p className={styles.right_content_desc}>
              Uploaded by {fileData.uploadedBy} on{' '}
              {new Date(fileData.createdAt).toLocaleString('en-us', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                minute: '2-digit',
                hour: '2-digit',
              })}{' '}
              in {fileData.folder}
            </p>
            {!fileData?.type.startsWith('video/') && downloadFile && downloadFile.loading ? (
              <Button label='Downloading File' span={false} style={{ justifyContent: 'center', marginTop: '2rem' }} disabled={true} />
            ) : (
              !fileData?.type.startsWith('video/') && (
                <Button label='Download File' span={false} style={{ justifyContent: 'center', marginTop: '2rem' }} onClick={handleDownloadFile} />
              )
            )}
          </div>
        </div>
      )}
    </Content>
  );
};

export default ViewFile;
