import React from 'react';
import styles from './NoFile.module.css';

const NoFile = () => {
  return (
    <div className={styles.container}>
      <img src='/assets/no-files.jpg' alt='No File' />
      <h5>No files - yet!</h5>
      <p>Looks like you have not uploaded any file in this folder.</p>
    </div>
  );
};

export default NoFile;
