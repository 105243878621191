import React from 'react';
import NoContentStyles from './NoContent.module.css';

const NoContent = ({ title1, title2 }) => {
  return (
    <div className={NoContentStyles.noContent}>
      <svg className={NoContentStyles.noContent_icon}>
        <use xlinkHref={`/assets/sprite.svg#icon-file-text`} />
      </svg>
      <p className={NoContentStyles.noContent_text}>
        {title1 ? <span>{title1}</span> : <span>Sorry, we couldn't find any user data.</span>}
        {title2 ? title2 : 'We suggest you to add one.'}
      </p>
    </div>
  );
};

export default NoContent;
