import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import ResetPasswordSkeleton from '../../components/Skeletons/ResetPasswordSkeleton';

const ResetPasswordModal = ({
	resetPasswordModal,
	loading = false,
	handleResetPassword,
	handleResetPasswordModalClose,
	data
}) => {
	const { resetPassword, setResetPassword, resetPasswordConfirm, setResetPasswordConfirm, resetPasswordData } = data;
	return (
		<Modal show={resetPasswordModal}>
			<ModalHeading heading={`Reset Password of ${resetPasswordData.name}`} />
			{loading ? (
				<ResetPasswordSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection>
						<InputBox
							type='password'
							label='New Password'
							placeholder='Enter new password'
							value={resetPassword.value}
							onChange={(event) => {
								setResetPassword({ value: event.target.value, error: '' });
							}}
							errorMessage={resetPassword.error}
						/>
						<InputBox
							type='password'
							label='Confirm Password'
							placeholder='Enter password again'
							value={resetPasswordConfirm.value}
							onChange={(event) => {
								setResetPasswordConfirm({ value: event.target.value, error: '' });
							}}
							errorMessage={resetPasswordConfirm.error}
						/>
					</InputsSection>
					<ModalButtons
						submitButtonLabel='Reset Password'
						onSubmit={handleResetPassword}
						cancelButtonLabel='Cancel'
						onCancel={handleResetPasswordModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default ResetPasswordModal;
