import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';

const NewSecurityKeyModal = ({ securityKeyModal, loading, handleCreateKeyModalClose, handleCreateKey }) => {
	return (
		<Modal show={securityKeyModal}>
			<ModalHeading heading='Create New Security Key' />
			{loading ? (
				<DeleteModalSkeleton />
			) : (
				<ModalForm style={{ marginBottom: '2.5rem' }}>
					<InputsSection style={{ width: '34rem' }}>
						<p className='primaryText'>Do you want to create and use new security key to encrypt your messages?</p>
					</InputsSection>
					<ModalButtons
						submitButtonLabel='Create New'
						onSubmit={handleCreateKey}
						cancelButtonLabel='Cancel'
						onCancel={handleCreateKeyModalClose}
					/>
				</ModalForm>
			)}
		</Modal>
	);
};

export default NewSecurityKeyModal;
