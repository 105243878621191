import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseModal from '../../../../components/BaseModal/BaseModal';
import Styles from './GroupInfoModal.module.css'
import SearchBox from '../../User/SearchBox';
import UserGroupAddCard from '../../User/UserGroupAddCard';
import PressableButton from '../../../../components/NewButton';
import UserGroupInforCard from '../../User/UserGroupInforCard';
import NoData from '../../../../components/NoData';
import Toaster from '../../../../components/Toast/SuccessToast';
import SuccessToast from '../../../../components/Toast/SuccessToast';
import { getAllGroupUsersAction, getAllGroupUsersActionByNumber, getAllGroupUsersActionBySearch, getNonGroupUsersAction, getNonGroupUsersByNumberAction, getNonGroupUsersBySearchAction } from '../../../../redux/actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../User/ConfirmationModal';
import ErrorToast from '../../../../components/Toast/ErrorToast';
import GroupInfoUserInput from './GroupInfoUserInput/GroupInfoUserInput';
import Button from '../../../../components/Modal/Button';
import InviteUserModal from '../InviteUserModal/InviteUserModal';
import CircularLoader from '../../../../components/CircularLoader/CircularLoader';

const GroupInfoModal = ({
    showModal,
    setShowModal,
    acceptedUsers,
    setShowSelectedUser,
    groupId,
    socket,
    getAcceptedUsers,
    groupName,
    setGroupName
}) => {

    const [isChecked, setIsChecked] = useState(false);
    const [removeUserConfirmModal, setRemoveUserConfirmModal] = useState(false);
    const [leaveConfirmModal, setLeaveConfirmModal] = useState(false);
    const [circularLoader, setCircularLoader] = useState(false);
    const [adminControl, setAdminControl] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [loading, setLoading] = useState(true);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [nameEdit, setNameEdit] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [addUser, setAddUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [groupMembers, setGroupMembers] = useState([]);
    const [notAvailableUsers, setNotAvailableUsers] = useState([]);
    const [mySocketData, setMySocketData] = React.useState({});
    const [dynamicWidth, setDynmaicWidth] = useState(160)
    const [groupInfo, setGroupInfo] = React.useState({});
    const [inputGrouNameValue, setInputGrouNameValue] = useState(groupName);

    const [searchResults, setSearchResults] = useState([]);
    const [addUserSearchResults, setAddUserSearchResults] = useState([]);

    const [debouncedSearch, setDebouncedSearch] = useState('');
    const debounceDelay = 200;
    const debounceTimeoutRef = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [hasMoreNonGroupData, setHasMoreNonGroupData] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [isAddUserSearching, setIsAddUserSearching] = useState(false);
    const [page, setPage] = useState(2);
    const [nonGroupUserspage, setNonGroupUserspage] = useState(2);
    const pageSize = 10



    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const userData = Object.values(onlineUsers.onlineUsers).find((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id);
        const clinicId = userLogin.userInfo.data.user.clinic;
        socket.emit('sendMultipleRequest', {
            members: selectedItems,
            groupId: groupId,
            mySocketId: userData.socketId,
            // senderId: userData.senderId,
            clinicId: clinicId,
        },);

        setLoading(true);
        setTimeout(() => {
            onClose()
        }, 2000)
    }, [selectedItems])

    const userLogin = useSelector((state) => state.userLogin);
    const onlineUsers = useSelector((state) => state.onlineUsers);

    const adminId = useMemo(() => {
        if (groupMembers) {
            const admin = groupMembers?.filter((item) => item?.role === 'admin');
            return admin[0]?.hasOwnProperty('_id') ? admin[0]._id : null;
        }
    }, [groupMembers]);

    const dispatch = useDispatch();

    const [data, setData] = useState([
        {
            name: '',
            id: '1'
        },
        {
            name: '',
            id: '2'
        },
        {
            name: '',
            id: '3'
        },
        {
            name: '',
            id: '4'
        },
        {
            name: '',
            id: '5'
        }
    ])

    useEffect(() => {
        if (adminId && userLogin?.userInfo?.data?.user?._id === adminId) {
            setAdminControl(true);
        }
    }, [adminId, userLogin]);

    const getGroupUsers = React.useCallback(async () => {
        try {
            const groupData = await dispatch(getAllGroupUsersActionByNumber(groupId, 1));
            const members = groupData?.data ?? [];
            setGroupInfo(groupData);
            setGroupMembers(members);
            setSearchResults(members);
            if (members && members.length > 0) setLoading(false)
        } catch (error) {
            console.error('Error fetching group data:', error);
        }
    }, [dispatch, groupId]);


    const getNotAvailableUsers = React.useCallback(async () => {
        try {
            const data = await dispatch(getNonGroupUsersByNumberAction(groupId, 1));
            setNotAvailableUsers(data?.data);
            setAddUserSearchResults(data?.data)
        } catch (error) {
            console.error('Error fetching group data:', error);
        }
    }, [dispatch, groupId]);


    const handleRemoveUser = useCallback(() => {
        const userData = Object.values(onlineUsers.onlineUsers).find((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id);
        const clinicId = userLogin.userInfo.data.user.clinic;
        const formData = {
            groupId: groupId,
            userId: selectedUserId,
            clinicId: clinicId,
            mySocketId: userData.socketId,
        };
        const updatedMember = searchResults.filter((item) => item._id !== selectedUserId)
        setTimeout(() => setSearchResults(updatedMember), 1400)
        socket.emit('removedFromGroup', formData);
    }, [groupId, onlineUsers.onlineUsers, selectedUserId, socket, userLogin.userInfo.data.user._id, userLogin.userInfo.data.user.clinic])

    const handleLeaveGroup = useCallback(() => {
        const userData = Object.values(onlineUsers.onlineUsers).find((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id);
        const clinicId = userLogin.userInfo.data.user.clinic;
        const formData = {
            groupId: groupId,
            userId: userData?.userId,
            clinicId: clinicId,
            mySocketId: userData.socketId,
        };
        socket.emit('removedFromGroupSelf', formData);
        getAcceptedUsers();
        setShowSelectedUser(false);
        setLeaveConfirmModal(false);
        setShowModal(false)
    }, [getAcceptedUsers, groupId, onlineUsers.onlineUsers, setShowModal, setShowSelectedUser, socket, userLogin.userInfo.data.user._id, userLogin.userInfo.data.user.clinic])

    useEffect(() => {
        getGroupUsers();
    }, []);

    useEffect(() => {
        getNotAvailableUsers()
    }, [addUser])

    React.useEffect(
        () => {
            if (socket) {
                socket.on('removedFromGroupSuccess', (response) => {
                    if (response.data.status) {
                        setToastMessage('Removing User')
                        setShowSuccessToast(true);
                        getGroupUsers();
                        const updatedMmebers = (() => groupMembers.filter(
                            (item) => item._id !== selectedUserId)
                        )
                        setGroupMembers(updatedMmebers);
                        setSearchQuery('');
                        getAcceptedUsers()
                    }
                    else {
                        setToastMessage('Request failed try again !')
                        setShowErrorToast(true);
                        getGroupUsers();
                    }
                });

                socket.on('removedFromGroupSelfSuccess', (response) => {
                    if (response.data.status) {
                        getAcceptedUsers();
                        setToastMessage('You have left the group')
                        setShowSuccessToast(true);
                        setShowSelectedUser(false);
                    }
                    else {
                        setToastMessage('Request failed try again !')
                        setShowErrorToast(true);
                        setShowModal(false)
                    }
                });

                socket.on('sendMultipleRequestSuccess', (response) => {
                    if (response.data.status) {
                        setToastMessage('Sending Request')
                        setShowSuccessToast(true);
                    }
                    else {
                        setToastMessage('Request failed try again !')
                        setShowErrorToast(true);
                    }
                });

                socket.on('updateGroupSuccess', (response) => {
                    if (response.data.status) {
                        setToastMessage('Updating Group Name')
                        setShowSuccessToast(true);
                        getAcceptedUsers()
                    }
                    else {
                        setToastMessage('Request failed try again !')
                        setShowErrorToast(true);
                    }
                });
            }
        },
        [getAcceptedUsers, getGroupUsers, groupMembers, selectedUserId, setShowModal, socket]
    );

    const onClose = useCallback(() => {
        setShowModal(false);
        setLoading(false);
        setAddUser(false);
        setAdminControl(false)
        setSelectedItems([]);
        setSearchQuery('');
        setSearchResults([]);
        setNotAvailableUsers([])
    }, [setShowModal])

    const search = useCallback(async (query) => {
        setIsSearching(true);
        if (query.length > 0) {
            const filteredData = groupMembers.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
            |
            item.email.toLowerCase().includes(query.toLowerCase())
        );
            setSearchResults(filteredData);
        //     console.log(filteredData, 'filter')
        setCircularLoader(true)
        const data = await dispatch(getAllGroupUsersActionBySearch(groupId, query));
         setTimeout(() => {
            setCircularLoader(false)
        }, 2000)
        setSearchResults(data.data);
        } else {
            const groupData = await dispatch(getAllGroupUsersActionByNumber(groupId, 1))
            setSearchResults(groupData.data);
            setIsSearching(false);
        }
    }, [dispatch, groupId]);

    const searchNonAavailableUsers = useCallback(async (query) => {
        setIsAddUserSearching(true);
        if (query.length > 0) {
            const data = await dispatch(getNonGroupUsersBySearchAction(groupId, query));
            setAddUserSearchResults(data.data);
        } else {
            const groupData = await dispatch(getNonGroupUsersByNumberAction(groupId, 1))
            setAddUserSearchResults(groupData.data);
            setIsAddUserSearching(false);
        }
    }, [dispatch, groupId]);

    const handleInputChange = useCallback((e) => {
        setSearchQuery(e.target.value);
        search(e.target.value);
    }, [search]);


    const handleNameChange = useCallback(() => {
        const userData = Object.values(onlineUsers.onlineUsers).find((onlineUser) => onlineUser['userId'] === userLogin.userInfo.data.user._id);
        const clinicId = userLogin.userInfo.data.user.clinic;
        setGroupName(inputGrouNameValue)

        socket.emit('updateGroup', {
            name: inputGrouNameValue,
            groupId: groupId,
            clinic: clinicId,
            mySocketId: userData.socketId,
        },);
    }, [groupId, inputGrouNameValue, onlineUsers.onlineUsers, setGroupName, setShowModal, socket, userLogin.userInfo.data.user._id, userLogin.userInfo.data.user.clinic]);

    const handleInputChange1 = useCallback((e) => {
        setSearchQuery1(e.target.value);
        searchNonAavailableUsers(e.target.value)
    }, [searchNonAavailableUsers]);

    const handleCheckboxChange = useCallback((item) => {

        if (selectedItems.some((selected) => selected.id === item._id)) {
            setSelectedItems(selectedItems.filter((selected) => selected.id !== item._id));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    }, [selectedItems]);

    const addPartcipiants = useCallback(() => {
        setAddUser(true);
    }, [])

    const onBack = useCallback(() => {
        setAddUser(false);
        setAddUser(false)
        setLoading(false);
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const handleGroupNameInputChange = (event) => {
        setInputGrouNameValue(event.target.value);
    };

    const fetchData = useCallback(async () => {
        if (!circularLoader && hasMoreData && !isSearching) {
            setCircularLoader(true);
            const data = await dispatch(getAllGroupUsersActionByNumber(groupId, page));
            const newData = await data.data;
            if (newData.length > 0) {
                setSearchResults((prevResults) => [...prevResults, ...newData]);
                setPage((prevPage) => prevPage + 1);
            } else {
                setHasMoreData(false);
            }

            setCircularLoader(false);
        }
    }, [circularLoader, dispatch, groupId, hasMoreData, isSearching, page]);

    const fetchNonGroupData = useCallback(async () => {
        if (!circularLoader && hasMoreNonGroupData && !isAddUserSearching) {
            setCircularLoader(true);
            const data = await dispatch(getNonGroupUsersByNumberAction(groupId, nonGroupUserspage));
            const newData = await data.data;
            if (newData.length > 0) {
                setAddUserSearchResults((prevResults) => [...prevResults, ...newData]);
                setNonGroupUserspage((prevPage) => prevPage + 1);
            } else {
                setHasMoreNonGroupData(false);
            }

            setCircularLoader(false);
        }
    }, [circularLoader, dispatch, groupId, hasMoreNonGroupData, isAddUserSearching, nonGroupUserspage]);

    useEffect(() => {
        search(debouncedSearch);
    }, [debouncedSearch, search]);

    const cardListRef1 = useRef(null);
    const cardListRef2 = useRef(null);

    const handleScroll = useCallback(() => {
        if (cardListRef1.current) {
            const { scrollTop, clientHeight, scrollHeight } = cardListRef1.current;

            if (scrollTop + clientHeight >= scrollHeight - 2 && hasMoreData) {
                fetchData();
            }
        }
    }, [fetchData, hasMoreData]);

    const handleNonGroupScroll = useCallback(() => {
        if (cardListRef2.current) {
            const { scrollTop, clientHeight, scrollHeight } = cardListRef2.current;

            if (scrollTop + clientHeight >= scrollHeight - 2 && addUser && hasMoreNonGroupData) {
                fetchNonGroupData();
            }
        }
    }, [addUser, fetchNonGroupData, hasMoreNonGroupData]);

    useEffect(() => {
        if (cardListRef1.current) {
            cardListRef1.current.addEventListener('scroll', handleScroll);
        }
        if (cardListRef2.current) {
            cardListRef2.current.addEventListener('scroll', handleNonGroupScroll);
        }

        return () => {
            if (cardListRef1.current) {
                cardListRef1.current.removeEventListener('scroll', handleScroll);
            }
            if (cardListRef2.current) {
                cardListRef2.current.removeEventListener('scroll', handleNonGroupScroll);
            }
        };
    }, [handleNonGroupScroll, handleScroll, hasMoreData, hasMoreNonGroupData, loading]);


    return (
        <BaseModal showModal={showModal} className={Styles.addModal}>
            <div className={Styles.closeIconContainer}>
                <svg className={`header_group_chat_icon ${Styles.group_chat_close}`} onClick={onClose}>
                    <use xlinkHref={`/assets/sprite.svg#icon-close-group-modal`} />
                </svg>
            </div>
            <div className={Styles.iconContainer}>
                <div className={Styles.iconAvatarContainer}>
                    <svg className={Styles.iconAvatarIcon} onClick={() => setShowModal(false)}>
                        <use xlinkHref={`/assets/sprite.svg#icon-group-logo`} />
                    </svg>
                </div>
            </div>
            {adminControl ? (!nameEdit ? <div className={Styles.icon_name_container}>
                <div className={Styles.groupNameEdit}>
                    <span className={Styles.groupName}>{groupName}</span>
                </div>
                <svg className={Styles.group_name_edit_icon} onClick={() => setNameEdit(true)}>
                    <use xlinkHref={`/assets/sprite.svg#icon-group-edit`} />
                </svg>
            </div> :
                <div className={Styles.groupNameInput}>
                    <GroupInfoUserInput
                        handleInputChange={handleGroupNameInputChange}
                        inputValue={inputGrouNameValue}
                        dynamicWidth={dynamicWidth}
                        setInputValue={setInputGrouNameValue}
                        handleSubmit={handleNameChange}
                    />
                </div>) : <span className={Styles.groupName}>{groupName}</span>}
            <div className={Styles.inputContainer}>
                {
                    addUser ?
                        <div className={Styles.input}>
                            <SearchBox
                                value={searchQuery1}
                                onChange={handleInputChange1}
                            />
                        </div>
                        :
                        <div className={Styles.input}>
                            <SearchBox
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                        </div>
                }
            </div>
            <div className={Styles.paricipantsContainer}>
                {!addUser ?
                    <span className={Styles.participants_tag}>Participants</span>
                    :
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={onBack}>
                        <div className={Styles.participants_tag2}>
                            &#x2190;
                        </div>
                        <div className={Styles.participants_tag1}>
                            Back
                        </div>
                    </div>
                }
                {searchResults && searchResults.length > 0 &&
                    adminControl &&
                    (addUser ?
                        // <button className={Styles.participants_tag} onClick={() => setShowInviteModal(true)}>Invite User</button>
                        <span className={Styles.participants_tagnew}>Available Users</span>
                        :
                        <span className={Styles.participants_tag1} onClick={addPartcipiants}>Add Partcipants +</span>
                    )}
                {(!loading && searchResults && searchResults.length > 0 &&
                    !adminControl && <div
                        className={Styles.leaveGroup}
                        onClick={() => setLeaveConfirmModal(true)}
                    >
                        <svg className='header__logout_icon' style={{ marginBottom: '0.2rem' }}>
                            <use xlinkHref={`/assets/sprite.svg#icon-leave-out`} />
                        </svg>
                        <span className={Styles.participants_tag3}>Exit Group</span>
                    </div>)
                }
            </div>
            {loading ?
                (<div >
                    {
                        data?.map((item) => (
                            <UserGroupInforCard
                                key={item._id}
                                duration={3000}
                                loading={true}
                                name={item.name}
                                id={item._id}
                                email={item.email}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                handleCheckboxToggle={handleCheckboxChange}
                                onClick={() => {
                                    // setGroupMembers(groupMembers.filter((updatedItem) => item.id !== updatedItem.id))
                                    // setToastMessage('Removed User')
                                    // setShowSuccessToast(true)
                                }
                                }
                            />
                        ))
                    }
                </div>)
                :
                (
                    addUser ?
                        (addUserSearchResults && addUserSearchResults.length > 0 ?
                            <div
                                className={Styles.cardList}
                                ref={cardListRef2}
                                style={{ height: addUser ? '270px' : '308px' }}>
                                {
                                    addUserSearchResults &&
                                        addUserSearchResults.length > 0 ?
                                        addUserSearchResults?.map((item) => (
                                            <UserGroupAddCard
                                                key={item._id}
                                                name={item.name}
                                                id={item._id}
                                                email={item.email}
                                                isChecked={isChecked}
                                                setIsChecked={setIsChecked}
                                                handleCheckboxToggle={handleCheckboxChange}
                                            />
                                        )
                                        )
                                        :
                                        <div>
                                            {!loading && <NoData title='No Users Found' imgStyle={{ height: '21rem', marginTop: '3rem' }} />}
                                        </div>
                                }
                                {addUserSearchResults &&
                                    addUserSearchResults.length > 0 && circularLoader && <div className={Styles.circularLoader}>
                                        <CircularLoader />
                                    </div>}
                            </div>
                            :
                            <div>
                                {!loading && <NoData title='No Users Found' imgStyle={{ height: '21rem', marginTop: '3rem' }} />}
                            </div>
                        )
                        :
                        (searchResults && searchResults.length > 0 ?
                            <div
                                className={Styles.cardList}
                                ref={cardListRef1}
                                style={{ height: '312px' }}>
                                {
                                    searchResults &&
                                        searchResults.length > 0 ?
                                        searchResults?.map((item) => (
                                            <UserGroupInforCard
                                                loading={false}
                                                key={item._id}
                                                duration={1000}
                                                role={item.role}
                                                handleRemoveUser={handleRemoveUser}
                                                name={item.name}
                                                id={item._id}
                                                status={item.status}
                                                email={item.email}
                                                adminControl={adminControl}
                                                isChecked={isChecked}
                                                setIsChecked={setIsChecked}
                                                searchResults={searchResults}
                                                setSearchResults={setSearchResults}
                                                handleCheckboxToggle={handleCheckboxChange}
                                                onClick={() => {
                                                    setSelectedUserId(item._id)
                                                }
                                                }
                                            />
                                        )
                                        )
                                        :
                                        <div>
                                            {!loading && <NoData title='No Users Found' imgStyle={{ height: '21rem', marginTop: '3rem' }} />}
                                        </div>
                                }
                                {searchResults &&
                                    searchResults.length > 0 && circularLoader && <div className={Styles.circularLoader}>
                                        <CircularLoader />
                                    </div>}
                            </div>
                            :
                            <div>
                                {!loading && <NoData title='No Users Found' imgStyle={{ height: '21rem', marginTop: '3rem' }} />}
                            </div>
                        )
                )

            }
            {addUser && !loading && <div className={Styles.buttonContainer}>
                <PressableButton
                    name={loading ? 'SENDING........' : 'SEND REQUEST'}
                    icon={!loading ? 'dash' : null}
                    onClick={handleSubmit}
                />
            </div>}
            <SuccessToast
                message={toastMessage}
                showToast={showSuccessToast}
                onClose={() => setShowSuccessToast(false)}
                duration={3500}
            />
            <ErrorToast
                message={toastMessage}
                showToast={showErrorToast}
                onClose={() => setShowErrorToast(false)}
                duration={3500}
            />
            {/* <ConfirmationModal
                showModal={removeUserConfirmModal}
                handleRemoveUser={handleRemoveUser}
                message={'Are you sure you want to remove user from group ?'}
                buttonLabel={'Remove User'}
                setShowModal={setRemoveUserConfirmModal}
            /> */}
            <ConfirmationModal
                title={'Leave Group'}
                showModal={leaveConfirmModal}
                handleRemoveUser={handleLeaveGroup}
                message={'Are you sure you want to leave group ?'}
                buttonLabel={'Leave Group'}
                setShowModal={setLeaveConfirmModal}
            />
            <InviteUserModal showModal={showInviteModal} setShowModal={setShowInviteModal} />
        </BaseModal>
    )
}

export default GroupInfoModal
