import React, { Fragment } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import DeleteModalSkeleton from '../../components/Skeletons/DeleteModalSkeleton';
import classes from '../VideoTranscript/VideoTranscript.module.css';
import SelectBox from '../../components/SelectBoxButton';

const VideoNotesModal = ({
  addNew = false,
  onAddNewClick,
  videoNotesModal,
  loading,
  videoNotes,
  selectedUser,
  handleModalClose,
  data,
}) => {
  const {
    videoNoteSelected,
    setVideoNoteSelected,
    newVideoNoteText,
    setNewVideoNoteText,
    newVideoNoteType,
    setNewVideoNoteType,
    handleEditVideoNote,
    setVideoNotesDeleteModal,
  } = data;

  const editBtnRef = React.useRef(null);
  const scrollToBtn = () => {
    editBtnRef &&
      editBtnRef.current &&
      editBtnRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    scrollToBtn();
  }, [videoNoteSelected]);

  return (
    <Modal show={videoNotesModal}>
      <ModalHeading
        heading={`All video notes with ${selectedUser && selectedUser.name}`}
      />
      {loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <div className='scrollContainer'>
            {videoNotes && videoNotes.length > 0 ? (
              videoNotes.map((note) => {
                const readableDate = new Date(
                  note.createdAt
                ).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                });

                const readableTime = new Date(
                  note.createdAt
                ).toLocaleTimeString('en-US', {
                  minute: 'numeric',
                  hour: 'numeric',
                });

                return (
                  <InputsSection
                    key={note._id}
                    style={{ width: '50rem', gap: '.5rem' }}
                  >
                    {videoNoteSelected &&
                    videoNoteSelected._id &&
                    videoNoteSelected._id === note._id ? (
                      <div className={classes.transcriptDataEdit}>
                        <textarea
                          className={classes.transcriptDataEdit_textBox}
                          rows={5}
                          value={newVideoNoteText.value}
                          onChange={(e) =>
                            setNewVideoNoteText({
                              value: e.target.value,
                              error: '',
                            })
                          }
                          autoFocus={
                            videoNoteSelected &&
                            videoNoteSelected._id &&
                            videoNoteSelected._id === note._id
                          }
                        />
                        {newVideoNoteText.error && (
                          <p className='dangerText'>{newVideoNoteText.error}</p>
                        )}
                        {/* <SelectBox
                          // label='Note Type'
                          value={newVideoNoteType.value}
                          onChange={(e) =>
                            setNewVideoNoteType({
                              value: e.target.value,
                              error: '',
                            })
                          }
                          containerStyle={{
                            boxShadow: 'unset',
                            backgroundColor: 'rgb(248, 248, 248)',
                          }}
                          errorMessage={newVideoNoteType.error}
                        >
                          <option value='internal'>Internal Note</option>
                          <option value='public'>Shared with user</option>
                        </SelectBox> */}
                        <SelectBox
                          buttons={[
                            {
                              label: 'Internal Note',
                              value: 'internal',
                              onClick: () =>
                                setNewVideoNoteType({
                                  value: 'internal',
                                  error: '',
                                }),
                            },
                            {
                              label: 'Shared with user',
                              value: 'public',
                              onClick: () =>
                                setNewVideoNoteType({
                                  value: 'public',
                                  error: '',
                                }),
                            },
                          ]}
                          value={newVideoNoteType.value}
                        />
                        <div ref={editBtnRef}>
                          <button
                            type='button'
                            className={classes.transcriptDataEditSave}
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditVideoNote();
                            }}
                          >
                            Save
                          </button>
                          <button
                            type='button'
                            className={classes.transcriptDataEditClose}
                            onClick={(e) => {
                              e.preventDefault();
                              setVideoNoteSelected({});
                            }}
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <p className='primaryText_note'>{note.noteText}</p>
                        <div className='primaryText_note_bottom'>
                          <p className='primaryText_note_date'>
                            Note taken on {readableDate} {readableTime}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '.5rem',
                            }}
                          >
                            <p className='primaryText_note_type'>
                              {note.noteType === 'internal'
                                ? 'Internal Note'
                                : note.noteType === 'public'
                                ? 'Shared with user'
                                : note.noteType}
                            </p>
                            {videoNoteSelected &&
                              Object.keys(videoNoteSelected).length === 0 && (
                                <Fragment>
                                  <button
                                    type='button'
                                    className='primaryText_note_btn'
                                    onClick={() => setVideoNoteSelected(note)}
                                  >
                                    <svg>
                                      <use
                                        xlinkHref={`/assets/sprite.svg#icon-edit-2`}
                                      />
                                    </svg>
                                  </button>

                                  <button
                                    type='button'
                                    className='primaryText_note_btn'
                                    onClick={() =>
                                      setVideoNotesDeleteModal({
                                        loading: false,
                                        status: true,
                                        data: note,
                                      })
                                    }
                                  >
                                    <svg>
                                      <use
                                        xlinkHref={`/assets/sprite.svg#icon-trash`}
                                      />
                                    </svg>
                                  </button>
                                </Fragment>
                              )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </InputsSection>
                );
              })
            ) : (
              <InputsSection style={{ width: '45rem', gap: '.5rem' }}>
                <p className='dangerText'>No notes has been taken yet.</p>
              </InputsSection>
            )}
          </div>

          <ModalButtons
            submitButtonLabel={addNew && 'Add New Note'}
            onSubmit={addNew && onAddNewClick}
            cancelButtonLabel='Cancel'
            onCancel={handleModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default VideoNotesModal;
