import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import UpdateMyProfileSkeleton from '../../components/Skeletons/UpdateMyProfileSkeleton';

const EditProfileModal = ({
  editProfileModal,
  handleModalClose,
  handleEditProfile,
  loading = false,
  data,
}) => {
  const {
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    userPhone,
    setUserPhone,
    userAddress,
    setUserAddress,
  } = data;

  return (
    <Modal show={editProfileModal}>
      <ModalHeading heading='Update your profile' />
      {loading ? (
        <UpdateMyProfileSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection>
            <InputBox
              label='Full Name'
              placeholder='eg, John Doe'
              value={userName.value}
              onChange={(event) => {
                setUserName({ value: event.target.value, error: '' });
              }}
              errorMessage={userName.error}
            />
            <InputBox
              label='Email'
              placeholder='eg, johndoe@example.com'
              value={userEmail.value}
              onChange={(event) => {
                // setUserEmail({ value: event.target.value, error: '' });
              }}
              errorMessage={userEmail.error}
              disabled={true}
            />
          </InputsSection>

          <InputsSection>
            <InputBox
              label='Phone'
              placeholder='eg, 123-456-789'
              value={userPhone.value}
              onChange={(event) => {
                setUserPhone({ value: event.target.value, error: '' });
              }}
              errorMessage={userPhone.error}
            />
            <InputBox
              label='Address'
              placeholder='eg, 692 SW. 53rd St. Irwin, PA 15642'
              value={userAddress.value}
              onChange={(event) => {
                setUserAddress({ value: event.target.value, error: '' });
              }}
              errorMessage={userAddress.error}
            />
          </InputsSection>

          <ModalButtons
            submitButtonLabel='Save'
            onSubmit={handleEditProfile}
            cancelButtonLabel='Cancel'
            onCancel={handleModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default EditProfileModal;
