import React, { useState } from 'react';
import Style from './CircularLoader.module.css'

const CircularLoader = () => {
    const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <div className={Style.loading_spinner}></div>}
    </>
  )
}

export default CircularLoader
