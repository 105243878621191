import axios from 'axios';
// import { BASE_URL } from './ip';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllTranscriptsActions = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${BASE_URL}/transcript/`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const updateTranscriptAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.patch(
        `${BASE_URL}/transcript/${id}`,
        formData,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data
        ? err.response.data
        : err.message;
    }
  };

export const deleteTranscriptAction = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/transcript/${id}`, config);

    return { status: 'success' };
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};
