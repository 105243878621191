import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import { getFormTitle } from '.';

const DownloadFormModal = ({ show, selectedForm, handleDownload, handleCancel }) => {
  return (
    <Modal show={show}>
      <ModalHeading heading={`Download ${getFormTitle(selectedForm.type, selectedForm.agent)} form`} />
      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <InputsSection style={{ width: '34rem' }}>
          {/* <p className='dangerText'>Are you sure you want to delete this form?</p> */}
          <p className='primaryText'>This form contains additional attachments. To view these please download each attachment separately.</p>
        </InputsSection>

        <ModalButtons submitButtonLabel='Download Form' onSubmit={handleDownload} cancelButtonLabel='Cancel' onCancel={handleCancel} />
      </ModalForm>
    </Modal>
  );
};

export default DownloadFormModal;
