import React from 'react';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import ModalButtons from '../../components/Modal/ModalButtons';
import classes from './VideoTranscript.module.css';

const TranscriptDetail = ({
  transcript,
  handleEditTranscript,
  handleCloseTranscript,
  selectedTranscriptText,
  setSelectedTranscriptText,
}) => {
  const [newTranscriptOneText, setNewTranscriptOneText] = React.useState('');
  const [newTranscriptTwoText, setNewTranscriptTwoText] = React.useState('');

  const formattedDate = new Date(transcript.data.createdAt).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  );

  const formattedTime = new Date(transcript.data.createdAt).toLocaleTimeString(
    'en-US',
    {
      minute: 'numeric',
      hour: 'numeric',
    }
  );

  React.useEffect(() => {
    if (
      transcript.data &&
      transcript.data.transcriptOneText &&
      transcript.data.transcriptTwoText
    ) {
      setNewTranscriptOneText(transcript.data.transcriptOneText);
      setNewTranscriptTwoText(transcript.data.transcriptTwoText);
    }
  }, [transcript]);

  if (
    !(
      transcript.data &&
      transcript.data.transcriptOneUser &&
      transcript.data.transcriptOneUser.name &&
      transcript.data.transcriptTwoUser &&
      transcript.data.transcriptTwoUser.name &&
      transcript.data.transcriptOneText &&
      transcript.data.transcriptTwoText
    )
  ) {
    return '';
  }

  return (
    <Modal show={transcript.status}>
      <ModalHeading
        heading={`Video Transcript between ${transcript.data.transcriptOneUser.name} and ${transcript.data.transcriptTwoUser.name}`}
      />

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <div className={classes.transcriptCard_title}>
          <p>{formattedDate + ' ' + formattedTime}</p>
        </div>

        <div className={classes.transcriptData}>
          <div className={classes.transcriptData__user}>
            <h3>{transcript.data.transcriptOneUser.name}</h3>
            {selectedTranscriptText &&
            selectedTranscriptText.status &&
            selectedTranscriptText.user ===
              transcript.data.transcriptOneUser._id ? (
              <div className={classes.transcriptDataEdit}>
                <textarea
                  className={classes.transcriptDataEdit_textBox}
                  rows={8}
                  value={newTranscriptOneText}
                  onChange={(e) => setNewTranscriptOneText(e.target.value)}
                  autoFocus={
                    selectedTranscriptText &&
                    selectedTranscriptText.status &&
                    selectedTranscriptText.user ===
                      transcript.data.transcriptOneUser._id
                  }
                />
                <div>
                  <button
                    type='button'
                    className={classes.transcriptDataEditSave}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditTranscript(transcript.data._id, {
                        transcriptOneText: newTranscriptOneText,
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    type='button'
                    className={classes.transcriptDataEditClose}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedTranscriptText({
                        status: false,
                        user: '',
                        text: '',
                      });
                      setNewTranscriptOneText(
                        transcript.data.transcriptOneText
                      );
                    }}
                  >
                    Discard
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ position: 'relative' }}>
                <p
                  className={classes.transcriptData__user_text}
                  onDoubleClick={() =>
                    setSelectedTranscriptText({
                      status: true,
                      user: transcript.data.transcriptOneUser._id,
                      text: transcript.data.transcriptOneText,
                    })
                  }
                >
                  <span>{transcript.data.transcriptOneText}</span>
                  <button
                    className={classes.transcriptData__user_btn}
                    onClick={() =>
                      setSelectedTranscriptText({
                        status: true,
                        user: transcript.data.transcriptOneUser._id,
                        text: transcript.data.transcriptOneText,
                      })
                    }
                  >
                    Edit transcript
                  </button>
                </p>
              </div>
            )}
          </div>

          <div className={classes.transcriptData__user}>
            <h3>{transcript.data.transcriptTwoUser.name}</h3>

            {selectedTranscriptText &&
            selectedTranscriptText.status &&
            selectedTranscriptText.user ===
              transcript.data.transcriptTwoUser._id ? (
              <div className={classes.transcriptDataEdit}>
                <textarea
                  className={classes.transcriptDataEdit_textBox}
                  rows={8}
                  // value={selectedTranscriptText.text}
                  // onChange={(e) =>
                  //   setSelectedTranscriptText({
                  //     ...selectedTranscriptText,
                  //     text: e.target.value,
                  //   })
                  // }
                  value={newTranscriptTwoText}
                  onChange={(e) => setNewTranscriptTwoText(e.target.value)}
                  autoFocus={
                    selectedTranscriptText &&
                    selectedTranscriptText.status &&
                    selectedTranscriptText.user ===
                      transcript.data.transcriptTwoUser._id
                  }
                />
                <div>
                  <button
                    className={classes.transcriptDataEditSave}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditTranscript(transcript.data._id, {
                        transcriptTwoText: newTranscriptTwoText,
                      });
                    }}
                  >
                    Save
                  </button>
                  <button
                    className={classes.transcriptDataEditClose}
                    onClick={() => {
                      setSelectedTranscriptText({
                        status: false,
                        user: '',
                        text: '',
                      });
                      setNewTranscriptTwoText(
                        transcript.data.transcriptTwoText
                      );
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ position: 'relative' }}>
                <p
                  className={classes.transcriptData__user_text}
                  onDoubleClick={() =>
                    setSelectedTranscriptText({
                      status: true,
                      user: transcript.data.transcriptTwoUser._id,
                      text: transcript.data.transcriptTwoText,
                    })
                  }
                >
                  <span>{transcript.data.transcriptTwoText}</span>
                  <button
                    className={classes.transcriptData__user_btn}
                    onClick={() =>
                      setSelectedTranscriptText({
                        status: true,
                        user: transcript.data.transcriptTwoUser._id,
                        text: transcript.data.transcriptTwoText,
                      })
                    }
                  >
                    Edit transcript
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>

        <ModalButtons
          submitButtonLabel='Close'
          onSubmit={handleCloseTranscript}
        />
      </ModalForm>
    </Modal>
  );
};

export default TranscriptDetail;
