import TextBoxStyles from '../InputBox/TextBox.module.css';
import SelectMultipleStyles from './SelectMultiple.module.css';

const SelectMultiple = ({ label, loading, loadingText, data, userFolders, handleAddFolder, errorMessage }) => {
	return (
		<div className={TextBoxStyles.form_input}>
			{label && (
				<label htmlFor={label} className={TextBoxStyles.form_input__label}>
					{label}
				</label>
			)}

			<div className={SelectMultipleStyles.items}>
				{loading ? (
					<div className={SelectMultipleStyles.item}>
						<p>{loadingText}</p>
					</div>
				) : data && data.length > 0 ? (
					data.map((d) => (
						<div
							key={d._id}
							onClick={() => handleAddFolder(d._id)}
							className={
								userFolders.value.includes(d._id) ? SelectMultipleStyles.itemExists : SelectMultipleStyles.item
							}>
							<p>{!d.isSubFolder ? d.name : `${d.parentFolder.name} / ${d.name}`}</p>
						</div>
					))
				) : (
					<p>No data</p>
				)}
			</div>
			{errorMessage && <p className={TextBoxStyles.form_input__error}>{errorMessage}</p>}
		</div>
	);
};

export default SelectMultiple;
