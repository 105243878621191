import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';

import './SideBar.css';
import { checkTokenAction, logout } from '../../redux/actions/userActions';
import AdminSidebar from './AdminSidebar';
import FileAdminSidebar from './FileAdminSidebar';
import FileUserSidebar from './FileUserSidebar';
import ClientAdminSidebar from './ClientAdminSidebar';

const Sidebar = ({ socket, currentMenu }) => {
  const dispatch = useDispatch();
  const [role, userRole] = React.useState('');
  const [logoutModal, setLogoutModal] = React.useState(false);
  const [loadedLogoImage, setLoadedLogoImage] = React.useState('');

  const clientLogo = useSelector(state => state.clientLogo);
  const userLogin = useSelector(state => state.userLogin);
  const loggedinUser = useSelector(state => state.loggedinUser);
  const clinicDetails = useSelector(state => state.clinicDetails);

  React.useLayoutEffect(() => {
    if (loggedinUser && loggedinUser.userInfo && loggedinUser.userInfo.data && loggedinUser.userInfo.data.data && loggedinUser.userInfo.data.data.role) {
      userRole(loggedinUser.userInfo.data.data.role);
    }
  }, [socket, loggedinUser]);

  // console.log(userLogin);
  React.useLayoutEffect(() => {
    const checkToken = async () => {
      const data = await dispatch(checkTokenAction());
      if (data && data.status === 'fail') {
        setLogoutModal(true);
      }
    };
    checkToken();
  }, [dispatch]);

  React.useLayoutEffect(() => {
    function checkImage(url) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.send();
      request.onload = function () {
        // status = request.status;
        if (request.status == 200) {
          setLoadedLogoImage(url);
        } else {
          setLoadedLogoImage('/assets/logo.png');
        }
      };
    }
    if (clientLogo && clientLogo.logo && clientLogo.logo.image) checkImage(process.env.REACT_APP_BASE_SOCKET_URL + '/' + clientLogo.logo.image);
  }, [clientLogo]);

  return (
    <Fragment>
      {role === 'admin' ? (
        <AdminSidebar currentMenu={currentMenu} />
      ) : role === 'fileAdmin' ? (
        <FileAdminSidebar currentMenu={currentMenu} clinicDetails={clinicDetails} userLogin={userLogin} clientLogoLink={loadedLogoImage} />
      ) : role === 'fileUser' || role === 'fileUserOnlyDownload' ? (
        <FileUserSidebar currentMenu={currentMenu} userLogin={userLogin} clinicDetails={clinicDetails} clientLogoLink={loadedLogoImage} />
      ) : role === 'clientAdmin' ? (
        <ClientAdminSidebar userLogin={userLogin} currentMenu={currentMenu} clinicDetails={clinicDetails} clientLogoLink={loadedLogoImage} />
      ) : (
        <nav className='navigation'>
          <ul className='navigation__list'>
            <li className='navigation__list--link' style={{ marginTop: '2rem', width: '13rem', height: '5rem' }}>
              {loadedLogoImage ? <img src={loadedLogoImage} alt='logo' className='navigation__logo' /> : null}
            </li>
            {/* {role === 'admin' && (
						<li className='navigation__list--item'>
							<Link
								to='/'
								className={currentMenu === 'dashboard' ? 'navigation__list--link-active' : 'navigation__list--link'}>
								<svg className={currentMenu === 'dashboard' ? 'navigation__icon-active' : 'navigation__icon'}>
									<use xlinkHref={`/assets/sprite.svg#icon-menu2`} />
								</svg>
								<span>Dashboard</span>
							</Link>
						</li>
					)} */}
            {role !== '' && (
              <li className='navigation__list--item'>
                <Link to='/' className={currentMenu === 'secure-files' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'secure-files' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-secure-file2`} />
                  </svg>
                  <span>
                    Document
                    <br /> Archive
                  </span>
                </Link>
              </li>
            )}
            {role === 'admin' && (
              <li className='navigation__list--item'>
                <Link to='/clients' className={currentMenu === 'clinics' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'clinics' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-clinic2`} />
                  </svg>
                  <span>Clients</span>
                </Link>
              </li>
            )}

            {(role === 'admin' ||
              role === 'clinic' ||
              (role === 'fileAdmin' &&
                clinicDetails &&
                clinicDetails.details &&
                clinicDetails.details.package &&
                clinicDetails.details.package.metadata &&
                clinicDetails.details.package.metadata.users !== 1)) && (
              <li className='navigation__list--item'>
                <Link to='/users' className={currentMenu === 'users' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'users' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
                  </svg>
                  <span>Users</span>
                </Link>
              </li>
            )}

            {role === 'admin' && (
              <li className='navigation__list--item'>
                <Link to='/packages' className={currentMenu === 'packages' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'packages' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-package`} />
                  </svg>
                  <span>Packages</span>
                </Link>
              </li>
            )}

            {role === 'admin' && (
              <li className='navigation__list--item'>
                <Link to='/coupons' className={currentMenu === 'coupons' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'coupons' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-ticket`} />
                  </svg>
                  <span>Coupons</span>
                </Link>
              </li>
            )}
            {role === 'admin' && (
              <li className='navigation__list--item'>
                <Link to='/promo-codes' className={currentMenu === 'promoCodes' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'promoCodes' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-ticket`} />
                  </svg>
                  <span>Promo Codes</span>
                </Link>
              </li>
            )}

            <li className='navigation__list--item'>
              <Link to='/audit-logs' className={currentMenu === 'logs' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'logs' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-document-text`} />
                </svg>
                <span>Audit Logs</span>
              </Link>
            </li>
            {/* <li className='navigation__list--item'>
                <Link to='/chat' className={currentMenu === 'chat' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'chat' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-chat-alt-stroke`} />
                  </svg>
                  <span>Chat</span>
                </Link>
              </li> */}

            {(role === 'admin' ||
              (role === 'fileAdmin' &&
                clinicDetails &&
                clinicDetails.details &&
                clinicDetails.details.package &&
                clinicDetails.details.package.metadata &&
                clinicDetails.details.package.metadata.users !== 1)) && (
              <li className='navigation__list--item'>
                <Link to='/video-transcript' className={currentMenu === 'transcript' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'transcript' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-file_video`} />
                  </svg>
                  <span>Transcripts</span>
                </Link>
              </li>
            )}

            {/* <li className='navigation__list--item'>
            <Link
              to='/email'
              className={
                currentMenu === 'chat'
                  ? 'navigation__list--link-active'
                  : 'navigation__list--link'
              }
            >
              <svg
                className={
                  currentMenu === 'chat'
                    ? 'navigation__icon-active'
                    : 'navigation__icon'
                }
              >
                <use xlinkHref={`/assets/sprite.svg#icon-email`} />
              </svg>
              <span>Email</span>
            </Link>
          </li> */}
            {role !== 'admin' && (
              <li className='navigation__list--item'>
                <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                  <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
                    <use xlinkHref={`/assets/sprite.svg#icon-user`} />
                  </svg>
                  <span>My Profile</span>
                </Link>
              </li>
            )}
          </ul>

          <div>
            {role === 'admin' && (
              <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-user`} />
                </svg>
                <span>My Profile</span>
              </Link>
            )}

            <p className='powered__text'>
              <span>
                Powered By
                <br />
                <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
                  The Algorithm
                </a>
              </span>
              &copy; {new Date(Date.now()).getFullYear()}. Continia
            </p>
          </div>
          {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
        </nav>
      )}

      <Modal show={logoutModal}>
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={() => {}}>
          <ModalHeading heading='Session Timeout' />

          <InputsSection style={{ width: '34rem' }}>
            <p className='primaryText'>It is been 1 hour since your last login. Please login again to continue.</p>
          </InputsSection>
          <ModalButtons submitButtonLabel='Logout Now' onSubmit={() => dispatch(logout())} />
        </ModalForm>
      </Modal>
    </Fragment>
  );
};

export default Sidebar;
