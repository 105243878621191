import React, { Fragment } from 'react';
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  allocateUserSpaceAction,
  createNewUserAction,
  deleteUserAction,
  getAllUsers,
  getClinicUserCount,
  refreshUserAction,
  resetPasswordAction,
  toggleUserActive,
  updateUserAction,
} from '../../redux/actions/userActions';
import { foldersLevelListAction, foldersListAction } from '../../redux/actions/folderActions';
import { clinicsListAction } from '../../redux/actions/clinicActions';
import {
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_RESET,
  DELETE_USER_RESET,
  GET_ALL_USERS_RESET,
  RESET_USER_PASSWORD_RESET,
  UPDATE_USER_RESET,
  UPDATE_USER_STATUS_RESET,
} from '../../redux/constants/userConstants';
import { LIST_CLINIC_RESET } from '../../redux/constants/clinicConstants';
import { GET_ALL_FOLDER_DATA_RESET, GET_ALL_LEVEL_FOLDER_DATA_RESET } from '../../redux/constants/folderConstants';

import Content from '../../components/Content';
import UserListTable from './UserListTable';
import AddUserModal from './AddUserModal';
import UserQuotaReachedModal from './UserQuotaReached';
import ResetPasswordModal from './ResetPasswordModal';
import DeleteUserModal from './DeleteUserModal';
import Pagination from '../../components/Pagination';
import ToggleUserActive from './ToggleUserActive';
import { validateEmail, validateOnlyChacters, validatePassword, validatePhone } from '../../utils/validators';

const UsersScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = React.useState('');
  // const [ startValue, setStartValue ] = React.useState(1);
  // const [ endValue, setEndValue ] = React.useState(1);
  const [totalPageSize, setTotalPageSize] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const pageLimit = 15;
  const [selectedClient, setSelectedClient] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState('');

  // Add or update user states
  const [maxUserQuotaModal, setMaxUserQuotaModal] = React.useState(false);
  const [addUserModal, setAddUserModal] = React.useState(false);
  const [userName, setUserName] = React.useState({ value: '', error: '' });
  const [userEmail, setUserEmail] = React.useState({ value: '', error: '' });
  const [userPhone, setUserPhone] = React.useState({ value: '', error: '' });
  const [userAddress, setUserAddress] = React.useState({
    value: '',
    error: '',
  });
  const [userRole, setUserRole] = React.useState({ value: '', error: '' });
  const [userSpace, setUserSpace] = React.useState({ value: 0, error: '' });
  const [userPassword, setUserPassword] = React.useState({
    value: '',
    error: '',
  });
  const [userClinic, setUserClinic] = React.useState({ value: '', error: '' });
  const [userFolders, setUserFolders] = React.useState({
    value: [],
    error: '',
  });
  const [editUserData, setEditUserData] = React.useState({});

  // Reset password states
  const [resetPasswordModal, setResetPasswordModal] = React.useState(false);
  const [resetPasswordData, setResetPasswordData] = React.useState({});
  const [resetPassword, setResetPassword] = React.useState({
    value: '',
    error: '',
  });
  const [resetPasswordConfirm, setResetPasswordConfirm] = React.useState({
    value: '',
    error: '',
  });

  // Delete user states
  const [toggleUserModal, setToggleUserModal] = React.useState(false);
  const [deleteUserModal, setDeleteUserModal] = React.useState(false);
  const [deleteUserData, setDeleteUserData] = React.useState({});
  const [deleteEmail, setDeleteEmail] = React.useState({
    value: '',
    error: '',
  });

  // Redux states
  const userLogin = useSelector(state => state.userLogin);
  const usersList = useSelector(state => state.usersList);
  const foldersList = useSelector(state => state.foldersList);
  const foldersLevelList = useSelector(state => state.foldersLevelList);
  const clinicsList = useSelector(state => state.clinicsList);
  const createNewUser = useSelector(state => state.createNewUser);
  const updateUser = useSelector(state => state.updateUser);
  const resetUserPassword = useSelector(state => state.resetUserPassword);
  const updateUserStatus = useSelector(state => state.updateUserStatus);
  const deleteUser = useSelector(state => state.deleteUser);

  const [clinicTotalUsers, setClinicTotalUsers] = React.useState({});
  const getClinicTotalUsers = React.useCallback(
    async clientId => {
      const data = await dispatch(getClinicUserCount(clientId));
      setClinicTotalUsers(data);
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!(userLogin && userLogin.userInfo)) {
      history.push('/login');
    }
  }, [userLogin, history]);

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'admin') {
      dispatch(clinicsListAction());
    }
  }, [dispatch, addUserModal, userLogin]);

  // React.useEffect(() => {
  //   if (userClinic) dispatch(foldersListAction('folders', userClinic.value));
  // }, [userClinic, dispatch]);

  React.useEffect(() => {
    if (userClinic) dispatch(foldersLevelListAction('folders', userClinic.value));
  }, [userClinic, dispatch]);

  React.useEffect(() => {
    dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
  }, [dispatch, pageNumber, selectedClient, selectedRole, searchKey]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: GET_ALL_USERS_RESET });
      dispatch({ type: LIST_CLINIC_RESET });
      dispatch({ type: GET_ALL_FOLDER_DATA_RESET });
      dispatch({ type: GET_ALL_LEVEL_FOLDER_DATA_RESET });
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (usersList && usersList.userInfo && usersList.userInfo.status === 'success' && usersList.userInfo.data && usersList.userInfo.data.data) {
      setTotalPageSize(usersList.userInfo.totalResults);
    }
  }, [usersList]);

  const handlePageChange = async currentPage => {
    setPageNumber(currentPage.selected + 1);
    // setStartValue(currentPage.selected + 1 * pageNumber);
    // setEndValue(startValue + pageLimit);
  };

  const allocateUserSpace = React.useCallback(
    async (userId, allowedStorage, message = 'Successful') => {
      dispatch({ type: CREATE_NEW_USER_REQUEST });
      const data = await dispatch(allocateUserSpaceAction(userId, allowedStorage));
      if (data && data.status === 'fail') {
        toast.error(data.msg);
        handleAddModalClose();
      } else if (data && data.status === 'success') {
        toast.success(message);
        handleAddModalClose();
        if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role !== 'admin') {
          getClinicTotalUsers(userLogin.userInfo.data.user.clinic);
        } else if (
          userLogin &&
          userLogin.userInfo &&
          userLogin.userInfo.data &&
          userLogin.userInfo.data.user &&
          userLogin.userInfo.data.user.role === 'admin' &&
          userClinic.value !== ''
        ) {
          getClinicTotalUsers(userClinic.value);
        }
      }
      dispatch({ type: CREATE_NEW_USER_RESET });
      dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
    },
    [dispatch, userLogin, userClinic, getClinicTotalUsers, searchKey]
  );

  // Add user functions
  React.useEffect(
    () => {
      if (createNewUser && createNewUser.userInfo && createNewUser.userInfo.status === 'success') {
        if (createNewUser.userInfo && createNewUser.userInfo.data && createNewUser.userInfo.data.data && createNewUser.userInfo.data.data.role === 'fileAdmin') {
          allocateUserSpace(createNewUser.userInfo.data.data._id, userSpace.value, 'New User Created');
        } else {
          toast.success('New User Created');
          handleAddModalClose();
          dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
        }
        dispatch({ type: CREATE_NEW_USER_RESET });
      } else if (createNewUser && !createNewUser.loading && createNewUser.error) {
        toast.error(createNewUser.error);
        dispatch({ type: CREATE_NEW_USER_RESET });
      }
    },
    //eslint-disable-next-line
    [createNewUser, dispatch, pageNumber, selectedClient, selectedRole, searchKey]
  );

  React.useEffect(
    () => {
      if (updateUser && updateUser.userInfo && updateUser.userInfo.status === 'success') {
        if (updateUser.userInfo && updateUser.userInfo.data && updateUser.userInfo.data.data && updateUser.userInfo.data.data.role === 'fileAdmin') {
          allocateUserSpace(updateUser.userInfo.data.data._id, userSpace.value, 'User data modified');
        } else {
          toast.success('User data modified');
          handleAddModalClose();
          dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
        }
        dispatch({ type: UPDATE_USER_RESET });
      } else if (updateUser && !updateUser.loading && updateUser.error) {
        toast.error(updateUser.error);
        dispatch({ type: UPDATE_USER_RESET });
      }
    },
    //eslint-disable-next-line
    [updateUser, dispatch, pageNumber, selectedClient, selectedRole, userSpace, searchKey]
  );

  React.useEffect(() => {
    if (editUserData && editUserData._id) {
      setUserName({ value: editUserData.name });
      setUserEmail({ value: editUserData.email });
      setUserPhone({ value: editUserData.phone });
      setUserAddress({ value: editUserData.address });
      setUserRole({ value: editUserData.role });
      setUserSpace({
        value: editUserData.maxStorageAllowed ? editUserData.maxStorageAllowed : 0,
      });
      setUserClinic({
        value: editUserData.clinic ? editUserData.clinic._id : '',
      });

      const folders = [];
      editUserData.uploadFolder.length > 0 && editUserData.uploadFolder.forEach(folder => folder && folders.push(folder));
      setUserFolders({ value: folders });
    }
  }, [editUserData]);

  const handleEditUserButtonClick = data => {
    setEditUserData(data);
    setAddUserModal(true);
  };

  const clearData = () => {
    setUserName({ value: '', error: '' });
    setUserEmail({ value: '', error: '' });
    setUserPhone({ value: '', error: '' });
    setUserAddress({ value: '', error: '' });
    setUserRole({ value: '', error: '' });
    setUserSpace({ value: 0, error: '' });
    setUserPassword({ value: '', error: '' });
    // setUserClinic({ value: '', error: '' });
    setUserFolders({ value: [], error: '' });
    setEditUserData({});
  };

  const handleAddModalClose = () => {
    setAddUserModal(false);
    clearData();
  };

  const handleAddFolder = id => {
    if (!userFolders.value.includes(id)) {
      setUserFolders({
        ...userFolders,
        value: [...userFolders.value, id],
        error: '',
      });
    } else {
      setUserFolders({ value: userFolders.value.filter(d => d !== id) });
    }
  };

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role !== 'admin') {
      setUserClinic({ value: userLogin.userInfo.data.user.clinic, error: '' });
      getClinicTotalUsers(userLogin.userInfo.data.user.clinic);
    }
  }, [userLogin, getClinicTotalUsers]);

  React.useEffect(
    () => {
      if (
        userLogin &&
        userLogin.userInfo &&
        userLogin.userInfo.data &&
        userLogin.userInfo.data.user &&
        userLogin.userInfo.data.user.role === 'admin' &&
        userClinic.value !== ''
      )
        getClinicTotalUsers(userClinic.value);
    },
    // eslint-disable-next-line
    [userLogin, userClinic, getClinicTotalUsers]
  );

  const handleAddUser = () => {
    if (userName.value === '' || userName.value.trim() === '') {
      setUserName({ ...userName, error: 'User name is required' });
    } else if (!validateOnlyChacters(userName.value)) {
      setUserName({ ...userName, error: 'Name must contain only alphabets' });
    } else if (userEmail.value === '' || userEmail.value.trim() === '') {
      setUserEmail({ ...userEmail, error: 'User email is required' });
    } else if (!validateEmail(userEmail.value)) {
      setUserEmail({ ...userEmail, error: 'User email is invalid' });
    } else if (userPhone.value === '' || userPhone.value.trim() === '') {
      setUserPhone({ ...userPhone, error: 'User phone number is required' });
    } else if (!validatePhone(userPhone.value)) {
      setUserPhone({ ...userPhone, error: 'Phone number must have 10 digits' });
    } else if (userAddress.value === '' || userAddress.value.trim() === '') {
      setUserAddress({ ...userAddress, error: 'User address is required' });
    } else if (userRole.value === '' || userRole.value.trim() === '') {
      setUserRole({ ...userRole, error: 'User role is required' });
    } else if (userClinic.value === '' || userClinic.value.trim() === '') {
      setUserClinic({ ...userClinic, error: 'User clinic is required' });
    } else if (
      (clinicTotalUsers && !clinicTotalUsers.customClient && clinicTotalUsers.maxQuotaReached) ||
      (clinicTotalUsers && clinicTotalUsers.customClient && userRole.value === 'fileAdmin' && clinicTotalUsers.maxAdminQuotaReached) ||
      (clinicTotalUsers && clinicTotalUsers.customClient && userRole.value === 'fileUserOnlyDownload' && clinicTotalUsers.maxUserQuotaReached)
    ) {
      setUserRole({
        ...userRole,
        error: 'You have reached maximum user quota.',
      });
    } else if (userRole.value === 'fileAdmin' && userSpace.value <= 0) {
      setUserSpace({
        ...userSpace,
        error: 'Please allocate some space to admin user',
      });
    } else if ((userRole.value === 'fileUser' || userRole.value === 'fileUserOnlyDownload') && userFolders.value.length === 0) {
      setUserFolders({ ...userFolders, error: 'Select user folders' });
    } else {
      if (editUserData && editUserData._id) {
        const formData = {
          name: userName.value,
          // email: userEmail.value,
          phone: userPhone.value,
          address: userAddress.value,
          role: userRole.value,
          uploadFolder: userFolders.value,
        };
        dispatch(updateUserAction(editUserData._id, formData));
      } else {
        if (!validatePassword(userPassword.value)) {
          setUserPassword({ ...userPassword, error: 'Please enter strong password' });
        } else {
          const formData = {
            name: userName.value,
            email: userEmail.value,
            phone: userPhone.value,
            address: userAddress.value,
            password: userPassword.value,
            passwordConfirm: userPassword.value,
            role: userRole.value,
            clinic: userClinic.value,
            uploadFolder: userFolders.value,
          };
          dispatch(createNewUserAction(formData));
        }
      }
    }
  };

  // Toggle User functions
  React.useEffect(() => {
    if (updateUserStatus && updateUserStatus.userInfo && updateUserStatus.userInfo.status === 'success') {
      toast.success('User status change successfull.');
      setToggleUserModal(false);
      dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
      dispatch({ type: UPDATE_USER_STATUS_RESET });
    }
  }, [updateUserStatus, pageNumber, dispatch, selectedClient, selectedRole, searchKey]);
  const handleToggleButtonClick = data => {
    setDeleteUserData(data);
    setToggleUserModal(true);
  };

  const handleUserToggle = (e, id, active) => {
    dispatch(toggleUserActive(id, !active));
  };

  const handleToggleModalClose = () => {
    setToggleUserModal(false);
    setDeleteUserData({});
  };

  // Reset password functions
  React.useEffect(() => {
    if (resetUserPassword && resetUserPassword.userInfo && resetUserPassword.userInfo.status === 'Success') {
      toast.success('User password reset successfull.');
      handleResetPasswordModalClose();
      dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
      dispatch({ type: RESET_USER_PASSWORD_RESET });
    }
  }, [resetUserPassword, dispatch, pageNumber, selectedClient, selectedRole, searchKey]);

  const handleResetPasswordButtonClick = data => {
    setResetPasswordData(data);
    setResetPasswordModal(true);
  };

  const handleResetPassword = () => {
    if (resetPassword.value === '' || resetPassword.value.trim() === '') {
      setResetPassword({
        ...resetPassword,
        error: 'Please enter new password',
      });
    } else if (resetPassword.value.length < 8) {
      setResetPassword({
        ...resetPassword,
        error: 'Password must have atleast 8 characters',
      });
    } else if (resetPasswordConfirm.value === '' || resetPasswordConfirm.value.trim() === '') {
      setResetPasswordConfirm({
        ...resetPasswordConfirm,
        error: 'Please confirm new password',
      });
    } else if (resetPassword.value !== resetPasswordConfirm.value) {
      setResetPasswordConfirm({
        ...resetPasswordConfirm,
        error: 'Passwords do not match',
      });
    } else {
      dispatch(resetPasswordAction(resetPasswordData._id, resetPassword.value));
    }
  };

  const handleResetPasswordModalClose = () => {
    setResetPasswordModal(false);
    setResetPasswordData({});
    setResetPassword({ value: '', error: '' });
    setResetPasswordConfirm({ value: '', error: '' });
  };

  // Delete user functions
  React.useEffect(() => {
    if (deleteUser && deleteUser.userInfo && deleteUser.userInfo.status === 'Success') {
      toast.error('User delete successfull.');
      setDeleteEmail({ value: '', error: '' });
      handleDeleteUserModalClose();
      dispatch({ type: DELETE_USER_RESET });
      if (usersList && usersList.userInfo && usersList.userInfo.data && usersList.userInfo.data.data && usersList.userInfo.data.data.length === 1) {
        dispatch(getAllUsers(pageNumber - 1, pageLimit, selectedClient, selectedRole, searchKey));
      } else dispatch(getAllUsers(pageNumber, pageLimit, selectedClient, selectedRole, searchKey));
    }
  }, [usersList, pageNumber, deleteUser, dispatch, selectedClient, selectedRole, searchKey]);

  React.useEffect(() => {
    if (deleteUser && deleteUser.userInfo && deleteUser.userInfo.status === 'Success') {
      if (userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role !== 'admin') {
        getClinicTotalUsers(userLogin.userInfo.data.user.clinic);
      } else if (
        userLogin &&
        userLogin.userInfo &&
        userLogin.userInfo.data &&
        userLogin.userInfo.data.user &&
        userLogin.userInfo.data.user.role === 'admin' &&
        userClinic.value !== ''
      ) {
        getClinicTotalUsers(userClinic.value);
      }
    }
  }, [userLogin, userClinic, deleteUser, getClinicTotalUsers]);

  const handleDeleteUserButtonClick = data => {
    setDeleteUserData(data);
    setDeleteUserModal(true);
  };

  const handleDeleteUserModalClose = () => {
    setDeleteUserModal(false);
    setDeleteUserData({});
    setDeleteEmail({ value: '', error: '' });
  };

  const handleDeleteUser = () => {
    if (deleteEmail.value === '' || deleteEmail.value.trim() === '') {
      setDeleteEmail({ ...deleteEmail, error: "Please enter user's email id" });
    } else if (deleteEmail.value !== deleteUserData.email) {
      setDeleteEmail({
        ...deleteEmail,
        error: "Email id does not match with user's email",
      });
    } else {
      dispatch(deleteUserAction(deleteUserData._id));
    }
  };

  const refreshUserData = async data => {
    if (data._id) {
      const resData = await dispatch(refreshUserAction(data._id));

      if (resData.status === 'success') {
        toast.success(resData.msg);
      } else {
        toast.error(resData.msg);
      }
    }
  };

  return (
    <Content
      currentMenu='users'
      headerTitle='Users'
      soloUser={clinicTotalUsers && clinicTotalUsers.package && clinicTotalUsers.package.metadata && clinicTotalUsers.package.metadata.users}
      addBtn={clinicTotalUsers && !clinicTotalUsers.maxQuotaReached ? true : false}
      addBtnTitle='Add User'
      addBtnClick={() =>
        userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'admin'
          ? setAddUserModal(true)
          : clinicTotalUsers && !clinicTotalUsers.maxQuotaReached
            ? setAddUserModal(true)
            : setMaxUserQuotaModal(true)
      }
      searchBox={true}
      searchPlaceholder='Search Users...'
      handleSearchChange={e => {
        setSearchKey(e.target.value);
        setPageNumber(1);
      }}
      totalReult={totalPageSize !== 0 ? totalPageSize + ' users' : ''}
      dropdownFilter={
        userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role === 'admin' ? true : false
      }
      dropdownName='select-client'
      dropdownOptions={
        clinicsList && clinicsList.loading ? (
          <option value=''>Loading</option>
        ) : (
          <Fragment>
            <option value=''>Show all users</option>
            {clinicsList && clinicsList.clinics && clinicsList.clinics.data && clinicsList.clinics.data.data && clinicsList.clinics.data.data.length > 0 ? (
              clinicsList.clinics.data.data.map(clinic => (
                <option value={clinic._id} key={clinic._id}>
                  {clinic.name}
                </option>
              ))
            ) : (
              <option value=''>No clinics found</option>
            )}
          </Fragment>
        )
      }
      dropdownOnChange={e => {
        setSelectedClient(e.target.value);
        setPageNumber(1);
      }}
      dropdownFilter2={
        userLogin &&
          userLogin.userInfo &&
          userLogin.userInfo.data &&
          userLogin.userInfo.data.user &&
          (userLogin.userInfo.data.user.role === 'admin' || userLogin.userInfo.data.user.role === 'fileAdmin')
          ? true
          : false
      }
      dropdownName2='select-role'
      dropdownOptions2={
        <Fragment>
          <option value=''>Show all roles</option>
          <option value='fileUser'>File User</option>
          <option value='fileAdmin'>File Admin</option>
          <option value='clientAdmin'>Client Admin</option>
          <option value='fileUserOnlyDownload'>File User (Read Only)</option>
        </Fragment>
      }
      dropdownOnChange2={e => {
        setSelectedRole(e.target.value);
        setPageNumber(1);
      }}
    >
      <UserListTable
        userLogin={userLogin}
        usersList={usersList}
        searchKey={searchKey}
        refreshUserData={refreshUserData}
        handleToggleButtonClick={handleToggleButtonClick}
        handleResetPasswordButtonClick={handleResetPasswordButtonClick}
        handleEditUserButtonClick={handleEditUserButtonClick}
        handleDeleteUserButtonClick={handleDeleteUserButtonClick}
        loggedinUserRole={userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role}
      />

      <div className='table__footer'>
        {/* <p className='table__footer_text'>
					Showing {startValue} - {endValue} results out of {totalPageSize} users
				</p> */}
        <p />
        <Pagination onPageChange={handlePageChange} rowsPerPage={pageLimit} totalPageSize={totalPageSize} pageNumber={pageNumber} />
      </div>

      {/* Add User Modal */}
      <AddUserModal
        addUserModal={addUserModal}
        handleAddModalClose={handleAddModalClose}
        handleAddUser={handleAddUser}
        handleAddFolder={handleAddFolder}
        createUserLoading={createNewUser.loading}
        updateUserLoading={updateUser.loading}
        role={userLogin && userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user.role}
        totalSize={
          clinicTotalUsers && clinicTotalUsers.customClient
            ? clinicTotalUsers.maxClientStorage
            : clinicTotalUsers.package && clinicTotalUsers.package.metadata && clinicTotalUsers.package.metadata.space
        }
        data={{
          userName,
          setUserName,
          userEmail,
          setUserEmail,
          userPhone,
          setUserPhone,
          userAddress,
          setUserAddress,
          userRole,
          setUserRole,
          userSpace,
          setUserSpace,
          userPassword,
          setUserPassword,
          userClinic,
          setUserClinic,
          userFolders,
          clinicsList,
          foldersList: foldersLevelList,
          editUserData,
        }}
      />

      <UserQuotaReachedModal maxUserQuotaModal={maxUserQuotaModal} handleMaxUserQuotaModalClose={() => setMaxUserQuotaModal(false)} />

      {/* Toggle User Modal */}
      <ToggleUserActive
        toggleUserModal={toggleUserModal}
        deleteUserData={deleteUserData}
        loading={updateUserStatus && updateUserStatus.loading}
        handleToggleModalClose={handleToggleModalClose}
        handleUserToggle={handleUserToggle}
      />

      {/* Reset Password modal */}
      <ResetPasswordModal
        resetPasswordModal={resetPasswordModal}
        loading={resetUserPassword && resetUserPassword.loading}
        handleResetPassword={handleResetPassword}
        handleResetPasswordModalClose={handleResetPasswordModalClose}
        data={{
          resetPassword,
          setResetPassword,
          resetPasswordConfirm,
          setResetPasswordConfirm,
          resetPasswordData,
        }}
      />

      {/* Delete User Modal */}
      <DeleteUserModal
        deleteUserModal={deleteUserModal}
        loading={deleteUser && deleteUser.loading}
        handleDeleteUser={handleDeleteUser}
        handleDeleteUserModalClose={handleDeleteUserModalClose}
        data={{ deleteEmail, setDeleteEmail, deleteUserData }}
      />
    </Content>
  );
};

export default UsersScreen;
