import React from 'react';
import Table from '../../components/Table';
import NoContent from '../../components/NoContent';
import TableSkeleton from '../../components/Skeletons/TableSkeleton';

const UserListTable = ({
  userLogin,
  usersList,
  searchKey,
  loggedinUserRole,
  refreshUserData,
  handleToggleButtonClick,
  handleResetPasswordButtonClick,
  handleEditUserButtonClick,
  handleDeleteUserButtonClick,
}) => {

  return (
    <Table
      thead={
        <tr>
          <th />
          <th>Name</th>
          <th>E-mail</th>
          <th>Phone</th>
          <th>Address</th>
          {loggedinUserRole === 'admin' && <th>Client</th>}
          <th>Role</th>
          <th>Allocated Space</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      }
      tbody={
        usersList && usersList.loading ? (
          <TableSkeleton rows={loggedinUserRole === 'admin' ? 10 : 9} />
        ) : usersList &&
          usersList.userInfo &&
          usersList.userInfo.status === 'success' &&
          usersList.userInfo.data &&
          usersList.userInfo.data.data &&
          usersList.userInfo.data.data.length > 0 ? (
          usersList.userInfo.data.data
            // .filter(user => user.name.toLowerCase().includes(searchKey.toLowerCase()))
            .map(user => (
              <tr key={user._id}>
                <td className='table__profile_container'>
                  <img src='/assets/user.png' alt={user.name} className='table__profile_image' />
                </td>
                <td className='table__row_title'>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.address && user.address.length > 20 ? user.address.slice(0, 20) + '...' : user.address}</td>
                {loggedinUserRole === 'admin' && <td>{user?.clinic?.name ? user?.clinic?.name : '-'}</td>}
                <td>
                  {user.role && user.role === 'fileUserOnlyDownload'
                    ? 'File User (Read Only)'
                    : user.role.startsWith('file')
                      ? 'File ' + user.role.split('file')[1]
                      : user.role === 'clientAdmin'
                        ? 'Client Admin'
                        : user.role.slice(0, 1).toUpperCase() + user.role.slice(1)}
                </td>
                <td>{user.isUserUnderStoragePlan ? user.maxStorageAllowed.toFixed(2) + ' GB' : '-'}</td>
                <td>
                  <div className='flex'>
                    {user.active ? (
                      <p className='table__status'>
                        <span>•</span> Active
                      </p>
                    ) : (
                      <p className='table__status_deactive'>
                        <span>•</span> Deactive
                      </p>
                    )}
                    {userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user._id !== user._id && (
                      <label className='switch'>
                        <input id={user._id} checked={user.active} onChange={() => handleToggleButtonClick(user)} type='checkbox' className='checkbox' name='active' />
                        <span className='slider round' />
                      </label>
                    )}
                  </div>
                </td>
                {userLogin.userInfo && userLogin.userInfo.data && userLogin.userInfo.data.user && userLogin.userInfo.data.user._id !== user._id ? (
                  <td className='table__buttons'>
                    <button className='table__button' onClick={() => refreshUserData(user)}>
                      <svg className='table__button_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-refresh`} />
                      </svg>
                      <p className='table__button_label'>Refresh</p>
                    </button>
                    <button className='table__button' onClick={() => handleResetPasswordButtonClick(user)}>
                      <svg className='table__button_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
                      </svg>
                      <p className='table__button_label'>Reset Password</p>
                    </button>
                    <button className='table__button' onClick={() => handleEditUserButtonClick(user)}>
                      <svg className='table__button_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-edit`} />
                      </svg>
                      <p className='table__button_label'>Edit user</p>
                    </button>
                    <button className='table__button_delete' onClick={() => handleDeleteUserButtonClick(user)}>
                      <svg className='table__button_delete_icon'>
                        <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
                      </svg>
                      <p className='table__button_label'>Delete user</p>
                    </button>
                  </td>
                ) : (
                  <td className='table__buttons'></td>
                )}
              </tr>
            ))
        ) : (
          <tr className='noContent_table_row'>
            <td colSpan={8}>
              <NoContent />
            </td>
          </tr>
        )
      }
    />
  );
};

export default UserListTable;
