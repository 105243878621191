import React from 'react';
import Style from './InviteUserInput.module.css'

const InviteUserInput = ({ email }) => {
    return (
        <div className={Style.container}>
            <span className={Style.inputLabel}>{email ? 'Email' : 'Phone No'}</span>
            <div className={Style.inputContainer}>
                <svg className={email ? Style.inputEmailIcon : Style.inputIcon}>
                    <use xlinkHref={`/assets/sprite.svg#${email ? 'icon-input-email' : 'icon-input-phone'}`} />
                </svg>
                <input type="text" />
            </div>
        </div>
    )
}

export default InviteUserInput
