import React, { useCallback, useEffect, useState } from "react";
import styles from "./UserGroupAddCard.module.css";
import PressableButton from "../../../components/NewButton";
import ConfirmationModal from "./ConfirmationModal";

const UserGroupInforCard = ({
	role,
	loading,
	duration,
	onClick,
	isChecked,
	status,
	setIsChecked,
	name,
	email,
	handleCheckboxToggle,
	adminControl,
	id,
	searchResults,
	handleRemoveUser,
	setSearchResults,
}) => {
	const [isLoading, setLoading] = useState(loading);
	const [swipedOut, setSwipedOut] = useState(false);
	const [removeUserConfirmModal, setRemoveUserConfirmModal] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, duration);

		return () => clearTimeout(timer);
	}, [duration]);

	const removeHandler = useCallback(() => {
		onClick();
		setRemoveUserConfirmModal(true);
	}, [onClick]);

	const onRemove = useCallback(() => {
		setTimeout(() => setSwipedOut(true), 1000);
		setRemoveUserConfirmModal(false);
		handleRemoveUser();
	}, [handleRemoveUser]);

	return (
		<>
			<div
				key={id}
				style={status === "pending" ? { opacity: "60%" } : null}
				className={`${styles.card} ${
					swipedOut ? styles.swipedOut : ""
				}`}
			>
				{isLoading ? (
					<div className={`${styles.cards}`}>
						<div
							className={`${styles.card_title} ${styles.loading}`}
						></div>
					</div>
				) : (
					<>
						<div className={styles.chatLists_user_container}>
							<div className={styles.chatLists_user_imageBox}>
								<img
									src="/assets/indviual.png"
									alt="User"
									className={styles.chatLists_user_image}
								/>
							</div>
						</div>
						<div className={styles.radioContainer}>
							<div className={styles.nameContainer}>
								<span
									style={
										status === "pending"
											? { color: "grey" }
											: null
									}
									className={styles.userName}
								>
									{name}
								</span>
								<span className={styles.emailName}>
									{email}
								</span>
							</div>
							{role === "user" &&
								status === "accepted" &&
								adminControl && (
									<PressableButton
										name={"Remove"}
										style={{
											borderRadius: "32px",
											fontSize: "11px",
											padding: "6px 16px",
										}}
										onClick={removeHandler}
									/>
								)}
							{role === "admin" && (
								<span className={styles.adminText}>Admin</span>
							)}
							{status === "pending" && (
								<span className={styles.adminText}>
									Pending
								</span>
							)}
						</div>
					</>
				)}
				<ConfirmationModal
					showModal={removeUserConfirmModal}
					handleRemoveUser={onRemove}
					message={`Are you sure you want to remove '${name}' from group?`}
					buttonLabel={"Remove User"}
					setShowModal={setRemoveUserConfirmModal}
				/>
			</div>
		</>
	);
};

export default UserGroupInforCard;
