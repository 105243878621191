import React from 'react';
import classes from './SelectBoxButton.module.css';

const SelectBox = ({ buttons, value }) => {
  return (
    <div className={classes.select_box}>
      {buttons &&
        buttons.length > 0 &&
        buttons.map((btn, index) => (
          <button
            key={index}
            type='button'
            onClick={btn.onClick}
            className={
              value === btn.value
                ? classes.select_box_btn_selected
                : classes.select_box_btn
            }
          >
            {btn.label}
          </button>
        ))}
    </div>
  );
};

export default SelectBox;
