import React from 'react';
import ChatStyles from '../../screens/ChatScreenNew/Chat.module.css';
import SkeletonStyles from './Skeletons.module.css';

const PendingChatUserSkeleton = () => {
	const data = [ 1, 2, 3, 4, 5 ];
	return (
		data &&
		data.map((d) => (
			<div className={ChatStyles.chatLists_user} key={d}>
				<div className={ChatStyles.chatLists_user_data}>
					<div className={ChatStyles.chatLists_user_imageBox}>
						<div className={[ SkeletonStyles.chatLists_user_image, SkeletonStyles.skeleton ].join(' ')} />
					</div>
					<div>
						<div className={[ SkeletonStyles.chatLists_user_name, SkeletonStyles.skeleton ].join(' ')} />

						<div className={ChatStyles.chatLists_user_req_btns}>
							<div
								className={[ SkeletonStyles.chatLists_user_msg, SkeletonStyles.skeleton ].join(' ')}
								style={{ width: '5rem' }}
							/>
							<div
								className={[ SkeletonStyles.chatLists_user_msg, SkeletonStyles.skeleton ].join(' ')}
								style={{ width: '5rem' }}
							/>
						</div>
					</div>
				</div>
			</div>
		))
	);
};

export default PendingChatUserSkeleton;
