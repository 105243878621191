import React from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import classes from './WebsiteForms.module.css';
import FormData from './FormData';
import DownloadFormModal from './DownloadFormModal';
import { getFormTitle } from '.';

const UserCard = ({ data, onDelete, onSelect, selected }) => {
  const { toPDF, targetRef } = usePDF({ filename: `${data.type} (${data.from}).pdf`, page: { margin: Margin.NONE } });

  const [formTime, setFormTime] = React.useState('');
  const [showAttachmentWarning, setShowAttachmentWarning] = React.useState(false);

  React.useEffect(() => {
    const emailDate =
      new Date(data.createdAt).getDate() === new Date(Date.now()).getDate()
        ? 'Today ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')
        : new Date(data.createdAt).getDate() === new Date(Date.now()).getDate() - 1
        ? 'Yesterday ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')
        : new Date(data.createdAt).toLocaleDateString() + ' ' + new Date(data.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

    setFormTime(emailDate);
  }, [data]);

  return (
    <>
      <div className={selected ? classes.user_card_selected : classes.user_card} onClick={onSelect}>
        <p className={classes.user_card_date}>{formTime}</p>
        <div className={classes.user_card_content}>
          <div className={classes.user_card_icon_box}>
            <svg className={classes.user_card_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-user-circle-o`} />
            </svg>
          </div>
          <div className={classes.user_card_data}>
            <h4 className={classes.user_card_heading}>{getFormTitle(data.type, data.agent)}</h4>
            <p className={classes.user_card_user}>{data.from}</p>
          </div>
        </div>

        <div className={classes.user_card_btns}>
          {data.isAttachment ? (
            <svg className={classes.user_card_attchment_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-attachment`} />
            </svg>
          ) : null}
          <button
            type='button'
            className={classes.user_card_button}
            onClick={e => {
              e.stopPropagation();
              if (data.isAttachment) setShowAttachmentWarning(true);
              else toPDF();
            }}
          >
            <svg className={classes.user_card_button_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-download_pdf`} />
            </svg>
          </button>
          <button
            type='button'
            className={classes.user_card_button}
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <svg className={classes.user_card_button_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-delete`} />
            </svg>
          </button>
        </div>

        <div style={{ opacity: '0', position: 'absolute', top: '-300vh', left: '-100vw', zIndex: '-1' }}>
          <div ref={targetRef} style={{ width: '100vw', minHeight: '100vh', backgroundColor: '#f1f1f1', padding: '2rem' }}>
            <FormData noIcon data={data} contentStyle={{ height: '100%' }} />
          </div>
        </div>
      </div>
      {showAttachmentWarning ? (
        <DownloadFormModal
          show={showAttachmentWarning}
          selectedForm={data}
          handleDownload={() => {
            toPDF();
            setShowAttachmentWarning(false);
          }}
          handleCancel={() => setShowAttachmentWarning(false)}
        />
      ) : null}
    </>
  );
};

export default UserCard;
