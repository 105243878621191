import React from 'react';
import classes from './VideoTranscript.module.css';

const TranscriptCard = ({
  index,
  date,
  userOne,
  userTwo,
  onButtonClick,
  onDeleteTranscript,
}) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = new Date(date).toLocaleTimeString('en-US', {
    minute: 'numeric',
    hour: 'numeric',
  });

  return (
    <div className={classes.transcriptCard}>
      <div className={classes.transcriptCard_title}>
        <h3>Transcript {index + 1}</h3>
        <p>{formattedDate + ' ' + formattedTime}</p>
      </div>
      <h2 className={classes.transcriptCard_content}>
        Call between {userOne} and {userTwo}
      </h2>
      <div className={classes.transcriptCard_btns}>
        <button
          className={classes.transcriptCard_details}
          onClick={onButtonClick}
        >
          Transcript Details
        </button>
        <button className='table__button_delete' onClick={onDeleteTranscript}>
          <svg className='table__button_delete_icon'>
            <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TranscriptCard;
