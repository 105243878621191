import React, { useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSection';
import InputsSectionColumn from '../../components/Modal/InputsSectionColumn';
import InputBox from '../../components/Modal/InputBox';
import ModalButtons from '../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../components/Skeletons/AddUserSkeleton';

import TextBox from '../../components/Modal/InputBox/TextBox.module.css';

const AddClinicModal = ({ addClinicModal, handleAddModalClose, handleAddClinic, createClinicLoading, updateClinicLoading, data }) => {
  const {
    clinicName,
    setClinicName,
    clinicEmail,
    setClinicEmail,
    clinicPhone,
    setClinicPhone,
    clinicAddress,
    setClinicAddress,
    clinicLogo,
    setClinicLogo,
    customClientData,
    setCustomClientData,
    editClinicData,
    clinicModules,
    setClinicModules,
  } = data;

  const [showAdminChatOnly, setShowAdminChatOnly] = useState(false);

  return (
    <Modal show={addClinicModal}>
      <ModalHeading heading={editClinicData && editClinicData._id ? 'Update Client Data' : 'Add New Client'} />
      {createClinicLoading || updateClinicLoading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <InputsSection>
            <InputBox
              label='Client Name'
              placeholder='eg, John&#39;s Company'
              value={clinicName.value}
              onChange={event => {
                setClinicName({ value: event.target.value, error: '' });
              }}
              errorMessage={clinicName.error}
            />
            <InputBox
              label='Client Email'
              placeholder='eg, johncompany@example.com'
              value={clinicEmail.value}
              onChange={event => {
                setClinicEmail({ value: event.target.value, error: '' });
              }}
              errorMessage={clinicEmail.error}
              style={{ width: '25rem' }}
            />
            <InputBox
              label='Client Phone'
              placeholder='eg, 123-456-789'
              value={clinicPhone.value}
              onChange={event => {
                setClinicPhone({ value: event.target.value, error: '' });
              }}
              errorMessage={clinicPhone.error}
            />
          </InputsSection>
          <InputsSection>
            <InputBox
              label='Client Address'
              placeholder='eg, 692 SW. 53rd St. Irwin, PA 15642'
              value={clinicAddress.value}
              onChange={event => {
                setClinicAddress({ value: event.target.value, error: '' });
              }}
              errorMessage={clinicAddress.error}
            />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <InputBox
                label='Client Logo (optional)'
                type='file'
                placeholder='Choose Logo'
                // value={clinicAddress.value}
                onChange={event => {
                  setClinicLogo({ value: event.target.files[0], error: '' });
                }}
                errorMessage={clinicLogo.error}
                style={{ width: '25rem' }}
              />
              {editClinicData && editClinicData.photo && (
                <a
                  href={process.env.REACT_APP_BASE_SOCKET_URL + '/' + editClinicData.photo}
                  target='__blank'
                  style={{
                    alignSelf: 'flex-end',
                    textDecoration: 'none',
                    color: 'var(--primary-color)',
                    fontWeight: '600',
                    fontSize: '1.1rem',
                  }}
                >
                  View Current Logo
                </a>
              )}
            </div>

            {(Object.keys(editClinicData).length === 0 || (editClinicData.customClient && editClinicData.customClientDetails)) && (
              <InputBox
                label='Allocate Space (in GB)'
                placeholder='eg, 100'
                value={customClientData.space.value}
                onChange={event => {
                  setCustomClientData({
                    ...customClientData,
                    space: { value: event.target.value, error: '' },
                  });
                }}
                errorMessage={customClientData.space.error}
              />
            )}
          </InputsSection>

          {(Object.keys(editClinicData).length === 0 || (editClinicData.customClient && editClinicData.customClientDetails)) && (
            <InputsSection>
              <InputBox
                label='Total Admin Users'
                placeholder='eg, 10'
                value={customClientData.adminUsers.value}
                onChange={event => {
                  setCustomClientData({
                    ...customClientData,
                    adminUsers: { value: event.target.value, error: '' },
                  });
                }}
                errorMessage={customClientData.adminUsers.error}
              />
              <InputBox
                label='Total File Users'
                placeholder='eg, 500'
                value={customClientData.downloadUsers.value}
                onChange={event => {
                  setCustomClientData({
                    ...customClientData,
                    downloadUsers: { value: event.target.value, error: '' },
                  });
                }}
                errorMessage={customClientData.downloadUsers.error}
              />
            </InputsSection>
          )}
          <InputsSectionColumn>
            <p className={TextBox.form_input__label}>Allowed Modules</p>
            <div className='flex' style={{ gap: '2rem' }}>
              <div className='flex'>
                <label htmlFor='client-chat'>Chat module</label>
                <label className='switch'>
                  <input
                    id='client-chat'
                    checked={clinicModules.chat}
                    onChange={e => {
                      setClinicModules({ ...clinicModules, chat: !clinicModules.chat });
                    }}
                    type='checkbox'
                    className='checkbox'
                    name='active'
                  />
                  <span className='slider round' />
                </label>
              </div>
              <div className='flex'>
                <label htmlFor='admin-only-chat'>Admins only Chat</label>
                <label className='switch'>
                  <input
                    id='admin-only-chat'
                    checked={clinicModules.adminOnlyChat}
                    onChange={() => setClinicModules({ ...clinicModules, adminOnlyChat: !clinicModules.adminOnlyChat })}
                    type='checkbox'
                    className='checkbox'
                    name='active'
                  />
                  <span className='slider round' />
                </label>
              </div>
              <div className='flex'>
                <label htmlFor='client-video-call'>Video Call module</label>
                <label className='switch'>
                  <input
                    id='client-video-call'
                    checked={clinicModules.videoCall}
                    onChange={() => setClinicModules({ ...clinicModules, videoCall: !clinicModules.videoCall })}
                    type='checkbox'
                    className='checkbox'
                    name='active'
                  />
                  <span className='slider round' />
                </label>
              </div>
              <div className='flex'>
                <label htmlFor='client-file-sharing'>File Exchange</label>
                <label className='switch'>
                  <input
                    id='client-file-sharing'
                    checked={clinicModules.fileSharing}
                    onChange={() => setClinicModules({ ...clinicModules, fileSharing: !clinicModules.fileSharing })}
                    type='checkbox'
                    className='checkbox'
                    name='active'
                  />
                  <span className='slider round' />
                </label>
              </div>
              <div className='flex'>
                <label htmlFor='client-file-sharing'>Website Forms</label>
                <label className='switch'>
                  <input
                    id='client-file-sharing'
                    checked={clinicModules.websiteForms}
                    onChange={() => setClinicModules({ ...clinicModules, websiteForms: !clinicModules.websiteForms })}
                    type='checkbox'
                    className='checkbox'
                    name='active'
                  />
                  <span className='slider round' />
                </label>
              </div>
            </div>
          </InputsSectionColumn>

          <ModalButtons
            submitButtonLabel={editClinicData && editClinicData._id ? 'Update Client' : 'Add Client'}
            onSubmit={handleAddClinic}
            cancelButtonLabel='Cancel'
            onCancel={handleAddModalClose}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddClinicModal;
