import React from 'react'
import styles from "./ButtonIcon.module.css"
const ButtonIcon = ({ style , onClick, icon}) => {
  return (
   <button className={styles.icon} onClick={onClick} style={style}>
    <svg width="15" height="15">
         <use xlinkHref={`/assets/sprite.svg#${icon}`} />
    </svg>
   </button>
  )
}

export default ButtonIcon