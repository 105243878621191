import React from 'react';
import FileExchangeStyles from '../FileExchangeScreen.module.css';

const AddFolderButton = ({ label, onClick }) => {
  return (
    <button className={FileExchangeStyles.addFolder} onClick={onClick}>
      <svg className={FileExchangeStyles.addFolderIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-plus`} />
      </svg>
      <h4 className={FileExchangeStyles.addFolderName}>{label}</h4>
    </button>
  );
};

export default AddFolderButton;
