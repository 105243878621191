import React from 'react';
import ModalForm from '../Modal/ModalForm';
import InputsSection from '../Modal/InputsSectionColumn';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const DeactivateUserModalSkeleton = () => {
	return (
		<ModalForm style={{ marginBottom: '2.5rem' }}>
			<InputsSection>
				<div className='flexCol'>
					<div
						className={[ SkeletonStyles.form_input__text, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '30rem' }}
					/>
					<div className={[ SkeletonStyles.form_input__text, SkeletonStyles.skeleton ].join(' ')} />
					<div
						className={[ SkeletonStyles.form_input__text, SkeletonStyles.skeleton ].join(' ')}
						style={{ width: '10rem' }}
					/>
				</div>
			</InputsSection>
			<ModalButtonsSkeleton />
		</ModalForm>
	);
};

export default DeactivateUserModalSkeleton;
