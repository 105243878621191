import React from 'react';

const SearchBox = ({ icon = 'search', placeholder = 'Search', value, onChange, style }) => {
	return (
		<div className='headerTextInput' style={style}>
			<svg className='headerTextInput_icon'>
				<use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
			</svg>
			<input type='text' className='headerTextBox' placeholder={placeholder} value={value} onChange={onChange} />
		</div>
	);
};

export default SearchBox;
