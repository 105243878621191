import React from 'react';
import { toPng } from 'html-to-image';
import classes from '../screens/SignPDF/SIgnPDF.module.css';
import moment from 'moment';

const SignatureArea = ({ signatureImage, setSignatureImage, handleSignPDF, signPDFLoading, myIPAddress }) => {
  const [signatureText, setSignatureTest] = React.useState('');
  const [currentFont, setCurrentFont] = React.useState('Qwitcher Grypen');
  const ref = React.useRef(null);

  const onButtonClick = React.useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then(dataUrl => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        // link.click();
        setSignatureImage(dataUrl);
      })
      .catch(err => {
        console.log(err);
      });
  }, [ref]);

  return (
    <div className={classes.sign}>
      <input
        type='text'
        name='sign'
        id='sign'
        className={classes.sign_input}
        placeholder='type your signature here...'
        value={signatureText}
        onChange={e => {
          setSignatureTest(e.target.value);
          setSignatureImage('');
        }}
      />
      <div className={classes.sign_image}>
        {signatureText && (
          <div ref={ref} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
            <div>
              <h5 style={{ fontSize: '1rem', fontWeight: 400, textTransform: 'uppercase', textAlign: 'center', width: '30rem' }}>Accepted and signed</h5>
              <p style={{ fontSize: '2rem', fontWeight: 500, textAlign: 'center' }}>{signatureText}</p>
            </div>
            <div>
              <h5 style={{ fontSize: '1rem', fontWeight: 400, textTransform: 'uppercase', textAlign: 'center', width: '30rem' }}>Accepted and signed by</h5>
              <p style={{ fontSize: '1.2rem', fontWeight: 500, textAlign: 'center' }}>{signatureText}</p>
            </div>
            <div>
              <h5 style={{ fontSize: '1rem', fontWeight: 400, textTransform: 'uppercase', textAlign: 'center', width: '30rem' }}>Accepted and signed on</h5>
              <p style={{ fontSize: '1.2rem', fontWeight: 500, textAlign: 'center' }}>
                {moment(new Date()).format('DD MMM YYYY')} at {moment(new Date()).format('hh:mm A')}
              </p>
            </div>
            <div>
              <h5 style={{ fontSize: '1rem', fontWeight: 400, textTransform: 'uppercase', textAlign: 'center', width: '15rem' }}>Ip address from signature location</h5>
              <p style={{ fontSize: '1.2rem', fontWeight: 500, textAlign: 'center' }}>{myIPAddress}</p>
            </div>
          </div>
        )}
        {/* {signatureText && <p style={{ fontFamily: currentFont }}>{signatureText}</p>} */}
      </div>
      {/* {signatureText && (
        <div className={classes.sign_styles}>
          <p className={classes.sign_styles_title}>Choose your signature style</p>
          <div>
            <button
              className={`${classes.sign_styles1} ${currentFont === 'Qwitcher Grypen' && classes.sign_styles_active}`}
              onClick={() => setCurrentFont('Qwitcher Grypen')}
            >
              {signatureText}
            </button>
            <button className={`${classes.sign_styles2} ${currentFont === 'Great Vibes' && classes.sign_styles_active}`} onClick={() => setCurrentFont('Great Vibes')}>
              {signatureText}
            </button>
            <button className={`${classes.sign_styles3} ${currentFont === 'Yellowtail' && classes.sign_styles_active}`} onClick={() => setCurrentFont('Yellowtail')}>
              {signatureText}
            </button>
            <button
              className={`${classes.sign_styles4} ${currentFont === 'Playwrite BE WAL' && classes.sign_styles_active}`}
              onClick={() => setCurrentFont('Playwrite BE WAL')}
            >
              {signatureText}
            </button>
            <button className={`${classes.sign_styles5} ${currentFont === 'Playwrite CU' && classes.sign_styles_active}`} onClick={() => setCurrentFont('Playwrite CU')}>
              {signatureText}
            </button>
            <button className={`${classes.sign_styles6} ${currentFont === 'Sacramento' && classes.sign_styles_active}`} onClick={() => setCurrentFont('Sacramento')}>
              {signatureText}
            </button>
          </div>
        </div>
      )} */}
      {signatureImage ? (
        <button onClick={handleSignPDF} disabled={signPDFLoading} className={classes.sign_generate}>
          Sign PDF
        </button>
      ) : (
        <button onClick={onButtonClick} disabled={!signatureText} className={classes.sign_generate}>
          Generate signature
        </button>
      )}
    </div>
  );
};

export default SignatureArea;
