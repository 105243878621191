import axios from 'axios';
// import { BASE_URL } from './ip';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllVideoNotesAction = (user) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    let uri = `${BASE_URL}/video-notes`;
    if (user) uri = `${BASE_URL}/video-notes?user=${user}`;

    const { data } = await axios.get(uri, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};

export const createVideoNotesAction =
  (formData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/video-notes`,
        formData,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    }
  };

export const updateVideoNotesAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.patch(
        `${BASE_URL}/video-notes/${id}`,
        formData,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    }
  };
export const deleteVideoNotesAction = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.delete(`${BASE_URL}/video-notes/${id}`, config);

    return { status: 'success' };
  } catch (err) {
    return err.response && err.response.data.message
      ? err.response.data.message
      : err.message;
  }
};
