import React from 'react';
import { Link } from 'react-router-dom';

const AdminSidebar = ({ currentMenu }) => {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link' style={{ marginTop: '2rem' }}>
          <img src='/assets/logo.png' alt='logo' className='navigation__logo' />
        </li>

        <li className='navigation__list--item'>
          <Link to='/' className={currentMenu === 'secure-files' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'secure-files' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-secure-file2`} />
            </svg>
            <span>
              Document
              <br /> Archive
            </span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/clients' className={currentMenu === 'clinics' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'clinics' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-clinic2`} />
            </svg>
            <span>Clients</span>
          </Link>
        </li>

        <li className='navigation__list--item'>
          <Link to='/users' className={currentMenu === 'users' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'users' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
            </svg>
            <span>Users</span>
          </Link>
        </li>

        {/* <li className='navigation__list--item'>
          <Link to='/packages' className={currentMenu === 'packages' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'packages' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-package`} />
            </svg>
            <span>Packages</span>
          </Link>
        </li> */}

        {/* <li className='navigation__list--item'>
          <Link to='/coupons' className={currentMenu === 'coupons' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'coupons' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ticket`} />
            </svg>
            <span>Coupons</span>
          </Link>
        </li> */}
        {/* <li className='navigation__list--item'>
          <Link to='/promo-codes' className={currentMenu === 'promoCodes' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'promoCodes' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ticket`} />
            </svg>
            <span>Promo Codes</span>
          </Link>
        </li> */}

        <li className='navigation__list--item'>
          <Link to='/audit-logs' className={currentMenu === 'logs' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'logs' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-document-text`} />
            </svg>
            <span>Audit Logs</span>
          </Link>
        </li>
      </ul>

      <div>
        <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
          <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
            <use xlinkHref={`/assets/sprite.svg#icon-user`} />
          </svg>
          <span>My Profile</span>
        </Link>

        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. Continia
        </p>
      </div>
      {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
    </nav>
  );
};

export default AdminSidebar;
