import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

const useShareFileForESign = () => {
  const { loading: shareForESignLoading, makeApiCall } = useApi({
    url: `/sign-pdf/shareDocument`,
    method: 'POST',
  });

  const shareForESign = async formData => {
    const { response, error } = await makeApiCall(formData);
    if (response?.status === 'success') {
      toast.success(response.msg);
      return response;
    }

    if (!response?.status === 'fail' && response?.msg) {
      toast.error(response.message);
      return response;
    }
    if (error) {
      toast.error(error.msg);
      return { status: 'fail' };
    }
  };

  return {
    shareForESignLoading,
    shareForESign,
  };
};

export default useShareFileForESign;
