import React from 'react';
import classes from '../Logs.module.css';

const TextBox = ({ label, icon, placeholder, value, onChange }) => {
  return (
    <div className={classes.filter_text_box}>
      <label htmlFor={label} className={classes.filter_text_box_label}>
        {label}
      </label>
      <div className={classes.filter_text_box_input}>
        <svg className={classes.filter_text_box_input_icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
        </svg>
        <input type='text' name={label} className={classes.filter_text_box_input_tag} placeholder={placeholder} value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default TextBox;
